@import 'app/assets/styles/css-assets';

.navigation_link {
    .new-post {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;
        background: $primary-color;
        @include border-radius($radius);
        text-decoration: none;
        font-size: $fs-14;
        &:hover:active {
            text-decoration: none;
        }
        .more-options {
            margin-top: 1px;
        }
        .ms-Dropdown {
            padding: 0 12px;
            .ms-Dropdown-title {
                border: none;
                padding-left: 2px;
                padding-right: 20px;
                background-color: transparent;
                @include border-radius($radius);
                color: $white;
                > .dropdown-placeholder {
                    display: flex;
                }
                i {
                    margin-right: 8px;
                }
            }
            .ms-Dropdown-caretDownWrapper {
                right: 12px;
                i {
                    color: $white;
                }
            }
        }
    }
}

.email-detail-modal {
    .modal-body {
        .text-field {
            margin: 5px 0px;
        }
        .text-field:nth-last-child(2) {
            margin-bottom: 15px;
        }
        .label {
            margin-right: 10px;
            font-weight: 600;
        }
    }
}

.email-view {
    .tv-body {
        .tv-row {
            cursor: default !important;
            .ms-Dropdown .ms-Dropdown-title {
                border: none;
                padding: 0px 5px;
                @include border-radius($radius);
            }
            .ms-Dropdown-caretDownWrapper {
                display: none;
            }
            .ms-Dropdown {
                &::after {
                    @include border-radius($radius/2);
                }
                &:focus::after {
                    border: none !important;
                }
            }
        }
    }
}
