@import '../../../../../../assets/styles/css-assets.scss';

.aging-filter {
    padding-left: 1rem;
    .dropdown-cont {
        min-width: 12rem;
    }
    .dropdown-item {
        display:flex;
        align-items: center;
        flex-direction: row;
        border-radius: 8px;
        &.selected {
            label {
                font-weight: 600;
                color: $gray-100;
            }
        }
        .aging-icon {
            display: block;
            width: 2.5rem;
            height: 0.9375rem;
        }
        .icon-1d-7d {
            background: url(app/static/icons/0hr-12hrs-icon.svg) no-repeat;
        }
        .icon-8d-30d {
            background: url(app/static/icons/1d-7d-icon.svg) no-repeat;
        }
        .icon-31d-plus {
            background: url(app/static/icons/1w-4w-icon.svg) no-repeat;
        }
        .icon-1m-plus {
            background: url(app/static/icons/1m+-icon.svg) no-repeat;
        }
    }
}

.aging-picker-box {
    width: 150px;
    height: 38px;
    padding-left: 4px;
    border: 1px solid gray;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow:hidden;
    position: relative;
}

.lb-picker-title {
    cursor: pointer;
    font-size: $fs-14;
    color: $primary-color;
    i {
        font-weight: 600;
        font-size: $fs-10;
        position: absolute;
        right: -1px;
    }
    .placeholder-label {
        display: inline-block;
        max-width: 6.25rem;
        margin-right: 1.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .static-label {
        font-size: 0.875rem;
        font-weight: 600;
        color: $gray-100;
    }
}