@import '../../assets/styles/css-assets.scss';
@import './animations.scss';

.collapsible-Panel {
    overflow: hidden;
    max-height: 12rem;
    transition: max-height 1s ease;
    @include border-radius($radius);
    &.minimized {
        max-height: 0;
        overflow: hidden;
    }
}

.calendar-head {
    display: flex;
    margin: 0.5rem 0 1rem;

    .title {
        font-size: $fs-18;
    }

    .cal-container {
        margin-left: auto;
        display: flex;

        .cal_icon {
            i {
                color: $gray-100;
            }
        }

        .date-picker-block {
            display: flex;
            border: 1px solid $blue-700;
            @include border-radius($radius);
            overflow: hidden;

            .ms-DatePicker-event--without-label {
                display: none;
            }

            .date-picker-wrapper {
                overflow: hidden;
                &:not(:last-child) {
                    border-right: 1px solid $blue-700;
                }

                .ms-DatePicker {
                    .ms-TextField-fieldGroup {
                        @include border-radius(0);
                        border: none;
                        input {
                            &::placeholder {
                                color: $gray-300;
                            }
                        }
                    }
                }
            }
        }

        .cal-tabs {
            @include border-radius($radius);
            overflow: hidden;
            border: 1px solid $blue-700;
            margin-left: 0.5rem;

            .cal-item {
                margin: 0;
                border: none;
                color: $gray-200;
                &:not(:last-child) {
                    border-right: 1px solid $blue-700;
                }

                &.active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }
}

.cards-container {
    display: flex;
    justify-content: space-between;
    @include border-radius($radius);
    .menu-card {
        min-height: 103px;
        @include border-radius($radius);
        border: 1px solid rgba($primary-color, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-200;
        cursor: pointer;
        width: 32%;

        &.active {
            background: linear-gradient(260.63deg, $primary-color 5.91%, #3e9fe9 46.33%, $primary-color 94.09%);
            box-shadow: 0px 4px 20px rgba(0, 120, 212, 0.5);
            color: $white;
            animation: fadeIn 1s ease 0s 1 normal forwards;
        }

        .icon {
            font-size: $fs-32;
        }

        .mc-details {
            margin-left: 0.7rem;
            align-items: flex-start;

            .count {
                display: block;
                font-size: $fs-32;
                font-weight: 600;
            }
            .label {
                display: block;
                font-size: $fs-16;
            }
        }
    }
}

.cards-toggle {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    button {
        display: flex;
        align-items: center;
        background-color: rgba($primary-color, 0.1);
        color: $primary-color;
        @include border-radius(44px);
        padding: 2px 3rem 0;
        box-sizing: border-box;
        i {
            font-weight: 600;
            &:hover {
                text-decoration: none;
            }

            &:active {
                text-decoration: none;
            }
        }

        &:hover {
            text-decoration: none !important;
        }

        &:active {
            text-decoration: none;
        }
    }
}
