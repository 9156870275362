@import 'app/assets/styles/css-assets';

.save-folder-header {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    margin-left:6%;
    display: flex;
    align-items: flex-end;

    color: #000000;
}

.addFolderBackArrow {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: #000000;
}

.backArrowStyles {
    width: 110px;
    height:40px;
    display:flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-left:25px;
}

.textField-btn-container {
    width:600px;
    margin-left:20px;
    margin-top:20px;
}

.textfield-folder {
    color: #C7E0F4;
    background: $white;
    @include border-radius($radius);
    width: 564px;
    margin-left:20px;
    .ms-TextField-wrapper {
        
        .ms-TextField-fieldGroup {
            width: 550px !important;
            margin-top: 10px;
            border: 1px solid #C7E0F4;
            box-sizing: border-box;
            height: 40px !important;
            padding: 0;

            input {
                padding-left: 8px;
            }
        }
    
    }
}

.addFolderHeader {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:98%;
}

.folder-modal-header{
    background-color: #0078D4;
    margin-top:10px;
    width: 640px;
    height: 54px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom:20px;
    margin-left:3px;
    margin-right:3px;
    margin-top:3px;
    text-align: center;
    display:flex;
}

.folder-modal-footer {
    width: 500px;
    height:60px;
    margin-top:30px;
    margin-left:20px;
}