@import '../../../../assets/styles/css-assets.scss';

button {
    @include border-radius($radius);
    font-size: $fs-12;
    padding: 0rem 0.5rem;
    cursor: pointer;
}

button.primary {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $white;
}

.no-association-content {
    background-color: $gray-700;
    border-radius: $radius;
    height: 350px;
    width: 954px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 57px;

    .ms-Image {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .no-association-text {
        margin-top: 30px;
    }

    .primary-associate-button {
        margin-top: 20px;
    }
}

.manage-request-cont {
    align-items: center;

    .row-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .col-flex-box {
        display: flex;
        flex-direction: column;
    }
    .justify-sb {
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }
    .paginate-cont {
        display: flex;
        justify-content: center;
        margin-top: 1.875rem;
        .pagination {
            .active {
                background: $primary-color;
                color: $white;
                @include border-radius($radius/2);
            }
            li.next,
            li.previous {
                font-weight: normal !important;
                @include border-radius($radius/2);
            }
        }
    }
    .table-cont {
        margin-top: $fs-10;
        @include border-radius($radius);
        animation: fadeIn 1s ease-in;
        min-height: 25rem;

        .team-menu-container {
            @include border-radius($radius);
            overflow: hidden;
        }

        .fadeout {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease;
        }
        .request-notification {
            .notify-msg {
                color: $green-100;
            }
        }
        .notify-msg {
            font-style: italic;
        }
        .text-bold {
            font-weight: 600;
        }

        .is-checked {
            .ms-Checkbox-checkbox {
                background: $white;
                border: 1px solid $primary-color !important;

                .ms-Checkbox-checkmark {
                    color: $primary-color;
                }
            }
        }
        .ms-Checkbox {
            span {
                margin-left: 0.5rem;
            }
            .ms-Checkbox-checkbox {
                @include border-radius(0.25rem);
                border: 1px solid $gray-400;
            }
        }

        .pr-table-name {
            margin-bottom: 1rem;
        }

        .cr-panel-wrapper {
            margin-right: -1.5rem;
            margin-left: -1.5rem;
            margin-top: 2rem;
        }

        .txt-link {
            font-size: $fs-14;
            line-height: 18px;
            color: $blue-400;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 1rem;
        }
    }
    .association-table-cont {
        .k-grid {
            border-right-style: none;
            border-left-style: none;
        }
        .grid-cont {
            margin-right: -1.5rem;
            margin-left: -1.5rem;

            .k-master-row {
                cursor: default !important;
                &:hover {
                    background-color: white !important;
                }
            }
        }
    }

    .ms-TextField-fieldGroup {
        padding: 0 15px;
        border: hidden !important;
        min-height: 35px;
        width: 210px;
        input {
            &::placeholder {
                color: $gray-400;
            }
        }
        &::after {
            border: hidden;
        }
    }
    .active {
        .ms-TextField-fieldGroup {
            border-radius: 8px 8px 0 0 !important;
            border: hidden !important;

            &::after {
                border-radius: 8px 8px 0 0 !important;

                border: hidden;
                animation: none;
            }
            &:focus-within {
                border: none;
                animation: none;
            }
        }
    }

    .head-col-left {
        align-self: center;
    }
}
