@import '../../../../assets/styles/css-assets.scss';
@import '../animations.scss';
.manage-request-detailed-container {
    color: $gray-100;
    .list-panel-container {
        @include border-radius($radius);
        box-sizing: border-box;
        height: 100%;
        .ms-Shimmer-container {
            margin-bottom: 0.5rem;
        }
        .list-panel-top-pane {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem;
            padding-bottom: 1rem;
            box-sizing: border-box;
            .list-view-toggle {
                display: flex;
                margin-right: 0.5rem;
                span {
                    margin: 0 0.5rem;
                    font-size: $fs-12;
                    &.active {
                        color: $primary-color;
                    }
                }
                .lv-toggle-wrap {
                    display: flex;
                    background: #edebe9;
                    border-radius: 40px;
                    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.05);
                    border: 1px solid #ddd;
                    color: $gray-300;
                    .lv-toggle {
                        padding: 0.5rem 0.5rem;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                    .handle-left {
                        border-radius: 40px;
                        background: #fff;
                        box-shadow: 0px 0px 6px #00000014;
                        color: $primary-color;
                        cursor: pointer;
                        animation: toggleLeft 0.2s ease 0s 1 normal forwards;
                    }
                    .handle-right {
                        border-radius: 40px;
                        background: #fff;
                        box-shadow: 0px 0px 6px #00000014;
                        color: $primary-color;
                        cursor: pointer;
                        animation: toggleRight 0.2s ease 0s 1 normal forwards;
                        padding: 0.5rem 0.8rem;
                        box-sizing: border-box;
                    }
                }
            }
            .status-filter-cont {
                span {
                    margin: auto 0;
                    color: $gray-300;
                    font-size: $fs-12;
                }
                .checkbox-cont {
                    background-color: $white;
                    @include border-radius($fs-16);
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0.2rem 0.6rem;
                    box-sizing: border-box;
                    .ms-Checkbox-checkbox {
                        height: 16px;
                        width: 16px;
                    }
                    .ms-Checkbox-text {
                        margin-left: 0.1rem;
                        line-height: 16px;
                    }
                    .is-checked {
                        span {
                            color: $primary-color;
                        }
                    }
                }
                .is-checked {
                    .ms-Checkbox-checkbox {
                        background: $white;
                        border: 1px solid $primary-color !important;
                        .ms-Checkbox-checkmark {
                            color: $primary-color;
                        }
                    }
                }
                .ms-Checkbox {
                    margin: 0.3rem 0;
                    span {
                        margin-left: 0.5rem;
                    }
                    .ms-Checkbox-checkbox {
                        @include border-radius(0.25rem);
                        border: 1px solid $gray-400;
                    }
                }
            }
        }
        .scroll-panel {
            height: 100%;
            overflow-y: scroll;
            padding-right: 0.25rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .list-item {
            //box-shadow: 2px 2px 2px rgba(96, 94, 92, 0.13);
            margin-bottom: 0.8rem;
            @include border-radius($radius);
            padding: 1rem;
            box-sizing: border-box;
            margin-right: 0.3rem;
            cursor: pointer;
            background-color: $white;
            border: 1px solid rgba(0, 120, 212, 0.1);
            .quick-tag {
                @include border-radius(2rem);
                color: $white;
                padding: 0.1rem 0.6rem;
                font-size: $fs-10;
            }
            .limit-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 7.5rem;
                display: inline-block;
            }
            .tag-type-a {
                background-color: $purple-100;
            }
            .tag-type-b {
                background-color: $green-200;
            }
            .tag-type-c {
                background-color: $yellow-100;
            }
            .tag-type-d {
                background-color: $blue-600;
            }
            .tag-type-e {
                background-color: $gray-250;
            }
            .tag-type-f {
                background-color: $blue-350;
            }
            .tag-type-g {
                background-color: $cyan-100;
            }
            .list-title {
                display: flex;
                margin-bottom: 0.3rem;
                span {
                    font-weight: 600;
                    margin-right: 0.5rem;
                }
                .link-list-add {
                    font-size: $fs-12;
                    margin-left: auto;
                    text-decoration: underline;
                }
                .icon {
                    color: $yellow-100;
                }
            }
            .text-normal {
                .title-field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.25rem;
                    max-height: 2rem;
                    -webkit-line-clamp: 1;
                    /* Write the number of 
                                              lines you want to be 
                                              displayed */
                    -webkit-box-orient: vertical;
                    color: $gray-100;
                }
            }
            .list-desc {
                span {
                    font-weight: 600;
                }
                &.text-normal {
                    span {
                        font-weight: normal;
                    }
                }
            }
            .list-item-detail-wrap {
                display: flex;
                .list-item-col {
                    &:first-child {
                        min-width: 40%;
                        max-width: 40%;
                    }
                    &:last-child {
                        text-align: right;
                        flex-grow: 1;
                    }
                    .list-head {
                        margin-top: 0.5rem;
                        span {
                            font-size: $fs-11;
                        }
                    }
                    .list-value {
                        span {
                            font-size: $fs-14;
                        }
                        .ms-Link {
                            font-size: $fs-14;
                            text-decoration: underline;
                        }
                    }
                }

                .app-id-value {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            &.active {
                background-color: $gray-800;
                border-left: 3px solid $primary-color;
                border-color: $primary-color;
                a {
                    color: $blue-350;
                }
            }
        }
        .ad-results-count-wrap {
            background: $gray-600;
            font-style: italic;
            margin: 0 0.5rem 0.5rem 0rem;
            @include border-radius(5px);
            padding: 0.2rem 1rem;
            box-sizing: border-box;
            font-size: $fs-14;
        }
    }
    .cust-name {
        margin-right:20px;
        word-wrap: break-word;
    }
}
