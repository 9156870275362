@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';
.unified-dashboard-container {
    .details-panel-container {
        background-color: $white;
        @include border-radius($radius);
        padding: 0.5rem 0rem;
        box-sizing: border-box;
        height: 100%;
        .scroll-panel {
            height: 100%;
            overflow-y: scroll;
            padding: 0 0rem;
            .no-data {
                text-align: center;
                padding-top: 2rem;
            }
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .details-panel-wrapper {
            border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
            @include border-radius($radius);
            overflow: hidden;
            margin-bottom: 0.1rem;
            min-height: 50rem;
            a {
                color: $primary-color;
            }
            .quick-tag {
                @include border-radius(2rem);
                color: $white;
                padding: 0.2rem 0.6rem;
                font-size: $fs-10;
            }
            .tag-type-a {
                background-color: $purple-100;
            }
            .tag-type-b {
                background-color: $gray-250;
            }
            .tag-type-c {
                background-color: $green-200;
            }
            .tag-type-d {
                background-color: $blue-600;
            }
            .tag-type-e {
                background-color: $red-100;
            }
            .dp-header {
                background-color: $gray-800;
                padding: 1rem 1.5rem;
                .dp-head-title {
                    margin-right: 0.5rem;
                    font-weight: 600;
                }
                .dp-head-desc {
                    margin: 0.5rem 0;
                    .title-field {
                        cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: 1.35rem;
                        max-height: 3rem;
                        -webkit-line-clamp: 2;
                        /* Write the number of
                                              lines you want to be
                                              displayed */
                        -webkit-box-orient: vertical;
                    }
                    span {
                        font-size: $fs-18;
                        font-weight: 600;
                    }
                }
                .dp-head-user {
                    color: $gray-200;
                    span {
                        font-size: $fs-12;
                    }
                }
                .dp-head-separator {
                    margin: 0 0.6rem;
                }
            }
            .dp-filter-panel {
                display: flex;
                align-items: center;
                padding: 1rem 1.5rem;
                box-sizing: border-box;
                border-bottom: 1px solid $blue-700;
                .dp-heading {
                    &.tab-heading {
                        font-size: $fs-14;
                        color: $primary-color;
                        border-bottom: 2px solid $primary-color;
                    }
                    font-size: $fs-16;
                    font-weight: 600;
                }
                .dp-filter-links {
                    margin-right: auto;
                    button {
                        color: $gray-200;
                        margin-right: 1rem;
                        &.active {
                            color: $primary-color;
                            text-decoration: underline;
                            padding-bottom: 2px;
                            animation: fadeIn 0.5s ease 0s 1 normal forwards;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .dp-filter-controls {
                    display: flex;
                    margin-left: auto;
                    align-items: center;
                    .filter-icon {
                        background: #eff6fc;
                        border: 1px solid #c7e0f4;
                        text-align: center;
                        margin-right: 1rem;
                        padding: 8px 12px 4px;
                        @include border-radius($radius);
                    }
                    .icon {
                        &.starred {
                            color: $yellow-100;
                        }
                    }
                    & > span {
                        margin-right: 0.5rem;
                    }
                    .add-cust-btn, .disabled-add-cust-btn {
                        color: $white;
                        @include border-radius($radius);
                        font-size: $fs-12;
                        padding: 0.5rem 1rem;
                        box-sizing: border-box;
                    }
                    .add-cust-btn {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                    }
                    .add-cust-label {
                        font-size: $fs-16;
                    }
                    .disabled-add-cust-btn {
                        border: 1px solid $gray-300;
                        background-color: $gray-300;
                    }
                    .attachments-icon-link {
                        display: flex;
                        align-items: center;
                        margin-right: 1rem;
                        padding: 0.5rem;
                        .attachments-icon-count {
                            margin-left: 0.2rem;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .dp-content {
                padding: 1rem 1.5rem;
                .dp-details {
                    animation: fadeIn 1s ease 0s 1 normal forwards;
                }
                .dp-content-details-tbl {
                    margin-bottom: 2rem;
                    width: 100%;
                    tr:nth-child(1) {
                        td {
                            font-size: $fs-12;
                            padding-right: 2rem;
                        }
                    }
                    tr:nth-child(2) {
                        td {
                            font-weight: 600;
                            padding-right: 2rem;
                        }
                    }
                    tbody {
                        td {
                            font-size: $fs-14;
                        }
                    }
                }
                .dp-progress-wrap {
                    @include border-radius(1.2rem);
                    min-width: 2rem;
                    min-height: 0.5rem;
                    display: inline-flex;
                    overflow: hidden;
                    .dp-progress {
                        display: inline-block;
                    }
                }
                .dp-content-title {
                    margin-bottom: 0.6rem;
                    span {
                        background-color: $gray-800;
                    }
                }
                .type-a {
                    background-color: rgba($color: $green-400, $alpha: 0.5);
                    .dp-progress {
                        background-color: $green-400;
                    }
                }
                .dp-content-text {
                    margin-bottom: 2rem;
                    font-size: $fs-14;
                }
                .dp-table {
                    font-size: $fs-12;
                    width: 100%;
                    margin-top: 0.6rem;
                    margin-bottom: 2rem;
                    @include border-radius($radius);
                    overflow: hidden;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.1);
                    border-collapse: collapse;
                    thead {
                        background-color: $gray-800;
                        td {
                            font-weight: 600;
                            white-space: nowrap;
                        }
                    }
                    td {
                        padding: 0.4rem 0.6rem;
                        box-sizing: border-box;
                    }
                }
                .impact-table {
                    tr {
                        td {
                            &:nth-child(6) {
                                text-align: right;
                            }
                            &:nth-child(7) {
                                text-align: right;
                            }
                        }
                    }
                }
                .textfield-wrap {
                    margin-top: 1rem;
                    textarea {
                        border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                        @include border-radius($radius);
                        font-size: $fs-14;
                        padding: 1rem;
                        width: 100%;
                        &::placeholder {
                            color: $gray-600;
                        }
                    }
                }
                .form-btn-wrap {
                    margin-top: 24px;
                    button {
                        @include border-radius($radius);
                        font-size: $fs-14;
                        padding: 1.1rem 1.5rem;
                        cursor: pointer;
                    }
                    button.primary {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                        color: $white;
                    }
                    button.default {
                        border: 1px solid $gray-300;
                        background-color: $white;
                        color: $gray-300;
                    }
                }
                .dp-related-requests {
                    animation: fadeIn 1s ease 0s 1 normal forwards;
                }
                .related-table {
                    font-size: $fs-12;
                    .ms-Link {
                        text-decoration: underline;
                    }
                    tr {
                        td {
                            &:nth-child(4) {
                                .quick-tag {
                                    width: 100%;
                                    text-align: center;
                                    display: inline-block;
                                }
                            }
                            &:nth-child(5) {
                                text-align: right;
                            }
                            &:nth-child(6) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            .comments-status-toggle-block {
                margin-bottom: 1rem;
                font-weight: 600;
                display: flex;
                & > span {
                    cursor: pointer;
                }
                .ms-Toggle {
                    margin: 0 0.5rem;
                    .ms-Toggle-background {
                        background: $primary-color;
                    }
                    .ms-Toggle-thumb {
                        background: $white;
                    }
                }
                .active {
                    color: $primary-color;
                }
            }
            .link-item {
                color: $primary-color;
                font-size: $fs-14;
                font-weight: 600;
            }
            .status-disp-container {
                background: rgba($color: $yellow-100, $alpha: 0.1);
                display: flex;
                padding: 0.5rem 1.5rem;
                box-sizing: border-box;
                .status-disp-label {
                    font-size: $fs-14;
                    font-weight: 600;
                    color: $primary-color;
                    padding-right: 0.7rem;
                    box-sizing: border-box;
                    min-width: 4rem;
                }
                .status-disp-desc {
                    .status-desc-text {
                        font-size: $fs-14;
                        font-weight: 400;
                        margin-bottom: 0.2rem;
                    }
                    .status-desc-sub-text {
                        font-size: $fs-10;
                        margin-bottom: 0.2rem;
                    }
                    .tagged-user {
                        font-weight: 600;
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
