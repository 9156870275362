@import 'app/assets/styles/css-assets';

.ms-Grid-col {
    .resource-center-container {
        padding: 30px;
        border: 1px solid $gray-600;
        box-sizing: border-box;
        @include border-radius($radius);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.1);
        background: $white;
        margin: 90px auto 0;
        width: 80%;

        .card-wrapper {
            position: relative;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
            a {
                display: block;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
                svg {
                    animation: whiteToGrayIcon 0.8s ease forwards;
                    @keyframes whiteToGrayIcon {
                        0% {
                            fill: $white;
                        }
                        100% {
                            fill: $gray-200;
                        }
                    }
                }
            }
            a,
            h5,
            .desc-txt {
                animation: whiteToGrayText 0.8s ease forwards;
                @keyframes whiteToGrayText {
                    0% {
                        color: $white;
                    }
                    100% {
                        color: $gray-200;
                    }
                }
            }
            .card-title {
                font-weight: 600;
                font-size: $fs-20;
                line-height: 28px;
                margin: 16px 0 5px;
                text-decoration: none;
            }

            &.bg-gray {
                @include border-radius($radius);
                padding: 23px;
                cursor: pointer;

                text-align: center;

                min-height: 145px;
                transition: all 0.3s ease-out;

                .icon-dis-hover {
                    display: none;
                }
                .icon-dis {
                    display: inline-block;
                }

                &:hover {
                    background-color: $primary-color;
                    transform: scale(1.05);

                    a,
                    h5,
                    .desc-txt {
                        animation: grayToWhiteText 0.3s ease forwards;
                        @keyframes grayToWhiteText {
                            0% {
                                color: $gray-200;
                            }
                            100% {
                                color: $white;
                            }
                        }
                    }
                    svg {
                        animation: grayToWhiteIcon 0.5s ease forwards;
                        @keyframes grayToWhiteIcon {
                            0% {
                                fill: $gray-200;
                            }
                            100% {
                                fill: $white;
                            }
                        }
                    }
                    .icon-dis {
                        display: none;
                    }
                    .icon-dis-hover {
                        display: inline-block;
                    }
                }
            }

            .card-list-box {
                h6 {
                    font-size: $fs-15;
                    line-height: 19px;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    font-weight: 600;
                    margin-top: 0;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        background: url(app/static/icons/list_icon.svg) no-repeat;
                        background-size: 32px 32px;
                        list-style: none;
                        padding-left: 40px;
                        margin-bottom: 10px;
                        min-height: 32px;
                        display: table;
                        &:hover {
                            padding-left: 42px;
                        }
                        .ms-TooltipHost {
                            display: table-cell;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .desc-txt {
                font-size: $fs-13;
                line-height: 18px;
                color: $gray-300;
            }
        }
    }
}
