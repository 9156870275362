@import 'app/assets/styles/css-assets';

.help-request-banner-container {
    ol {
        list-style: none;

        padding: 0;
        margin: 0 0 33px;
        li {
            display: inline-block;
            font-size: 14px;
            line-height: 19px;
            color: $gray-100;
            font-weight: 600;
            margin-left: 15px;
            position: relative;

            &::before {
                background: url(app/static/icons/right-arrow.svg) no-repeat;
                width: 5px;
                height: 10px;
                position: absolute;
                top: 7px;
                left: 0px;
                content: '';
            }

            &:first-child {
                margin: 0;
                &::before {
                    display: none;
                }
            }
            a {
                color: $gray-100;
                text-decoration: none;
                font-weight: 400;
            }
            span {
                padding-left: 15px;
            }
        }
    }

    .request-help-breadcrumb-link {
        color: $gray-100;
        text-decoration: none;
        font-weight: normal;
    }
}
