@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';
@import '../animations.scss';

#new-feedback {
    &.table-view {
        border: 2px solid $gray-700;
        margin-top: 20px;
        font-size: $fs-14;
        color: $gray-200;
        @include border-radius($radius);
        animation: slideLeftToRight 0.5s ease forwards;

        .tv-header {
            display: flex;
            background-color: $gray-800;
            padding: 1rem 1rem;
            font-weight: 600;
            & > div {
                padding: 0 10px;
                box-sizing: border-box;
            }
        }
        .tv-body {
            .tv-row {
                display: flex;
                border-bottom: 2px solid $gray-700;
                padding: 1rem 1rem;
                box-sizing: border-box;
                cursor: pointer;

                & > div {
                    padding: 0 10px;
                    box-sizing: border-box;
                }

                .col-experience {
                    text-align: center;
                    .ms-Image {
                        text-align: center;
                        img {
                            display: inline;
                            height: 30px;
                            width: 30px;
                        }
                    }
                    .experience-title {
                        font-size: $fs-10;
                    }
                }

                .team-name {
                    display: block;
                }

                .feedback-link {
                    margin-top: 5px;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
            .tv-no-content {
                text-align: center;
                padding: 1rem 1rem;
            }
        }
    }
}
