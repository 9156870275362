@import '../../../../assets/styles/css-assets.scss';
$NewsCard: '.news-card';

#{$NewsCard} {
    transition: all 0.2s ease-in-out;
    @include border-radius($radius);
    width: 100%;
    height: 526px;
    margin-bottom: 16px;
    background-color: $white !important;
    display: flex;
    flex-direction: column;
    border:1px solid $gray-600;

    &.no-border{
        border:none !important;
    }

    &:hover {
        transform: scale(1.025);
        .card-text {
            color: $primary-color;
        }
    }

    .card-img {
        width: 100%;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .img-area {
            width: 100%;
            height: 100%;
            display: flex;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            img {
                width: 100%;
                position: relative;
            }
            &.placeholder {
                background-color: $primary-color;
                width: 100%;
                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    svg {
                        fill: $white;
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }
        &.default-newscard {
            @include border-radius($radius $radius 0 0);
        }

        .ms-Image {
            @include border-radius($radius $radius 0 0);
            width: 100%;
        }
        .status {
            position: absolute;
            z-index: 999;
            float: right;
            top: 16px;
            right: 16px;
        }
    }
    &.latest--card {
        height: 526px;
        .card-img {
            height: 250px;
        }
    }
    &.category--card,
    &.in-the-news-card {
        height: 480px;
    }
    .card-details {
        margin-top: 12px;
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 265px);
        flex-grow: 1;

        .tags {
            margin-bottom: 8px;
            display: flex;
            .ms-Dropdown .ms-Dropdown-title {
                border: none;
                padding: 0px 5px;
                @include border-radius($radius);
            }

            .ms-Dropdown-container {
                display: flex;
                align-items: flex-end;
            }

            .ms-Dropdown-caretDownWrapper {
                display: none;
            }
            .tag {
                display: none;
                &:nth-child(1),
                &:nth-child(2) {
                    display: flex;
                }
                .icon {
                    float: none;
                }
                &.tag--more {
                    cursor: pointer;
                    display: block;
                }
                span {
                    white-space: nowrap;
                    max-width: 100px;
                    text-overflow: ellipsis;
                    display: inline-block;
                    overflow: hidden;
                }
            }
        }
        .date {
            color: $gray-200;
            font-size: $fs-12 !important    ;
        }
        h4 {
            font-weight: 600;
            font-size: $fs-18;
            line-height: $fs-28;
            color: $primary-color;
            margin-bottom: 10px;
            margin-top: 4px;
            max-height: 1.625rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            button {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                max-width: 100%;
            }
        }
        .description {
            height: 4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: $fs-14;
            .card-description {
                line-height: $fs-20;
                color: $gray-200;

                * {
                    font-size: $fs-14 !important;
                    line-height: 120%;
                    margin: 0px 0 5px;
                }
                a {
                    color: $primary-color;
                }
                sup,
                sub {
                    font-size: $fs-10;
                }
            }
        }
        .author-data {
            width: 100%;
            display: flex;
            margin-bottom: 0px;
            align-items: center;
            .profile-txt {
                img {
                    width: 24px;
                    height: 24px;
                    @include border-radius(50%);
                    margin-right: 8px;
                    float: left;
                }
            }
            .ms-Persona {
                max-width: 24px;
                margin-right: 10px;
            }
            .profile-name {
                margin-top: -4px;
                font-size: $fs-14;
                font-weight: 400;
                line-height: $fs-16;
                color: $gray-100;
            }
        }
        .card-footer {
            margin: 10px 0 8px;
            display: flex;
            flex-direction: column;
            margin-top: auto;
            .capsule {
                width: fit-content;
            }
            .last-modified-details {
                display: flex;
                align-items: center;
                .last-modified {
                    flex-grow: 1;
                    font-size: $fs-12;
                }
            }
            .ms-Dropdown .ms-Dropdown-title {
                border: none;
                padding: 0px 5px;
                @include border-radius($radius);
            }
            .ms-Dropdown-caretDownWrapper {
                display: none;
            }
            .ms-Dropdown {
                &::after {
                    @include border-radius($radius/2);
                }
            }
        }
    }
    &#{$NewsCard} {
        &--wide {
            height: 383px;
            margin-bottom: 40px;
            flex-direction: row;

            .card-img {
                width: 70%;
                height: 383px;
                &.default-newscard,
                .ms-Image {
                    @include border-radius($radius 0 0 $radius);
                }
                .placeholder {
                    height: 383px;
                }
            }
            .description {
                .card-description {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            .card-details {
                width: 30%;
                height: calc(100% - 20px);
            }
        }
        &--small {
            height: inherit;
            display: flex;
            width: 100%;
            height: 170px;
            margin-bottom: 12px;
            box-sizing: border-box;
            .author-data {
                margin: 0px;
            }
            .card-details {
                flex-grow: 1;
                height: calc(100% - 20px);
                max-width: calc(100% - 40px);
            }
            .card-footer {
                display: flex;

                flex-direction: row;
                margin-bottom: 5px;
                .author-data {
                    width: auto;
                }
                .capsule {
                    height: fit-content;
                    margin: 5px 10px;
                }
            }
        }
    }
}
