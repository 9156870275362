//Border Radius @include border-radius(10px);
@mixin border-radius($radius) {
  border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
}

//This is to stretch the fonts if required
@mixin font-stretch($stretchX, $stretchY) {
  transform: scale($stretchX, $stretchY);
  -webkit-transform: scale($stretchX, $stretchY);
  /* Safari and Chrome */
  -moz-transform: scale($stretchX, $stretchY);
  /* Firefox */
  -ms-transform: scale($stretchX, $stretchY);
  /* IE 9+ */
  -o-transform: scale($stretchX, $stretchY);
  /* Opera */
}

// Random dots creation for Request Help Background
@function random-stars ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';

  @for $_ from 1 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }
  @return unquote($value);
}

//Creating Margin and Padding Classes. 
@mixin spacings($className, $styleName) {
  $max: 50; 
  $offset: 5; 
  $unit: 'px';
  $i: 0;

  $max2: 9;
  $offset2: 1;

  @while $i <=$max2 {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }

    $i: $i+$offset2;
  }

  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }

    $i: $i+$offset;
  }  
}

//Make margin auto
@mixin push--auto {
  margin: { 
      left: auto;
      right: auto;
  }
}