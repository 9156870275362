@import 'app/assets/styles/css-assets';
@import '../animations';

.search-MSX-cont {
    border: 1px solid $gray-250;
    background: $white;
    @include border-radius($radius);
    &:focus-within {
        border: 2px solid $primary-color;
        animation: mymove 2s infinite;
    }
    .ms-TextField-fieldGroup {
        padding: 0 15px;
        border: hidden !important;
        min-height: 45px;
        input {
            &::placeholder {
                color: $gray-400;
            }
        }
        &::after {
            border: hidden;
        }
    }
    .active {
        .ms-TextField-fieldGroup {
            border-radius: 8px 8px 0 0 !important;
            border: hidden !important;
            &::after {
                border-radius: 8px 8px 0 0 !important;

                border: hidden;
                animation: none;
            }
            &:focus-within {
                border: none;
                animation: none;
            }
        }
    }

    .suggest-cont-box {
        background: $white;
        padding: 0 10px;
        border-radius: 0 0 10px 10px;
        margin: 0 10px;
        border-top: 1px solid $blue-700;
        .suggestion-heading {
            span {
                color: $gray-100;
                font-weight: 600;
            }
        }
        .suggestion-item {
            padding-left: 10px;
            cursor: pointer;
            &:hover {
                background: $gray-600;
            }
        }
    }
}
