@import 'app/assets/styles/css-assets';

.search-results-links {
    padding: 10px 50px 10px 0;
    a {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $primary-color;
        text-decoration: none;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all; 
    }
    .search-results-description{
        font-size: $fs-12;
        line-height: 20px;
        margin:0;
        color: $gray-100;
        width: 90%;
        text-align: justify;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;        
    }

    .tags-container {
        margin:0;
        .result-type {
            font-size: 12px;
            line-height: 16px;
            color: $gray-100;
            margin: 5px 12px 5px 0;
            padding: 4px 10px;
            background: $gray-800;
            border: 1px solid $gray-700;
            box-sizing: border-box;
            border-radius: 14px;
            display: inline-block;
    

            .type-icon {
                background: url(app/static/icons/content_icon.svg) no-repeat;
                background-size: 13px 13px;
                height: 13px;
                width: 13px;
                display: inline-block;
                top: 3px;
                position: relative;
                margin-right: 5px;
            }
        }

        .result-date {
            font-size: 10px;
            line-height: 12px;
            color: $gray-200;
            margin-right: 12px;
        }
    }
}
