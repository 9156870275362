@import 'app/assets/styles/css-assets';

.main-spot-light-container {
    margin-bottom: 40px;

    h2 {
        font-weight: normal;
        font-size: $fs-24;
        line-height: 32px;
        color: $gray-100;
        margin: 2.5rem 0 1.5rem;
        i.ms-Icon {
            transform: rotate(45deg);
            margin: 0 0 0 20px;
            font-size: 14px;
        }
    }
  
}
.rec-pagination {
    display: none !important;
}

.rec-carousel {
    position: relative;
}
.rec-arrow-previous,
.rec-arrow-next {
    border: none;
    font-size: $fs-32 !important;
    width: $fs-42 !important;
    height: $fs-42 !important;
    min-width: $fs-36 !important;
    line-height: $fs-36 !important;
    font-weight: normal;
    position: absolute;
    z-index: 1;
    top: 50%;
    background-color: $blue-800 !important;
    box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22) !important;
    &:disabled {
        box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22) !important;
        background-color: $gray-600 !important;
        opacity: 0.8;
        cursor: default !important;
    }
    &:hover:enabled,
    &:focus:enabled {
        color: $primary-color !important;
        transition: all 0.2s ease-in;
    }
    &::before {
        content: ' ';
        border: 2px solid;
        border-radius: 0px;
        border-width: 2px 0px 0px 2px;
        transform: rotate(-45deg);
        position: absolute;
        width: 13px;
        height: 13px;
        top: 14px;
        left: 16px;
    }
}
.rec-arrow-previous {
    left: -15px;
    text-indent: -9999px;
}
.rec-arrow-next {
    right: -15px;
    text-indent: -9999px;
    &::before {
        transform: rotate(133deg);
        left: 11px;
    }
}
.rec-slider-container {
    margin: -10px 0px !important;
}
.rec-slider {
    padding: 20px 0;
}
.rec-item-wrapper {
    overflow: visible !important;
}

.color-primary {
    color: $primary-color;
}

.button-padding {
    margin-top: 10px;
    margin-bottom: 10px;
}