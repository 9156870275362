@import '../../assets/styles/css-assets';
.more-options {
    position: absolute;
    background-color: $white;
    @include border-radius($radius);
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    right: 0px;
    margin-top: 10px;
    z-index: 1;
    padding: 0px !important;
    &.left {
        right: 0px;
    }
    &.right {
        left: 0px;
    }
    &.top {
        bottom: 100%;
    }
    &.bottom {
        top: 100%;
    }
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
        flex-direction: column;
        li {
            font-size: $fs-14;
            line-height: $fs-20;
            .ms-Link {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: $gray-100;
                padding: 8px 12px 8px 8px;
                width: 100%;
                box-sizing: border-box;
                font-weight: normal;
                .icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    color: $white;
                    align-self: flex-end;
                    color: $gray-100;
                }
                &:hover {
                    background-color: $primary-color;
                    color: $white;
                    &:active {
                        text-decoration: none;
                    }
                    .icon {
                        color: $white;
                    }
                }
            }
            &:first-child {
                .ms-Link {
                    @include border-radius(8px 8px 0px 0px);
                }
            }
            &:last-child {
                .ms-Link {
                    @include border-radius(0px 0px 8px 8px);
                }
            }
            &:only-child {
                .ms-Link {
                    @include border-radius(8px);
                }
            }
        }
    }
}
#moreOptions-list {
    .ms-Button-flexContainer > span {
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }
}

.ms-Dropdown-callout {
    min-width: 180px;
}