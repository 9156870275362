@import '../../assets/styles/css-assets';

.ms-Dropdown-title {
    .icon {
        color: $primary-color;
        margin-right: 8px;
        font-size: $fs-12;
        line-height: $fs-12;
    }
}
.ms-Dropdown-item {
    .icon {
        color: $primary-color;
        margin-right: 8px;
        font-size: $fs-12;
        line-height: $fs-12;
    }
    &:hover {
        background: $primary-color !important;
        color: $white !important;
        .icon {
            color: $white !important;
        }
    }
}

.ms-Dropdown-callout {
    @include border-radius($radius);
    .ms-Dropdown-items {
        max-height: 25rem;
        overflow-y: auto;
        .ms-Dropdown-item {
            &:hover {
                span {
                    color: $white;
                }
            }
            border-bottom: 1px solid $gray-600;
        }
        .ms-Dropdown-header {
            height: auto;
            border-bottom: 1px solid $gray-600;
            span {
                color: $black;
                white-space: normal;
                line-height: 25px;
            }
        }
        .ms-Checkbox {
            .ms-Checkbox-label {
                font-size: 14px;
                line-height: 26px;
                span {
                    color: #4f4f4f;
                    padding-left: 5px;
                }
            }

            &.is-checked {
                background: $primary-color;
                color: $white;
                span {
                    color: $white;
                }
                .ms-Checkbox-checkbox {
                    background: $white;
                    .ms-Checkbox-checkmark {
                        color: $primary-color;
                    }
                }
            }

            .sub-category {
                margin-left: 30px;
            }

            .iconRight {
                position: absolute;
                right: 0;
            }
        }
        button + button {
            margin-left: 0px;
        }
    }
}
.ms-Callout-main {
    @include border-radius($radius);
    overflow: hidden;
}

.selectType {
    width: 144px;
    background: $white;
    height: 30px;
    @include border-radius($radius);
    .ms-Dropdown-title {
        @include border-radius($radius);
        width: 144px;
        height: 30px;
        display: flex;
        align-items: center;
    }
}
