@import '../../../../assets/styles/css-assets.scss';
.manage-request-cont {
    .list-view-toggle {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 0.625rem;
        color: $gray-200;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: $radius;
        padding: 0.9375rem 1.25rem;
        .filter-panel {
            .filter-heading {
                font-size: $fs-12;
                color: $gray-200;
                font-weight: normal;

                .filter-active-icon {
                    color: #34CDA8;
                }
            }

            .filter-active-count {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                text-align: center;
                background-color: #34CDA8;
                color: white;
                font-size: 12px;
            }
            
            .placeholder-title {
                cursor: pointer;
                font-size: $fs-14;
                color: $primary-color;
                i {
                    margin-left: 0.5rem;
                    font-weight: 600;
                    font-size: $fs-10;
                }
                .placeholder-label {
                    display: inline-block;
                    max-width: 6.25rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &.filter-disabled {
                    color: $gray-300;
                    cursor: default;
                }
            }
            .ms-Checkbox-checkbox {
                border: 1px solid $blue-700;
                @include border-radius(2px);
            }
            label {
                color: $gray-200;
                cursor: pointer;
            }
            .is-checked {
                label {
                    font-weight: 600;
                    color: $gray-100;
                }
                .ms-Checkbox-checkbox {
                    @include border-radius(2px);
                    border: 1px solid $primary-color;
                    background: $white;
                    i {
                        color: $primary-color;
                    }
                }
            }
            label {
                font-weight: normal;
                font-size: $fs-14;
                color: $gray-200;
            }
            .dropdown-header {
                cursor: pointer;
                box-sizing: border-box;
                padding: 0.625rem;
                background: $gray-800;
                color: $blue-200;
                i {
                    margin-left: 0.5rem;
                    font-weight: 600;
                    font-size: $fs-10;
                }
                .ms-Link {
                    text-decoration: underline;
                    font-size: $fs-12;
                }
            }
            .customize-col-btn {
                padding: 0 1rem;
                position: relative;
                .customize-col-menu {
                    left: -4.0625rem;
                    position: absolute;
                    z-index: 999999;
                    max-height: 30rem;
                    overflow-y: auto;
                    min-width: 16rem;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    background: $white;
                    @include border-radius($radius);
                    .list-item {
                        border-top: 1px solid $gray-600;
                    }
                    .ms-Checkbox {
                        &.is-disabled {
                            .ms-Checkbox-checkbox {
                                border: 1px solid $gray-400;
                            }
                        }
                    }
                }
            }
            .products-filter {
                padding-left: 1rem;
                .dropdown-cont {
                    min-width: 16rem;
                    right: -1.25rem;
                }
            }
            .search-bar-cont {
                padding: 1.25rem 0.625rem;
                .ms-SearchBox i {
                    transform: rotate(270deg);
                }
                .tags-cont {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0.3125rem;
                    .tag-item {
                        display: flex;
                        align-items: center;
                        background: $gray-800;
                        margin-right: 0.3125rem;
                        padding: 0.125rem 0.3125rem;
                        color: $gray-100;
                        margin-bottom: 0.3125rem;
                        @include border-radius(0.1875rem);
                        span {
                            font-size: $fs-12;
                        }
                        i {
                            cursor: pointer;
                            margin-left: 0.625rem;
                            font-size: $fs-10;
                        }
                    }
                }
                .ms-SearchBox {
                    @include border-radius($radius);
                    border: 1px solid $blue-700;
                    input::placeholder {
                        color: $gray-300;
                    }
                    i {
                        font-size: $fs-14;
                        color: $gray-300;
                    }
                    .ms-SearchBox-clearButton {
                        i {
                            font-size: $fs-14;
                        }
                    }
                    &::after {
                        @include border-radius($radius);
                    }
                }
            }
            .dropdown-cont {
                max-height: 30rem;
                overflow-y: auto;
                position: absolute;
                background: $white;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                @include border-radius($radius);
                z-index: 999999;
                font-size: $fs-14;
                font-weight: normal;
                &.position-cont {
                    top: 2.8125rem;
                }
                .dropdown-item {
                    border-top: 1px solid $gray-600;
                    box-sizing: border-box;
                    padding: 0.625rem;
                    &.child-item {
                        padding-left: 30px;
                    }
                    &.parent-item {
                        label {
                            font-weight: 600;
                        }
                    }
                }
            }
            .filter-cont {
                position: relative;
                padding-right: 1rem;
            }
            .customize-col-btn {
                padding-right: 1rem;
                border-right: 2px solid $gray-600;
            }
            
            .clear-btn-container {
                display: flex;
            }

            .clear-filter-btn {
                max-width: 4rem;
                padding-left: 1rem;
                text-decoration: none;
                color: $blue-400;
                font-size: $fs-12;
                width: 3rem;

                &.filter-disabled {
                    color: $gray-300;
                }
            }
            .request-type-filter {
                padding-left: 1rem;
                .dropdown-cont {
                    min-width: 16rem;
                }
            }
            .status-filter {
                padding-left: 1rem;
                .dropdown-cont {
                    min-width: 10rem;
                }
            }
            .dcf-filter {
                .dropdown-cont {
                    min-width: 10rem;
                }
            }
            .assignedTo-filter {
                padding-left: 1rem;
                .dropdown-cont {
                    min-width: 19rem;
                }
                .dropdown-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    label {
                        font-weight: normal;
                    }
                    .ms-Persona-details {
                        display: none;
                    }
                    .ms-Persona-coin {
                        margin-right: 0.625rem;
                    }
                    &.selected {
                        label {
                            font-weight: 600;
                            color: $gray-100;
                        }
                    }
                    .ms-Checkbox {
                        margin-right: 0.625rem;
                    }
                }
            }
            .aging-filter {
                padding-left: 1rem;
                .dropdown-cont {
                    min-width: 12rem;
                }
                .dropdown-item {
                    &.selected {
                        label {
                            font-weight: 600;
                            color: $gray-100;
                        }
                    }
                    .aging-icon {
                        display: block;
                        width: 2.5rem;
                        height: 0.9375rem;
                    }
                    .icon-0hr-12hrs {
                        background: url(app/static/icons/0hr-12hrs-icon.svg) no-repeat;
                    }
                    .icon-0d-7d {
                        background: url(app/static/icons/0hr-12hrs-icon.svg) no-repeat;
                    }
                    .icon-1d-7d {
                        background: url(app/static/icons/1d-7d-icon.svg) no-repeat;
                    }
                    .icon-8d-30d {
                        background: url(app/static/icons/1d-7d-icon.svg) no-repeat;
                    }
                    .icon-1w-4w {
                        background: url(app/static/icons/1w-4w-icon.svg) no-repeat;
                    }
                    .icon-1m-plus {
                        background: url(app/static/icons/1m+-icon.svg) no-repeat;
                    }
                    .icon-31d-plus {
                        background: url(app/static/icons/31d-icon.svg) no-repeat;
                    }
                }
            }
        }
        .view-text {
            margin: 0 0.5rem;
            font-size: $fs-12;
            &.active {
                color: $primary-color;
                font-weight: 600;
            }
        }
        .lv-toggle-wrap {
            display: flex;
            background: $gray-650;
            border-radius: 40px;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);
            color: $gray-200;
            .lv-toggle {
                padding: 0.5rem 1.3rem;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .handle-left {
                border-radius: 40px;
                background: $primary-color;
                box-shadow: 0px 0px 6px #00000014;
                color: $primary-color;
                cursor: pointer;
                animation: toggleLeft 0.2s ease 0s 1 normal forwards;
            }
            .handle-right {
                border-radius: 40px;
                background: $primary-color;
                box-shadow: 0px 0px 6px #00000014;
                color: $primary-color;
                cursor: pointer;
                animation: toggleRight 0.2s ease 0s 1 normal forwards;
            }
        }
    }
}
