@import '../../../../assets/styles/css-assets.scss';

.search-result-container {
    margin-bottom: 2rem;

    .result-title {
        font-weight: 600;
        font-size: $fs-16;
        line-height: $fs-22;
        color: $blue-400;
        margin-top: 19px;
        cursor: pointer;
        text-decoration: none;

        b.highlight {
            background: #c7e0f4;
            border-radius: 4px;
            padding: 0 3px 3px;
            font-weight: 700;
        }
    }

    .result-id {
        background: #eff6fc;
        border-radius: 13px;
        padding: 3px 8px;
        font-size: $fs-12;
        line-height: $fs-18;
        color: $gray-100;
        cursor: default;
    }

    .result-section {
        margin-top: 12px;
    }

    .result-status {
        border-radius: 13px;
        font-size: $fs-12;
        line-height: $fs-18;
        color: $white;
        padding: 3px 12px;
        cursor: default;
        background: $blue-600;
        &.New {
            background: $purple-100;
        }
        &.Active {
            background: $green-200;
        }

        &.Inprogress,
        &.Resolved {
            background: $blue-600;
        }
        &.Closed {
            background: $red-200;
        }
    }

    .in-progress-status {
        background: $yellow-100;
    }

    .active-status {
        background: $green-200;
    }

    .result-tag {
        margin-left: 8px;
        background: $cyan-800;
        border: 1px solid $cyan-100;
        box-sizing: border-box;
        border-radius: 13px;
        font-size: $fs-12;
        line-height: 18px;
        color: $gray-100;
        padding: 3px 8px;
        cursor: default;
    }

    .result-association {
        margin-left: 8px;
        background: $brown-100;
        box-sizing: border-box;
        border-radius: 13px;
        font-size: $fs-12;
        line-height: 18px;
        color: white;
        padding: 3px 8px;
        cursor: default;

        &.Parent {
            background: $brown-100;
        }

        &.Child {
            background: $red-50;
        }
    }

    .result-description {
        font-size: $fs-14;
        line-height: 20px;
        color: $gray-100;
        margin-top: 17px;
        margin-bottom: 19px;
        b.highlight {
            background: #c7e0f4;
            border-radius: 4px;
            padding: 0 3px 3px;
            font-weight: 400;
        }
    }

    .tags-container {
        margin-left: 8px;
        display: flex;
        border-left: 1px solid $gray-300;
        .tag-display {
            background: #eff6fc;
            @include border-radius($radius);
            display: inline-block;
            padding: 3px 7px;
            margin: 0px 8px;
            cursor: pointer;
            color: #0078d4;
            font-size: $fs-14;
            border: 1px solid transparent;
            &:hover {
                border: 1px solid $primary-color;
            }
        }

        .tags-count {
            position: relative;
            margin-left: 0.625rem;
            background: #deecf9;
            @include border-radius(50%);
            padding-left: 5px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            box-sizing: border-box;
            margin-left: 10px;
            top: -1px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #0078d4;
            cursor: pointer;
        }
    }
}
.tags-txt {
    cursor: pointer;
    &:hover {
        color: $primary-color;
    }
}
