@import '../../../../assets/styles/css-assets.scss';
.category {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: $radius;
    .header-container {
        display: flex;
        align-items: center;
        span.icon {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            svg {
                width: 25px;
                height: 25px;
            }
        }
        h2 {
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            color: $gray-100;
            margin-bottom: 32px;
            font-family: $font-family;
        }
        img {
            width: 25px;
            height: 25px;
            padding-top: 40px;
            margin-right: 15px;
        }
    }
    &.icon-white {
        .header-container {
            span.icon {
                svg {
                    fill: $white;
                }
            }
        }
    }
    .viewall-link {
        float: right;
        margin-top: 46px;
        button {
            display: flex;
            color: $gray-200;
            font-size: $fs-14;
            align-items: baseline;
            .ms-Icon {
                font-size: $fs-10;
                padding-left: 4px;
            }
            &:hover {
                text-decoration: none;
                color: $gray-100;
            }
        }
    }
    &.gradient {
        &--purple {
            background: linear-gradient(107.2deg, $purple-100 0%, $purple-250 100%);
            padding-top: 10px;
            padding-bottom: 50px;
            margin-top: 40px;
            margin-bottom: 0px;
            h2,
            .viewall-link button {
                color: $white;
            }
            .viewall-link button {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}