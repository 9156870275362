@import 'app/assets/styles/css-assets';
@import '../../../../modules/user/newReqExperience/animations.scss';

.choiceGroupStyles {
    width: 600px;
    height: 120px;
    display: flex;
    flex-direction: column !important;
    .ms-ChoiceField {
        background-color: white;
        margin-top: 2px;
        height: auto;

        .ms-ChoiceField-wrapper {
            height: 55px;
            width: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 7px;
            background-color: white;
            margin-top: 10px;
            border: 1px solid lightgrey;
            border-radius: 8px;
        }
        .ms-ChoiceField-field {
            border: none;
            height: 40px;
            width: 64px;
        }
    }
}

.save-lists-icons {
    cursor: pointer;
    display: flex;
    margin-bottom: 30px;
    height: 120px;
    width: 720px;
}

.save-lists-icons,
.select-icons {
    margin-left: 15px;
    height: 220px;

    z-index: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cart-lb {
    display: flex;
    flex-direction: column;
    padding: 40px 20px 20px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    @include border-radius($radius);
    text-align: center;
    font-size: $fs-13;
    cursor: pointer;
    position: relative;
    margin: 0 0.6rem;
    background-color: rgba($white, 0.3);

    @include layout-max {
        width: 80px;
    }
    .quantity-field {
        input {
            text-align: center;
            &::placeholder {
                font-size: $fs-10;
            }
        }
    }
    .quantity-field ::-webkit-outer-spin-button,
    .quantity-field ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    img {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 30px;
        margin: 2px;
        object-fit: contain;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    &.selected-icon-lb {
        box-shadow: 0px 4px 10px rgba(0, 120, 212, 0.5);
        background: $white;
        border: 2px solid blue;
        font-weight: 600;
        color: $primary-color;
        margin-top: 20px;
        overflow: hidden;

        span {
            font-weight: 600;
        }
        &::before {
            content: '';
            position: absolute;
            width: 40px;
            height: 65px;
            background-color: #0178d4;
            transform: rotate(45deg);
            animation: selectedProduct 0.5s ease forwards;
        }

        @keyframes selectedProduct {
            0% {
                left: -40px;
                top: -50px;
            }

            100% {
                left: -20px;
                top: -30px;
            }
        }

        &::after {
            background: url(app/static/icons/tick-icon.svg) no-repeat;
            content: '';
            position: absolute;
            left: 4px;
            top: 7px;
            width: 42px;
            height: 42px;
            transform: scale(1);
            animation: tickMark 0.5s ease 0.3s forwards;
        }

        @keyframes tickMark {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.2);
            }

            100% {
                transform: scale(1);
            }
        }
        &.added {
            border-color: $green-100;
            box-shadow: 0px 4px 10px rgba(16, 124, 16, 0.3);
            &::before {
                background-color: $green-100;
            }
        }
    }

    .css-40 {
        margin-top: 20px;
    }
}

.save-list-header {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    margin-left: 4%;
    display: flex;
    align-items: flex-end;

    color: #000000;
}

.image-styles {
    .ms-Image {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 30px;
        margin: 2px;
        object-fit: contain;
        border: 1px solid #c7e0f4;
        border-radius: 8px;
    }
}

.icons-list,
.select-model-lb {
    z-index: 1;
    .device-animate-lb {
        height: 100px;
        &:hover {
            span {
                animation: productTextHover 0.5s ease forwards;
            }
        }
        &.add-align-prop {
            align-self: flex-start;
        }
    }

    .cart-lb {
        .ms-Image {
            animation: productHoverOut 0.5s ease forwards;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2px;
            object-fit: contain;
            border: 1px solid #c7e0f4;
            border-radius: 8px;
            img {
                max-width: 43px;
                max-height: 36px;
            }
        }

        .ms-Image {
            animation: productHoverOut 0.5s ease forwards;
            img {
                max-width: 40px;
                max-height: 34px;
            }
        }

        &:hover {
            .ms-Image {
                animation: productHover 0.5s ease forwards;
            }
        }

        &.selected-icon-lb {
            .ms-Image {
                animation: none;
            }
        }
    }
}

.select-initial {
    border-bottom: 1px solid #c7e0f4;
    width: 600px;
}

.addCustomerHeader {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 98%;
}

.customer-modal-header {
    background-color: #0078d4;
    margin-top: 10px;
    width: 780px;
    height: 54px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    text-align: center;
    display: flex;
}

.icon-select-box {
    border: 1px solid #c7e0f4;
    border-radius: 8px;
    margin: 20px;
}

.textStyles {
    margin: 10px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    color: #a19f9d;
    font-size: 14px;
    line-height: 20px;
}

.select-styles {
    height: 40px;
    width: 95%;
    cursor: pointer;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
    align-items: center;
    .imgStyles {
        margin-top: 5px;
        width: auto;
        max-height: 30px;
        object-fit: contain;
    }
}

.select-folder-styles {
    height: 40px;
    width: 95%;
    cursor: pointer;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c7e0f4;
}

.chevron-styles {
    height: 20px;
    display: flex;
}

.textfield-name {
    color: #c7e0f4;
    background: $white;

    @include border-radius($radius);
    width: 600px;
    height: 40px;
    margin-left: 20px;
    margin-bottom: 20px;
    .ms-TextField-wrapper {
        .ms-TextField-fieldGroup {
            width: 750px !important;
            margin: 20px auto 0;
            border: 1px solid #c7e0f4;
            box-sizing: border-box;
            height: 40px !important;
            padding: 0;

            input {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.select-a-folder {
    border: 1px solid #c7e0f4;
    height: 40px;
    width: 95%;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 23px;
    border-radius: 8px;
}

.chevron-folder {
    display: flex;
    margin-right: 5px;
}

.chevron-folder-collapsed {
    display: flex;
    margin-right: 30px;
}
.selectFolder {
    margin-left: 5px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    color: #a19f9d;
}

.selectFolderCollapsed {
    margin-left: 25px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    color: #a19f9d;
}
.modal-footer {
    width: 760px;
    margin-top: 30px;
    margin-left: 20px;
}

.addFolder {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 22px;
    height: 50px;
    cursor: pointer;
}

.folderTextStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    width: 380px;
    color: #323130;
    margin-left: 5px;
}

.folderContainer {
    width: 400px;
    margin-bottom: 12px;
    display: flex;
    cursor: pointer;
    margin-left: 48px;
}
.folderStructureStyles {
    height: 200px;
    margin-top: 20px;
    border: 1px solid #c7e0f4;
    border-radius: 8px;
    width: 745px;
    margin-left: 22px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.3rem;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba($color: #c7e0f4, $alpha: 0.4);
        max-height: 20px;
        @include border-radius(4px);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba($color: #c7e0f4, $alpha: 0.4);
    }
    &::-webkit-scrollbar-track {
        @include border-radius(0);
    }
    &::-webkit-scrollbar-track-piece:end {
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 2.8em;
    }
}

.effect:hover img.image {
    display: none;
}
.effect img.hover {
    display: none;
}
.effect:hover img.hover {
    display: block;
}

.saving-list-spinner {
    margin-top: 3rem;
}
