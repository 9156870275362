@import '../../../../assets/styles/css-assets';
.activemenu {
    display: block !important;
}

.select-tags-component {
    width: 250px;
    display: none;
    background: $white;
    top: 58px;
    left: 0px;
    z-index: 2147483648;
    position: absolute;
    box-shadow: 0px 20px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
    @include border-radius($radius);
    .cancel-button {
        img {
            padding-right: 14px;
            padding-top: 14px;
            width: 16px;
            height: 16px;
            float: right;
            cursor: pointer;
        }
    }
    .All-Categories {
        cursor: pointer;
        position: relative;
        .text-icon-arrow-container {
            display: flex;
            justify-content: space-between;
            padding: 6px 0;
            .text-icon-box {
                display: flex;
                height: 34px;
                padding: 0 $radius;
                align-items: center;
                .bld {
                    .category-link {
                        margin-left: 15px;
                        color: $gray-100;
                        font-size: 14px;
                        &:hover {
                            font-weight: 600;
                            text-decoration: none;
                        }
                    }
                }
                img {
                    height: 16px;
                    width: 16px;
                    padding-top: 2px;
                }
            }
            .menu-arrow {
                padding: 6px 12px;
                .submenu {
                    position: absolute;
                    left: -250px;
                    text-align: left;
                    display: none;
                    transition: 0.3s;
                    opacity: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    .menu-arrow {
                        padding-top: 10px;
                        text-align: right;
                        &:hover {
                            color: $gray-100;
                        }
                    }
                    .submenu-item-container {
                        @include border-radius($radius);
                        box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
                        background: $white;
                        min-width: 250px;
                        max-width: 250px;
                        .submenu-item {
                            .icon-text-container {
                                display: flex;
                                color: $gray-100;
                                justify-content: space-between;
                                text-align: left;
                                padding: 6px 0;
                                position: relative;
                                .item-heading {
                                    padding: 7px 10px;
                                    font-size: $fs-14;
                                    flex-grow: 1;
                                }
                                .ms-Image {
                                    padding-top: 10px;
                                    min-width: 16px;
                                    max-width: 16px;
                                    min-height: 16px;
                                    max-height: 16px;
                                    padding-left: 15px;
                                    padding-right: 7px;
                                    img {
                                        width: 16px;
                                    }
                                }
                                &:hover > .menu-arrow > .submenu {
                                    display: block;
                                    opacity: 1;
                                }
                            }
                            &:hover {
                                @include border-radius($radius);
                                background-color: $gray-700;
                            }                          
                        }
                    }
                    &:hover {
                        display: block;
                        opacity: 1;
                    }
                }
            }
            &:hover > .menu-arrow > .submenu {
                display: block;
                opacity: 1;
            }
        }
        &:hover {
            background-color: $gray-700;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}