@import '../../../../assets/styles/css-assets';

.se-toolbar {
    background-color: $white !important;
    @include border-radius($radius $radius 0 0);
    margin: -1px;
    outline: none !important;
    border-bottom: 1px solid $gray-500;
}

.se-resizing-bar {
    display: none !important;
}

.se-btn-tray {
    padding: 5px 3px 0px !important;
}

.sun-editor-editable {
    font-family: $font-family;
    * {
        font-family: $font-family !important;
    }
    .__se__float-left {
        margin-right: 20px;
    }
    .__se__float-right {
        margin-left: 20px;
    }
}
.sun-editor {
    @include border-radius($radius);
    font-family: $font-family;
    .se-wrapper {
        position: initial !important;
        @include border-radius($radius);
        .sun-editor-editable {
            min-height: 500px;
            height: inherit !important;
        }
        .se-wrapper-code {
            min-height: 450px;
        }
    }
    .se-dialog {
        .se-dialog-inner {
            .se-dialog-header {
                .se-modal-title {
                    font: inherit;
                    font-weight: bold;
                }
            }
            .se-dialog-content {
                label {
                    font: inherit;
                }
                .se-dialog-footer {
                    label {
                        display: none !important;
                    }
                }
            }
            .se-dialog-form {
                .se-input-form {
                    padding: 5px;
                }
                &.se-dialog-form-footer {
                    display: none;
                }
            }
            .se-dialog-body {
                .se-dialog-form {
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }
        }
        ._se_tab_content_image {
            .se-dialog-body {
                > div {
                    border: none !important;
                }
            }
        }
        ._se_tab_content_image {
            .se-dialog-body {
                > div {
                    border: none !important;
                }
                > .se-dialog-form {
                    display: none;
                }
            }
        }
    }
}
.se-btn-module-border {
    border: none !important;
    border-radius: 0px !important;
}
