@import '../../assets/styles/css-assets.scss';

.ms-Breadcrumb {
    margin: 0px;
    .ms-Breadcrumb-listItem {
        line-height: $fs-30;
        font-size: $fs-16;

        .ms-Breadcrumb-item,
        .ms-Breadcrumb-itemLink {
            line-height: $fs-30;
            font-size: $fs-12;
            font-weight: 400;
            color: $gray-200;
        }
        .ms-Breadcrumb-itemLink {
            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
        .ms-Breadcrumb-chevron {
            font-size: $fs-10;
        }
        &:first-child {
            .ms-Breadcrumb-item,
            .ms-Breadcrumb-itemLink {
                padding-left: 0px;
            }
        }
        &:last-child {
            .ms-Breadcrumb-itemLink,
            .ms-Breadcrumb-item {
                color: $gray-100;
            }
        }
    }
}
