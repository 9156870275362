@import 'app/assets/styles/css-assets';

$VisualFlow: '.visual-flow';
$step: '.step';
$stepIcon: '.step-icon';

#{$VisualFlow} {
    &__header {
        display: flex;
        align-items: stretch;
        width: calc(90% + 44px);
        padding: 16px 22px;
        box-sizing: border-box;
        background: $blue-800;
        @include border-radius(8px);
        gap: 10px;
        animation: visualHeaderAnimation 0.6s ease forwards;
        position: relative;

        > div {
            &.icon-column {
                .leftArrow {
                    transform: rotate(45deg);
                    cursor: pointer;
                    opacity: 0;
                    position: relative;
                    animation: breadcrumbArrowAnimation 1.5s ease 0.5s forwards;
                    font-size: $fs-9;
                }
            }

            &.list-view-btn {
                height: 21px;
                margin-top: auto;
            }

            &.ticket-details {
                flex-grow: 1;

                .ticket-no {
                    .req-id {
                        color: $gray-200;

                        font-size: $fs-14 !important;
                    }
                    .badge--status {
                        opacity: 0;
                        position: relative;
                        animation: rightToLeft 0.8s ease 0.5s forwards;
                        text-transform: uppercase;
                        &.bg-active {
                            background-color: #6b8e23;
                            color: $white;
                        }

                        &.bg-closed {
                            background-color: #b22222;
                            color: $white;
                        }

                        &.bg-resolved {
                            background: #00bfff;
                            color: $white;
                        }

                        &.bg-new {
                            background-color: #9370db;
                            color: $white;
                        }
                    }
                }

                h2 {
                    margin: 12px 0 8px;
                    color: #333333;
                    font-weight: 600;
                    font-size: $fs-22;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }

                .links {
                    display: flex;

                    .vertical-line {
                        border: 0.8px solid $primary-color;
                        margin: 0px 13px;
                    }

                    .see-all-details {
                        font-weight: normal;
                        font-size: $fs-14;
                        line-height: $fs-20;
                        text-decoration-line: underline;
                        color: $primary-color;
                    }

                    .attachment-count {
                        font-size: 14px;
                        line-height: 20px;
                        color: $primary-color;
                    }

                    a {
                        color: $primary-color;
                        font-size: $fs-14;
                        line-height: $fs-14;

                        &:not(:first-child) {
                            padding-left: 15px;
                            margin-left: 15px;
                            border-left: 1px solid $primary-color;
                        }

                        &.download {
                            font-weight: normal;
                            font-size: $fs-14;
                            line-height: $fs-20;
                            color: $primary-color;
                        }
                    }
                }
            }

            &.buttons {
                align-self: flex-end;
                display: flex;
                flex-direction: row;
                min-width: 275px;
                button {
                    display: flex;
                    align-items: center;
                    background-color: $primary-color;
                    @include border-radius(0.5rem);
                    color: $white;
                    border: none;
                    padding: 9px 11px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        background: radial-gradient(circle, rgba(63, 203, 251, 1) 0%, rgba(0, 120, 212, 1) 100%);
                        content: '';
                        display: inline-block;
                        width: 100%;
                        position: absolute;
                        left: -100%;
                        height: 33px;
                        opacity: 0;
                        z-index: 0;
                        animation: buttonAnimationReverse 0.6s ease forwards;
                    }

                    &:hover {
                        box-shadow: 0 2px 10px 0 rgba(65, 132, 234, 0.75);

                        &:before {
                            animation: buttonAnimation 0.6s ease forwards;
                        }
                    }

                    .text {
                        position: relative;
                        z-index: 1;
                    }

                    &.full-screen,
                    &.maximize-view {
                        span.icon {
                            width: 10px;
                            height: 10px;
                            margin-right: 7px;
                            position: relative;
                            margin-top: -1px;

                            > i {
                                width: 4px;
                                height: 4px;
                                font-size: 4px;

                                &:first-child {
                                    position: absolute;
                                    left: 0px;
                                    animation: maximizeButtonAnimationReverse1 0.3s ease-in-out forwards;
                                }

                                &:nth-child(2) {
                                    position: absolute;
                                    right: 0px;
                                    animation: maximizeButtonAnimationReverse2 0.3s ease-in-out forwards;
                                }

                                &:nth-child(3) {
                                    position: absolute;
                                    right: 0px;
                                    bottom: 0px;
                                    animation: maximizeButtonAnimationReverse3 0.3s ease-in-out forwards;
                                }

                                &:last-child {
                                    position: absolute;
                                    left: 0px;
                                    bottom: 0px;
                                    animation: maximizeButtonAnimationReverse4 0.3s ease-in-out forwards;
                                }
                            }
                        }

                        &:hover {
                            span.icon {
                                > i {
                                    &:first-child {
                                        animation: maximizeButtonAnimation1 0.3s ease-in-out forwards;
                                    }

                                    &:nth-child(2) {
                                        animation: maximizeButtonAnimation2 0.3s ease-in-out forwards;
                                    }

                                    &:nth-child(3) {
                                        animation: maximizeButtonAnimation3 0.3s ease-in-out forwards;
                                    }

                                    &:last-child {
                                        animation: maximizeButtonAnimation4 0.3s ease-in-out forwards;
                                    }
                                }
                            }
                        }
                    }

                    &.maximize-view {
                        span.icon {
                            > i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}
.banner-btn {
    background: $primary-color;
    @include border-radius(0.5rem);
    padding: 0.4rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    color: $white;
    span {
        font-size: $fs-12;
    }
    &:hover {
        box-shadow: 0 2px 10px 0 rgba(65, 132, 234, 0.75);

        &:before {
            animation: buttonAnimation 0.6s ease forwards;
        }
    }
}
.view-icon {
    background: url(app/static/icons/view-white-icon.svg) no-repeat;

    display: inline-block;
    width: 22px;
    height: 14px;
}
.timeline-icon {
    background: url(app/static/icons/vf-timeline-icon.svg) no-repeat;
    display: inline-block;
    width: 30px;
    height: 20px;
}
#{$step} {
    &.openComments {
        animation-fill-mode: initial !important;
        opacity: 1 !important;
    }
}

@keyframes buttonAnimation {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0%;
        opacity: 0.4;
    }
}

@keyframes buttonAnimationReverse {
    0% {
        left: 0%;
        opacity: 0.4;
    }

    100% {
        left: 100%;
        opacity: 0;
    }
}

@keyframes breadcrumbArrowAnimation {
    0% {
        left: -30px;
        opacity: 0;
    }

    30% {
        left: 0px;
        opacity: 1;
        transform: rotate(45deg);
    }

    100% {
        left: 0px;
        opacity: 1;
        transform: rotate(-135deg);
    }
}

@keyframes rightToLeft {
    0% {
        right: -30px;
        opacity: 0;
    }

    100% {
        right: 0px;
        opacity: 1;
    }
}

@keyframes visualHeaderAnimation {
    0% {
        top: -30px;
        opacity: 0;
    }

    100% {
        top: 0px;
        opacity: 1;
    }
}

@keyframes maximizeButtonAnimation1 {
    0% {
        left: 0px;
        top: 0px;
    }

    100% {
        left: -2px;
        top: -2px;
    }
}

@keyframes maximizeButtonAnimation2 {
    0% {
        right: 0px;
        top: 0px;
    }

    100% {
        right: -2px;
        top: -2px;
    }
}

@keyframes maximizeButtonAnimation3 {
    0% {
        right: 0px;
        bottom: 0px;
    }

    100% {
        right: -2px;
        bottom: -2px;
    }
}

@keyframes maximizeButtonAnimation4 {
    0% {
        left: 0px;
        bottom: 0px;
    }

    100% {
        left: -2px;
        bottom: -2px;
    }
}

@keyframes maximizeButtonAnimationReverse1 {
    0% {
        left: -2px;
        top: -2px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

@keyframes maximizeButtonAnimationReverse2 {
    0% {
        right: -2px;
        top: -2px;
    }

    100% {
        right: 0px;
        top: 0px;
    }
}

@keyframes maximizeButtonAnimationReverse3 {
    0% {
        right: -2px;
        bottom: -2px;
    }

    100% {
        right: 0px;
        bottom: 0px;
    }
}

@keyframes maximizeButtonAnimationReverse4 {
    0% {
        left: -2px;
        bottom: -2px;
    }

    100% {
        left: 0px;
        bottom: 0px;
    }
}

.download-icon {
    font-size: $fs-14;
    margin-right: 3.75px;
}
