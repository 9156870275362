@import 'app/assets/styles/css-assets';

.search-banner-container {
    width: 100%;
    position: relative;

    .banner-img {
        display: flex;
        width: 100%;
        height: 380px;
        background: url(app/static/hero_banner_img.jpg) no-repeat;
        background-position: center 36%;
        position: absolute;
        z-index: -1;
        background-size: 100%;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
    }

    &::before {
        height: 30px;
        top: 380px;
        background: $blue-800;
    }

    &::after {
        height: 285px;
        top: 410px;
        background: transparent;
    }

    .data-container {
        position: relative;
        z-index: 1;
    }

    .container-move-up {
        position: relative;
        top: -100px;
    }
}
