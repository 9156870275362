@import '../../../../assets/styles/css-assets.scss';

.bug-info-modal {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 60%;
    max-width: 60%;

    .modal-head {
        display: flex;
        align-items: center;
        padding: 1rem 0.8rem;
        background-color: $blue-400;
        color: $white;
        justify-content: center;
        position: relative;
        font-size: $fs-18;
        span {
            font-size: $fs-18;
        }

        .modal-close {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
        }
    }

    .bug-info-modal-body {
        .scroll-panel {
            max-height: 35rem;
            overflow-y: auto;
            padding: 0 0.3rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }

        .dp-content {
            padding: 1.5rem 2rem 2rem;
            box-sizing: border-box;
        }

        .form-btn-wrap {
            margin-top: 2rem;
            button {
                @include border-radius($radius);
                font-size: $fs-14;
                padding: 1.1rem 1.5rem;
                cursor: pointer;
            }

            button.primary {
                border: 1px solid $primary-color;
                background-color: $primary-color;
                color: $white;
            }

            button.default {
                border: 1px solid $gray-300;
                background-color: $white;
                color: $gray-300;
            }
        }

        .textfield-wrap {
            .ms-BasePicker-text {
                @include border-radius($radius);
                border-color: $gray-200;
                &::after {
                    @include border-radius($radius);
                }
            }

            .ms-TextField {
                .ms-TextField-fieldGroup {
                    .ms-TextField-field {
                        border: 1px solid $blue-500;
                        @include border-radius($radius);
                    }
                    @include border-radius($radius);

                    &::after {
                        @include border-radius($radius);
                    }
                }
            }
        }

        .bug-info-control-group {
            margin-bottom: 1rem;

            .error-msg {
                color: $red-200;
                margin-top: 0.5rem;
            }
        }

        .checkbox-wrap {
            margin-bottom: 1rem;
            .ms-Checkbox-checkbox {
                border: 1px solid $blue-500;
                @include border-radius(5px);
                height: 1.2rem;
                width: 1.2rem;
            }
        }
    }
}
