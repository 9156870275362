@import "../../../../assets/styles/css-assets";
.sidebar {
    padding: 0px 0 0px 40px;
    border-left: 1px solid $gray-600;
    min-height: calc(100vh - 410px);
    margin-bottom: 30px;
    position: relative;
    z-index: 6;
    .gap {
        gap: 30px;
    }
    label {
        font-size: $fs-12;
        color: $gray-100;
    }
    .date {
        font-size: $fs-18;
        color: $primary-color;
        line-height: $fs-24;
    }
    .type-dropdown {
        .ms-Dropdown-title {
            border-radius: 5px;
            font-family: arial;
            font-size: $fs-14;
            color: $gray-100;
            padding-top: 2px;
        }
    }
    .select-tags {
        position: relative;
        .select-tags-input {
            border: 1px solid $gray-200;
            @include border-radius($radius);
            font-size: $fs-14;
            line-height: $fs-20;
            color: $gray-300;
            padding: 2px 5px;
            margin-top: 4px;
            display: flex;
            flex-wrap: wrap;
            button {
                width: 50px;
                height: 26px;
                border: none;
                box-shadow: none;
                outline: none;
                margin-left: 10px;
                flex-grow: 1;
                background-color: transparent;
            }
        }
    }
}

.author-details {
    display: flex;
    margin-top: 10px;
    img {
        margin-right: 8px;
    }
    .details {
        display: flex;
        flex-direction: column;
        gap: 3px;
        .name {
            font-size: $fs-14;
            line-height: $fs-20;
            color: $gray-100;
        }
        .role {
            columns: $gray-200;
            font-size: $fs-12;
            line-height: $fs-16;
            color: $gray-200;
        }
    }
    .details-author-info-card-avatar {
        img {
            @include border-radius(50%);
        }
        .ms-Persona {
            max-width: 72px;
            margin-right: 20px;
        }
    }
}

.last-edited {
    .time {
        color: $gray-100;
    }
    .details {
        font-size: $fs-12;
        line-height: $fs-16;
        font-style: italic;
        color: $gray-240;
    }
}