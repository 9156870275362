@import '../../../../assets/styles/css-assets';

.sun-editor-editable {
    &.email {
        table {
            border:0px;
            border-collapse: collapse;
            padding: 0px;
            margin:0px;
            td,tr{
                border: 0px;    
                padding: 0px;
                margin:0px;           
            }
        }
    }
}
