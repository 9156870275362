@import '../../../assets/styles/variables.scss';

.triage-model {
    .ms-Modal-scrollableContent {
        overflow-y: auto !important;
    }
    .ms-Dialog-main {
        border-radius: 10px;
        overflow-y: hidden !important;
        max-width: 62.5rem;
    }
    .close-model {
        .ms-Button.ms-Button--icon {
            position: absolute;
            top: 5px;
            right: 10px;

            &:hover {
                background: none;
            }
            .ms-Button-flexContainer {
                color: $white;
            }
        }

        &.successCross {
            .ms-Button.ms-Button--icon {
                .ms-Button-flexContainer {
                    color: $primary-color;
                }
            }
        }
    }
    &.success-modal {
        .ms-Dialog-main {
            width: 925px !important;
            height: 384px !important;
        }
    }
}
.dots-menu {
    &.grid-list-view-ellipse-id {
        .ms-Button-icon {
            color: $gray-300;
            font-weight: 600;
            font-size: $fs-20;
        }
    }
    &.grid-details-view-ellipse-id {
        .ms-Button-icon {
            color: $primary-color;
            font-weight: 600;
            font-size: $fs-20;
        }
    }
    .ms-Button-menuIcon {
        display: none;
    }

    &:active {
        background-color: unset;
    }
}

#grid-list-view-ellipse-id {
    .ms-ContextualMenu-item {
        border-bottom: 1px solid $gray-600;
        .ms-ContextualMenu-link {
            padding-left: 0.5rem;
            box-sizing: border-box;
        }
    }
}

#grid-details-view-ellipse-id {
    .ms-ContextualMenu-item {
        border-bottom: 1px solid $gray-600;
        .ms-ContextualMenu-link {
            padding-left: 0.5rem;
            box-sizing: border-box;
        }
    }
}
