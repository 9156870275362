@import '../../../../../assets/styles/css-assets.scss';

.newsfeed-article-banner-container {
    color: $white;
    width: calc(100% - 8px);
    margin: auto;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .blue--overlay{
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        z-index: 1;
    }
    .banner-img{
        width: 100%;
        position: relative;
        display: block;
    }
    .banner-text{
        position: absolute;
        top:0px;
        z-index: 2;
        width: 100%;
    }
    .article-holder {
        padding:40px 0 64px 0;
        span.back-button{
            cursor: pointer;
            margin-bottom: 20px;
            svg{
                width: 18px;
                height:18px;
            }
        }
        .newsfeed-article-banner-date {
            font-size: $fs-12;
        }

        .newsfeed-article-banner-title {
            font-size: $fs-28;
        }

        .newsfeed-banner-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: $base-font-size;
            .tag {
                .icon {
                    img {
                        filter: invert(1) opacity(1) ;
                    }
                }
            }
            .hidden {
                display: none;
            }
        }
    }
}
