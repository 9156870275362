@import './css-assets';
$capsule: 'capsule';
$green: 'green';
$cyan: 'cyan';
$draft: 'Draft';
$submitted: 'Submitted';
$scheduled: 'ScheduledToPublish';
$published: 'Published';
$removed: 'Removed';
$redrafted: 'Redrafted';
.#{capsule} {
    display: inline-block;
    @include border-radius(100px);
    font-size: $fs-12;
    padding: 1px 8px;
    border: 1px solid $gray-300;
    background-color: $gray-600;
    color: $gray-100;
    line-height: $fs-16;
    .icon {
        margin-right: 4px;
        max-width: 16px;
        max-height: 16px;
        float: left;
        margin-top: 3px;
    }
    +.#{tag} {
        margin-left: 20px;
    }
    &.#{$capsule} {
        &--#{$green} {
            background-color: $cyan-800;
            border-color: $cyan-400;
            color: $gray-100;
        }
        &--#{$draft} {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
        &--#{$submitted} {
            background-color: $purple-250;
            border-color: $purple-250;
            color: $white;
        }
        &--#{$scheduled} {
            background-color: $cyan-800;
            border-color: $cyan-400;
            color: $gray-100;
        }
        &--#{$published} {
            background-color: $green-100;
            border-color: $green-100;
            color: $white;
        }
        &--#{$removed} {
            background-color: $yellow-400;
            border-color: $yellow-400;
            color: $black;
        }
        &--#{$redrafted} {
            background-color: $cyan-150;
            border-color: $cyan-150;
            color: $white;
        }
        &--#{$cyan} {
            background-color: $cyan-100;
            color: $gray-100;
        }
        &--transparent {
            background-color: transparent;
        }
        &--color-green {
            color: $green-200;
        }
        &--color-red {
            color: $red-200;
        }
    }
}