@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes toggleLeft {
    0% {
        opacity: 0.5;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes toggleRight {
    0% {
        opacity: 0.5;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
