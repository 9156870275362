@import 'app/assets/styles/css-assets';
.custom-textfield {
    border: 1px solid $gray-250;
    cursor: pointer;
    background: $white;
    overflow: hidden;
    padding: 0.9375rem;
    @include border-radius($radius);
    &.error-field {
        border: 1px solid $red-200 !important;
    }
    textarea {
        font-size: 14px;
        font-weight: 400;
        font-family: Segoe UI;
        color: rgb(50, 49, 48);
        width: 100%;
        resize: none;
        border: hidden;
        &:focus-visible {
            border: hidden;
            outline: none;
        }
    }
    &:focus-within {
        border: 2px solid $primary-color;
        animation: mymove 2s infinite;
    }
    .description-box {
        display: flex;
        position: relative;
        color: $gray-200;
        @include border-radius($radius);
        padding: 0.5rem 0.625rem;
        &.add-bg {
            background: $gray-650;
        }
        .ms-TooltipHost {
            display: table-cell;
            position: relative;
            cursor: pointer;
            top: 0.1875rem;
            left: 0.25rem;
        }
        .info-text {
            font-weight: 600;
            color: $blue-200;
        }
    }
}