@import '../../../../assets/styles/css-assets.scss';

.delete-tags-container {
    min-height: 200px;
    .close-model {
        position: absolute;

        right: 10px;
        top: 10px;
        .ms-Button,
        .ms-Button:hover {
            color: $white;
            background: unset;
        }

        &.close-primary {
            color: $blue-400;
            .ms-Button,
            .ms-Button:hover {
                color: $blue-400;
                background: unset;
            }
        }
    }

    .ms-Modal-scrollableContent {
        .modal-heading {
            background: $primary-color;
            color: $white;
            text-align: center;
            @include border-radius(1px 1px 0 0);

            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 12px;
        }
    }

    .body-tags-container {
        padding: 25px 45px;

        .del-txt {
            font-size: 16px;
            line-height: 22px;
            color: #201f1e;
            text-align: center;
            display: block;
            margin: 17px 0 31px;
        }
        .warning-container {
            text-align: center;
            .warning-icon {
                background: url(app/static/icons/warning_tag.svg) no-repeat;
                width: 49px;
                height: 45px;
                display: inline-block;
                position: relative;
                top: 2px;
            }
        }
        .btm-btn-container {
            text-align: center;
            button {
                margin: 0 12px 0 0;
                font-weight: 600;
            }

            .ms-Button--default {
                border: 1px solid #d2d0ce;
                color: #d2d0ce;
            }
        }
    }
}
