@import '../../../../assets/styles/css-assets.scss';
$resource: 'resource';

.helpful-resources-wrapper {
    box-sizing: border-box;
    .ms-Grid-row {
        display: flex;
        flex-wrap: wrap;
        .ms-Grid-col {
            min-height: 170px;
            margin-bottom: 20px;
        }
    }
    .#{$resource}-card {
        background: $white;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        @include border-radius($radius);
        margin-bottom: 20px;
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        font-size: $fs-14;
        color: $gray-200;
        transition: 0.3s;
        margin: 0px;
        height: 100%;
        position: relative;
        .#{$resource} {
            &__icon {
                width: 32px;
                height: 32px;
                margin-right: 12px;
                img {
                    width: 100%;
                }
            }
            &__details {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                width:90%;
                
                .ms-Icon.delete-icon {
                    position: absolute;
                    top:20px;
                    right:20px;
                    color:$gray-200; 
                    padding:5px;
                    cursor: pointer;
                    &:hover{
                        color: $primary-color;
                    }
                }
                .#{$resource} {
                    &__title {
                        color: $primary-color;
                        font-weight: 600;
                        font-size: $base-font-size;
                        margin-bottom: $radius;
                        cursor: pointer;
                        position: relative;
                        max-width: calc(100% - 15px);
                        a{
                            float: left;
                        }
                       
                    }
                    &__description {
                        font-size: $fs-14;
                        flex-grow: 1;
                    }
                    &__footer {
                        margin-top: auto;
                        padding-top: 20px;
                        text-align: right;
                        .more-link {
                            font-size: $fs-14;
                            float: right;
                        }
                    }
                }
            }
        }
        &:hover {
            transform: scale(1.03);
        }
    }
}
