@import '../../../../../assets/styles/variables.scss';

.disabledSearch {
    display: none;
}
.customer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom:20px;
}
.addCustomerHeader {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:660px;
}
.customer-modal-header{
    background-color: #0078D4;
    width: 660px;
    height: 54px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom:20px;
    margin-left:3px;
    margin-right:3px;
    margin-top:3px;
    text-align: center;
    display:flex;
}
.companyNameStyles {
    display: flex;
    justify-content: flex-start;
    width: 580px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 19px;
}
.confirmation-buttons {
    display: flex;
}
.modal-footer {
    text-align: left;
    display:flex;
    justify-content: left;
    min-width: 610px;
    margin-top: 20px;
    margin-bottom:41px;
}
.fontStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    color: #000000;
    /* identical to box height, or 143% */
}
.regular-text-field-modal {
    .ms-TextField-fieldGroup {
        padding: 0 15px;
        height: 45px;
        width: 588px;
        margin-top: 15px;
        margin-bottom:20px;
        input {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    height: 45px;
}