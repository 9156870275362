@import '../../../../assets/styles/css-assets.scss';

.add-tags-container {
    min-height: 200px;
    .close-model {
        position: absolute;

        right: 10px;
        top: 10px;
        .ms-Button,
        .ms-Button:hover {
            color: $white;
            background: unset;
        }

        &.close-primary {
            color: $blue-400;
            .ms-Button,
            .ms-Button:hover {
                color: $blue-400;
                background: unset;
            }
        }
    }

    .ms-Modal-scrollableContent {
        .modal-heading {
            background: $primary-color;
            color: $white;
            text-align: center;
            @include border-radius(1px 1px 0 0);

            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 12px;
        }
    }

    .body-tags-container {
        padding: 25px 45px;

        .btm-btn-container {
            text-align: left;
            margin-top: 40px;
            button {
                margin: 0 12px 0 0;
                font-weight: 600;
            }

            .ms-Button--default {
                border: 1px solid $gray-400;
                color: $gray-400;
            }
        }

        .ms-Label {
            margin: 0 0 10px 2px;
        }
    }

    .helper-txt {
        color: $red-100;
        font-size: $fs-15;
        line-height: 25px;
        display: block;
        padding-left: 15px;
        animation: slideDown2 0.5s ease forwards;
        z-index: 0;
        position: relative;
        margin-top: 5px;
    }

    input.ms-TextField-field {
        padding-left: 10px;
    }
}
