@import 'app/assets/styles/css-assets';

.search-results-container {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    margin-top: 5.6rem;
    margin-bottom: 2rem;

    .left-sidebar {
        padding-right: 0.3rem;
        box-sizing: border-box;
        width: 25%;
        min-width: 25%;
        .left-sidebar-container {
            background-color: $white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            @include border-radius($radius);
            padding: 1rem;
            box-sizing: border-box;
            position: sticky;
            top: 0;
            .left-sidebar-filters {
                padding: 0 1rem;
            }
        }
        h4 {
            display: flex;
            font-weight: 600;
            justify-content: space-between;
            align-items: baseline;
            border-bottom: 1px solid $gray-600;
            padding-bottom: 26px;
            margin-top: 6px;

            span {
                color: $black;
                font-size: $fs-16;
                line-height: 22px;
            }

            a {
                font-size: $fs-12;
                line-height: 16px;
                color: $blue-400;
                font-weight: 600;
                text-decoration: none;
            }
        }

        .chips-container {
            margin-bottom: 1.6rem;
            padding: 0 1rem;
            .chips {
                background: $blue-800;
                padding: 6px 10px;
                border-radius: 8px;
                margin: 5px 5px 5px 0;
                display: inline-block;
                .chip-item {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.25rem;
                    max-height: 1rem;
                    -webkit-line-clamp: 1; /* Write the number of 
                              lines you want to be 
                              displayed */
                    -webkit-box-orient: vertical;
                }
                .chip {
                    color: $blue-400;
                    font-size: 12px;
                    line-height: 16px;
                    text-decoration: none;
                    cursor: pointer;
                }
                .remove-container {
                    text-align: center;
                    background-color: rgba($blue-600, 0.1);
                    border-radius: 4px;
                    margin-left: 8px;
                    top: 2px;
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    cursor: pointer;
                    .remove {
                        background: url(app/static/icons/cross-icon.svg) no-repeat;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        h5 {
            color: $black;
            font-size: $fs-16;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 0.9rem;
        }

        .ms-Checkbox {
            position: relative;
            display: block;
            min-height: 1.5rem;

            &.is-checked {
                .label-text {
                    color: $black;
                }
            }
            .ms-Checkbox-checkbox {
                margin-right: 12px;
                border-color: $blue-400;
            }
            .label-text {
                color: $gray-100;
                font-size: $fs-14;
                line-height: 22px;
                .number-count {
                    position: absolute;
                    right: 0;
                    top: 4px;
                    font-size: $fs-14;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }

        .clear-all-btn {
            font-size: $fs-12;
            line-height: 16px;
            color: $blue-400;
            font-weight: 600;
            text-decoration: none;
        }
    }

    .right-sidebar {
        box-sizing: border-box;
        padding-left: 0.3rem;
        width: 75%;
        min-width: 75%;
        .right-sidebar-container {
            background-color: $white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            @include border-radius($radius);
            padding: 1rem;
            box-sizing: border-box;
            height: 100%;
        }

        .top-controls {
            display: flex;
            .tab-buttons {
                flex: 4;
            }
            .drop-dropdown-container {
                .selectType {
                    min-width: 200px;
                }
            }
        }

        .buttonsContainer {
            .btn {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $gray-200;
                padding: 0.375rem 0rem;
                margin: 0 1.9rem 0 0.8rem;
                border-bottom: 2px solid transparent;
                padding-bottom: 6px;
                background: transparent;
                color: $gray-200;
                border-radius: 0;
                cursor: pointer;
                &:focus:not(:focus-visible) {
                    outline: none;
                }
                &.active {
                    border-bottom: 2px solid $primary-color;
                    background: transparent;
                    color: $blue-400;
                    font-weight: 600;
                    @include border-radius(0);
                }
            }
        }
        .search-list-container {
            margin: 30px 0;
            border: 1px solid $gray-600;
            box-sizing: border-box;
            @include border-radius($radius);
            padding: 1.3rem 0.5rem 1.3rem 1.3rem;
            min-height: 15rem;

            .scroll-panel {
                height: 100%;
                overflow-y: scroll;
                padding: 0 0.3rem;
                &::-webkit-scrollbar {
                    width: 0.3rem;
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba($color: $gray-300, $alpha: 0.2);
                    @include border-radius(4px);
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: rgba($color: $gray-300, $alpha: 0.5);
                }
                &::-webkit-scrollbar-track {
                    @include border-radius(0);
                }
            }
        }
    }
}

.pagination {
    list-style: none;

    li {
        display: inline-block;
        padding: 6px 13px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 14px;
        border: 1px solid transparent;
        cursor: pointer;
        margin-right: 5px;

        &.active {
            background: blue;
            color: $white;
        }
        &.previous,
        &.next {
            border: 1px solid $primary-color;
            border-radius: 1px;
            color: $primary-color;

            &.disabled {
                background: $yellow-800;
                border: 1px solid $gray-400;
                box-sizing: border-box;
                border-radius: 1px;
                color: $gray-400;
                cursor: auto;
            }
        }

        a {
            &:focus {
                outline: none;
            }
        }
    }
}

.ms-ChoiceField-wrapper,
.ChoiceGroup10-All Dates {
    width: 100%;
    label {
        width: 100%;
    }
}

.label-text {
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
}
