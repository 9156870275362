/*
    Add new variables to variables.module.scss if you want to import and use in app components.
    variables.module imports this file and uses the variables to avoid changes in both files.
*/

//Font family
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
SegoeUI,
Arial,
sans-serif,
'Helvetica Neue',
Helvetica;
//font sizes
$base-font-size: 16px;
$fs-10: 0.625rem;
$fs-9: 0.563rem;
$fs-11: 0.688rem;
$fs-12: 0.75rem;
$fs-13: 0.813rem;
$fs-14: 0.875rem;
$fs-15: 0.938rem;
$fs-16: 1rem;
$fs-18: 1.125rem;
$fs-20: 1.25rem;
$fs-22: 1.375rem;
$fs-24: 1.5rem;
$fs-26: 1.625rem;
$fs-28: 1.75rem;
$fs-30: 1.875rem;
$fs-32: 2rem;
$fs-36: 2.25rem;
$fs-42: 2.625rem;
$fs-68: 4.25rem;
//Original colors
$black: #000000;
$white: #ffffff;
$black-opacity-40: rgba(0, 0, 0, 0.4);
//Blue color shades
$blue-200: #004578;
$blue-300: #106ebe;
$blue-350: #006cbe;
$blue-400: #0078d4;
$blue-500: #2b88d8;
$blue-600: #00bfff;
$blue-700: #c7e0f4;
$blue-750: #deecf9;
$blue-800: #eff6fc;
$primary-color: $blue-400;
//Gray color shades
$gray-100: #323130;
$gray-200: #605e5c;
$gray-240: #757372;
$gray-250: #8a8886;
$gray-300: #a19f9d;
$gray-350: #c8c6c4;
$gray-400: #d2d0ce;
$gray-500: #e1dfdd;
$gray-600: #edebe9;
$gray-650: #f3f2f1;
$gray-700: #faf9f8;
$gray-800: #eff6fc;
//Red color shades
$red-50: #9b0062;
$red-100: #b22222;
$red-200: #dc143c;
$red-300: #da3b01;
//Orange color shades
$orange-300: #c24e00;
$orange-400: #ffaa44;
//Yellow color shades
$yellow-100: #ffb800;
$yellow-400: #ffe6c7;
$yellow-800: #faf9f8;
//Green color shades
$green-100: #107c10;
$green-150: #00ad56;
$green-200: #6b8e23;
$green-250: #4d7f4d;
$green-300: #6bb700;
$green-400: #66cdaa;
$green-500: #d1f0e6;
$green-600: #cfe5cf;
$green-700: #498205;
//Purple color shades
$purple-100: #9370db;
$purple-200: #7b68ee;
$purple-250: #815fcb;
$purple-300: #8464c5;
//Cyan color shades
$cyan-100: #00b7c3;
$cyan-150: #007d85;
$cyan-400: #00b7c3;
$cyan-800: #b3e9ed;
//Brown color shades
$brown-100: #c19c00;
//Radius
$radius: 8px;
//Internal Global Color
$internal: $green-700;
//External Global Color
$external: $red-200;
