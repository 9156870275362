@import './assets/styles/css-assets.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
    display: flex;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
}

.msftLogo {
    padding: 0px 30px;
}

.headerDivider {
    width: 0.5px;
    background-color: white;
    height: 100%;
}

.headerTitle {
    font-size: 1.3em;
    padding: 0 30px;
    color: white;
}
