@import '../../../../assets/styles/variables.scss';

.fc-response {
    padding: 1.5rem 4rem;
    transition: 0.5s;
    position: relative;
    min-height: 400px;

    .btn-close {
        position: absolute;
        right: 2rem;
    }

    .fc-response-icon {
        font-size: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 3rem;
    }

    .fc-res-title {
        font-size: $fs-24;
        font-weight: 600;
        text-align: center;
        margin: 1rem 0;
    }

    .fc-res-text {
        line-height: 2em;
        margin-bottom: 3rem;
        text-align: center;
    }
}
