@import '../../../assets/styles/css-assets.scss';

.powerBI-slider {
    &.slick-slider {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        margin: 20px 0;
    }
    .slick-dots {
        position: inherit;
        bottom: auto;
        width: 20%;
        display: flex;
        flex-direction: column;
        text-align: left;
        background-color: $gray-700;
        padding: 10px 0px;
        @include border-radius($radius);
        .title {
            font-size: $fs-18;
            font-weight: 600;
            text-align: center;
            border-bottom: 1px solid $gray-400;
            padding-bottom: 10px;
            margin-bottom: 1px;
        }
        ul {
            margin:0px;
            padding: 0px;
            overflow-y: auto;
            max-height: calc(100vh - 50px);
        }
        li {
            width: 100%;
            padding: 0px 15px;
            margin: 0px 0 1px;
            height: inherit;
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            color: $gray-100;

            > div {
                display: flex;
                width: 100%;
                cursor: pointer;
                font-size: $fs-16;
                line-height: $fs-18;
                padding: 8px 0px;
                font-size: $fs-14;
            }
            &:hover,
            &.slick-active {
                background-color: $primary-color;
                color: $white;
            }
            &.slick-active {
                > div {
                    font-weight: 600;
                }
            }
        }
    }
    .slick-list {
        width: 85%;
    }
}
