.dragCellStyles {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 40px;

    .k-icon .k-i-reorder {
        padding:0 !important;
    }
}
