@import '../../../assets/styles/css-assets.scss';

.request-details-container {
    .manage-request-detailed-container {
        color: $gray-100;
        .details-panel-container {
            box-shadow: unset;
            .scroll-panel {
                padding: 0;
            }
        }
    }
    .back-request {
        font-weight: 600;
        font-size: $fs-18;
        line-height: 24px;
        color: $gray-100;
        text-decoration: none;
        .arrow-icon {
            background: url(app/static/icons/arrow-black.svg) no-repeat;
            display: inline-block;
            width: 16px;
            height: 14px;
            margin-right: 10px;
            position: relative;
            top: 1px;
        }
    }

    .no-results-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30rem;
        box-sizing: border-box;
        font-size: $fs-20;
        border: 1px solid $gray-500;
        @include border-radius($radius);
        margin-bottom: 1rem;
    }
}
