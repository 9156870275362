@import 'app/assets/styles/css-assets';
.visual-flow-body {
    position: relative;
    .vf-comments-container {
        width: 300px;
        background-color: #fff;
        background: #ffffff;
        box-shadow: -2px 2px 2px 3px rgba(0, 0, 0, 0.1);
        @include border-radius(5px);
        position: absolute;
        z-index: 9999;
        opacity: 1;
        padding: 16px;
        left: -9999px;
        transition: all 0.5s ease-out;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        .custom-row {
            display: flex;
            flex-direction: row;

            .step-title-section {
                margin-left: 60px;
            }

            .step-title {
                font-weight: 600;
                font-size: $fs-14;
                line-height: $fs-20;
                color: $gray-100;
            }

            .last-activity {
                font-size: $fs-10;
                line-height: $fs-12;
                display: flex;
                align-items: center;
                color: #8a8886;
                margin-top: 4px;
            }

            .attachments {
                font-size: $fs-12;
                line-height: 16px;
                color: #8a8886;
                display: flex !important;
                flex-direction: row !important;
                margin-top: 7px;
            }
        }

        .status {
            background: $green-200;
            padding: 3px 8px;
            width: fit-content;
            margin-bottom: 7px;
            border-radius: 11px;
            font-size: $fs-10;
            color: $white;
            text-transform: capitalize;
            &.bg-active {
                background-color: #6b8e23;
                color: $white;
            }

            &.bg-closed {
                background-color: #b22222;
                color: $white;
            }

            &.bg-resolved {
                background: #00bfff;
                color: $white;
            }

            &.bg-new {
                background-color: #9370db;
                color: $white;
            }
        }

        .comment-icon {
            margin-top: 3px;
        }

        .comment-row {
            margin: 21px 15px;
            .details-102 {
                display: none;
            }
            .root-42 {
                display: block;
            }
        }

        .profile-pic {
            width: 30px;
            height: 30px;
        }

        .comment-detail {
            margin-left: 0px;
            max-width: 210px;
            width: 190px;

            .tagged-user {
                color: $primary-color;
                font-weight: 600;
            }
        }

        .comment-display-name {
            font-weight: 600;
            font-size: $fs-14;
            line-height: $fs-20;
            color: $gray-100;
        }

        .comment-text {
            font-weight: normal;
            font-size: $fs-14;
            line-height: $fs-18;

            img {
                max-width: 100%;
            }
            a {
                color: $primary-color;
            }
        }

        .comment-time {
            font-weight: normal;
            font-size: $fs-10;
            line-height: $fs-12;
            color: #8a8886;
            margin-top: 4px;
        }

        .step-icon {
            display: inline-block;
            width: 50px;
            height: 50px;
            position: absolute;
        }

        &.openComments {
            display: block;
        }
        &.hideComments {
            display: none;
        }

        &.hideComments {
            animation-direction: reverse;
        }
    }

    .vf-comments-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $gray-600;
        border-radius: 20px;
    }

    .vf-comments-container::-webkit-scrollbar {
        width: 6px;
        background-color: $gray-200;
        border-radius: 20px;
    }

    .vf-comments-container::-webkit-scrollbar-thumb {
        background-color: $gray-600;
        border-radius: 20px;
    }
}

@keyframes commentsSectionAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.hover-child-comment-container {
    display: block;
    border-left: 0.5px solid #c7e0f4;
    margin-top: -43px;
    margin-left: 30px;
    padding-top: 27px;
    padding-left: 14px;
    padding-bottom: 1px;
    margin-bottom: -18px;
}
