@import '../../../assets/styles/css-assets.scss';

.custom-reporting {
    min-height: calc(100vh - 330px);
    &__header {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        align-items: center;
        .left-column {
            display: flex;
            flex-grow: 1;
            .tabs {
                display: flex;
                flex-direction: row;
                button {
                    margin-right: 30px;
                    font-size: $fs-14;
                    padding: 0px 0 7px;
                    border: none;
                    border-bottom: 2px solid transparent;
                    background-color: transparent;
                    cursor: pointer;
                    line-height: $fs-20;
                    color: $gray-100;
                    text-decoration: none;
                    @include border-radius(0px);

                    &.active {
                        color: $primary-color;
                        font-weight: 600;
                        border-bottom: 2px solid $primary-color;
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
        .right-column {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            .sort-dropdown {
                display: flex;
                align-items: center;
                margin-right: $radius;
                .sort-by {
                    width: 65px;
                }
                .ms-Dropdown-title {
                    border-color: $primary-color;
                }
            }
        }
    }
    &__data {
        position: relative;
    }
    .k-command-cell {
        button {
            border: none;
            background-color: transparent;
            padding: 2px 3px;
            margin-right: 8px;
            cursor: pointer;
            i {
                font-size: $fs-14;
            }
        }
    }
}
.inline-radio {
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        .ms-ChoiceField {
            margin-right: 20px;
        }
    }
}
.ms-Stack {
    .ms-Label {
        font-size: $fs-14;
        font-weight: 600;
        color: $gray-100;
        box-sizing: border-box;
        box-shadow: none;
        margin: 0px;
        display: block;
        padding: 5px 0px;
        overflow-wrap: break-word;
    }
}
.sharing-report {
    color: $gray-100;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
    label {
        font-weight: 600;
        font-size: $fs-14;
        line-height: $fs-20;
        margin-bottom: 20px;
    }
    .report-title {
        font-size: $fs-18;
    }
    .report-link {
        text-decoration: underline;
        color: $primary-color;
    }
}
.delete-confirmation,
.success-confirmation {
    .sharing-report {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        label {
            font-weight: normal;
            font-size: $fs-18;
            margin-bottom: 0px;
            margin-right: 10px;
        }
    }
}
.delete-confirmation {
    i.ms-Icon {
        font-size: 40px;
        color: $red-100;
    }
}
.k-grid .link--url {
    color: $primary-color;
    text-decoration: underline;
    display: inline-block;
    line-height: 20px;
    word-break: break-all;
}

.grid-loader {
    position: absolute;
    z-index: 999999;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.icon-border {
    display: flex;
    justify-content: center;
}

.notification-icon {
    width: max-content;
    padding: 10px;
    border: 2px solid $primary-color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    img{
        margin: auto;
    }
}
