@import '../../../../assets/styles/variables.scss';
@import '../../../../../app/assets/styles/css-assets';

.customerStyles {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 20px;
}
.cssContainerStyles {
    height: 100px;
}

.customer-request-type {
    margin-bottom: 10px;
}
.labelStyles {
    font-size: 14px;
    color: $blue-400;
    font-weight: bolder;
    font-family: $font-family;
    margin-bottom:4px;
    margin-left: 26px;
}

.requestContainer {
    display: flex;
    flex-direction: column;
}
.customer-impact {
    .ms-TextField-fieldGroup {
            border: 1px solid $gray-250;
    }
}

.customer-name {
    .ms-TextField-fieldGroup {
        input {
            text-align: center;
            &::placeholder {
                text-align: center;
            }
        }
        input:focus {
            text-align: center;
        }
        input:focus::placeholder {
            color: transparent;
        }
        margin-right:.3px;
        
    }
}
.format-field-cont {
    &.error-feild {
        border: 1px solid $red-200;
    }
    @include border-radius($radius);
    height: 45px !important;
    border: 1px solid $gray-250;
    overflow: hidden;
    padding: 0 15px;
    background: $white;
    &:focus-within {
        border: 2px solid $primary-color;
        animation: mymove 2s infinite !important;
    }
    .format-field {
        width: 100%;
        border: none;
        color: rgb(50, 49, 48);
        display: flex;
        &::placeholder {
            color: $gray-300;
        }
    }
}
  .searchOverlay {
    position: relative;
    top: 0;
    left: 0;
  }

  .parentClassOverlay {
      position: relative;
  }

.popUpContainer {
    display:flex;
    align-items: center;
    justify-content: end;

}

.hideSearch {
    .show{
        display: block;
    }
    .hide {
        display: none;
    }
}

.searchCust {
    .hide {
        display: none;
    }
    .show {
        display:block;
    }
}

.parent-div-back-button {
    position: relative;
    right: 750px;
    top: 70px;
}

.messageContainer {
    display: flex;
    border: 2px solid black;
    width: 300px;
    height:50px;
    background-color: white;
}

.disable {
    display: none;
}

.account-id {
    .ms-TextField-fieldGroup {
        input {
            text-align: center;
            &::placeholder {
                text-align: center;
            }
        }
        input:focus {
            text-align: center;
        }
        input:focus::placeholder {
            color: transparent;
        }
        
        border-top-left-radius: 0% !important;
        border-bottom-left-radius: 0% !important;
    }
}

.regular-text-field {
    .ms-TextField-fieldGroup {
        padding: 0 15px;
        height: 45px;
        margin-bottom: 20px;
        input {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    height: 35px;
}

.customer-info {
    .ms-TextField-fieldGroup {
            height: 47px;
            width:266px;
    }
    
}

.textfieldContainer {
    display: flex;
    flex-direction: column;
    border-right: 0px;

}
.cust-impact {
    min-width:50rem;
}
.customerImpactStyles {
    margin-bottom: 2rem;
    margin-top:1rem;
}
.deviceAndRevenueContainer {
    margin-bottom: 1rem;
    margin-top: 2rem;
}
.countryMargins {
    margin-top:3rem;
}