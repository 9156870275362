@import '../../assets/styles/css-assets';
$btn: 'btn';
$blue: 'primary';
$cyan: 'cyan';
$green: 'green';
$red: 'red';
button {
    &.#{$btn} {
        @include border-radius(8px);
        font-size: $fs-14;
        padding: 6px 20px;
        border: none;
        background-color: $gray-600;
        color: $gray-100;
        line-height: $fs-20;
        cursor: pointer;
        align-items: center;
        min-width: 70px;
        display: inline-flex;
        justify-content: center;
        .icon {
            margin-right: 8px;
            max-width: 16px;
            max-height: 16px;
            line-height: $fs-16;
            font-size: $fs-16;
        }
        .icon-svg{
            margin-right: 8px;
            max-width: 16px;
            max-height: 16px;
            overflow: hidden;
            svg{
                fill: $gray-100;
                width: 14px;
                height: auto;  
            }
        }
        &.#{$btn} {
            &--#{$blue} {
                background-color: $primary-color;
                color: $white;
                .icon-svg{
                    svg{
                        fill: $white;             
                    }
                }
            }
            &--#{$cyan} {
                background-color: $cyan-100;
                color: $white;
                .icon-svg{
                    svg{
                        fill: $white;             
                    }
                }
            }
            &--#{$green} {
                background-color: $green-100;
                color: $white;
                .icon-svg{
                    svg{
                        fill: $white;             
                    }
                }
            }
            &--#{$red} {
                background-color:$red-100;
                color: $white;
                .icon-svg{
                    svg{
                        fill: $white;             
                    }
                }
            }
            &--transparent {
                background-color: transparent;
                border: 1px solid $gray-300;
                .icon-svg{
                    svg{
                        fill: $gray-100;             
                    }
                }
            }
            &--vertical-ellipsis {
                background-color: transparent;
                padding-right: 10px;
                padding-left: 10px;
                background: url(../../static/icons/vertical-ellipsis.svg) no-repeat center;
                text-indent: -9999px;
            }
            &--text {
                background-color: transparent;
                border: none;
                padding-left: 0px;
            }
            &--sm{
                padding:5px 10px;
                font-size: $fs-12;
                .icon{
                    margin-right: 4px;
                    max-width: 10px;
                    max-height: 10px;
                    line-height: $fs-10;
                    font-size: $fs-10;
                }
            }
        }

        + .#{$btn} {
            margin-left: 20px;
        }

        &:disabled {
            background-color: $gray-600;
            color: $gray-300;
            cursor: auto;
            .icon-svg{
                svg{
                    fill: $gray-300;             
                }
            }
        }
    }
}
.#{$btn} {
    &-group {
        display: flex;
        .#{$btn} {
            margin: 0px;
            padding:8px 20px;
            &:first-child {
                @include border-radius($radius 0px 0px $radius);
            }
            &:last-child {
                @include border-radius(0px $radius $radius 0);
            }
            + .#{$btn} {
                margin-left: 0px;
            }
        }
        &--icons {
            .#{$btn} {
                .icon-svg{
                    display: flex;
                }
                .icon, .icon-svg {
                    margin-right: 0px !important;
                }
            }
        }
    }
}
