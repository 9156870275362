@import './css-assets';

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label {
        font-size: $fs-14;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .form-control {
        border: 1px solid $gray-300;
        padding: $radius;
        @include border-radius($radius);
        font-size: $fs-14;
        color: $gray-100;
        margin-bottom: 10px;
        &.no-border {
            border: none;
        }
    }
}

input {
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray-400;
        font-weight: 400;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray-400;
        font-weight: 400;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray-400;
        font-weight: 400;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray-400;
        font-weight: 400;
    }
}
.ms-TextField-fieldGroup,
.ms-Dropdown,
.ms-Dropdown-title {
    @include border-radius($radius);
    &:after {
        @include border-radius($radius);
    }
}
.progressbar {
    .ms-ProgressIndicator-progressTrack,
    .ms-ProgressIndicator-progressBar {
        @include border-radius($radius);
        overflow: hidden;
    }
    .ms-ProgressIndicator-progressBar{
        background-color:$primary-color;
    }
}
