@import 'app/assets/styles/css-assets';

.you-need-container {
    .you-box-container {
        background: $gray-700;
        padding: 30px;
        @include border-radius(8px);
        margin: 30px 0;
        box-sizing: border-box;
        border: 1px solid $gray-400;
        text-align: center;
        .txt-cont {
            margin: 0;
            color: $gray-100;
            font-style: normal;
            font-weight: normal;
            font-size: $fs-18;
            line-height: 44px;
            text-align: center;
            .requestHelpBtn {
                font-size: $fs-18;
                line-height: 24px;
                color: $primary-color;
                border: 1px solid $primary-color;
                box-sizing: border-box;
                @include border-radius(8px);
                text-decoration: none;
                font-weight: 400;
                min-height: 40px;
                margin-left: 60px;
                padding: 14px 30px;

                &:hover {
                    color: $white;
                    background: $primary-color;
                    text-decoration: none;
                }
            }
        }
    }
}
