@import '../../../../assets/styles/css-assets.scss';

.search-news-container {
    display: inline-block;
    direction: rtl;
    position: relative;

    .search-news-input {
        color: $gray-100;
        background: $white;

        @include border-radius($radius);
        width: 180px;

        .ms-TextField-fieldGroup {
            width: 180px;
            box-sizing: border-box;
            @include border-radius($radius);

            background: $white;
            color: $primary-color;
            cursor: pointer;
            height: 30px;

            text-align: left;
            transition: width 0.5s;

            .ms-TextField-field {
                padding: 0.5rem 2.5rem 0.5rem 2rem;
                font-size: 14px;
                color: $gray-100;
                direction: ltr;

                &::placeholder {
                    color: $gray-100;
                    font-size: 14px;
                }
                &:hover {
                    &::placeholder {
                        color: $gray-100;
                    }
                }
                &:focus {
                    outline: none;
                }
            }

            i {
                top: 6px;
                right: calc(100% - 1.5rem);
                font-size: 15px;
                line-height: 18px;
                color: $primary-color;
                transform: scaleX(-1);
            }
        }
        &.animation {
            .ms-TextField-field,
            .ms-TextField-fieldGroup {
                &:hover {
                    outline: none;
                    width: 338px;
                }

                &:focus-within {
                    outline: none;
                    width: 338px;
                }
            }
        }
    }

    .micro-icon {
        background: url(app/static/icons/microVoice.svg) no-repeat;
        background-size: 13px 18px;
        width: 17px;
        height: 24px;
        display: inline-block;
        top: 5px;
        right: 8px;
        position: absolute;
        cursor: pointer;
        z-index: 3;
    }
    .recording-position {
        top: 5px;
        right: 12px;
    }
    .recording-row {
        display: flex;
        flex-direction: row;

        .first-line {
            height: 5px;
            width: 2px;
            background-color: $primary-color;
            margin-top: 7.5px;
            border-radius: 4px;
            animation: voiceGrow-1 1s ease 0s infinite;
        }

        .second-line {
            height: 12px;
            width: 2px;
            background-color: $primary-color;
            margin-left: 1.5px;
            margin-top: 4px;
            border-radius: 4px;
            animation: voiceGrow-2 1s ease 0s infinite;
        }

        .third-line {
            height: 20px;
            width: 2px;
            background-color: $primary-color;
            margin-left: 1.5px;
            border-radius: 4px;
            animation: voiceGrow-3 1s ease 0s infinite;
        }

        .fourth-line {
            height: 12px;
            width: 2px;
            background-color: $primary-color;
            margin-left: 1.5px;
            margin-top: 4px;
            border-radius: 4px;
            animation: voiceGrow-2 1s ease 0s infinite;
        }

        .fifth-line {
            height: 5px;
            width: 2px;
            background-color: $primary-color;
            margin-top: 7.5px;
            margin-left: 1.5px;
            border-radius: 4px;
            animation: voiceGrow-1 1s ease 0s infinite;
        }
    }
}
