@import '../../../../assets/styles/css-assets.scss';

.news-banner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    .top-stories-container {
        overflow: hidden;
        max-height: 898px;
        padding: 10px;
        margin: -10px;
        .slick-vertical {
            .slick-slide {
                max-height: 210px;
                width: 100% !important;
            }
            .slick-list {
                overflow: hidden;
                padding: 10px 10px 0;
                margin: -10px -10px 0;
            }
        }
    }
    .data-container {
        display: block;
    }
    .data-recommend-container {
        padding: 0 0px;
    }

    .author-news-container {
        background: linear-gradient(107.2deg, $purple-100 0%, $purple-200 100%);
        height: 300px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .text-left {
        padding-right: 0px;
    }

    .text-right {
        text-align: right;
    }

    .container-move-up {
        position: relative;
        top: -100px;
    }
    @include xs {
        .top-stories-container {
            .news-card--small {
                .card-footer {
                    flex-wrap: wrap;
                }
            }
            .tags > .tag {
                &.tag--sc:not(:first-child):not(.more-links) {
                    display: none;
                }
            }
        }
        .news-card--wide {
            .card-img {
                width: 60%;
            }
            .tags > .tag {
                &.tag--sc:not(:first-child):not(.more-links) {
                    display: none;
                }
            }
        }
    }
    @include sm {
        .news-card--wide {
            .card-img {
                width: 60%;
            }
            .tags > .tag {
                &.tag--sc:not(:first-child):not(.more-links) {
                    display: none;
                }
            }
        }
    }
    @include md {
        .top-stories-container {
            .tags > .tag {
                &.tag--sc:not(:first-child):not(.more-links) {
                    display: none;
                }
            }
        }
    }
}
