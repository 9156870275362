.delete-modal-header {
    width: 100%;
    height: 54px;
    margin-bottom: 20px;
    margin-top: 3px;
    text-align: center;
    display: flex;
}

.deleteHeader {
    color: #0078d4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}

.delete-modal-body {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    padding-right: 32px;
}

.modal-body-content {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
}

.modal-body-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modal-button {
    margin: 1rem;
    width: 90px;
    height: 40px;
}
