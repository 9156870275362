// Margins 5,10,15,20,25,30,35,40,45,50
@include spacings('.m-t-', 'margin-top');
@include spacings('.m-r-', 'margin-right');
@include spacings('.m-b-', 'margin-bottom');
@include spacings('.m-l-', 'margin-left');
@include spacings('.m-a-', 'margin');
// Paddings 5,10,15,20,25,30,35,40,45,50
@include spacings('.p-t-', 'padding-top');
@include spacings('.p-r-', 'padding-right');
@include spacings('.p-b-', 'padding-bottom');
@include spacings('.p-l-', 'padding-left');
@include spacings('.p-a-', 'padding');
.m-auto {
    margin-left: auto;
    margin-right: auto;
}

.rounded-image {
    @include border-radius(50%);
    overflow: hidden;
}

.semi-bold {
    font-weight: 500;
}

.rotate-45 {
    transform: rotate(45deg);
}

.d-none {
    display: none;
}

.jc-flex-end {
    justify-content: flex-end;
}

// horizontal divider line
hr {
    border: 0px;
    border-top: 1px solid $gray-600;
    margin: 5px 0;
}

// Text decorations
.text {
    &-italic {
        font-style: italic;
    }
    &-uppercase {
        text-transform: uppercase !important;
    }
    &-capitalize {
        text-transform: capitalize !important;
    }
    &-transform-none {
        text-transform: none !important;
    }
    &-lowercase {
        text-transform: lowercase !important;
    }
    &-wn {
        font-weight: normal !important;
    }
    &-line-through {
        text-decoration: line-through;
    }
    &-decoration {
        &--none {
            text-decoration: none !important;
        }
        &--underline {
            text-decoration: underline !important;
        }
    }
    &-right {
        text-align: right !important;
    }
    &-left {
        text-align: left !important;
    }
    &-center {
        text-align: center !important;
    }
    &-break {
        word-wrap: break-word;
        &-all {
            word-break: break-all;
        }
    }
    &-disabled {
        color: $gray-600 !important;
    }
    &-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &--1 {
            -webkit-line-clamp: 1;
        }
        &--2 {
            -webkit-line-clamp: 2;
        }
        &--3 {
            -webkit-line-clamp: 3;
        }
    }
    &-overflow {
        &--100 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        &--90 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }
        &--80 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80%;
        }
    }
    &-lineHeight {
        &--14 {
            line-height: $fs-14 !important;
        }
        &--16 {
            line-height: $fs-16 !important;
        }
        &--18 {
            line-height: $fs-18 !important;
        }
        &--20 {
            line-height: $fs-20 !important;
        }
        &--22 {
            line-height: $fs-22 !important;
        }
        &--24 {
            line-height: $fs-24 !important;
        }
        &--26 {
            line-height: $fs-26 !important;
        }
    }
}

.gap {
    &-5 {
        gap: 5px;
    }
    &-7 {
        gap: 7px;
    }
    &-10 {
        gap: 10px;
    }
    &-20 {
        gap: 20px;
    }
    &-30 {
        gap: 30px;
    }
}

.dashed-border-line {
    background-image: radial-gradient(circle at 2px, #b2b2b2 6px, rgba(255, 255, 255, 0) 0px), radial-gradient(circle, #b2b2b2 4px, rgba(255, 255, 255, 0) 0px), radial-gradient(circle at 1px, #b2b2b2 6px, rgba(255, 255, 255, 0) 6px), radial-gradient(circle, #b2b2b2 4px, rgba(255, 255, 255, 0) 0px);
    background-position: top, right, bottom, left;
    background-size: 12px 2px, 2px 14px;
    background-repeat: repeat-x, repeat-y;
}

.bg {
    &--primary {
        background-color: $primary-color;
    }
    &--gray-700 {
        background-color: $gray-700;
    }
    &-overlay {
        &--black {
            background-color: rgba(0, 0, 0, 0.56);
        }
    }
}

.c {
    &-pointer {
        cursor: pointer;
    }
    &-auto {
        cursor: auto;
    }
}

.d {
    &-block {
        display: block !important;
    }
    &-flex {
        display: flex;
        &-grow {
            &--1 {
                flex-grow: 1;
            }
        }
        &--justEnd {
            display: flex;
            justify-content: flex-end;
        }
        &--center {
            display: flex;
            justify-content: center;
        }
        &--dirReverse {
            display: flex;
            flex-direction: row-reverse;
        }
        &--col {
            display: flex;
            flex-direction: column;
        }
        &-align-center {
            display: flex;
            align-items: center;
        }
        &--between {
            display: flex;
            justify-content: space-between;
            @include lt-lg {
                flex-direction: column;
            }
        }
    }
}

.justify-content-center {
    justify-content: center;
}

.vertical-align {
    align-items: center;
}

.fw {
    &--normal {
        font-weight: normal;
    }
    &--400 {
        font-weight: 400;
    }
    &--500 {
        font-weight: 500;
    }
    &--600 {
        font-weight: 600;
    }
    &--700 {
        font-weight: 700;
    }
    &--bold {
        font-weight: bold;
    }
}

.fs {
    &--10 {
        font-size: $fs-10 !important;
    }
    &--12 {
        font-size: $fs-12 !important;
    }
    &--14 {
        font-size: $fs-14 !important;
    }
    &--16 {
        font-size: $fs-16 !important;
    }
    &--18 {
        font-size: $fs-18 !important;
    }
    &--20 {
        font-size: $fs-20 !important;
    }
    &--22 {
        font-size: $fs-22 !important;
    }
}

.color {
    &-gray {
        &--100 {
            color: $gray-100;
        }
        &--200 {
            color: $gray-200;
        }
        &--300 {
            color: $gray-300;
        }
        &--400 {
            color: $gray-400;
        }
        &--500 {
            color: $gray-500;
        }
    }
    &-blue {
        &--200 {
            color: $blue-200;
        }
        &--300 {
            color: $blue-300;
        }
        &--400,
        &--primary {
            color: $blue-400;
        }
        &--500 {
            color: $blue-500;
        }
    }
    &-yellow {
        &--100 {
            color: $yellow-100;
        }
    }
    &-red {
        &--100 {
            color: $red-100;
        }
    }
}

.bg {
    &-color {
        &-gray {
            &--100 {
                background-color: $gray-100;
            }
            &--200 {
                background-color: $gray-200;
            }
            &--300 {
                background-color: $gray-300;
            }
            &--400 {
                background-color: $gray-400;
            }
            &--500 {
                background-color: $gray-500;
            }
            &--700 {
                background-color: $gray-500;
            }
        }
        &-blue {
            &--200 {
                background-color: $blue-200;
            }
            &--300 {
                background-color: $blue-300;
            }
            &--400 {
                background-color: $blue-400;
            }
            &--500 {
                background-color: $blue-500;
            }
        }
        &-transparent {
            background-color: transparent !important;
        }
    }
}

.error-message {
    color: $red-200;
    font-size: $fs-14;
}

.float {
    &-left {
        float: left;
    }
    &-right {
        float: right;
    }
}

.whitespace {
    &-nowrap {
        white-space: nowrap;
    }
}

.hashtag-decoration {
    color: $primary-color;
    text-decoration: none !important;
}

.border {
    &--none {
        border: none !important;
    }
    &-radius {
        &--4 {
            @include border-radius($radius/2);
        }
        &--8 {
            @include border-radius($radius);
        }
    }
}

.pos {
    &-sticky {
        position: -webkit-sticky;
        position: sticky;
        &.top {
            &-0 {
                top: 0px;
            }
        }
    }
    &-relative {
        position: relative;
    }
    &-absolute {
        position: absolute;
    }
}

.width {
    &-4 {
        width: 4rem;
    }
    &-5 {
        width: 5rem;
    }
    &-6 {
        width: 6rem;
    }
}


.no-styles{
    padding: 0;
    margin: 0;
    font-size: $fs-14;
    color: $gray-100;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    text-decoration: none;
}
    