@import '../../../assets/styles/css-assets.scss';
.missing-modal {
    .modal-heading {
        background: $primary-color;
        color: $white;
        text-align: center;
        @include border-radius(1px 1px 0 0);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;

        padding: 0.8rem 0;
        span {
            font-size: $fs-18;
        }
        button {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
            color: $white;
        }
    }
    .missing-modal-body {
        padding: 2rem 5rem 2rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .ms-Button--primary {
            margin-top: 2rem;
        }
        .btn-cont {
            display: flex;
            justify-content: center;
        }
    }
}
.manage-request-detailed-container {
    color: $gray-100;
    .imp-evidence {
        font-size: $fs-12;
    }
    .closed-status-banner {
        position: absolute;
        margin-top: -28px;
        background: rgba(255, 200, 10, 0.2);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #323130;
        padding: 2px 20px;
        cursor: default;
        span {
            font-weight: 600;
        }
    }
    .details-panel-container {
        @include border-radius($radius);
        box-sizing: border-box;
        height: 100%;
        .error-msg-block {
            color: $red-100;
        }
        .scroll-panel {
            height: 100%;

            .no-data {
                text-align: center;
                padding-top: 2rem;
            }
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .details-panel-wrapper {
            border: 1px solid rgba($color: $primary-color, $alpha: 0.1);
            @include border-radius($radius);
            margin-bottom: 0.1rem;
            background-color: $white;
            .quick-tag {
                @include border-radius(2rem);
                color: $white;
                padding: 0.1rem 0.6rem;
                font-size: $fs-10;
            }
            .tag-type-a {
                background-color: $purple-100;
            }
            .tag-type-b {
                background-color: $green-200;
            }
            .tag-type-c {
                background-color: $yellow-100;
            }
            .tag-type-d {
                background-color: $blue-600;
            }
            .tag-type-e {
                background-color: $gray-250;
            }
            .tag-type-f {
                background-color: $blue-350;
                margin-left: 0.5rem;
            }
            .tag-type-g {
                background-color: $cyan-100;
                margin-left: 0.5rem;
            }
            .dp-header {
                background-color: $gray-800;
                padding: 1rem 1.5rem;
                display: flex;
                flex-wrap: wrap;
                .head-col-left {
                    .dp-head-title {
                        margin-right: 0.5rem;
                        font-weight: 600;
                    }
                    .dp-head-desc {
                        margin: 0.5rem 0;
                        max-width: 35rem;
                        .title-field {
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            line-height: 1.35rem;
                            max-height: 3rem;
                            -webkit-line-clamp: 2;
                            /* Write the number of 
                                                      lines you want to be 
                                                      displayed */
                            -webkit-box-orient: vertical;
                        }
                        span {
                            font-size: $fs-18;
                            font-weight: 600;
                        }
                        .editTitle .ms-TextField-fieldGroup {
                            background: transparent;
                            border: 1px solid $gray-300 !important;
                            width: 100%;
                        }
                    }
                    .dp-head-user {
                        color: $gray-200;
                        display: flex;
                        flex-wrap: wrap;
                        span {
                            font-size: $fs-10;
                        }
                        .ms-Link {
                            text-decoration: underline;
                        }
                    }
                    .dp-head-separator {
                        margin: 0 0.5rem;
                    }
                }
                .head-col-right {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: auto;
                    align-self: center;
                    .dots-menu {
                        margin-left: 0.5rem;
                        .ms-Button-flexContainer {
                            background: $primary-color;
                            border-radius: 0.5rem;
                            .ms-Button-icon {
                                color: $white;
                            }
                        }
                    }
                    .add-cust-btn {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                        color: $white;
                        @include border-radius($radius);
                        font-size: $fs-12;
                        padding: 0.5rem 1rem;
                        box-sizing: border-box;
                    }
                }
                .form-btn-wrap {
                    button {
                        @include border-radius($radius);
                        font-size: $fs-12;
                        padding: 0rem 0.5rem;
                        cursor: pointer;
                    }
                    button.primary {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                        color: $white;
                    }
                    button.default {
                        border: 1px solid $gray-200;
                        background-color: $white;
                        color: $gray-200;
                    }
                }
            }
            .dp-filter-panel {
                display: flex;
                align-items: center;
                padding: 1rem 1.5rem;
                box-sizing: border-box;
                background-color: $gray-600;
                margin: 0.2rem;
                .dp-filter-links {
                    margin-right: auto;
                    button {
                        color: $gray-200;
                        margin-right: 1rem;
                        &.active {
                            color: $primary-color;
                            text-decoration: underline;
                            padding-bottom: 2px;
                            animation: fadeIn 0.5s ease 0s 1 normal forwards;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .dp-filter-controls {
                    display: flex;
                    margin-left: auto;
                    align-items: center;
                    .icon {
                        margin-right: 1rem;
                        &.starred {
                            color: $yellow-100;
                        }
                    }
                    & > span {
                        margin-right: 0.5rem;
                    }
                    .add-cust-btn {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                        color: $white;
                        @include border-radius($radius);
                        font-size: $fs-12;
                        padding: 0.5rem 1rem;
                        box-sizing: border-box;
                    }
                }
            }
            .dp-content {
                padding: 1rem 1.5rem;
                .dp-details {
                    animation: fadeIn 1s ease 0s 1 normal forwards;
                    .pivot-container {
                        .pivot-header-container {
                            border: 1px solid $blue-800;
                            border-width: 1px 0 0 0;
                            @include border-radius(8px 8px 0 0);
                            padding: 10px;
                            span {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 18px;
                            }
                        }
                        .ms-FocusZone {
                            border: 1px solid $blue-800;
                            border-width: 0 0 1px 0;
                            @include border-radius(8px);
                            background-color: $gray-800;
                        }
                        .comments-status-toggle-block {
                            font-size: $fs-16;
                            margin-bottom: 1rem;
                            display: flex;
                            color: $gray-300;

                            & > span {
                                cursor: pointer;
                            }
                            .ms-Toggle {
                                margin: 0 0.5rem;
                                .ms-Toggle-background {
                                    background: $primary-color;
                                    @include border-radius(14px);
                                }
                                .ms-Toggle-thumb {
                                    background: $white;
                                }
                            }
                            .active {
                                font-weight: 600;
                                color: $primary-color;
                                font-size: $fs-16;
                            }
                        }
                    }
                    .shared-container {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid $gray-800;
                        padding: 15px 0;
                        margin-bottom: 26px;
                        .txt-link {
                            font-weight: bold;
                            font-size: $fs-14;
                            line-height: 18px;
                            color: $blue-400;
                            cursor: pointer;
                        }
                        .tagSolidIcon {
                            background: url(app/static/icons/tagSolid_icon.svg) no-repeat;
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            position: relative;
                            top: 4px;
                            margin-right: 5px;
                        }
                        .shared-icon-container {
                            background: $blue-800;
                            border: 1px solid $blue-700;
                            box-sizing: border-box;
                            @include border-radius($radius);
                            margin-right: 8px;
                            padding: 8px 12px 4px;
                            cursor: pointer;
                            .shared-txt {
                                color: $blue-400;
                                font-size: $fs-14;
                                line-height: 20px;
                                position: relative;
                                top: -2px;
                                left: 3px;
                            }
                            .shared-icon {
                                background: url(app/static/icons/fav_icon.svg) no-repeat;
                                display: inline-block;
                                width: 15px;
                                height: 14px;
                                &.fav-icon {
                                    background: url(app/static/icons/fav_icon.svg) no-repeat;
                                }
                                &.attch-icon {
                                    background: url(app/static/icons/attachment_icon.svg) no-repeat;
                                }
                                &.share-icon {
                                    background: url(app/static/icons/share_icon.svg) no-repeat;
                                }
                            }
                            .icon {
                                color: $primary-color;
                                &.starred {
                                    color: $yellow-100;
                                }
                            }
                            &.mr-0 {
                                margin-right: 0;
                            }
                        }
                        .tag-list {
                            background: $blue-800;
                            border-radius: 4px;
                            display: inline-block;
                            padding: 7px;
                            margin: 2px 16px;

                            color: $blue-400;
                            font-size: $fs-14;
                            line-height: 20px;
                            .cross-icon {
                                background: url(app/static/icons/cross-fill.svg) no-repeat;
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                position: relative;
                                top: 2px;
                            }
                        }
                        .tags-block {
                            display: flex;
                            align-items: center;
                            .filter-cont {
                                border-right: none;
                            }
                            .device-count-container {
                                margin-left: 0.625rem;
                                background: $blue-750;
                                border-radius: 50%;
                                padding-left: 5px;
                                padding-right: 10px;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                box-sizing: border-box;
                                margin-left: 10px;
                                .device-count {
                                    position: relative;
                                    top: -1px;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: $blue-200;
                                }
                            }
                        }
                        .tag-icon-wrap {
                            display: flex;
                        }
                    }
                }
                .ms-DatePicker {
                    display: flex;
                    .ms-TextField-fieldGroup {
                        border: 1px solid $primary-color;
                        @include border-radius($radius);
                        &::after {
                            @include border-radius($radius);
                        }
                    }
                }
                .status-selection-menu {
                    padding: 0;
                    .ms-Button-textContainer {
                        white-space: nowrap;
                    }
                }
                .dp-content-details-tbl {
                    margin-bottom: 2rem;
                    width: 100%;
                    .tbl-header {
                        td {
                            font-size: 0.75rem;
                            color: $gray-240;
                            font-weight: normal;
                            margin-bottom: 0.5rem;
                        }
                    }

                    .tbl-css-num {
                        width: 100%;
                        word-break: break-all;
                        white-space: pre-wrap; /* CSS3 */
                        white-space: -moz-pre-wrap; /* Firefox */
                        white-space: -pre-wrap;
                    }
                    .attachments-icon-link {
                        display: flex;
                        align-items: center;
                        margin-right: 1rem;
                        .attachments-icon-count {
                            margin-left: 0.2rem;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .link-child-link {
                        text-decoration: underline;
                    }
                    tr:nth-child(1) {
                        td {
                            font-size: $fs-12;
                            padding-right: 2rem;
                        }
                    }
                    tr:nth-child(2) {
                        td {
                            font-weight: 600;
                            padding-right: 2rem;
                        }
                    }
                    tr:nth-child(3) {
                        td {
                            font-size: $fs-12;
                            padding-right: 2rem;
                        }
                        td:nth-child(2) {
                            margin-left: 156px;
                        }
                    }
                    tbody {
                        td {
                            font-size: $fs-14;
                        }
                    }
                }
                .dp-customer-details-tbl {
                    margin-bottom: 2rem;
                    font-size: $fs-14;
                    width: 70%;
                    tr {
                        margin-bottom: 1rem;
                        td {
                            padding-bottom: 0.5rem;
                            font-size: $fs-14;
                            &:nth-child(even) {
                                font-weight: 600;
                            }
                        }
                    }
                }
                .dp-progress-wrap {
                    @include border-radius(1.2rem);
                    min-width: 2rem;
                    min-height: 0.5rem;
                    display: inline-flex;
                    overflow: hidden;
                    .dp-progress {
                        display: inline-block;
                    }
                }
                .dp-content-title {
                    margin-bottom: 0.6rem;
                    span {
                        font-size: 0.875rem;
                        color: $gray-100;
                        font-weight: 600;
                        margin-bottom: 1rem;
                    }
                }
                .type-a {
                    background-color: rgba($color: $green-400, $alpha: 0.5);
                    .dp-progress {
                        background-color: $green-400;
                    }
                }
                .dp-content-text {
                    margin-bottom: 2rem;
                    .bld-content {
                        font-weight: 600;
                        white-space: nowrap;
                    }
                    a {
                        color: $primary-color;
                    }
                }
                .dp-table {
                    font-size: $fs-12;
                    width: 100%;
                    margin-top: 0.6rem;
                    margin-bottom: 2rem;
                    @include border-radius($radius);
                    overflow: hidden;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.1);
                    border-collapse: collapse;
                    thead {
                        background-color: $gray-800;
                        td {
                            font-weight: 600;
                            white-space: nowrap;
                        }
                    }
                    td {
                        padding: 0.4rem 0.6rem;
                        box-sizing: border-box;
                    }
                }
                .impact-table-count {
                    .ms-Link {
                        font-size: $fs-14;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                    .count-link {
                        color: $gray-200;
                        margin-right: 0.5rem;
                        color: $primary-color;
                        font-weight: 600;
                        &.active {
                            text-decoration: none;
                            color: $gray-200;
                            font-weight: normal;
                        }
                    }
                }
                .impact-table {
                    tr {
                        td {
                            &:nth-child(2) {
                                white-space: nowrap;
                            }
                            &:nth-child(6) {
                                text-align: right;
                            }
                            &:nth-child(7) {
                                text-align: right;
                            }
                            &:nth-child(8) {
                                text-align: right;
                            }
                            .ms-Icon {
                                font-size: $fs-12;
                                color: $gray-200;
                            }
                        }
                    }
                }
                .textfield-wrap {
                    margin-top: 1rem;
                    textarea {
                        border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                        @include border-radius($radius);
                        font-size: $fs-14;
                        padding: 1rem;
                        width: 100%;
                        &::placeholder {
                            color: $gray-600;
                        }
                    }
                }
                .desc-textfield-wrap {
                    textarea {
                        border: 1px solid $primary-color;
                        @include border-radius($radius);
                        &::placeholder {
                            color: $gray-300;
                        }
                    }
                    .format-field {
                        padding: 0 8px;
                        height: 32px;
                        width: 100%;
                        font-size: $fs-14;
                        font-weight: 400;
                        color: $gray-200;
                        &::placeholder {
                            color: $gray-200;
                        }
                    }
                }
                .form-btn-wrap {
                    margin-top: 24px;
                    button {
                        @include border-radius($radius);
                        font-size: $fs-14;
                        padding: 1.1rem 1.5rem;
                        cursor: pointer;
                    }
                    button.primary {
                        border: 1px solid $primary-color;
                        background-color: $primary-color;
                        color: $white;
                    }
                    button.default {
                        border: 1px solid $gray-300;
                        background-color: $white;
                        color: $gray-300;
                    }
                }
                .dp-related-requests {
                    animation: fadeIn 1s ease 0s 1 normal forwards;
                }
                .related-table {
                    font-size: $fs-12;
                    .ms-Link {
                        text-decoration: underline;
                    }
                    tr {
                        td {
                            &:nth-child(4) {
                                .quick-tag {
                                    width: 100%;
                                    text-align: center;
                                    display: inline-block;
                                }
                            }
                            &:nth-child(5) {
                                text-align: right;
                            }
                            &:nth-child(6) {
                                text-align: right;
                            }
                        }
                    }
                }
                .ms-Pivot-link {
                    &.is-selected {
                        color: $primary-color;
                        &::before {
                            background-color: $primary-color;
                        }
                    }
                }
                .tab-content {
                    margin: 2rem 0 0 0;
                }
                .tab-details-heading {
                    font-size: $fs-14;
                    color: $gray-100;
                    font-weight: 600;
                    margin-bottom: 1rem;
                }
                .tab-details-sub-heading {
                    font-size: $fs-12;
                    color: $gray-240;
                    font-weight: normal;
                    margin-bottom: 0.5rem;
                }
                .tab-details-text {
                    font-size: $fs-14;
                    color: $gray-100;
                    font-weight: normal;
                    margin-bottom: 1rem;
                    a {
                        color: $primary-color;
                    }
                }
                .custom-selection-menu {
                    padding: 0;
                    color: $primary-color;
                    .ms-Button-label {
                        margin: 0;
                    }
                    .ms-Button-menuIcon {
                        color: $primary-color;
                    }
                }
                .status-selection-menu {
                    padding: 0;
                    color: $primary-color;
                    .ms-Button-label {
                        margin: 0;
                    }
                    .ms-Button-menuIcon {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .row-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .align-center {
        align-items: center;
    }
    .link-item {
        color: $primary-color;
        font-size: $fs-14;
        font-weight: 600;
    }
}

.ms-ContextualMenu-Callout {
    @include border-radius($radius);
}

.admin-edit-status-menu {
    .ms-ContextualMenu-link {
        border-bottom: 1px solid $gray-500;
        &[aria-checked='true'] {
            color: $primary-color;
        }
    }
}

.remove-impact-confirmation {
    .ms-Dialog-main {
        min-height: auto;
    }
    .ms-Modal-scrollableContent {
        min-height: auto !important;
        & > div {
            min-height: auto !important;
        }
        .ms-Dialog-inner {
            padding: 0 2rem 1rem;
        }
    }
}
