@import '../../../../../assets/styles/css-assets.scss';
.spot-light-news-container {
    margin-bottom: 40px;
    display: flex;

    .left-container {
        display: block;
        width: 65%;
        height: 866px;

        .first-row {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .second-row {
            display: flex;

            .second-row-first-block {
                margin-right: 20px;
                height: auto;
            }
        }
        margin-right: 5%;
    }
    .right-container {
        width: 30%;
        height: 866px;
        margin-top: 40px;
        margin-bottom: 0px;
        .article-heading {
            font-size: 20px;
            font-weight: 600;
        }
        .top-stories-container {
            margin-top: $base-font-size;
        }
    }
}
.news-section {
    margin-top: 20px;
    .left {
        padding-right: 37px;
        margin-right: 23px;
        border-right: 1px solid $gray-600;
    }

    .article-heading {
        margin-bottom: $base-font-size;
        display: block;
        font-size: 20px;
    }
}
@include xs {
    .news-section {
        .left {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
        }
    }
}
@include sm {
    .news-section {
        .left {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
        }
    }
}
@include md {
    .news-section {
        .left {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
        }
    }
}
