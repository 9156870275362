@import 'app/assets/styles/css-assets';

#div2 {
    animation-timing-function: ease;
}

/* listbuilder */
.listBuilder {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 860px;
    height: 52px;
    /* Grey palette / White */
    background: #ffffff;
    /* Theme / Primary */
    border: 1px solid #0078d4;
    border-radius: 8px;
    display: flex;
}

.listBuilder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 300;
    height: 150px;
    width: 950px;
    .ms-Dropdown:focus::after {
        display:none;
    }

    .request-type-filter {
        width: 20% !important;
        border: 1px solid grey!important;
        border-radius: 8px;
        height: 38px;
        margin-left: 4%;
    }

    .placeholder-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 38px;
        cursor: pointer;
        font-size: .875rem;
        color: #0078d4;
        justify-content: space-between;
        margin-right: 10px;
        margin-left: 2px;
        margin-bottom: 2px!important;
    }

    .placeholder-label {
        display: inline-block;
        max-width: 6.8rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 10%;
    }
}
.listBuilder-hover {
    *:focus,
    div:focus,
    span:focus,
    *:hover,
    div:hover,
    span:hover,
    *:active,
    div:active,
    span:active {
        color: #0078d4 !important;
    }
}
.lb-labelStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.requestTypeStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    color: #a19f9d;
    font-size: 14px;
    width: 157px;
    line-height: 20px;
    display: flex;
}

/* Drop Down Styles */
.combo-container {
    width: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ms-Dropdown:active {
        border-color: #a19f9d;
    }
}
.DropDown-margins {
    height: 10px;
}
