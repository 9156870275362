@import '../../../../assets/styles/css-assets.scss';
.pivot-container {
    .ms-Button-label {
        line-height: unset;
    }
}

.add-impact-model {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    .ms-Dialog-main {
        max-width: 80%;
        margin-left: 10%;
        min-width: 800px;
    }
}

.dp-content-details-tbl {
    .device-count-container {
        cursor: pointer;
        margin-left: 0.625rem;
        background: $blue-750;
        border-radius: 50%;
        padding-left: 5px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        margin-left: 10px;
        .device-count {
            position: relative;
            top: 1px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $blue-200;
            left: 2px;
        }
    }
    .pro-img {
        position: relative;
        top: 14px;
        margin-right: 4px;
        width: 37px;
        height: auto;
    }
    .add-bug-link {
        &.ms-Link {
            text-decoration: underline;
            font-weight: 600;
        }
    }
    .bug-info-disp-item {
        font-weight: normal;
        margin-top: 0.8rem;
        .ms-Link {
            text-decoration: underline;
            font-weight: 600;
        }
        & > span {
            color: $primary-color;
            font-weight: 600;
        }
    }
}

.tab-details-heading {
    .txt-links {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $blue-400;
        margin-left: 10px;
        cursor: pointer;
    }
    .update-product-link {
        text-decoration: underline;
    }
}

.admin-edit-status-menu {
    &.multi-menu {
        .ms-ContextualMenu-checkmarkIcon {
            width: 1.3rem;
            height: 1.3rem;
            line-height: 1.3rem;
            border: 1px solid $primary-color;
            text-align: center;
            box-sizing: border-box;
        }
    }
}
.request-details-tab {
    .request-details-row {
        margin-bottom: 2rem;
        .request-details-col {
            width: 50%;
            img {
                width: 7%;
                margin-right: 0.6rem;
            }
            .device-count-container {
                cursor: pointer;
                margin-left: 0.625rem;
                background: $blue-750;
                border-radius: 50%;
                padding-left: 5px;
                padding-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                box-sizing: border-box;
                margin-left: 10px;
                .device-count {
                    position: relative;
                    top: 1px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: $primary-color;
                    left: 2px;
                }
            }
        }
        .header-tab {
            color: rgb(117, 115, 114);
            font-weight: 400;

            font-size: 0.75rem;
        }
        .content-tab {
            margin-top: 0.5rem;
        }
        .mr-top {
            margin-top: 1rem;
        }
    }
    .row-flex-box {
        display: flex;
    }
    .align-center {
        align-items: center;
    }
}
