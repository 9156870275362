@import 'app/assets/styles/css-assets';

$small-star: random-stars(3000);
$medium-star: random-stars(2000);
$big-star: random-stars(100);

.screen-bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: calc(100% + 15px);
    top: 0px;
    overflow:hidden;
    .gradient-bg {
        background: linear-gradient(120.91deg, #ecf5ff 1.92%, #80c8ef 59.56%, #fdc9c8 98.15%);
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%) rotate(-180deg);
    }

    .stripes1 {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0.15;
    }

    .stripes2 {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0.15;
        transform: rotate(-180deg);
    }

    .dotted-bg {
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;
        opacity: 0.8;

        img {
            position: absolute;

            &:first-child {
                right: 0%;
                top: 0%;
                width: 20%;
                transform: rotate(180deg);
            }

            &:last-child {
                left: 0%;
                bottom: 0%;
                width: 20%;
            }
        }
    }

    .stars {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 6;

        .stars1 {
            width: 1px;
            height: 1px;
            background: transparent;
            box-shadow: $small-star;
        }

        .stars2 {
            width: 2px;
            height: 2px;
            background: transparent;
            box-shadow: $medium-star;
            @include border-radius(2px);
        }

        .stars3 {
            width: 3px;
            height: 3px;
            background: transparent;
            box-shadow: $big-star;
            @include border-radius(3px);
        }
    }

    &.step0 {
        .gradient-bg {
            animation: gradientInitial 1s ease forwards;
        }

        .stripes1 {
            animation: stripes1Initial 1s ease forwards;
        }

        .stripes2 {
            animation: stripes2Initial 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: dotedInitial 1s ease forwards;
            }
        }

        .stars {
            animation: starsInitial 1s ease forwards;
        }
    }

    &.step1 {
        .gradient-bg {
            animation: gradient 1.5s ease forwards;
        }

        .stripes1 {
            animation: stripes1 1s ease forwards;
        }

        .stripes2 {
            animation: stripes2 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted 1s ease forwards;
            }
        }

        .stars {
            animation: stars 1s ease forwards;
        }
    }

    &.step1-rev {
        .gradient-bg {
            animation: gradientrev 1s ease forwards;
        }

        .stripes1 {
            animation: stripes1rev 1s ease forwards;
        }

        .stripes2 {
            animation: stripes2rev 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: dotedrev 1s ease forwards;
            }
        }

        .stars {
            animation: starsrev 1s ease forwards;
        }
    }

    &.step2 {
        .gradient-bg {
            animation: gradient2 1.5s ease forwards;
        }

        .stripes1 {
            animation: stripes12 1s ease forwards;
        }

        .stripes2 {
            animation: stripes22 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted2 1s ease forwards;
            }
        }

        .stars {
            animation: stars2 1s ease forwards;
        }
    }

    &.step2-rev {
        .gradient-bg {
            animation: gradient2rev 1s ease forwards;
        }

        .stripes1 {
            animation: stripes12rev 1s ease forwards;
        }

        .stripes2 {
            animation: stripes22rev 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted2rev 1s ease forwards;
            }
        }

        .stars {
            animation: stars2rev 1s ease forwards;
        }
    }

    &.step3 {
        .gradient-bg {
            animation: gradient3 1s ease forwards;
        }

        .stripes1 {
            animation: stripes13 1s ease forwards;
        }

        .stripes2 {
            animation: stripes23 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted3 1s ease forwards;
            }
        }

        .stars {
            animation: stars3 1s ease forwards;
        }
    }

    &.step3-rev {
        .gradient-bg {
            animation: gradient3rev 1s ease forwards;
        }

        .stripes1 {
            animation: stripes13rev 1s ease forwards;
        }

        .stripes2 {
            animation: stripes23rev 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted3rev 1s ease forwards;
            }
        }

        .stars {
            animation: stars3rev 1s ease forwards;
        }
    }

    &.step4 {
        .gradient-bg {
            animation: gradient4 1s ease forwards;
        }

        .stripes1 {
            animation: stripes14 1s ease forwards;
        }

        .stripes2 {
            animation: stripes24 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted4 1s ease forwards;
            }
        }

        .stars {
            animation: stars4 1s ease forwards;
        }
    }

    &.step4-rev {
        .gradient-bg {
            animation: gradient4rev 1s ease forwards;
        }

        .stripes1 {
            animation: stripes14rev 1s ease forwards;
        }

        .stripes2 {
            animation: stripes24rev 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: doted4rev 1s ease forwards;
            }
        }

        .stars {
            animation: stars4rev 1s ease forwards;
        }
    }

    &.step4-to-step0 {
        .gradient-bg {
            animation: gradientFullReverse 1s ease forwards;
        }

        .stripes1 {
            animation: stripes1FullReverse 1s ease forwards;
        }

        .stripes2 {
            animation: stripes2FullReverse 1s ease forwards;
        }

        .dotted-bg {
            img {
                animation: dotedFullReverse 1s ease forwards;
            }
        }

        .stars {
            animation: starsFullReverse 1s ease forwards;
        }
    }
}

@keyframes gradientInitial {
    0% {
        width: 140%;
        height: 140%;
    }

    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes starsInitial {
    0% {
        opacity: 1;
        transform: scale(1.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes stripes1Initial {
    0% {
        left: -25%;
        top: -25%;
    }

    100% {
        left: 0%;
        top: 0%;
    }
}

@keyframes stripes2Initial {
    0% {
        right: -25%;
        bottom: -25%;
    }

    100% {
        right: 0%;
        bottom: 0%;
    }
}

@keyframes dotedInitial {
    0% {
        width: 40%;
    }

    100% {
        width: 20%;
    }
}

///// Step 1 Animation
@keyframes gradient {
    0% {
        width: 100%;
        height: 100%;
    }

    100% {
        width: 110%;
        height: 110%;
    }
}

@keyframes stars {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes stripes1 {
    0% {
        left: 0%;
        top: 0%;
    }

    100% {
        left: -10%;
        top: -10%;
    }
}

@keyframes stripes2 {
    0% {
        right: 0%;
        bottom: 0%;
    }

    100% {
        right: -10%;
        bottom: -10%;
    }
}

@keyframes doted {
    0% {
        width: 20%;
    }

    100% {
        width: 25%;
    }
}

///// Step 1 Reverse Animation
@keyframes gradientrev {
    0% {
        width: 110%;
        height: 110%;
    }

    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes starsrev {
    0% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes stripes1rev {
    0% {
        left: -10%;
        top: -10%;
    }

    100% {
        left: 0%;
        top: 0%;
    }
}

@keyframes stripes2rev {
    0% {
        right: -10%;
        bottom: -10%;
    }

    100% {
        right: 0%;
        bottom: 0%;
    }
}

@keyframes dotedrev {
    0% {
        width: 25%;
    }

    100% {
        width: 20%;
    }
}

//////Step 2 Animation
@keyframes gradient2 {
    0% {
        width: 110%;
        height: 110%;
    }

    100% {
        width: 120%;
        height: 120%;
    }
}

@keyframes stars2 {
    0% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1.4);
    }
}

@keyframes stripes12 {
    0% {
        left: -10%;
        top: -10%;
    }

    100% {
        left: -15%;
        top: -15%;
    }
}

@keyframes stripes22 {
    0% {
        right: -10%;
        bottom: -10%;
    }

    100% {
        right: -15%;
        bottom: -15%;
    }
}

@keyframes doted2 {
    0% {
        width: 25%;
    }

    100% {
        width: 30%;
    }
}

//////Step 2 Reverse Animation
@keyframes gradient2rev {
    0% {
        width: 120%;
        height: 120%;
    }

    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes stars2rev {
    0% {
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes stripes12rev {
    0% {
        left: -15%;
        top: -15%;
    }

    100% {
        left: -10%;
        top: -10%;
    }
}

@keyframes stripes22rev {
    0% {
        right: -15%;
        bottom: -15%;
    }

    100% {
        right: -10%;
        bottom: -10%;
    }
}

@keyframes doted2rev {
    0% {
        width: 30%;
    }

    100% {
        width: 25%;
    }
}

//////Step 3 Animation
@keyframes gradient3 {
    0% {
        width: 120%;
        height: 120%;
    }

    100% {
        width: 130%;
        height: 130%;
    }
}

@keyframes stars3 {
    0% {
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        opacity: 1;
        transform: scale(1.6);
    }
}

@keyframes stripes13 {
    0% {
        left: -15%;
        top: -15%;
    }

    100% {
        left: -20%;
        top: -20%;
    }
}

@keyframes stripes23 {
    0% {
        right: -15%;
        bottom: -15%;
    }

    100% {
        right: -20%;
        bottom: -20%;
    }
}

@keyframes doted3 {
    0% {
        width: 30%;
    }

    100% {
        width: 35%;
    }
}

//////Step 3 Reverse Animation
@keyframes gradient3rev {
    0% {
        width: 130%;
        height: 130%;
    }

    100% {
        width: 120%;
        height: 120%;
    }
}

@keyframes stars3rev {
    0% {
        opacity: 1;
        transform: scale(1.6);
    }

    100% {
        opacity: 1;
        transform: scale(1.4);
    }
}

@keyframes stripes13rev {
    0% {
        left: -20%;
        top: -20%;
    }

    100% {
        left: -15%;
        top: -15%;
    }
}

@keyframes stripes23rev {
    0% {
        right: -20%;
        bottom: -20%;
    }

    100% {
        right: -15%;
        bottom: -15%;
    }
}

@keyframes doted3rev {
    0% {
        width: 35%;
    }

    100% {
        width: 30%;
    }
}

//////Step 4 Animation
@keyframes gradient4 {
    0% {
        width: 130%;
        height: 130%;
    }

    100% {
        width: 140%;
        height: 140%;
    }
}

@keyframes stars4 {
    0% {
        opacity: 1;
        transform: scale(1.6);
    }

    100% {
        opacity: 1;
        transform: scale(1.8);
    }
}

@keyframes stripes14 {
    0% {
        left: -20%;
        top: -20%;
    }

    100% {
        left: -45%;
        top: -45%;
    }
}

@keyframes stripes24 {
    0% {
        right: -20%;
        bottom: -20%;
    }

    100% {
        right: -45%;
        bottom: -55%;
    }
}

@keyframes doted4 {
    0% {
        width: 35%;
    }

    100% {
        width: 40%;
    }
}

//////Step 4 Reverse Animation
@keyframes gradient4rev {
    0% {
        width: 140%;
        height: 140%;
    }

    100% {
        width: 130%;
        height: 130%;
    }
}

@keyframes stars4rev {
    0% {
        opacity: 1;
        transform: scale(1.8);
    }

    100% {
        opacity: 1;
        transform: scale(1.6);
    }
}

@keyframes stripes14rev {
    0% {
        left: -25%;
        top: -25%;
    }

    100% {
        left: -20%;
        top: -20%;
    }
}

@keyframes stripes24rev {
    0% {
        right: -25%;
        bottom: -25%;
    }

    100% {
        right: -20%;
        bottom: -20%;
    }
}

@keyframes doted4rev {
    0% {
        width: 40%;
    }

    100% {
        width: 35%;
    }
}

///// Full reverse Animation
@keyframes gradientFullReverse {
    0% {
        width: 140%;
        height: 140%;
    }

    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes starsFullReverse {
    0% {
        opacity: 1;
        transform: scale(1.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes stripes1FullReverse {
    0% {
        left: -25%;
        top: -25%;
    }

    100% {
        left: 0%;
        top: 0%;
    }
}

@keyframes stripes2FullReverse {
    0% {
        right: -25%;
        bottom: -25%;
    }

    100% {
        right: 0%;
        bottom: 0%;
    }
}

@keyframes dotedFullReverse {
    0% {
        width: 40%;
    }

    100% {
        width: 20%;
    }
}
