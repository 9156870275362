@import '../../assets/styles/css-assets.scss';

.custom-message-modal-hook {
    .ms-Icon--WarningSolid {
        font-size: $fs-42;
        color: $red-100;
    }
}

.child-btn-container {
    display: flex;
    width: 100px;
}

.confirmation-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
}