@import '../../assets/styles/css-assets.scss';

.footer-admin {
    color: $white;
    width: 100%;
    background-color: $gray-200;
    z-index: 1;
    position: relative;
    .top-footer {
        padding: 40px 0 20px 0;
        h5 {
            font-size: $fs-14;
            margin: 0;
        }

        .footer_links {
            list-style-type: none;
            font-size: $fs-13;
            padding: 0px 0px 10px 0px;
            margin: 8px 0 0;
            display: flex;

            .footer_links_inline {
                display: inline-block;
                margin-right: 30px;
            }
            a {
                color: $gray-600;
                font-size: $fs-13;
                line-height: 28px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                .explore-icon {
                    background: url(app/static/icons/explore.svg) no-repeat;
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    position: relative;
                    top: 2px;
                    margin-left: 5px;
                }
            }

            &.links-bold a {
                font-size: $fs-14;
                line-height: 30px;
                font-weight: 600;
                color: $white;

                i {
                    font-size: $fs-20;
                    top: 5px;
                    position: relative;
                    left: 5px;
                }
            }
        }

        .links-container {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            height: 75px;
            top: 12px;
            position: relative;
            .footer-feedback-btn {
                @include border-radius($radius/2);
                padding: 12px 30px;
            }
            a {
                background: $gray-200;
                border: 1px solid $gray-300;
                box-sizing: border-box;
                @include border-radius(8px);
                color: $white;
                margin: 0 auto;
                font-weight: 600;
                font-size: $fs-14;
                line-height: 19px;
                display: block;
                width: 134px;
                cursor: pointer;
                max-width: 207px;
                text-align: center;
                text-decoration: none;

                &:hover {
                    opacity: 0.6;
                    text-decoration: none;
                }
            }
        }
    }

    .bottom-footer {
        background-color: $gray-100;
        padding: 12px 0;
        color: $gray-400;

        i {
            position: relative;
            top: 3px;
        }
        .lang {
            color: $gray-400;

            font-size: $fs-13;
        }
        .links {
            margin: 0 20px;
            a {
                color: $gray-400;
                text-decoration: none;
                font-size: $fs-13;
            }
        }
        .copyright {
            text-align: right;
            float: right;
            font-size: $fs-13;
        }

        .icon-global {
            background: url(app/static/icons/globe.svg);
            display: inline-block;
            height: 18px;
            width: 18px;
            position: relative;
            top: 5px;
            margin-right: 5px;
        }
    }
}

.chat-icon {
    position: fixed;
    right: 51px;
    bottom: 50px;
    z-index: 996;
    width: 80px;
    height: 80px;
    @include border-radius(4px);
    transition: all 0.4s;
    &:hover {
        transform: scale(1.1);
    }
}

.footer {
    color: rgba($white, 0.5);
    width: 100%;
    background-color: $gray-200;
    position: relative;
    z-index: 1;
    .row-flex {
        display: flex;
    }
    .col-flex {
        display: flex;
        flex-direction: column;
    }
    .left-footer-cont {
        width: 70%;
    }
    .top-footer {
        padding: 30px 32px 30px 52px;
        .portal-name {
            margin-bottom: 1rem;
            span {
                font-family: $font-family;
                font-weight: 600;
                font-size: $fs-14;
                color: $gray-700;
            }
            a {
                font-family: $font-family;
                font-weight: 600;
                font-size: $fs-14;
                color: $gray-700;
                text-decoration: none;
            }
        }
        h5 {
            font-weight: 600;
            font-size: $fs-14;
            line-height: 30px;
            margin: 0;
            color: $white;
        }
        .footer-articles {
            width: 25%;
        }
        .footer_links {
            list-style-type: none;
            font-size: $fs-14;
            padding: 0px 0px 10px 0px;
            margin: 8px 0 0;

            a {
                color: $gray-600;
                font-size: $fs-13;
                line-height: 28px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                .explore-icon {
                    background: url(app/static/icons/explore.svg) no-repeat;
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    position: relative;
                    top: 2px;
                    margin-left: 5px;
                }
                .request-icon {
                    background: url(app/static/icons/sales_question_icon.svg) no-repeat;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    position: relative;
                    top: 7px;
                    margin-right: 0.25rem;

                    &.sales-icon {
                        background: url(app/static/icons/sales_question_icon.svg) no-repeat;
                    }

                    &.support-icon {
                        background: url(app/static/icons/support_escalation_icon.svg) no-repeat;
                    }

                    &.warranty-icon {
                        background: url(app/static/icons/warranty_need_icon.svg) no-repeat;
                    }

                    &.feature-icon {
                        background: url(app/static/icons/feature_request_icon.svg) no-repeat;
                    }

                    &.messaging-icon {
                        background: url(app/static/icons/messaging_request_icon.svg) no-repeat;
                    }
                }
            }

            &.links-bold a {
                font-size: $fs-14;
                line-height: 30px;
                font-weight: 600;
                color: $white;

                i {
                    font-size: $fs-20;
                    top: 5px;
                    position: relative;
                    left: 5px;
                }
            }
            &.popular-articles {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 90%;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
            }
        }

        .links-container {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            height: 163px;
            top: 50px;
            position: relative;

            a {
                padding: 0.625rem 2rem;
                background: $gray-200;
                border: 1px solid $gray-700;
                box-sizing: border-box;
                @include border-radius($radius);
                color: $white;
                margin: 0 auto 16px;
                font-weight: 600;
                font-size: $fs-14;
                line-height: 19px;
                display: block;
                width: 100%;
                cursor: pointer;
                max-width: 207px;
                height: auto;
                text-align: center;
                text-decoration: none;

                &:hover {
                    opacity: 0.6;
                    text-decoration: none;
                }
                @media screen and (min-width: 480px) and (max-width: 768px) {
                    padding: 10px 5px;
                }
            }
        }

        .links-container-user {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            position: relative;
            top: 30px;

            a {
                padding: 0.625rem 2rem;
                background: $gray-200;
                border: 1px solid $gray-700;
                box-sizing: border-box;
                @include border-radius($radius);
                color: $white;
                margin: 0 auto 16px;
                font-weight: 600;
                font-size: $fs-14;
                line-height: 19px;
                display: block;
                width: 100%;
                cursor: pointer;
                max-width: 207px;
                height: auto;
                text-align: center;
                text-decoration: none;

                &:hover {
                    opacity: 0.6;
                    text-decoration: none;
                }
                @media screen and (min-width: 480px) and (max-width: 768px) {
                    padding: 10px 5px;
                }
            }
        }
    }

    .bottom-footer {
        background-color: $gray-100;
        padding: 12px 32px;
        color: $gray-400;
        opacity: 1;
        font-size: $fs-13;
        line-height: 26px;

        i {
            position: relative;
            top: 3px;
        }
        .links {
            margin: 0 20px;
            a {
                color: $gray-400;
                text-decoration: none;
            }
        }
        .copyright {
            text-align: right;
        }

        .icon-globa {
            background: url(app/static/icons/globe.svg);
            display: inline-block;
            height: 18px;
            width: 18px;
            position: relative;
            top: 5px;
            margin-right: 5px;
        }
    }
}
