@import '../../../../assets/styles/css-assets.scss';

.filter-heading {
    font-size: $fs-12;
    color: $gray-200;
    font-weight: normal;
}
.tags-title {
    cursor: pointer;
    font-size: $fs-14;
    color: $primary-color;
    i {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: $fs-10;
    }
    .tags-label {
        display: inline-block;
        max-width: 6.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .txt-link {
        font-weight: bold;
        font-size: $fs-14;
        line-height: 18px;
        color: $blue-400;
        cursor: pointer;
    }
}
.ms-Checkbox-checkbox {
    border: 1px solid $blue-700;
    @include border-radius(2px);
}
label {
    color: $gray-200;
}
.is-checked {
    label {
        font-weight: 600;
        color: $gray-100;
    }
    .ms-Checkbox-checkbox {
        @include border-radius(2px);

        border: 1px solid $primary-color;
        background: $white;
        i {
            color: $primary-color;
        }
    }
}
label {
    font-weight: normal;
    font-size: $fs-14;
    color: $gray-200;
}
.dropdown-header {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.625rem;
    background: $gray-800;
    color: $primary-color;
    i {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: $fs-10;
    }
    .ms-Link {
        text-decoration: underline;
        font-size: $fs-12;
    }
}
.customize-col-btn {
    padding: 0 1rem;
    position: relative;
    .customize-col-menu {
        left: -4.0625rem;
        position: absolute;
        z-index: 999999;
        max-height: 30rem;
        overflow-y: auto;
        min-width: 16rem;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        background: $white;
        @include border-radius($radius);
        .list-item {
            border-top: 1px solid $gray-600;
        }
    }
}
.products-filter {
    padding-left: 1rem;
    .tags-dropdown-cont {
        min-width: 16rem;
    }
}
.tags-search-cont {
    padding: 1.25rem 0.625rem;
    .tags-cont {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.3125rem;
        .tag-item {
            display: flex;
            align-items: center;
            background: $gray-800;
            margin-right: 0.3125rem;
            padding: 0.125rem 0.3125rem;
            color: $gray-250;
            margin-bottom: 0.3125rem;
            @include border-radius(0.1875rem);
            span {
                font-size: $fs-12;
            }
            i {
                cursor: pointer;
                margin-left: 0.625rem;
                font-size: $fs-10;
            }
        }
    }
    .ms-SearchBox {
        @include border-radius($radius);
        border: 1px solid $blue-700;
        input::placeholder {
            color: $gray-300;
        }
        i {
            font-size: $fs-14;
            color: $gray-300;
        }
        .ms-SearchBox-clearButton {
            i {
                font-size: $fs-14;
            }
        }
        &::after {
            @include border-radius($radius);
        }
    }
}
.tags-dropdown-cont {
    max-height: 30rem;
    overflow-y: auto;

    position: absolute;
    background: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    @include border-radius($radius);
    z-index: 999999;
    font-size: $fs-14;
    font-weight: normal;

    .tags-dropdown-item {
        border-top: 1px solid $gray-600;
        box-sizing: border-box;
        padding: 0.625rem;
        &.child-item {
            padding-left: 30px;
        }
        &.parent-item {
            label {
                font-weight: 600;
            }
        }
    }
}
.filter-cont {
    position: relative;
    border-right: 2px solid $gray-600;
    padding-right: 1rem;
}
.filter-cont:last-child {
    border-right: none;
}
.list-view-filter {
    & .filter-cont:last-child {
        border-right: none;
    }
}
.request-type-filter {
    .dropdown-cont {
        min-width: 16rem;
    }
}
.status-filter {
    padding-left: 1rem;
    .dropdown-cont {
        min-width: 10rem;
    }
}
.tags-filter {
    padding-left: 1rem;
    .tags-dropdown-cont {
        min-width: 19rem;
    }
    .tags-dropdown-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ms-Persona-details {
            display: none;
        }
        .ms-Persona-coin {
            margin-right: 0.625rem;
        }
        &.selected {
            label {
                font-weight: 600;
                color: $gray-100;
            }
        }
        .ms-Checkbox {
            margin-right: 0.625rem;
        }
    }

    &.details-type {
        display: flex;
        padding-left: 0;
        .filter-heading {
            position: relative;
            top: 8px;
            font-weight: 400;
            margin-right: 10px;
            text-transform: uppercase;
            font-size: $fs-14;
        }
        .ms-Persona {
            width: 40px;
        }
    }
}
.aging-filter {
    padding-left: 1rem;
    .dropdown-cont {
        min-width: 12rem;
    }
    .dropdown-item {
        &.selected {
            label {
                font-weight: 600;
                color: $gray-100;
            }
        }

        .aging-icon {
            display: block;
            width: 2.5rem;
            height: 0.9375rem;
        }
        .icon-0hr-12hrs {
            background: url(app/static/icons/0hr-12hrs-icon.svg) no-repeat;
        }
        .icon-1d-7d {
            background: url(app/static/icons/1d-7d-icon.svg) no-repeat;
        }
        .icon-1w-4w {
            background: url(app/static/icons/1w-4w-icon.svg) no-repeat;
        }
        .icon-1m-plus {
            background: url(app/static/icons/1m+-icon.svg) no-repeat;
        }
    }
}
