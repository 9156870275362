@import 'app/assets/styles/css-assets';

.this-field-text {
    width: 100px;
    height: 39px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323130;
    border: 1px solid gray !important;
    border-radius: 8px;
}

.picker-dropdown-cont {
    .ms-Dropdown:focus::after {
        display:none;
    }
}
.picker-textfield-cont {
    .ms-TextField-fieldGroup::after {
        border:1.2px solid rgb(140, 138, 140);
        border-radius: 8px;
    }
}

.picker-search-box {
    width: 150px !important;
    border:1px solid gray !important;
    border-radius: 8px;
    max-height: 40px;
    height: 38px;
    padding-left: 4px;
    display: flex;
    justify-content: center;
}

.picker-product-box {
    width: 150px !important;
    border:1px solid gray !important;
    border-radius: 8px;
    height: 38px;
}

.datePickerStyles {
    .ms-TextField-fieldGroup {
        height: 38px;
    }
    .msDatePickerDisabled {
        margin-top: 4px;
        height: 38px;
    }
}

.num-format {
    border: 1px solid gray;
    box-sizing: border-box;
    max-width: 125px;
    height: 40px;
    color: $gray-100;
    display: flex;
    align-items: center;
    padding-left:10px;
    @include border-radius(8px);
}

.numberFormatWrapper {
    max-width:150px;
}

.lb-multi-picker-callout {
    .ms-Dropdown-item {
        background: none !important;
        color: #323130 !important;
    }

    .ms-Dropdown-items .ms-Checkbox.is-checked span {
        color: #4f4f4f !important;
        font-weight: 400;
    }
}