@import '../../assets/styles/css-assets.scss';
.comment-type-selector {
    .cmt-type-selector-wrap {
        display: inline-flex;
        align-items: center;
        .ms-ChoiceFieldGroup {
            display: flex;
            .ms-ChoiceFieldGroup-flexContainer {
                display: flex;
                box-sizing: border-box;
                .ms-ChoiceField {
                    margin: 0;
                    .ms-ChoiceField-field {
                        &::before {
                            border-color: $green-200;
                            top: 8px;
                            left: 10px;
                        }
                        &::after {
                            border-color: $green-200;
                            top: 13px;
                            left: 15px;
                        }
                    }
                    &:first-child {
                        .ms-ChoiceField-field {
                            margin-top: 0;
                            color: $gray-200;
                            background-color: rgba($color: $green-200, $alpha: 0.15);
                            padding: 0.5rem 0.3rem;
                            box-sizing: border-box;
                            @include border-radius(1rem 0 0 0);
                            .ms-ChoiceFieldLabel {
                                margin: 0 0.5rem;
                            }
                            &.is-checked {
                                color: $white;
                                background-color: rgba($color: $green-100, $alpha: 1);
                                &::before {
                                    border-color: $white;
                                    border-width: 2px;
                                    width: 21px;
                                    height: 21px;
                                    background-color: rgba($color: $green-200, $alpha: 0.8);
                                }
                                &::after {
                                    height: 13px;
                                    width: 13px;
                                    top: 12px;
                                    left: 14px;
                                    border-width: 2px;
                                    border-color: $white;
                                }
                                .ms-ChoiceFieldLabel {
                                    color: $white;
                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                            &::before {
                                border-color: $green-200;
                            }
                            &::after {
                                border-color: $green-200;
                            }
                        }
                    }
                    &:last-child {
                        .ms-ChoiceField-field {
                            margin-top: 0;
                            color: $gray-200;
                            background-color: rgba($color: $red-300, $alpha: 0.1);
                            padding: 0.5rem 1rem;
                            box-sizing: border-box;
                            @include border-radius(0 1rem 0 0);
                            .ms-ChoiceFieldLabel {
                                margin: 0 0.1rem;
                            }
                            &.is-checked {
                                color: $white;
                                background-color: rgba($color: $red-100, $alpha: 1);
                                &::before {
                                    border-color: $white;
                                    border-width: 2px;
                                    width: 21px;
                                    height: 21px;
                                    background-color: rgba($color: $red-300, $alpha: 0.8);
                                }
                                &::after {
                                    height: 13px;
                                    width: 13px;
                                    top: 12px;
                                    left: 14px;
                                    border-width: 2px;
                                    border-color: $white;
                                }
                                .ms-ChoiceFieldLabel {
                                    color: $white;
                                    &:hover {
                                        color: $white;
                                    }
                                }
                            }
                            &::before {
                                border-color: $green-200;
                            }
                            &::after {
                                border-color: $green-200;
                            }
                        }
                    }
                }
            }
        }
        .cmt-unread-count {
            background-color: $red-200;
            padding: 0.2rem;
            @include border-radius(50%);
            height: 0.8rem;
            width: 0.8rem;
            line-height: 0.8rem;
            text-align: center;
            color: $white;
            font-size: $fs-14;
            margin-left: 0.5rem;
        }
    }
    .single-select {
        .ms-ChoiceField-field {
            margin-top: 0;
            color: $gray-300;
            padding: 0.5rem 1rem;
            box-sizing: border-box;
            @include border-radius(0 1rem 0 0);
            .ms-ChoiceFieldLabel {
                margin: 0 0.1rem;
            }
            &.is-checked {
                color: $white;
                background-color: rgba($color: $green-200, $alpha: 0.8) !important;
                &::before {
                    border-color: $white;
                    border-width: 2px;
                    width: 21px;
                    height: 21px;
                    background-color: rgba($color: $green-200, $alpha: 0.8) !important;
                }
                &::after {
                    height: 13px;
                    width: 13px;
                    top: 12px;
                    left: 14px;
                    border-width: 2px;
                    border-color: $white;
                    background-color: $white;
                }
                .ms-ChoiceFieldLabel {
                    color: $white;
                    &:hover {
                        color: $white;
                    }
                }
            }
            &::before {
                border-color: $green-200;
            }
            &::after {
                border-color: $green-200;
            }
        }
    }
}
