@import 'app/assets/styles/css-assets';

.req-app-styles {
    display: flex;
    flex-direction: row;
    min-width: 20rem;
    height: 2rem;
    align-items: center;
    
}

.label-styles {
    margin-left: 2px;
    padding: 2px 8px 0px 2px;

    .tooltip-display{
        height: 100%;
        padding: 2px;
    }
}

.cust-impact {
    max-width:44rem;
}

.pipe-styles {
    height:18px;
    border-left: 2px solid lightgray;
}

.info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.info-msg {
    display: flex;
    height: 10px;
    margin-bottom: 20px;
}

.css-case-styles {
    display: flex;

    flex-direction: column;
}
.error-info-message {
    display: flex;
    flex-direction: row;
    color: $red-200;
}
.tooltip-styles {
    height: 20px;
    display: flex;
    padding: 0px 8px 12px 2px;
}
.info-css-textfield-container {
    display: flex;
    width: 600px;
    margin-bottom: 7px;
    .ms-TextField-fieldGroup {
        margin-right: 20px;
        min-width: 325px;
        height: 40px;
        color: $gray-200;
        margin-bottom: 14px;
        input {
            @include border-radius(8px);
        }
    }
}