@import '../../../assets/styles/css-assets.scss';
.notification-banner {
    background: url(app/static/home-banner-admin.jpg) no-repeat;
    background-size: 100% 180px;
    min-height: 25rem;
    width: 100%;
    padding-top: 111px;
    z-index: 0;
}
.notification-overflow-1 {
    background: linear-gradient(179.92deg, rgba(255, 255, 255, 0.9) -28.53%, #ffffff 99.93%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    min-height: 25rem;

    .notification-header {
        font-size: $fs-18;
        margin-bottom: 30px;
    }

    .notification-header-time {
        font-size: $fs-16;
        font-weight: 400;
        line-height: 22px;
        color: $gray-100;
        margin: 20px 0px;
        float:left;
        clear:both;
    }

    .notification-item-row {
        padding:16px 30px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        max-width: 100%;
        box-sizing: border-box;
        float:left;
        clear:both;
    }

    .notification-icon {
        width: 32px;
        height: 32px;
    }

    .notification-message {
        font-size: $fs-14;
        line-height: $fs-20;
        cursor: pointer;
    }

    .notification-time {
        font-size: $fs-10;
        line-height: $fs-18;
        color: $gray-200;
    }

    .notification-message-container {
        margin-left: 10px;
        max-width: calc(100% - 65px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
