@import '../../../assets/styles/css-assets.scss';

.footer-contact-background {
    background: $gray-700;
    .column-flex-box {
        display: flex;
        flex-direction: column;
    }
    .bold-text {
        font-weight: 600;
    }
    .row-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .justify-sb {
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }
    .team-desc-container {
        background: $white;
        width: 15%;
        @include border-radius($radius);
        font-size: $fs-12;
        display: block;
        .team-name {
            color: $primary-color;
        }
        .team-icon {
            margin: auto;
            img {
                margin: 0 auto;
            }
        }
        .common-font {
            display: inline-block;
        }
    }
    .contact-heading {
        font-size: $fs-16;
        color: $gray-100;
    }
    .contact-table {
        @include border-radius($radius);
        width: 74%;
        background: $white;
        .ms-Pivot {
            padding-left: $fs-10;
            padding-bottom: $fs-20;
            .ms-Pivot-text {
                font-size: $fs-16;
            }
            .is-selected {
                .ms-Pivot-text {
                    color: $primary-color;
                }
            }
        }
    }
}
