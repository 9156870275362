@import '../../../../assets/styles/css-assets.scss';

.add-customer-modal {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 80%;
    max-width: 80%;

    .modal-head {
        display: flex;
        align-items: center;
        padding: 1rem 0.8rem;
        background-color: $blue-400;
        color: $white;
        justify-content: center;
        position: relative;
        font-size: $fs-18;
        span {
            font-size: $fs-18;
        }

        .modal-close {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
        }
    }

    .add-customer-modal-body {
        .dp-header {
            background-color: $gray-800;
            padding: 2rem 1.5rem;

            .head-wrap {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;

                .text-field-wrap {
                    flex-grow: 1;
                    flex-basis: 0;
                    margin-right: 1rem;
                }

                .btn-field-wrap {
                    align-self: flex-end;
                }

                .ms-ComboBox {
                    &::after {
                        @include border-radius($radius);
                    }
                }

                label {
                    font-weight: 600;
                    margin-left: 0.5rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                input {
                    &::placeholder {
                        color: $gray-400;
                    }
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }

        .dp-content {
            padding: 1rem 2rem 2rem;
            box-sizing: border-box;

            .dp-content-title {
                span {
                    font-size: $fs-20;
                    display: inline-block;
                    margin-bottom: 1rem;
                    font-weight: 600;
                }
            }

            .table-wrap {
                border: 1px solid $gray-500;
                @include border-radius($radius);
                overflow: hidden;
            }

            .dp-table {
                font-size: $fs-12;
                width: 100%;
                @include border-radius($radius);
                overflow: hidden;
                border-collapse: collapse;

                td {
                    font-weight: normal;
                    font-size: $fs-14;
                }
                thead {
                    tr {
                        border-bottom: 1px solid $gray-500;
                        td {
                            font-weight: 600;
                            white-space: nowrap;
                            padding: 0.8rem 0.6rem;
                            box-sizing: border-box;
                        }
                    }
                }
                tbody {
                    tr {
                        &:not(:last-child) {
                            border-bottom: 1px solid $gray-500;
                        }
                    }
                }
                td {
                    padding: 0.4rem 0.6rem;
                    box-sizing: border-box;
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                    font-size: $fs-12;
                }
            }

            .form-btn-wrap {
                margin-top: 2rem;
                button {
                    @include border-radius($radius);
                    font-size: $fs-14;
                    padding: 1.1rem 1.5rem;
                    cursor: pointer;
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                }

                button.default {
                    border: 1px solid $gray-300;
                    background-color: $white;
                    color: $gray-300;
                }
            }
        }
    }
}
