@import '../../assets/styles/css-assets.scss';

.people-picker-cont {
    position: relative; 
    
    .picker-combo-box {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $gray-250;
        @include border-radius(6px);
        padding: 0 2px;
        align-items: center;

        &:focus-within {
            border: 2px solid $primary-color;
        }
        .selected-item {
            display: flex;
            flex-direction: row;
            @include border-radius(15px);
            background-color: $gray-600;
            padding: 3px 10px 5px 15px;

            .close-button {
                &:hover {
                    background-color: $gray-500;
                }
                i {
                    font-size: $fs-10;
                    color: $gray-200;
                }
            }
        }
        .people-picker-field {
            flex-grow: 1;
            .ms-TextField-fieldGroup {
                width: 100%;
                border: none !important;
                border-radius: 8px;
                &::after {
                    border: none !important;
                }
            }
            .is-active {
                .ms-TextField-fieldGroup {
                    border: none !important;

                    .fieldGroup-152 {
                        border: none !important;
                    }
                }
            }
        }
    }
    .suggestion-container {
        width: 100%;
        position: absolute;
        z-index: 6;
        background: $white;
        align-items: center;
        @include border-radius($radius);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-250;
        max-height: 9.375rem;
        overflow-y: auto;
        .suggestion-item {
            border-bottom: 1px solid $gray-600;
            &:hover {
                background-color: $gray-600;
                cursor: pointer;
            }

            .suggestion-item-name {
                font-weight: 600;
            }
        }
    }
    &.top {
        .suggestion-container {
            bottom: 100%;
        }
    }    
}
