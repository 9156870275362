@import '../../../../assets/styles/css-assets.scss';
.customer-info-cont {
    tbody {
        tr {
            td {
                padding-bottom: 1.5rem !important;
            }
        }
    }
    select {
        border: 1px solid $primary-color;
        border-radius: 8px;
        min-height: 32px;
    }
    .col-flex {
        display: flex;
        flex-direction: column;
    }
    .row-flex {
        display: flex;
    }
    .row-flex-box {
        display: flex;
    }
    .custom-row {
        margin-bottom: 0;

        input {
            border: 1px solid $primary-color;
            border-radius: 8px;
            min-height: 32px;
            margin-left: 5px;
            padding: 0 10px;
            margin-right: 15px;
            width: 100%;
        }

        div:nth-child(2) {
            flex-grow: 1;
            flex-basis: 0;
            display: inline-flex;
        }
    }
}
.full-width-cont {
    width: 100%;
    .half-width-cont {
        box-sizing: border-box;
    }
    .custom-row {
        margin-bottom: 0;

        input {
            border: 1px solid $primary-color;
            border-radius: 8px;
            min-height: 32px;
            margin-left: 5px;
            padding: 0 10px;
            margin-right: 15px;
            width: 100%;
        }

        div:nth-child(2) {
            flex-grow: 1;
            flex-basis: 0;
            display: inline-flex;
        }
    }
    select {
        border: 1px solid $primary-color;
        border-radius: 8px;
        min-height: 32px;
    }
    .limit-text {
        overflow: hidden;
        cursor: pointer;
        max-width: 14rem;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .label-cont {
        padding-right: 0.5rem;
        font-size: $fs-14;
        font-weight: 400;
        display: flex;
        align-items: center;
    }
    .field-cont {
        font-weight: 600;
        font-size: $fs-14;
        span {
            font-weight: 600;
        }
    }
}
.customer-details-cont {
    .error-field {
        select,
        input,
        .ms-TextField-fieldGroup {
            border: 1px solid $red-200;
        }
    }
    .helper-txt {
        padding-left: 0;
    }
    table {
        width: 85% !important;
    }
}
.tooltip-text {
    cursor: pointer;
}
.dp-customer-details-tbl {
    .row-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .col-flex-box {
        display: flex;
        flex-direction: column;
    }
    .justify-sb {
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }
}

.impact-table-wrap {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    tbody {
        tr {
            td {
                text-align: right;
            }
        }
    }
}

.desc-textfield-wrap {
    input {
        border: 1px solid $primary-color;
        @include border-radius($radius);
        margin-right: 15px;
    }
}
.entry-width {
    width: 140px;
}
