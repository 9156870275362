@import '../../assets/styles/css-assets.scss';
.comment-editor {
    box-sizing: border-box;
    border: 1px solid $gray-300;
    cursor: text;
    @include border-radius($radius);
    font-size: $fs-14;
    min-height: 5rem;
}

.comment-reply-field-container {
    .comment-editor {
        min-height: 1rem;
    }
}

.comment-form-btn-wrap {
    margin-top: 24px;
    button {
        @include border-radius($radius);
        font-size: $fs-16;
        padding: 1.1rem 1.5rem;
        cursor: pointer;
        margin-right: 1rem;
    }
    button.primary {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: $white;
        &[aria-disabled='true'] {
            border: 1px solid rgba($primary-color, 0.1);
            background-color: rgba($primary-color, 0.5);
        }
    }
    button.default {
        border: 1px solid $gray-200;
        background-color: $white;
        color: $gray-200;
    }
}

.comment-reply-field-container {
    .comment-form-btn-wrap {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        button {
            @include border-radius($radius);
            font-size: $fs-12;
            padding: 0.5rem 0.5rem;
            cursor: pointer;
            min-width: auto;
            height: auto;
            margin-right: 0.5rem;
        }
    }
}

.mentions-menu-item {
    border-bottom: 1px solid $gray-600;
    padding: 0.3rem 1rem;
    &:hover {
        background: rgba($color: $primary-color, $alpha: 0.2);
    }
    .mentions-menu-item-name {
        font-size: $fs-14;
        font-weight: 600;
    }
    .mentions-menu-item-email {
        font-size: $fs-14;
        color: $gray-200;
    }
}

.comment-textfield {
    @include border-radius($radius);
    border: 1px solid $primary-color;
}