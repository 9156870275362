@import '../../assets/styles/css-assets.scss';

.surface-modal {
    
        .ms-Dialog-main {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        max-width: 50%;
        overflow: hidden;
        min-width: 500px;
        position: absolute;
    }
    &.medium {
        .ms-Dialog-main {
            width: 640px;
        }
    }
    &.large {
        .ms-Dialog-main {
            width: 80%;
            max-width: 80%;
            top: 10%;
            height: 80%;
        }
    }
    &.image-crop-dialog {
        .ms-Dialog-main {
            min-width: 50%;
            max-width: 95%;
            max-height: 90%;
            height: 90%;
            top:5%;
            .modal-body {
                overflow-x: auto;
                padding: inherit;
                margin: 0 24px 24px 24px;
                max-height: inherit !important;
            }
            .ReactCrop,
            .ReactCrop__image {
                max-width: 100%;
            }
        }
    }
    .modal-header {
        color: $gray-100;
        display: flex;
        align-items: top;
        padding: 12px 12px 14px 24px;
        font-size: $fs-20;
        line-height: $fs-28;
        font-weight: 600;
        justify-content: space-between;
    }
    .close {
        float: right;
        padding: 5px 10px;
        font-stretch: 1px;
        @include font-stretch(1.2, 1);
        color: $gray-300;
        font-weight: 400;
        cursor: pointer;
        &:hover {
            color: $gray-100;
        }
    }
    .modal-body {
        flex: 4 4 auto;
        padding: 0 24px 24px 24px;
        overflow-y: auto;
        max-height: 60vh;
        p {
            margin: 14px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .modal-footer {
        padding: 12px 12px 14px 24px;
        text-align: right;
    }
    button {
        &.ms-link {
            padding: 6px 20px;
            font-size: $fs-14;
            line-height: $fs-20;
            color: $gray-100;
            border: 1px solid $gray-200;
        }
    }
    &.primary {
        .modal-header {
            background-color: $primary-color;
            color: $white;
            padding: 10px 12px 12px 24px;

            span:first-child {
                width: 100%;
                text-align: center;
                line-height: 38px;
                font-weight: normal;
            }
            .close {
                color: $white;
                font-weight: 300;
            }
        }
    }
    &.modal--xl {
        .ms-Dialog-main {
            width: 50%;
        }
    }
}
.ms-Modal-scrollableContent {
    display: flex;
    flex-direction: column;
    .modal-footer {
        align-self: flex-end;
        &.ai-left {
            align-self: flex-start;
        }
    }
}
