@import '../../../assets/styles/css-assets';
.content-area {
    .page-title {
        font-size: $fs-24;
        line-height: $fs-36;
        font-weight: 600;
        color: $gray-100;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 1px solid $gray-500;
        box-sizing: border-box;
        padding: 5px 10px;
        @include border-radius($radius);
    }
}
.banner {
    position: relative;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.thumbnail {
        max-width: 522px;
        margin: inherit;
    }
    .ms-Dropdown {
        .ms-Dropdown-title {
            border: none;
            padding: 0px 5px;
            @include border-radius($radius/2);
        }
        &:after {
            display: none;
        }
    }
    .ms-Dropdown-caretDownWrapper {
        display: none;
    }
    .ms-Dropdown {
        &::after {
            @include border-radius($radius/2);
        }
    }
    img {
        width: 100%;
        position: relative;
    }
}
.icon-overlay {
    position: absolute;
    background: $black-opacity-40;
    padding: 5px;
    border-radius: $radius;
    bottom: $fs-20;
    right: $fs-32;
    cursor: pointer;
    color: $white;
}
