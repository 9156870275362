@import 'app/assets/styles/css-assets';

.addImpact-container {
    animation-fill-mode: forwards !important;
    .is-disabled {
        color: $gray-250;
    }
    .modal-body {
        min-height: auto;
    }
    .rdt_Table {
        border: 1px solid $gray-600;
        border-bottom: 0;
        box-sizing: border-box;
        @include border-radius(2px);
        svg {
            width: 9px !important;
            height: 9px !important;
            top: -2px;
            position: relative;
            left: 2px;
        }
    }

    .rdt_TableHeader {
        display: none;
    }
    .rdt_TableBody {
        border-bottom: 1px solid $gray-600;

        .rdt_TableCol {
            padding-left: 9px;
            padding-right: 9px;
            .sc-crzoAE {
                font-size: 14px;
                line-height: 18px;
                color: $gray-200;
            }
        }
        .rdt_TableCell {
            font-size: 14px;
            line-height: 18px;
            color: $gray-200;

            .select-btn {
                font-size: 14px;
                @include border-radius(1px);
                background: $gray-300;
                color: $white;
            }
        }

        .rdt_TableRow {
            cursor: default;
            .disable-data {
                opacity: 0.2;
                cursor: default;
            }
            &:hover {
                background: $blue-800;
                .rdt_TableCell {
                    .select-btn {
                        background: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }
        }
    }

    .add-heading {
        display: flex;
        padding: 0 52px 24px 52px;
        justify-content: space-between;
        .search-count-container {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: #323130;
        }
        .multiDetails-buttons-contaianer {
            margin-right: 3px;
        }
    }
}

.selected-customer-container {
    .success-body {
        .success-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #0078d4;
            margin: 10px auto 36px;
            justify-content: center;

            &.error-link:hover {
                text-decoration: underline;
            }
        }
    }
}

.body-result-container {
    span {
        &.helper-txt {
            z-index: 1 !important;
        }
    }
    height: 60%;
    padding: 0 0.3rem;

    &::-webkit-scrollbar {
        width: 0.3rem;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba($color: $gray-300, $alpha: 0.2);
        @include border-radius(4px);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba($color: $gray-300, $alpha: 0.5);
    }
    &::-webkit-scrollbar-track {
        @include border-radius(0);
    }
}

.add-impact-model {
    animation-fill-mode: forwards !important;
    .ms-Dialog-main {
        @include border-radius(4px);
        background: $white;
        min-height: auto;
        min-width: 1300px;
        .ms-Modal-scrollableContent {
            .modal-heading {
                background: $primary-color;
                color: $white;
                text-align: center;
                @include border-radius(1px 1px 0 0);

                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 12px;
            }
            .modal-body {
                .body-main-container {
                    margin-bottom: 1.875rem;
                    padding: 14px 23px;
                    background: $blue-800;
                    display: flex;
                    flex-direction: column;
                    .proceed-btn {
                        padding-left: 2rem;
                    }
                    .disabled-button {
                        display: none;
                    }
                    .body-main-sub {
                        padding-left: 26px;
                    }
                    .modal-body-title {
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 28px;
                        color: $gray-200;
                        margin-bottom: 10px;
                        display: inline-block;

                        &.title-field {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            line-height: 1.75rem;
                            max-height: 2rem;
                            -webkit-line-clamp: 1; /* Write the number of 
                                                      lines you want to be 
                                                      displayed */
                            -webkit-box-orient: vertical;
                        }
                        &.back-title {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                        }
                        .arrow-icon {
                            background: url(app/static/icons/arrow-black.svg) no-repeat;
                            display: inline-block;
                            width: 16px;
                            height: 14px;
                            margin-right: 10px;
                            position: relative;
                            top: 1px;
                        }
                    }
                    .secondary-text {
                        font-size: 12px;
                        line-height: 16px;
                        display: inline-block;
                        color: $gray-100;
                        padding-right: 15px;
                        &.mb-10 {
                            margin-bottom: 10px;
                        }
                        .text-bold {
                            font-weight: 600;
                            color: $gray-200;
                        }
                    }
                    .cust-details {
                        display: flex;

                        .secondary-text {
                            border-right: 1px solid $gray-600;
                            padding-right: 15px;
                            padding-left: 15px;

                            &:first-child {
                                padding-left: 0;
                            }
                            &:last-child {
                                border: 0;
                            }
                        }
                    }

                    .form-control {
                        margin-right: 33px;
                        .helper-text {
                            color: $red-200;
                            font-size: 14px;
                            line-height: 22px;
                        }
                        label {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            color: $gray-100;
                            margin-bottom: 12px;
                            display: inline-block;
                            .required {
                                color: $red-200;
                            }
                            &.helper-txt {
                                color: $red-200;
                                font-size: 14px;
                                line-height: 22px;
                                display: block;
                                animation: slideDown2 0.5s ease forwards;
                                z-index: 1;
                                position: relative;
                                margin-top: 5px;

                                &.search-error {
                                    margin-top: 50px;
                                }
                            }
                        }
                        .ms-TextField-fieldGroup {
                            @include border-radius(8px);
                            min-width: 500px;
                            min-height: 40px;
                            padding: 0;
                            .ms-TextField-field {
                                font-weight: 600;
                            }
                        }
                        &.auto-suggest-container {
                            width: 520px;
                            position: relative;
                            .search-MSX-cont {
                                position: absolute;
                                z-index: 2;
                            }

                            input {
                                box-sizing: border-box;
                                @include border-radius(8px);
                                color: $gray-200;
                                min-width: 400px;
                                min-height: 40px;
                                padding: 0px 8px;
                                font-weight: 600;
                            }
                        }
                    }

                    .ms-Button--primary {
                        background: $primary-color;
                        @include border-radius(8px);
                        border: 1px solid $primary-color;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        margin: 33px auto 0;
                        min-width: 130px;
                        min-height: 40px;

                        &:disabled {
                            background: $gray-500;
                            border: 1px solid $gray-500;
                        }
                    }
                }
                .body-result-container {
                    padding: 0 52px 24px 52px;
                    height: 24rem;
                    overflow-y: auto;
                    margin-right: 3px;
                    .add-icon {
                        background: url(app/static/icons/plus-black.svg) no-repeat;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        position: relative;
                        top: 3px;
                        left: 7px;
                    }
                    .minus-icon {
                        background: url(app/static/icons/minus-icon.svg) no-repeat;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        position: relative;
                        top: 8px;
                        left: 7px;
                    }
                    label {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: $gray-100;
                        margin-bottom: 12px;
                        display: inline-block;

                        .required {
                            color: $red-200;
                        }
                        &.helper-txt {
                            color: $red-200;
                            font-size: $fs-14;
                            line-height: 22px;
                            display: block;
                            padding-left: 15px;
                            animation: slideDown2 0.5s ease forwards;
                            z-index: 1;
                            position: relative;
                            margin-top: 5px;
                        }
                    }
                    .no-results-found {
                        font-size: 24px;
                        margin-left: 400px;
                        font-weight: bold;
                        color: $primary-color;
                    }
                    .ms-TextField-fieldGroup {
                        border: 1px solid $gray-600;
                        box-sizing: border-box;
                        @include border-radius(8px);
                    }

                    .impacted-text-fields {
                        display: flex;
                        margin-bottom: 7px;
                        .ms-TextField-fieldGroup {
                            margin-right: 50px;
                            min-width: 325px;
                            height: 40px;
                            color: $gray-200;
                            margin-bottom: 12px;
                            input {
                                @include border-radius(8px);
                            }
                        }
                        .helper-text {
                            color: $red-200;
                            font-size: 14px;
                            line-height: 22px;
                        }
                        .num-foramt {
                            border: 1px solid $gray-600;
                            box-sizing: border-box;
                            min-width: 325px;
                            height: 40px;
                            color: $gray-200;
                            @include border-radius(8px);
                            padding: 0px 8px;
                            display: block;
                            margin: 5px 50px 10px 0;
                        }
                        .country-select {
                            display: flex;
                            flex-direction: column;
                            select {
                                min-width: 20.313rem;
                            }
                        }
                        .format-field-cont {
                            &.error-feild {
                                border: 1px solid $red-200;
                            }
                            @include border-radius($radius);
                            height: 40px !important;
                            border: 1px solid #edebe9;
                            overflow: hidden;
                            padding: 0 15px;
                            background: $white;

                            .format-field {
                                width: 100%;
                                border: none;

                                color: rgb(50, 49, 48);
                                display: flex;
                                &::placeholder {
                                    color: $gray-300;
                                }
                            }
                        }
                        .format-field-cont:focus-visible {
                            outline: none;
                        }
                    }
                    .desc-text-field {
                        margin-bottom: 10px;
                        .ms-TextField-fieldGroup {
                            min-height: 150px;
                            width: 820px;
                        }
                        .helper-text {
                            color: $red-200;
                            font-size: 14px;
                            line-height: 22px;
                            margin-top: 5px;
                            display: block;
                        }
                    }
                    .add-details-btn {
                        margin-top: 30px;
                        button {
                            margin-right: 20px;
                        }
                    }
                    .link-txt {
                        position: relative;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 20px;
                        color: $gray-200;
                        margin-bottom: 20px;
                        display: inline-block;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .recently-added-container {
                        border-bottom: none;
                        display: flex;
                        flex-direction: column;
                        .recently-added-header {
                            padding: 5px;
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid $gray-600;
                        }
                        .recently-added-content {
                            display: flex;
                            flex-direction: column;

                            opacity: 1;
                            max-height: 500px;
                            transition: max-height 0.5s ease-in, opacity 3s linear;

                            &.is-hide {
                                opacity: 0;
                                max-height: 0;
                                transition: max-height 0.5s ease-out, opacity 3s linear;
                                overflow: hidden;
                            }
                        }
                        .recently-added-row {
                            padding: 5px;
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid $gray-600;
                        }

                        .results-cont {
                            color: $gray-100;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 20px;
                            margin-bottom: 36px;
                            display: block;
                            margin-right: 36px;
                        }
                    }
                    .btn-link-container {
                        height: 30px;
                        margin-bottom: 20px;
                        .btn-link {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            margin-top: 30px;
                            text-decoration: underline;
                            cursor: pointer;
                            &.link-right {
                                float: right;
                            }
                        }
                    }

                    .auto-suggest-container-sm {
                        .react-autosuggest__container {
                            position: relative;

                            .react-autosuggest__input {
                                @include border-radius(8px);
                                border: 1px solid $gray-400;
                                box-sizing: border-box;
                                color: $gray-200;
                                padding: 10px;
                                height: 40px;
                                min-width: 250px;
                                margin-right: 50px;
                                z-index: 99;
                                position: relative;
                            }
                            .react-autosuggest__suggestions-container {
                                position: absolute;
                                background: $white;
                                box-shadow: 0px 1px 2px $gray-600;
                                border: 1px solid $gray-600;
                                z-index: 98;

                                width: 247px;
                                top: 37px;
                            }

                            .react-autosuggest__suggestions-list {
                                list-style: none;
                                padding: 0 0 0 20px;
                            }
                            .react-autosuggest__suggestion {
                                cursor: pointer;
                                margin-bottom: 10px;
                            }
                        }

                        input {
                            border: 1px solid $gray-600;
                            box-sizing: border-box;
                            @include border-radius(8px);
                            color: $gray-200;
                            min-width: 400px;
                            min-height: 40px;
                            padding: 0px 8px;
                            font-weight: 600;
                        }
                    }

                    .select-count-btn {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.add-impact-model {
    animation-fill-mode: forwards !important;
    .close-model {
        position: absolute;

        right: 10px;
        top: 10px;
        .ms-Button,
        .ms-Button:hover {
            color: $white;
            background: unset;
        }

        &.close-primary {
            color: $blue-400;
            .ms-Button,
            .ms-Button:hover {
                color: $blue-400;
                background: unset;
            }
        }
    }
}

.rdt_TableCell input:focus,
.rdt_TableCell input:active {
    outline-color: $black !important;
    outline-width: 3px !important;
    outline: auto !important;
}

.addCustomer-table-container {
    .rdt_TableHeadRow, .rdt_TableRow{
        &>div:last-child{            
            flex: 0 0 100px;
        }
    }
    
    .rdt_TableCell {
        max-height: none !important;
    min-height: unset !important;
        input {
            width: 15px;
            height: 15px;
        }
        div {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        
        svg{
            top: -2px;
        }
    }
}
