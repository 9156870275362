@import '../../../../assets/styles/media-queries.scss';
@import '../../../../assets/styles/css-assets.scss';


.assigned-to-cell {
    display: flex;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #323130;
    flex-direction: row;

    .ms-Persona {
        max-width: 25px;
        margin-right: 10px;
    }

    .ms-Image {
        min-width: 25px;
    }
    .assigned-to-display-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.fontStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Type colors / Primary */

    color: #323130;
}

.title-link-styles {
    text-decoration: none;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #323130;

    &:hover {
        text-decoration: underline;
        color: $blue-300
    }
}

.tagNameContainers {
    display: flex;
    height:50px;
    align-items: center;
    justify-content: flex-start;
}

.tag-icon-styles {
    height:20px;
    margin-right: 10px;
    margin-left:5px;
}

.listItemStyles {  
    border-bottom: 1px solid lightgray;
    height:30px;
    max-width: 150px;
    min-width:30px;
    width:auto;   
    text-overflow: ellipsis;
    margin-bottom:10px;
    margin-top:10px;
}

.listItemStyles:is(:last-child) { border-bottom: 0; margin-bottom:0; }

.tag-count-container {
    background: $blue-750;
    border-radius: 50%;
    width:30px;
    height:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left:2px;
    .tag-count {
        font-weight: 600;
        font-size: 14px;
        color: $gray-200;
    }
    .tag-count:hover {
        cursor: pointer;
    }
}

.parent-grid-container {
    max-width:98%;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .k-grid-header, .k-grid-header-wrap, .k-widget, k.grid, .k-link, .k-header {
        border:none !important;
    }
    .k-grid-header:hover {
        border: none !important;
    }
        .tagNames {
            height:30px;
            max-width: 60px;
            min-width:60px;
            width:auto;   
            text-overflow: ellipsis;
            margin-bottom:10px;
            margin-top:10px;
            font-family: 'Segoe UI';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #323130;
        }
        .tag-names-tooltip {
            text-overflow: ellipsis !important;
            overflow: hidden;
            max-width:60px;
            padding-top:5px;
            padding-bottom:5px;
        }
    
    .k-grid-table {
        
    }
    .request-type-header, .k-link {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        background: #EFF6FC;
    }
    .k-grid, .k-grid-container {
     
    }

    .k-master-row {
        height:80px;
    }
    
    .k-hierarchy-cell {

    }

    .k-widget, .k-grid, .k-grid-header-wrap, .k-grid-content {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        border:none;
    }

    .k-grid-header {
        border:none;
        background: #EFF6FC;
    }

     .k-grid-header:hover {
        border:none;
        background: #EFF6FC;
    }

    .k-link {
        background: #EFF6FC;
        
    }
    .k-detail-row, .k-detail-cell {
        padding: 0;
        
        
    .k-grid th, .k-grid td {
     border:none !important;
    }
    }
}

.statusStyles {
    display: flex;
    align-items: center;

}

.page-container {
    height: 1000px;
}

.requestCountContainer {
    margin-left: 11%;
    height: 50px;
    display:flex;
    align-items: center;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.seperator-bar-left {
    width: 60%;
    height:50px;
    margin-left: 8%;
}

.seperator-bar-right {
    height: 50px;
    width: 80%;
}

.dataPreviewStyles {
    height: 105px;
    width: 94%;
    display:flex;
    justify-content: center;
    align-items: flex-end;
}