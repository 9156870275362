@import '../../assets/styles/css-assets.scss';
.drop-shadow {
    box-shadow: 0px 1px 2px rgba($black, 0.1);
}
.header {
    padding: 0.9rem 0;
    width: 100%;
    .header-text {
        color: $primary-color;
        a {
            font-size: $fs-26;
            font-weight: 600;
            color: $primary-color;
            text-decoration: none;
            &:hover {
                text-decoration: none;
                color: $primary-color;
            }
        }
        @include lt-md {
            text-align: left;
            margin: 20px 0;
        }
    }
    .user-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include lt-md {
            justify-content: flex-start;
        }
        a {
            text-decoration: none;
        }
        .border-right {
            border-right: 2px solid $gray-600;
        }
        .border-left {
            border-left: 2px solid $gray-600;
        }
        .language-dropdown {
            margin-left: 10px;
            .ms-Dropdown-title {
                border: none;
                font-family: arial;
                font-size: $fs-14;
                color: $gray-100;
                padding-top: 2px;
            }
        }
        .notif-button {
            cursor: pointer;
            position: relative;
            padding-right: 14px;
            i.bell-icon {
                font-size: $fs-20;
                cursor: pointer;
                &.animate {
                    animation: ring 5s 0.7s ease-in-out infinite;
                    transform-origin: 50% 4px;
                    @keyframes ring {
                        0% {
                            transform: rotate(0);
                        }
                        1% {
                            transform: rotate(30deg);
                        }
                        3% {
                            transform: rotate(-28deg);
                        }
                        5% {
                            transform: rotate(34deg);
                        }
                        7% {
                            transform: rotate(-32deg);
                        }
                        9% {
                            transform: rotate(30deg);
                        }
                        11% {
                            transform: rotate(-28deg);
                        }
                        13% {
                            transform: rotate(26deg);
                        }
                        15% {
                            transform: rotate(-24deg);
                        }
                        17% {
                            transform: rotate(22deg);
                        }
                        19% {
                            transform: rotate(-20deg);
                        }
                        21% {
                            transform: rotate(18deg);
                        }
                        23% {
                            transform: rotate(-16deg);
                        }
                        25% {
                            transform: rotate(14deg);
                        }
                        27% {
                            transform: rotate(-12deg);
                        }
                        29% {
                            transform: rotate(10deg);
                        }
                        31% {
                            transform: rotate(-8deg);
                        }
                        33% {
                            transform: rotate(6deg);
                        }
                        35% {
                            transform: rotate(-4deg);
                        }
                        37% {
                            transform: rotate(2deg);
                        }
                        39% {
                            transform: rotate(-1deg);
                        }
                        41% {
                            transform: rotate(1deg);
                        }
                        43% {
                            transform: rotate(0);
                        }
                        100% {
                            transform: rotate(0);
                        }
                    }
                }
            }
            .badge {
                cursor: pointer;
                position: absolute;
                right: 7px;
                top: -7px;
                color: $white;
                background: #dc143c;
                @include border-radius(50%);
                font-size: $fs-10;
                height: 16px;
                width: 16px;
                text-align: center;
                &--xl {
                    right: 3px;
                    width: 24px;
                }
            }
        }
        .borderLine {
            margin-right: 10px;
            border: 2px solid $gray-600;
            border-bottom: none;
            border-top: none;
            border-left: none;
        }
        .profile {
            display: flex;
            align-items: center;
            padding-right: 10px;
            padding-left: 10px;
            cursor: pointer;
            .userName {
                color: $gray-200;
                font-size: $fs-15;
                padding-right: 9px;
            }
            small {
                color: $gray-240;
            }
            .display-pic {
                width: 36px;
                margin-right: 10px;
                position: relative;
                height: 36px;
                border-radius: 50%;
                overflow: hidden;
                img {
                    vertical-align: middle;
                    border-style: none;
                }
                .place-holder {
                    @include border-radius(50%);
                }
                .status-indicator {
                    position: absolute;
                    bottom: 1px;
                    right: 2px;
                    @include border-radius(50%);
                    background-color: $green-300;
                    font-size: $fs-10;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }
}
.bottom-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    @include lt-md {
        flex-direction: column;
        .search-box-top {
            display: flex;
            align-self: center;
        }
    }
    .ms-SearchBox {
        width: 100px;
        border: none;
        margin-top: 5px;

        font-size: $fs-14;
    }
    .navigation_link {
        padding-left: 0;
        .ms-Nav-group {
            height: 44px;
            overflow: hidden;
        }
        .ms-Nav-groupContent {
            margin: 0;
        }
        ul {
            padding-left: 0px;

            li {
                display: inline-block;

                .ms-Nav-link {
                    background: none;
                    padding: 0;
                    margin: 0 20px;
                    line-height: 36px;
                    width: auto;
                    &:hover {
                        background: none;
                        @include border-radius(0);
                    }
                    .ms-Nav-linkText {
                        margin: 0 0 -5px 0;
                    }
                }

                .ms-Nav-compositeLink:hover {
                    .ms-Nav-link {
                        background-color: transparent;
                    }
                }

                .is-selected {
                    .ms-Nav-link {
                        background: none;
                        padding: 0;
                        &::after {
                            border: 0;
                        }
                        .ms-Nav-linkText {
                            border-bottom: 2px solid $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.navigation_link {
    ul {
        padding: 0;
        li {
            list-style: none;
            display: inline-block;
            margin-right: 40px;
            height: 23px;
            a {
                font-size: $fs-16;
                line-height: 20px;
                text-decoration: none;
                color: $gray-200;
                height: 38px;
                box-sizing: border-box;
                font-weight: 400;
            }

            &.active {
                a {
                    border-bottom: 2px solid $primary-color;

                    display: inline-block;
                    color: $primary-color;
                }
            }
            .admin-home-icon {
                background: url(app/static/icons/admin-home-default-icon.svg) no-repeat;
                background-size: 18px 20px;
                height: 22px;
                width: 18px;
                display: inline-block;
                position: relative;
                top: 5px;
            }
            .home-icon {
                background: url(app/static/icons/home-default.svg) no-repeat;
                background-size: 18px 20px;
                height: 20px;
                width: 18px;
                display: inline-block;
                position: relative;
                top: 5px;
            }

            &.active {
                .admin-home-icon {
                    background: url(app/static/icons/admin-home-icon.svg) no-repeat;
                }
                .home-icon {
                    background: url(app/static/icons/home.svg) no-repeat;
                }
            }

            &:first-child {
                a {
                    height: 43px;
                }
            }
        }
    }
}

.strip {
    background: $gray-700;
    padding: 24px 0;
    position: relative;
    .menu-icon {
        background: url(app/static/icons/menu_icon.svg) no-repeat;
        background-size: 15px 15px;
        height: 15px;
        width: 15px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        margin-right: 12.5px;
        &:hover {
            .menu {
                .MenuComponent {
                    display: block;
                }
            }
        }
    }
    .strip-txt {
        color: $gray-100;
        display: flex;
        align-items: flex-start;
        width: 100%;
        .brd {
            font-weight: 600;
            position: relative;
            width: 100%;
        }
        span.bld {
            font-size: $fs-24;
            font-weight: 600;
            display: flex;
            align-items: center;
            .back-arrow {
                margin-right: 15px;
                margin-top: 2px;
                display: flex;
                .ms-Icon {
                    display: flex;
                }
            }
        }
        .info-icon {
            background: url(app/static/icons/info-icon.svg) no-repeat;
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
            display: inline-block;
            position: relative;
            top: 3px;
            margin-right: 12.5px;
        }
    }
}
.notification-container {
}
