@import '../../assets/styles/css-assets';

.pagination-container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.pagination {
    padding: 0px;
    margin: 0px;
    li {
        color: $gray-200;
        padding: 0px;
        @include border-radius(4px);
        font-size: $fs-14;
        &:focus:not(:focus-visible) {
            outline: none;
        }
        a {
            padding: 6px 8px;
            display: inline-block;
            &:focus:not(:focus-visible) {
                outline: none;
            }
        }
        &.next,
        &.previous {
            margin: 0 15px;
            color: $blue-350;
            a {
                padding: 6px 15px;
            }
        }
        &:hover {
            color: $blue-400;
        }
        &.active {
            background: $blue-400;
            color: $white;
        }
    }
}
.paginate-cont {
    display: flex;
    align-items: center;
    font-size: $fs-12;
    color: $gray-200;
}
