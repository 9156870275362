@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideDown2 {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideUP {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideUPfromZero {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes titleUp {
    0% {
        margin-top: 150px;
        height: 200px;
    }

    100% {
        margin-top: 0px;
        height: 52px;
    }
}

@keyframes newReqBodyAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideRightToLeft {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes slideLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes nextSlide {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes prevSlide {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes animateToLeft {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }

    100% {
        opacity: 0;
        transform: translateX(-50px);
    }
}

@keyframes animateToRight {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }

    100% {
        opacity: 0;
        transform: translateX(50px);
    }
}

@keyframes productTextHover {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
        transform: translateY(10px);
    }
}

@keyframes productHover {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}

@keyframes productHoverOut {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes showDialog {
    0% {
        top: -100%;
        opacity: 0;
    }

    50% {
        top: 52%;
        opacity: 1;
    }

    70% {
        top: 48%;
        opacity: 1;
    }

    100% {
        top: 50%;
        opacity: 1;
    }
}

@keyframes showDialog2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mymove {
    0% {
        box-shadow: -1px 1px 5px rgba(0, 120, 212, 0.6);
    }

    10% {
        box-shadow: -1px 1px 10px rgba(0, 120, 212, 0.6);
    }

    20% {
        box-shadow: -1px 1px 15px rgba(0, 120, 212, 0.6);
    }

    30% {
        box-shadow: -2px 1px 24px rgba(0, 120, 212, 0.6);
    }

    40% {
        box-shadow: -4px 1px 30px rgba(0, 120, 212, 0.6);
    }

    60% {
        box-shadow: -4px 1px 30px rgba(0, 120, 212, 0.6);
    }

    70% {
        box-shadow: -2px 1px 24px rgba(0, 120, 212, 0.6);
    }

    80% {
        box-shadow: -1px 1px 15px rgba(0, 120, 212, 0.6);
    }

    90% {
        box-shadow: -1px 1px 10px rgba(0, 120, 212, 0.6);
    }

    100% {
        box-shadow: -1px 1px 5px rgba(0, 120, 212, 0.6);
    }
}

@keyframes greyToWhiteTextColor {
    0% {
        color: $blue-400;
    }

    100% {
        color: $white;
    }
}

@keyframes shadow-ani {
    0% {
        box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
    }

    50% {
        box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.3);
    }

    100% {
        box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
    }
}

@keyframes buttonAnimation {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0%;
        opacity: 0.4;
    }
}

@keyframes buttonAnimationReverse {
    0% {
        left: 0%;
        opacity: 0.4;
    }

    100% {
        left: 100%;
        opacity: 0;
    }
}

@keyframes thumbsUp {
    0% {
        top: 0px;
    }
    20% {
        top: -13px;
    }
    30%,
    100% {
        top: -3px;
    }
}
@keyframes thumb {
    0%,
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-70deg);
    }
}
