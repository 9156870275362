@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.feedback-card {
    font-size: $fs-14;
    color: $gray-200;
    background-color: $white;
    box-shadow: 0px 4px 4px rgba($gray-200, 0.25);
    @include border-radius($radius);
    margin-top: 1rem;
    margin-bottom: 5rem;

    .hidden {
        display: none;
    }
}
