@import '../../../assets/styles/css-assets.scss';

.attachments-icon {
    background: url(app/static/icons/attachment-icon.svg) no-repeat;
    display: inline-block;
    width: 12px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 4px;
}
.success-icon {
    background: url(app/static/icons/admin-success-icon.svg) no-repeat;
    display: block;
    width: 87px;
    height: 88px;
    cursor: pointer;
    margin: 0 auto 36px;
}
.triage-model {
    .to-field,
    .select-team-field,
    .select-type-field,
    .select-reason-field,
    .select-date-field,
    .cc-field {
        width: 47%;
    }

    .ms-Dialog-main {
        background: $white;
        min-height: 170px;
        min-width: 925px;
    }

    .modal-heading {
        background: $primary-color;
        color: $white;
        text-align: center;
        border-radius: 1px 1px 0 0;
        font-weight: 600;
        font-size: $fs-16;
        line-height: 20px;
        padding: 20px;
    }

    .modal-body {
        .ms-Image {
            display: flex;
            justify-content: center;
        }
        .body-main-container {
            padding: 23px 40px;
            background: $gray-700;
            display: flex;
            flex-direction: column;
            .cust-details {
                font-size: $fs-14;
                line-height: 20px;
                .secondary-text {
                    line-height: 20px;
                    border-right: 1px solid $gray-600;
                    padding-right: 15px;
                    color: $gray-200;
                    padding-left: 15px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        border: 0;
                    }
                    .text-bold {
                        font-weight: 600;
                        color: $gray-100;
                    }
                }
            }
            .link-txt {
                font-weight: 600;
                font-size: $fs-14;
                line-height: 20px;
                color: $primary-color;
                text-decoration: underline;
            }

            .modal-body-title {
                font-weight: 600;
                font-size: $fs-22;
                line-height: 20px;
                margin: 18px 0 12px;
                display: inline-block;
                &.title-field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.7rem;
                    max-height: 2rem;
                    -webkit-line-clamp: 1; /* Write the number of 
                                              lines you want to be 
                                              displayed */
                    -webkit-box-orient: vertical;
                }
            }
        }
        .body-form-container {
            padding: 23px 40px 40px;
            .ms-TextField-fieldGroup,
            .ms-Dropdown {
                &::after {
                }
            }
            .error-field {
                .ms-TextField-fieldGroup,
                .ms-Dropdown-title {
                    border: 1px solid $red-200;
                }
            }
            .form-control {
                .ms-Dropdown-title {
                    border-radius: 6px;
                    &::after {
                        border-radius: 6px;
                    }
                }
                .ms-Dropdown {
                    &::after {
                        border-radius: 6px;
                    }
                }
                .helper-text {
                    color: $red-200;
                    font-size: $fs-14;
                    line-height: 22px;
                    font-style: italic;
                }
                .note-field {
                    .ms-TextField-fieldGroup {
                    }
                }
                .form-row {
                    margin-bottom: 22px;
                    label {
                        font-size: $fs-16;
                        line-height: 28px;
                        margin-bottom: 6px;
                        padding: 0;
                        display: inline-block;
                        color: $gray-200;
                        .required {
                            color: $red-200;
                        }
                    }

                    .ms-Dropdown-title,
                    .ms-TextField-fieldGroup {
                        border: 1px solid $gray-250;
                        box-sizing: border-box;
                        width: 100%;
                    }
                }
                .attachments-section {
                    display: inline-block;
                    .ms-Link {
                        color: $primary-color;
                        font-size: $fs-16;
                        line-height: 20px;
                        margin-bottom: 10px;
                        display: flex;
                        text-decoration: underline;
                    }

                    .add-attachments-link {
                        .ms-Icon {
                            height: 20px;
                            width: 20px;
                            @include border-radius(50%);
                            background-color: rgba($color: $blue-700, $alpha: 0.5);
                            padding: 0.3rem;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0.5rem;
                        }
                    }
                }
                .form-inline {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .btn-container {
                margin-top: 33px;
                button {
                    min-width: 130px;
                    min-height: 40px;
                    @include border-radius(6px);
                }
                .ms-Button--default {
                    margin-right: 20px;
                    border: 1px solid $gray-600;
                    box-sizing: border-box;
                    @include border-radius(6px);
                    color: $gray-200;
                    font-size: $fs-14;
                    line-height: 20px;

                    .ms-Button-label {
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .selected-customer-container {
        height: 384px;
        display: table;
        margin: 0 auto;

        .success-body {
            vertical-align: middle;
            display: table-cell;
        }
    }
    .success-txt {
        .span-txt {
            font-size: $fs-28;
            line-height: 48px;
            text-align: center;
            display: block;
            .ms-Link {
                color: $primary-color;
            }
            button {
                font-size: $fs-28;
                line-height: 48px;
                text-align: center;
                color: $gray-100;
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
}
