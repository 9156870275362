@import 'app/assets/styles/css-assets';
@import './animations';

.content-cont {
    .landing-screen-cont {
        margin: 150px auto 130px;
        max-width: 1106px;
        &.no-margin {
            margin: 0 170px;
        }
        .quicklinks-tag {
            &.no-bg {
                background: none;
                box-shadow: none;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 2;
            position: relative;
            background: rgba(255, 255, 255, 0.7);
            @include border-radius($radius);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
            margin-bottom: 1rem;
            padding: 0.9rem 1.5rem;
            span {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: $gray-100;
            }
            img {
                margin-right: 1rem;
            }
        }
    }
    .flex-center {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
        padding-bottom: 1.3rem;
        .create-req-cont {
            @include border-radius($radius);
            padding: 0.4rem 2.81rem;
            border: 1px solid $primary-color;
            box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.4);
            span {
                color: $gray-300 !important;
                font-weight: normal !important;
                font-size: $fs-14 !important;
                .ms-Link {
                    font-weight: 600;
                }
            }
        }
    }
}

.title-container {
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
}

.title-header {
    &.remove-bg {
        background: none;
        padding: 1.25rem 4rem 0.75rem 1.5rem;
    }
    &.change-border {
        border-bottom: 2px solid $blue-750;
    }
    padding: 1.25rem 3.5rem;
    border-bottom: 2px solid $white;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    justify-content: space-between;
    .new-request-text {
        font-weight: 600;
        font-size: $fs-20;
        color: $gray-100;
    }
    .cancel-request-link {
        font-size: $fs-14;
        text-decoration: underline;
        color: $primary-color;
        cursor: pointer;
    }
}

.landing-screen {
    &.add-radius {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
    .welcome-page {
        padding: 45px 78px 79px;
    }
    .first-titlefield-cont {
        padding-bottom: 3.5625rem;
    }
    .first-titlefield {
        display: flex;
        flex-direction: column;
        padding: 30px 5%;
        .description-field {
            width: 83%;
        }
        .desc-label {
            margin-left: 5.1875rem;
        }
        .ms-TextField-wrapper {
            label {
                margin-left: 2.1875rem;
            }
        }
        .helper-txt {
            margin-left: 4.6875rem;
        }
        .btn {
            width: 2.6875rem;
            height: 2.6875rem;
            background: $gray-600;
            @include border-radius(2.5rem);
            position: relative;
            cursor: pointer;
            overflow: hidden;
            @extend .btn-animation;
            align-self: flex-end;
            margin-bottom: 0.3125rem;
            border: 0.058318375rem solid $gray-500;
            &.align-flex-start {
                align-self: flex-start;
            }
            &.active {
                background: $primary-color;
                border: hidden;
                .left-arrow {
                    background: url(app/static/icons/right-arrow-request-help.svg) no-repeat;
                    transform: rotate(180deg);
                    display: inline-block;
                    width: 0.625rem;
                    height: 1.1875rem;
                    position: absolute;
                    top: 0.8125rem;
                    left: 0.9375rem;
                }
                .right-arrow {
                    background: url(app/static/icons/right-arrow-request-help.svg) no-repeat;
                    display: inline-block;
                    width: 10px;
                    height: 19px;
                    position: absolute;
                    top: 13px;
                    left: 18px;
                }
            }
            .right-arrow {
                background: url(app/static/icons/inactive-arrow.svg) no-repeat;
                display: inline-block;
                width: 10px;
                height: 19px;
                position: absolute;
                top: 13px;
                left: 18px;
            }
            .left-arrow {
                background: url(app/static/icons/inactive-arrow.svg) no-repeat;
                transform: rotate(180deg);
                display: inline-block;
                width: 0.625rem;
                height: 1.1875rem;
                position: absolute;
                top: 0.8125rem;
                left: 0.9375rem;
            }
        }
        .ms-TextField-fieldGroup {
            margin: 0 2.5rem;
            background: $white;
            box-sizing: border-box;
            border-radius: $radius;
            border: 1px solid $gray-250;
            &::after {
                border-radius: $radius;
                animation: mymove 2s infinite;
            }
            textarea,
            input {
                &::placeholder {
                    color: $gray-300;
                }
            }
        }
        .regular-text-field {
            .ms-TextField-fieldGroup {
                padding: 0 15px;
                height: 45px;
                input {
                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
            height: 45px;
        }
        .action-date-picker {
            width: 40%;
            i {
                margin-top: 5px;
            }
        }
        .preview-case-num {
            font-weight: 600;
        }
        .title-field {
            .ms-TextField-fieldGroup {
                height: 4rem;
                padding: 0 15px;
            }
        }
        .desc {
            .ms-TextField-fieldGroup {
                padding: 14px;
            }
        }
        .error-feild {
            .ms-TextField-fieldGroup {
                border: 1px solid $red-200;
            }
        }
        label {
            font-weight: normal;
            font-size: $fs-18;
            line-height: 55px;
            color: $gray-100;
        }
        .row-flex {
            display: flex;
            flex-direction: row;
            .ms-TextField {
                flex-grow: 1;
            }
            .next-btn {
                margin-left: 40px;
                width: 43px;
                height: 43px;
                background: #edebe9;
                @include border-radius(40px);
                position: relative;
                cursor: pointer;
                overflow: hidden;
                @extend .btn-animation;
                align-self: flex-end;
                margin-bottom: 5px;
                .right-arrow {
                    background: url(app/static/icons/inactive-arrow.svg) no-repeat;
                    display: inline-block;
                    width: 10px;
                    height: 19px;
                    position: absolute;
                    top: 13px;
                    left: 18px;
                }
            }
        }
    }
    &.page-load-animation {
        .welcome-text {
            animation: slideDown 1s ease forwards;
        }
        .search-cont {
            animation: slideUP 1s ease forwards;
        }
        &.fadeOut {
            animation: slideUPfromZero 0.5s ease forwards;
        }
    }
}

.fadeOut {
    animation: fadeOut 1s ease forwards;
}

.slideDown {
    animation: slideDown 1s ease forwards;
}

.fadeIn {
    animation: fadeIn 1s ease forwards;
}

.slideUp {
    animation: slideUPfromZero 1s ease forwards;
}

.welcome-text {
    display: flex;
    flex-direction: column;
}

.hide {
    display: none;
}

.next-btn-container{
    height: 90px;
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
}

.req-exp-cont {
    font-family: $font-family;
    padding-top: 15px;
    min-height: 800px;
    position: relative;
    &.hide-overflow {
        overflow: hidden;
    }
    .content-cont {
        display: block;
        .landing-screen {
            display: flex;
            flex-direction: column;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            z-index: 2;
            position: relative;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 0.7);
            width: 100%;
            .head {
                font-size: $fs-28;
                font-weight: 400;
                color: $gray-100;
                line-height: 38px;
                margin-bottom: 10px;
            }
            .text {
                font-size: $fs-18;
                line-height: 28px;
                display: inline-block;
                margin-bottom: 10px;
                color: $gray-200;
            }
            .search-cont {
                width: 100%;
                box-sizing: border-box;
                &.remove-glow {
                    border: 1px solid $gray-400 !important;
                    animation: none !important;
                }
                &.suggest {
                    background: $white;
                    border: 2px solid $primary-color;
                    @include border-radius($radius);
                    animation: mymove 2s infinite;
                }
                .micro-icon {
                    background: url(app/static/icons/microVoice.svg) no-repeat;
                    background-size: 17px 24px;
                    width: 17px;
                    height: 24px;
                    display: inline-block;
                    top: 20px;
                    right: 24px;
                    position: absolute;
                    cursor: pointer;
                }
                .type-ahead {
                    position: relative;
                    width: 100%;
                    display: inline-block;
                    .recording-row {
                        cursor: pointer;
                    }
                    &.no-border {
                        padding-left: 10px;
                        .ms-TextField-fieldGroup {
                            border: none !important;
                            @include border-radius(none);
                            box-shadow: none !important;
                            width: 95%;
                        }
                        i[data-icon-name='Search'] {
                            left: 29px;
                        }
                    }
                    .ms-TextField-fieldGroup {
                        background: $white;
                        border: 2px solid $primary-color;
                        @include border-radius($radius);
                        animation: mymove 2s infinite;
                        height: 60px;
                        padding-left: 40px;
                        padding-right: 40px;
                        &::after {
                            border: none;
                        }
                    }
                    i[data-icon-name='Search'] {
                        font-size: $fs-18;
                        position: absolute;
                        top: 22px;
                        z-index: 1;
                        left: 24px;
                        font-weight: 600;
                        color: $gray-300;
                        transform: rotateY(-180deg);
                    }
                    i[data-icon-name='Microphone'] {
                        position: absolute;
                        z-index: 1;
                        font-size: $fs-22;
                        color: $primary-color;
                        top: 21px;
                        right: 20px;
                    }
                }
                .suggestion {
                    background: $white;
                    width: 100%;
                    @include border-radius($radius);
                    &.show {
                        max-height: 500px;
                        transition: max-height 0.25s ease-in;
                    }
                    .search-suggestion {
                        margin: 10px;
                        .suggestion-result-cont {
                            padding-left: 20px;
                            padding-bottom: 0.1rem;
                            border-top: 1px solid $gray-600;
                            color: $gray-100;
                            .dash {
                                font-weight: 600;
                            }
                            .add-bg {
                                background: $blue-800;
                                .suggestion-result {
                                    border: hidden;
                                }
                                @include border-radius($radius);
                            }
                        }
                        .rel-requests {
                            font-weight: 600;
                            font-size: $fs-14;
                            line-height: 20px;
                            color: $gray-200;
                            margin: 11px 0 9px 20px;
                            display: inline-block;
                        }
                        &.show {
                            max-height: 500px;
                            transition: max-height 0.25s ease-in;
                        }
                        .suggestion-result-cont {
                            &:nth-last-child(2) {
                                border-bottom: 1px solid $gray-600;
                            }
                            .result-skip-id {
                                font-weight: 600;
                            }
                            .expanded-result {
                                display: flex;
                                flex-direction: column;
                                padding-left: 32px;
                                .expanded-head {
                                    font-weight: 600;
                                    font-size: $fs-14;
                                    color: $gray-100;
                                    line-height: 28px;
                                }
                                .expanded-body {
                                    font-size: $fs-14;
                                    line-height: 20px;
                                    color: $gray-100;
                                    margin-bottom: 7px;
                                }
                            }
                            .addCustomer-container {
                                display: flex;
                                justify-content: center;
                                margin: 15px 0;
                                .add-cust-btn {
                                    min-width: 9.0625rem;
                                    border: 1px solid $primary-color;
                                    background-color: $primary-color;
                                    color: $white;
                                    @include border-radius($radius);
                                    font-size: $fs-12;
                                    padding: 0.63rem 1rem;
                                    box-sizing: border-box;
                                    height: auto;
                                }
                            }
                            .rel-requests {
                                font-weight: 600;
                                font-size: $fs-14;
                                line-height: 32px;
                                color: $gray-200;
                            }
                            .suggestion-result {
                                padding-top: 0.1rem;
                                font-size: $fs-14;
                                line-height: 32px;
                                position: relative;
                                cursor: pointer;
                                padding: 4px 0;
                                display: flex;
                                align-items: center;
                                .ms-Button {
                                    position: relative;
                                    top: 3px;
                                    &:hover {
                                        background: none;
                                    }
                                }
                                .ms-Icon {
                                    font-size: $fs-15;
                                    font-weight: 600;
                                    color: #868584;
                                }
                                .dot {
                                    background: url(app/static/icons/dot-icon.svg) no-repeat;
                                    display: inline-block;
                                    width: 10px;
                                    height: 8px;
                                    margin-left: 10px;
                                    margin-right: 6px;
                                }
                                .result-title {
                                    white-space: nowrap;
                                    width: 65%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                }
                                &:hover {
                                    font-weight: 600;
                                }
                            }
                        }
                        .create-req-link {
                            font-weight: 600;
                            font-size: $fs-14;
                            line-height: 19px;
                            color: $gray-240;
                            font-style: normal;
                            display: block;
                            width: 100%;
                            text-align: center;
                            .ms-Link {
                                text-decoration: underline;
                            }
                        }
                    }
                    .start {
                        margin: 7px;
                        background: $gray-700;
                        color: $gray-300;
                        font-size: $fs-14;
                        line-height: 20px;
                        @include border-radius(3px);
                        padding: 10px 12px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .root-44 {
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .new-req-container {
        .col-flex-box {
            display: flex !important;
            flex-direction: column;
        }
        .row-flex-box {
            display: flex !important;
        }
        .align-end {
            justify-content: flex-end;
        }
        .align-center {
            align-items: center;
        }
        .justify-center {
            justify-content: center;
        }
        .format-field-cont {
            &.error-feild {
                border: 1px solid $red-200;
            }
            @include border-radius($radius);
            height: 45px !important;
            border: 1px solid $gray-250;
            overflow: hidden;
            padding: 0 15px;
            background: $white;
            &:focus-within {
                border: 2px solid $primary-color;
                animation: mymove 2s infinite !important;
            }
            .format-field {
                width: 100%;
                border: none;
                color: rgb(50, 49, 48);
                display: flex;
                &::placeholder {
                    color: $gray-300;
                }
            }
        }
        .format-field-cont:focus-visible {
            outline: none;
        }
        .static-title {
            width: 100%;
            height: 52px;
            padding-left: 25px;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            opacity: 0;
            z-index: 1;
            margin: 0 auto;
            box-sizing: border-box;
            animation: fadeIn 1s ease 0.5 forwards;
            .title-icon {
                background: url(app/static/icons/title-icon.svg) no-repeat;
                display: inline-block;
                width: 1.75rem;
                height: 28px;
            }
            .title {
                font-weight: 600;
                font-size: $fs-16;
                line-height: 24px;
                color: $black;
                margin-right: 5px;
            }
            .title-value {
                font-weight: normal;
                font-size: $fs-16;
                line-height: 20px;
                color: $black;
            }
        }
        .ms-TextField-fieldGroup {
            background: $white;
            box-sizing: border-box;
            border-radius: $radius;
            border: 1px solid $gray-250;
            &::after {
                border-radius: $radius;
                animation: mymove 2s infinite;
            }
            textarea,
            input {
                &::placeholder {
                    color: $gray-300;
                }
            }
        }
        .regular-text-field {
            .ms-TextField-fieldGroup {
                padding: 0 15px;
                height: 45px;
                input {
                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
            height: 45px;
        }
        .action-issue-category {
            width: 45%;
            margin-top: 2.1875rem;
            .error-field {
                .ms-Dropdown-title {
                    border: 1px solid $red-200;
                }
            }
            .issue-category-dropdown {
                i,
                .ms-Dropdown-titleIsPlaceHolder {
                    color: $gray-300 !important;
                }
                .ms-Dropdown-title,
                .ms-Dropdown-caretDownWrapper {
                    height: 45px;
                    line-height: 45px;
                }
                .is-open {
                    .ms-Dropdown-title {
                        border: 2px solid $primary-color;
                        animation: mymove 2s infinite;
                    }
                }
                .ms-Dropdown {
                    &::after {
                        border: 2px solid $primary-color;
                        animation: mymove 2s infinite;
                    }
                }
            }
        }
        .action-date-picker {
            width: 40%;
            i {
                margin-top: 5px;
            }
        }
        .preview-case-num {
            font-weight: 600;
        }
        .title-field {
            .ms-TextField-fieldGroup {
                height: 50px;
                padding: 0 15px;
            }
        }
        .desc {
            .ms-TextField-fieldGroup {
                padding: 14px;
            }
        }
        .error-feild {
            .ms-TextField-fieldGroup {
                border: 1px solid $red-200;
            }
        }
        .post-sales-case-num {
            width: 100%;
            .case-num-label {
                display: flex;
                .ms-Image {
                    margin-top: 8px;
                    margin-left: 10px;
                }
            }
        }
        .static-title {
            width: 100%;
            height: 52px;
            padding-left: 25px;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            opacity: 0;
            z-index: 1;
            margin: 0 auto;
            box-sizing: border-box;
            animation: fadeIn 1s ease 0.5 forwards;
            .title-icon {
                background: url(app/static/icons/title-icon.svg) no-repeat;
                display: inline-block;
                width: 1.75rem;
                height: 1.25rem;
            }
            .title {
                font-weight: 600;
                font-size: $fs-16;
                line-height: 24px;
                color: $black;
                margin-right: 5px;
            }
            .title-value {
                font-weight: normal;
                font-size: $fs-16;
                line-height: 20px;
                color: $black;
                display: inline-block;
                width: 88%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .first-titlefield-cont {
            &.add-padding {
                padding: 45px 78px 79px;
            }
            background: rgba(255, 255, 255, 0.7);
            @include border-radius($radius);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
            width: 100%;
            height: 200px;
            margin: 150px auto 0;
            @include gt-md {
                min-width: 898px;
                width: 75%;
            }
            &.slideUp {
                position: relative;
                z-index: 2;
                animation: titleUp 0.8s ease forwards;
            }
            .first-titlefield {
                display: flex;
                flex-direction: column;
                padding: 30px 5%;
                label {
                    font-weight: 300;
                    font-size: $fs-26;
                    line-height: 55px;
                    color: $gray-100;
                }
                .row-flex {
                    display: flex;
                    flex-direction: row;
                    .ms-TextField {
                        flex-grow: 1;
                    }
                    .next-btn {
                        margin-left: 40px;
                        width: 43px;
                        height: 43px;
                        @include border-radius(40px);
                        position: relative;
                        cursor: pointer;
                        overflow: hidden;
                        @extend .btn-animation;
                        align-self: flex-end;
                        margin-bottom: 5px;
                        .right-arrow {
                            background: url(app/static/icons/right-arrow-request-help.svg) no-repeat;
                            display: inline-block;
                            width: 10px;
                            height: 19px;
                            position: absolute;
                            top: 13px;
                            left: 18px;
                        }
                    }
                }
            }
        }
        .form-preview {
            background: $white !important;
        }
        .new-req-body-container {
            min-height: 591px;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
            @include border-radius($radius);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
            backdrop-filter: blur(20px);
            animation: newReqBodyAnimation 1.5s ease 0.2s forwards;
            position: relative;
            margin: auto;
            @include gt-md {
                width: 75%;
                min-width: 898px;
            }
            .added-device-icon &.preview-bg {
                background: $white;
                .new-req-form-container {
                    box-sizing: border-box;
                    border-radius: 0px 0px 8px 8px;
                    .new-req-form-body {
                        box-sizing: border-box;
                        width: 100%;
                    }
                }
                .preview {
                    width: 100%;
                    .col-flex {
                        width: 50%;
                    }
                }
            }
            .active-bar {
                position: absolute;
                left: 0px;
                margin: 0 0.625rem;
                width: 25%;
                height: 3.125rem;
                background-color: $primary-color;
                @include border-radius(10px);
                animation: shadow-ani 3s infinite;
                transition: all 0.8s;
                &.first-tab {
                    width: 23%;
                }
                &.second-tab {
                    width: 49%;
                }
                &.third-tab {
                    width: 73%;
                }
                &.fourth-tab {
                    width: 98%;
                }
            }
            .new-req-body-menu {
                padding: 0;
                list-style: none;
                width: 98%;
                @include border-radius($radius);
                background: rgba(255, 255, 255, 0.4);
                margin: 0 0.625rem;
                height: 3.125rem;
                display: flex;
                overflow: hidden;
                li {
                    display: flex;
                    width: 25%;
                    text-align: left;
                    font-weight: normal;
                    font-size: $fs-16;
                    line-height: 28px;
                    color: $gray-300;
                    position: relative;
                    box-sizing: border-box;
                    transition: color 1s;
                    .main-tab-item {
                        justify-content: center;
                    }
                    div {
                        background-size: 200% 100%;
                        width: 96%;
                    }
                    span {
                        position: relative;
                        z-index: 2;
                        &.icon {
                            background: url(app/static/icons/select-device-icon.svg) no-repeat;
                            display: inline-block;
                            width: 22px;
                            height: 20px;
                            margin-right: 0.3125rem;
                            &.request-help-icon {
                                background: url(app/static/icons/request-help-icon.svg) no-repeat;
                                top: 0.25rem;
                            }
                            &.customer-opportunity-icon {
                                background: url(app/static/icons/customer-opportunity-icon.svg) no-repeat;
                            }
                            &.attach-icon {
                                background: url(app/static/icons/attach-icon.svg) no-repeat;
                            }
                            &.preview-icon {
                                background: url(app/static/icons/preview-icon.svg) no-repeat;
                            }
                        }
                    }
                    @keyframes shadow-animation {
                        20% {
                            box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
                        }
                        50% {
                            box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.4);
                        }
                        80% {
                            box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
                        }
                        100% {
                            box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.4);
                        }
                    }
                    &.active {
                        color: #fff;
                        font-weight: 600;
                        div {
                            @include border-radius(0 20px 20px 0);
                            width: 100%;
                        }
                        span {
                            &.icon {
                                &.request-help-icon {
                                    background: url(app/static/icons/request-help-active-icon.svg) no-repeat;
                                }
                                &.customer-opportunity-icon {
                                    background: url(app/static/icons/customer-opportunity-active-icon.svg) no-repeat !important;
                                }
                                &.attach-icon {
                                    background: url(app/static/icons/attach-active-icon.svg) no-repeat;
                                }
                                &.preview-icon {
                                    background: url(app/static/icons/preview-active-icon.svg) no-repeat;
                                }
                            }
                        }
                    }
                    &:first-child {
                        @include border-radius(20px 20px 20px 0px);
                        div {
                            @include border-radius(20px 20px 20px 0px);
                        }
                    }
                    &:last-child {
                        @include border-radius(0px 20px 0 0);
                        div {
                            @include border-radius(0px 20px 0 0);
                            width: 98.4%;
                            box-shadow: none;
                        }
                    }
                    &.complete-section {
                        color: #fff;
                        border-right: 1px solid $white;
                        div {
                            cursor: pointer;
                            width: 100%;
                        }
                        span {
                            &.icon {
                                background: url(app/static/icons/tick-icon.svg) no-repeat;
                                background-position: 5px 6px;
                                width: 23px;
                                height: 23px;
                                background-color: #51caad;
                                @include border-radius(50%);
                                top: 2px;
                            }
                        }
                        &:first-child {
                            @include border-radius(20px 0 0);
                        }
                    }
                }
            }
            .form-preview {
                background: $white;
            }
            .new-req-form-container {
                border-radius: 0px 0px 8px 8px;
                display: flex;
                min-height: 350px;
                padding-bottom: 2.5rem;
                padding-top: 40px;
                overflow: hidden;
                box-sizing: border-box;
                .submitted {
                    position: relative;
                    top: 21px;
                    left: 44px;
                    color: $gray-200;
                }
                .new-req-form-left {
                    width: 45px;
                    position: relative;
                    margin-right: 50px;
                    &.increase-margin {
                        margin-right: 5.625rem;
                    }
                }
                .new-req-form-right {
                    margin-left: 50px;
                    width: 45px;
                    position: relative;
                }
                .right-container {
                    margin-left: -20px !important;
                }
                .direction-arrow-container {
                    background: $gray-600;
                    border: 0.058318375rem solid $gray-500;
                    @include border-radius(40px);
                    width: 43px;
                    height: 43px;
                    cursor: pointer;
                    margin: 0 auto;
                    position: absolute;
                    top: 210px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    overflow: hidden;
                    margin-left: 20px;
                    @extend .btn-animation;

                    span {
                        background: url(app/static/icons/inactive-arrow.svg) no-repeat;
                        display: inline-block;
                        width: 10px;
                        height: 19px;
                        position: relative;
                        top: 11px;
                        left: 18px;
                        &.left-arrow {
                            transform: rotate(180deg);
                            left: 15px;
                        }
                    }
                    &.btn-active-shadow {
                        box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.7);
                    }
                    &.active {
                        background: $primary-color;
                        border: hidden;
                        span {
                            background: url(app/static/icons/right-arrow-request-help.svg) no-repeat !important;
                        }
                    }
                }
                .new-req-form-body {
                    width: 100%;
                    padding: 0 5.625rem;
                    box-sizing: border-box;
                    &.change-width {
                        width: calc(100% - 7.5rem);
                        padding: 0 1.25rem;
                    }
                    &.change-padding {
                        padding-left: 5.625rem;
                        padding-right: 0;
                    }
                    &.no-padding {
                        padding: 0;
                    }
                    .preview {
                        color: $gray-100 !important;
                        width: 100%;
                        .col-flex {
                            width: 50%;
                        }
                    }
                    .back-link {
                        margin-top: 3.5rem !important;
                        float: right;
                        &.no-float {
                            float: none;
                        }
                    }
                    .ms-Link {
                        font-size: $fs-16;
                        line-height: 21px;
                        text-decoration: underline;
                    }
                    .upload-attachment-spinner {
                        display: flex;
                        align-items: center;
                        .upload-attachment-spinner-text {
                            margin: 1rem;
                        }
                    }
                    .action-next-btn {
                        float: right;
                        @include border-radius(10px);
                        padding: 1.25rem 2.188rem;
                        margin-top: 9px;
                        margin-left: 2rem;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid transparent;
                        background-color: $gray-400;
                        color: $white;
                        @extend .btn-animation;
                        &.active {
                            background-color: $primary-color;
                        }
                        &.shadow-active {
                            box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.7);
                        }
                        &.submit-btn {
                            padding: 19px 28px;
                            margin-top: 2.8rem;
                            margin-left: 2rem;
                            position: relative;
                            border: 1px solid transparent;
                            box-sizing: border-box;
                        }
                    }
                    .action-next-btn-disabled {
                        float: right;
                        @include border-radius(10px);
                        padding: 1.25rem 2.188rem;
                        margin-top: 9px;
                        margin-left: 2rem;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid transparent;
                        background-color: $gray-400;
                        color: $white;
                        &.active {
                            background-color: $primary-color;
                        }
                        &.submit-btn {
                            padding: 19px 28px;
                            margin-top: 2.8rem;
                            margin-left: 2rem;
                            position: relative;
                            border: 1px solid transparent;
                            box-sizing: border-box;
                        }
                    }
                    .search-customer-cont {
                        display: flex;
                        flex-direction: column;
                        .new-customer-link {
                            margin-top: 1.5625rem;
                            align-self: flex-end;
                        }
                    }
                    .fields-grp {
                        display: flex;
                        flex-direction: row;
                        .shift-rt {
                            margin-left: 50px;
                            .row {
                                display: flex !important;
                            }
                            .left-space {
                                margin-left: 20px;
                            }
                            .block-sales {
                                background: #fff;
                                @include border-radius(10px);
                                padding: 6px 12px;
                                height: 47px;
                                box-sizing: border-box;
                                width: 290px;
                                .ms-ChoiceField {
                                    margin-top: 6px;
                                    .ms-ChoiceField-wrapper {
                                        border: transparent !important;
                                        padding: 0;
                                    }
                                }
                            }
                        }
                        .fields {
                            .ms-Dropdown-container {
                                background: $white;
                                border: 1px solid transparent;
                                @include border-radius(10px);
                                padding: 6px 14px;
                                box-sizing: border-box;
                                margin-bottom: 47px;
                                max-width: 290px;
                                width: 290px;
                                &:hover {
                                    border: 1px solid $primary-color;
                                    box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.6);
                                }
                                .ms-Dropdown-title {
                                    border: 0;
                                    @include border-radius(10px);
                                }
                                &::after {
                                    border: 0;
                                }
                            }
                            .ms-ChoiceFieldGroup-flexContainer {
                                display: flex;
                                flex-direction: row;
                                .ms-ChoiceField-wrapper {
                                    width: 70px;
                                    height: 35px;
                                    padding-top: 5px;
                                    border: 2px solid #201f1e;
                                    box-sizing: border-box;
                                    @include border-radius(6px);
                                    margin-right: 20px;
                                    .ms-ChoiceField-field {
                                        position: relative;
                                        .ms-ChoiceFieldLabel {
                                            font-size: $fs-14;
                                            line-height: 20px;
                                            color: #201f1e;
                                            position: absolute;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .head {
                        justify-content: space-between;
                        .title {
                            font-size: $fs-18;
                            line-height: 28px;
                            color: $gray-100;
                            margin-left: 10px;
                            margin-bottom: 13px;
                        }
                        .ms-Link {
                            font-size: $fs-18;
                            line-height: 28px;
                            text-decoration-line: underline;
                            color: $primary-color;
                            position: relative;
                            top: -4px;
                        }
                    }
                    .device-title {
                        font-size: $fs-20 !important;
                        line-height: 26px !important;
                        font-weight: normal !important;
                        color: $gray-100 !important;
                        margin-bottom: 20px;
                    }
                    .shift-icons {
                        justify-content: left;
                    }
                    .att-title {
                        font-weight: normal;
                        font-size: $fs-20;
                        line-height: 27px;
                        margin-left: 5px;
                        color: $gray-100;
                    }
                    h3 {
                        font-size: $fs-20;
                        font-weight: 400;
                    }
                    .file-drop {
                        .dropzone {
                            width: 100%;
                            background-color: rgba(255, 255, 255, 0.8);
                            border: 2px dashed $gray-350;
                            box-sizing: border-box;
                            @include border-radius(10px);
                            margin-top: 30px;
                            margin-bottom: 30px;
                            padding-top: 30px;
                            padding-bottom: 30px;
                            .css-40 {
                                font-size: $fs-16;
                                line-height: 21px;
                                color: $gray-100;
                            }
                            .text-cont {
                                .txt-1 {
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                }
                                .txt-2 {
                                    line-height: 40px;
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                }
                                .file-attach-icon {
                                    @include push--auto;
                                    display: flex;
                                    margin-top: 35px;
                                    margin-bottom: 20px;
                                }
                                .addfile__button {
                                    @include push--auto;
                                    @extend .button__primaty;
                                    margin-bottom: 20px;
                                    width: 80px;
                                    cursor: pointer;
                                    color: $white;
                                    font-size: $fs-14;
                                    line-height: 40px;
                                    display: flex;
                                    justify-content: center;
                                }
                                .dropzone-helper-text {
                                    line-height: 25px !important;
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .btn-attachments {
                        position: relative;
                        height: 40px;
                        margin-bottom: 20px;
                        button {
                            margin: 0;
                        }
                    }
                    .files-section {
                        .file-head-type {
                            font-weight: 600;
                            font-size: $fs-18;
                        }
                    }
                    .row-flex {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding-bottom: 20px;
                        @include lt-md {
                            flex-wrap: wrap;
                        }
                        .col-flex {
                            display: flex;
                            flex-direction: column;
                            .mar-top {
                                margin-top: 20px;
                            }
                            .space {
                                margin-right: 20px;
                            }
                            .space-sale {
                                margin-right: 66px;
                            }
                            .space-case {
                                margin-right: 32px;
                            }
                            .action-box {
                                width: 344px;
                            }
                            .space-action {
                                margin-right: 51px;
                            }
                            .r-flex {
                                display: flex;
                                &.r-column {
                                    flex-direction: column;
                                }
                                &.r-row {
                                    flex-direction: row;
                                }
                                &.impt-dvc {
                                    justify-content: space-between;
                                    width: 75%;
                                    color: $gray-200;
                                    margin-top: 5px;
                                }
                                .mail-icon {
                                    background: url(app/static/icons/mail-icon.svg) no-repeat;
                                    display: inline-block;
                                    width: 14px;
                                    height: 13px;
                                    margin-top: 13px;
                                    margin-right: 5px;
                                }
                                .address-icon {
                                    background: url(app/static/icons/address-icon.svg) no-repeat;
                                    display: inline-block;
                                    width: 14px;
                                    height: 15px;
                                    margin-top: 10px;
                                    margin-right: 5px;
                                }
                                .phone-icon {
                                    background: url(app/static/icons/phone-icon.svg) no-repeat;
                                    display: inline-block;
                                    width: 13px;
                                    height: 13px;
                                    margin-top: 11px;
                                    margin-right: 5px;
                                }
                                .c-flex {
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                        }
                        .boud-left-container {
                            border-left: 1px solid rgba(0, 120, 212, 0.32);
                        }
                        .cont {
                            width: 100%;
                            padding-left: 44px;
                            padding-right: 30px;
                            display: flex;
                            flex-direction: column;
                            font-size: $fs-15;
                            line-height: 30px;
                            margin-bottom: 40px;
                            box-sizing: border-box;
                            .heading {
                                font-weight: 600;
                                font-size: $fs-16 !important;
                                line-height: 22px !important;
                                color: $primary-color;
                                margin-bottom: 0.625rem;
                                display: block;
                            }
                            .title {
                                font-size: $fs-16;
                                margin-bottom: 8px;
                                line-height: 20px;
                            }
                            .txt-bold {
                                font-weight: 600;
                                color: $gray-100 !important;
                                line-height: 20px;
                            }
                            .name {
                                font-size: $fs-16 !important;
                                line-height: 28px !important;
                            }
                            .type-date {
                                span {
                                    margin-bottom: 0.3rem;
                                }
                                .c-flex {
                                    display: flex;
                                    .txt-bold {
                                        margin-left: 5px;
                                    }
                                    .mar-top {
                                        line-height: 20px;
                                    }
                                }
                            }
                            .date {
                                font-weight: 600;
                                color: $gray-200 !important;
                                line-height: 20px;
                            }
                            .text-container {
                                max-width: 250px;
                            }
                            .txt-black {
                                color: $gray-100 !important;
                                display: inline-block;
                                &.files-txt {
                                    display: inline-block;
                                }
                                &.bld {
                                    font-weight: 600;
                                }
                                &.txt-value {
                                    font-size: $fs-16;
                                }
                            }
                            .box-container {
                                margin: 40px 0 20px;
                            }
                            .d-inline {
                                display: block;
                                .txt-black {
                                    display: inline-block;
                                }
                            }
                        }
                        .fields {
                            .ms-ChoiceFieldGroup-flexContainer {
                                display: flex;
                                flex-direction: row;
                                border: 1px solid #bdbdbd;
                                box-sizing: border-box;
                                @include border-radius(10px);
                                background: $white;
                                width: 250px;
                                height: 35px;
                                .ms-ChoiceField-wrapper {
                                    width: 70px;
                                    height: 35px;
                                    padding-top: 5px;
                                    margin-right: 15px;
                                    .ms-ChoiceField-field {
                                        position: relative;
                                        .ms-ChoiceFieldLabel {
                                            font-size: $fs-14 !important;
                                            line-height: 20px !important;
                                            color: #201f1e !important;
                                            position: absolute;
                                            font-weight: normal !important;
                                        }
                                    }
                                }
                            }
                        }
                        .shift-rt {
                            margin-left: 20px;
                            width: 45%;
                            .country-selection {
                                width: 100%;
                            }
                            .row {
                                display: flex !important;
                            }
                            .left-space {
                                margin-left: 20px;
                            }
                        }
                        .shift-lt {
                            width: 45%;
                        }
                    }
                    .products-list,
                    .select-model {
                        .mar-rt {
                            .ms-Image {
                                animation: productHoverOut 0.5s ease forwards;
                            }
                            &:hover {
                                .ms-Image {
                                    animation: productHover 0.5s ease forwards;
                                }
                            }
                            &.selected-product {
                                .ms-Image {
                                    animation: none;
                                }
                            }
                        }
                        .selected-product {
                            .ms-Link,
                            .added-text {
                                font-size: $fs-14;
                                line-height: 22px;
                                text-decoration: underline;
                                font-weight: 600;
                                margin: 10px auto 0;
                            }
                            .added-text {
                                text-decoration: none;
                            }
                        }
                    }
                    .cssContainerStyles{
                        height: 75px;
                    }

                    .helper-txt-case-num {
                        color: #e60000;
                        font-size: $fs-15;
                        line-height: 25px;
                        display: block;
                        padding-left: 15px;
                        animation: slideDown2 0.5s ease forwards;
                        z-index: -1;
                        position: relative;
                        margin-bottom: 25px;

                        
                    }
                    .auto-search-cont {
                        .react-autosuggest__input--focused {
                            box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.6);
                            border: 1px solid $primary-color !important;
                        }
                        &.error-feild-auto {
                            .react-autosuggest__input {
                                border: 1px solid $red-200;
                            }
                        }
                        .react-autosuggest__input--open {
                            border-radius: 10px 10px 0px 0px !important;
                        }
                        .react-autosuggest__suggestions-container--open {
                            border-radius: 0px 0px 10px 10px !important;
                            border: 1px solid $primary-color;
                            box-sizing: border-box;
                            box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.6);
                            z-index: 2;
                        }
                        .react-autosuggest__container {
                            @include border-radius(20px);
                            outline: none;
                            position: relative;
                            &:focus-within {
                                @include border-radius(10px);
                                animation: mymove 2s infinite;
                                input {
                                    @include border-radius(10px);
                                    outline: none;
                                    border: none;
                                }
                            }
                        }
                        .react-autosuggest__input {
                            width: 100%;
                            height: 47px;
                            background: $white;
                            padding: 16px 15px;
                            border: 1px solid rgb(96, 94, 92);
                            box-sizing: border-box;
                            position: relative;
                            z-index: 2;
                            @include border-radius(10px);
                            &::after {
                                border-radius: 10px;
                            }
                            &:focus-within {
                                textarea {
                                    @include border-radius(10px);
                                }
                            }
                        }
                        .react-autosuggest__suggestions-container {
                            background: #fff;
                            z-index: 1;
                            position: absolute;
                            width: 100%;
                            ul {
                                list-style: none;
                                padding: 0;
                                li {
                                    padding: 15px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .list-menu {
                        list-style: none;
                        padding: 0;
                        margin: 0 auto;
                        max-width: 80%;
                        display: flex;
                        justify-content: center;
                        @include lt-md {
                            width: 100%;
                        }
                        li {
                            display: inline-block;
                            width: 33%;
                            text-align: center;
                            position: relative;
                            .address {
                                &::before {
                                    top: 6px !important;
                                }
                            }
                            .circle-icon {
                                border: 2px solid #b1b1b1;
                                box-sizing: border-box;
                                width: 30px;
                                height: 30px;
                                display: block;
                                @include border-radius(50%);
                                margin: 0 auto;
                                margin-bottom: 9px;
                                background: #eceef0;
                                &::before {
                                    background: url(app/static/icons/pencil-icon.svg) no-repeat;
                                    content: ' ';
                                    display: inline-block;
                                    width: 10px;
                                    height: 19px;
                                    position: relative;
                                    top: 8px;
                                    left: 0;
                                }
                                &.customer-icon {
                                    &::before {
                                        background: url(app/static/icons/customer-icon.svg) no-repeat;
                                    }
                                }
                                &.device-active-icon {
                                    &::before {
                                        width: 1.125rem;
                                        background: url(app/static/icons/device-icon.svg) no-repeat;
                                    }
                                }
                                &.customer-active-icon {
                                    &::before {
                                        background: url(app/static/icons/customer-active-icon.svg) no-repeat;
                                    }
                                }
                                &.address-icon {
                                    &::before {
                                        background: url(app/static/icons/home-grey-icon.svg) no-repeat;
                                        width: 13px !important;
                                    }
                                }
                                &.address-active-icon {
                                    &::before {
                                        background: url(app/static/icons/home-blue-icon.svg) no-repeat;
                                        width: 13px !important;
                                    }
                                }
                                &.action-icon {
                                    &::before {
                                        background: url(app/static/icons/action-icon.svg) no-repeat;
                                        width: 12px;
                                    }
                                }
                                &.action-active-icon {
                                    &::before {
                                        background: url(app/static/icons/action-active-icon.svg) no-repeat;
                                        width: 12px;
                                        top: 8px;
                                    }
                                }
                            }
                            .circle-txt {
                                font-weight: 300;
                                font-size: $fs-16;
                                line-height: 24px;
                                color: rgba($gray-100, 0.8);
                            }
                            &.active {
                                .circle-icon {
                                    border: 2px solid $primary-color;
                                    background: $primary-color;
                                }
                                .circle-txt {
                                    color: $primary-color;
                                    font-weight: 600;
                                }
                                &:not(:last-child)::after {
                                    border-bottom: 2px solid #b1b1b1;
                                }
                            }
                            &.completed {
                                cursor: pointer;
                                .circle-icon {
                                    border: 1px solid #51caad;
                                    background: #51caad;
                                    &::before {
                                        background: url(app/static/icons/tick-icon.svg) no-repeat;
                                        width: 12px;
                                    }
                                }
                                .circle-txt {
                                    color: rgba($gray-100, 0.8);
                                    font-weight: 300;
                                }
                                &:not(:last-child)::after {
                                    border-bottom: 2px solid #51caad;
                                }
                            }
                            &:not(:last-child)::after {
                                position: absolute;
                                content: '';
                                left: 54%;
                                top: -26%;
                                width: 100%;
                                height: 50%;
                                border-bottom: 2px solid #b1b1b1;
                                z-index: -1;
                            }
                        }
                        &.post-list {
                            li {
                                width: 25%;
                            }
                        }
                    }
                    label {
                        font-weight: normal;
                        font-size: $fs-20;
                        line-height: 27px;
                        margin-left: 10px;
                        color: $black;
                        display: inline-block;
                        margin-bottom: 16px;
                    }
                    .m-20 {
                        margin-top: 20px;
                    }
                    .buisness-just {
                        .ms-TextField-fieldGroup {
                            width: 100%;
                            textarea {
                                padding: 15px;
                            }
                        }
                    }
                    .title {
                        margin-bottom: 20px;
                        &.model-title {
                            color: $primary-color;
                        }
                    }
                    .detailsContainer {
                        .routing-failed-link {
                            z-index: 5 !important;
                            .ms-Link {
                                float: none;
                            }
                        }
                    }
                    .attachment-list-container {
                        min-height: 300px;
                        .attachment-list {
                            background: $white;
                            margin-bottom: 10px;
                            border: 1px solid #e0e0e0;
                            box-sizing: border-box;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
                            @include border-radius(10px);
                            padding: 16px 18px;
                            position: relative;
                            height: 60px;
                            .attach-icon {
                                background: url(app/static/icons/image-icon.svg) no-repeat;
                                display: inline-block;
                                width: 27px;
                                height: 20px;
                                margin-right: 15px;
                            }
                            .ms-Button {
                                position: absolute;
                                right: 10px;
                            }
                            .file-txt {
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }
                .added-devices {
                    .added-device-icon {
                        img {
                            max-width: 40px;
                        }
                    }
                    @include border-radius($radius);
                    background: $white;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
                    position: absolute;
                    right: -225px;
                    width: 210px;
                    top: 0px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 15px;
                    box-sizing: border-box;
                    overflow-y: auto;
                    overflow-x: hidden;
                    animation: slideRightToLeft 0.5s ease forwards;
                    &.animateToRight {
                        animation: animateToRight 0.5s ease forwards;
                    }
                    h2 {
                        font-size: $fs-18;
                        font-weight: 400;
                        border-bottom: 1px solid #dcdbdb;
                        line-height: 35px;
                        color: #323130;
                        margin: 0;
                        height: 40px;
                    }
                    &-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: calc(100% - 80px);
                        .item {
                            display: block;
                            border-bottom: 1px solid #dcdbdb;
                            padding: 15px 0;
                            h4 {
                                margin: 15px 0;
                                font-size: $fs-14;
                                font-weight: 600;
                            }
                            .added-device-details {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                .added-device-icon {
                                    min-width: 40px;
                                    img {
                                        max-width: 40px;
                                    }
                                }
                                .text-field {
                                    flex-grow: 1;
                                    .is-disabled {
                                        input {
                                            border-radius: $radius;
                                        }
                                    }
                                }
                                .trash-icon {
                                    min-width: 30px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    &.cart-preview {
                        .more-link {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            color: $blue-400;
                            margin-top: 8px;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                        .added-devices-container {
                            .item:first-child {
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 2000px) {
    .req-exp-cont {
        min-height: calc(100vh - 530px);
    }
}

.alternative-contact-modal {
    min-width: 50rem;
    .format-field-cont {
        &.error-feild {
            border: 1px solid $red-200;
        }
        @include border-radius($radius);
        height: 45px !important;
        border: 1px solid $gray-250;
        overflow: hidden;
        padding: 0 15px;
        background: $white;
        &:focus-within {
            border: 2px solid $primary-color;
            animation: mymove 2s infinite !important;
        }
        .format-field {
            width: 100%;
            border: none;
            color: rgb(50, 49, 48);
            display: flex;
            &::placeholder {
                color: $gray-300;
            }
        }
    }
    .w-140 {
        width: 140px;
        padding-left: 5px;
    }
    .ms-TextField-fieldGroup {
        background: $white;
        box-sizing: border-box;
        border-radius: $radius;
        border: 1px solid $gray-250;
        &::after {
            border-radius: $radius;
            animation: mymove 2s infinite;
        }
        textarea,
        input {
            &::placeholder {
                color: $gray-300;
            }
        }
    }
    .regular-text-field {
        .ms-TextField-fieldGroup {
            height: 45px;
            input {
                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
        }
        
    }
    .action-date-picker {
        width: 40%;
        i {
            margin-top: 5px;
        }
    }
    .title-field {
        .ms-TextField-fieldGroup {
            height: 4rem;
            padding: 0 15px;
        }
    }
    .desc {
        .ms-TextField-fieldGroup {
            padding: 14px;
        }
    }
    .error-feild {
        .ms-TextField-fieldGroup {
            border: 1px solid $red-200;
        }
        .country-selection {
            border: 1px solid $red-200;
        }
    }

    .alt-contact-update-btn {
        &.is-disabled {
            background-color: $gray-400;
            color: $white;
        }
    }
}

.list-right-animation {
    display: block;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;
}

.list-right-animation:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary-color;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
}

.list-right-animation:hover:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* icon move up and down */

@keyframes titleFieldShrink {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes hvr-icon-bob-float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-6px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes hvr-icon-bob {
    0% {
        transform: translateY(-6px);
    }
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(0px);
    }
}

.icon-move-up-down {
    transform: translateZ(0);
}

.icon-move-up-down span.icon {
    animation-name: hvr-icon-bob-float;
    animation-duration: 1.5s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
}

/* rotate spin */

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.hvr-icon-spin {
    animation: rotation 8s infinite linear;
}

/* button size increase and decrease */

@keyframes sur-size {
    25% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(0.8);
    }
}

.size-change {
    transform: perspective(1px) translateZ(0);
    animation-name: sur-size;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    display: inline-block;
}

/* text typing  */

.text-typing {
    width: 143px;
    animation: type 4s steps(40, end) infinite;
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
}

@keyframes type {
    from {
        width: 0;
    }
}

@keyframes blink {
    to {
        opacity: 0;
    }
}

.modal {
    display: flex;
    flex-direction: column;
    animation: showDialog2 1s ease forwards;
    .alternate-details-close-btn {
        color: $primary-color !important;
        margin-top: 0.1875rem;
        justify-content: flex-end;
        .ms-Link {
            &:active {
                text-decoration: none !important;
            }
        }
    }
    .ms-Dialog-main {
        min-width: 50%;
        padding: 20px 40px 30px 40px;
        @include border-radius($radius);
        transform: translateY(-50%);
        position: absolute;
        opacity: 0;
        top: 50%;
        animation: fadeIn 1s ease 0.2s forwards;
    }
    .close-btn {
        font-weight: 600;
        font-size: $fs-14;
        color: #868584;
    }
    .close-link {
        justify-content: flex-end;
        color: $primary-color;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        span {
            font-weight: 600;
            font-size: $fs-14;
        }
    }
    .ms-Link {
        float: right;
        display: flex;
        flex-direction: row;
        .close-icon {
            background: url(app/static/icons/close-grey-icon.svg) no-repeat;
            width: 23px;
            height: 23px;
            display: block;
        }
    }
    .modal-cont {
        align-items: center;
        padding: 2rem 3.75rem;
        text-align: center;
        .new-skp-id {
            font-weight: 600;
            font-size: $fs-16;
        }
        .customer-info {
            font-size: $fs-16;
            font-weight: 600;
            color: $primary-color;
        }
        .error-icon {
            .ms-Image {
                display: flex;
                justify-content: center;
            }
        }
        .unif-dashboard-link {
            font-weight: 600;
            font-size: $fs-16;
            color: $primary-color;
            text-decoration: underline;
        }
        .heading {
            font-size: $fs-16;
            line-height: 60px;
            color: $gray-100;
            display: block;
            text-align: center;
        }
        .text {
            font-size: $fs-16;
            line-height: 20px;
            color: $gray-100;
            display: block;
            text-align: center;
            .ms-Link {
                margin-left: 7px;
                font-weight: 600;
                text-decoration: underline;
                float: none;
                display: inline-block;
            }
        }
        .success-icon {
            background: url(app/static/icons/success-blue-icon.svg) no-repeat;
            width: 60px;
            height: 60px;
            display: inline-block;
        }
    }
    .new-req-link {
        font-size: $fs-14;
        line-height: 20px;
        text-decoration-line: underline;
        color: $primary-color;
    }
}

.helper-txt {
    color: #e60000;
    font-size: $fs-15;
    line-height: 25px;
    display: block;
    padding-left: 15px;
    animation: slideDown2 0.5s ease forwards;
    z-index: -1;
    position: relative;
    margin-top: 5px;
}

.nextSlide {
    opacity: 0;
    animation: nextSlide 0.5s ease forwards;
}

.prevSlide {
    opacity: 0;
    animation: prevSlide 0.5s ease forwards;
}

.animateToLeft {
    opacity: 1;
    animation: animateToLeft 0.5s ease forwards;
}

.animateToRight {
    opacity: 1;
    animation: animateToRight 0.5s ease forwards;
}

.btn-animation {
    &:before {
        background: radial-gradient(circle, rgba(63, 203, 251, 1) 0%, rgba(0, 120, 212, 1) 100%);
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        left: -100%;
        top: 0px;
        height: 43px;
        opacity: 0;
        z-index: 0;
        animation: buttonAnimationReverse 0.5s ease forwards;
    }
    &:hover {
        box-shadow: 0 2px 5px 0 rgba(65, 132, 234, 0.75);
        &:before {
            animation: buttonAnimation 0.5s ease forwards;
        }
    }
}

.button-active-hover {
    box-shadow: -4px 1px 19px rgba(0, 120, 212, 0.7) !important;
    &:before {
        animation: buttonAnimation 0.5s ease forwards;
    }
}

.button {
    &__primaty {
        background-color: $primary-color;
        line-height: 40px;
        font-size: $fs-14;
        padding-left: 20px;
        padding-right: 20px;
        color: $white;
        @include border-radius(10px);
        &:hover,
        &:focus,
        &:visited,
        &:active,
        &:hover:active {
            color: $white;
        }
    }
}

.add-my-customer {
    @extend .button__primaty;
    @include push--auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.add-customer-model {
    .ms-Dialog-main {
        .title {
            font-weight: 600;
            font-size: $fs-16;
            line-height: 28px;
            display: inline-block;
            margin-bottom: 25px;
            color: $primary-color;
        }
        .form-control {
            .form-inline {
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 48%;
                }
            }
            .form-row {
                margin-bottom: 20px;
                label {
                    font-size: 16px;
                    line-height: 28px;
                    margin-bottom: 6px;
                    display: inline-block;
                    color: rgb(0, 0, 0);
                    padding: 0px;
                }
                .ms-Button--primary {
                    float: right;
                    padding: 12px 49px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    @include border-radius(6px);
                    height: auto;
                    box-sizing: border-box;
                    height: 42px;
                    width: 165px;
                }
            }
        }
    }
}

.btn-attachments {
    .ms-Link {
        float: none !important;
    }
}

.file-txt {
    .ellipse-icon {
        background: url(app/static/icons/ellipse-grey.svg) no-repeat;
        width: 8px;
        height: 10px;
        display: inline-block;
        margin-left: 13px;
        margin-right: 5px;
    }
}

.icon-thumbsUp {
    display: flex;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    border: 2px solid $primary-color;
    @include border-radius(50%);
    opacity: 0;
    animation: fadeIn 0.5s ease 0.5s forwards;
    svg {
        width: 50%;
        height: 50%;
        animation: thumbsUp 2s ease 1s forwards;
        position: relative;
        margin: auto;
        path.thumb {
            position: relative;
            animation: thumb 1s ease 0.5s forwards;
            transform-origin: 138px 72px;
        }
    }
}

.icon-warning {
    display: flex;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border: 1px solid $primary-color;
    @include border-radius(50%);
    opacity: 0;
    animation: fadeIn 0.5s ease 0.5s forwards;
    span.icon {
        background: url(app/static/icons/warning-red.svg) no-repeat;
        width: 75%;
        height: 75%;
        background-size: cover;
        margin: auto;
        opacity: 0;
        animation: thumbsUp 0.5s ease 0.8s forwards;
    }
}

.go-to-home-link {
    float: right;
}

.attached-file {
    display: flex;
    align-items: center;
    .icon {
        width: 30px;
        height: 22px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: left top;
        &.icon {
            &--png {
                background: url(app/static/icons/png.svg) no-repeat;
            }
            &--jpg {
                background: url(app/static/icons/jpg.svg) no-repeat;
            }
            &--ppt {
                background: url(app/static/icons/ppt.svg) no-repeat;
            }
            &--doc {
                background: url(app/static/icons/doc.svg) no-repeat;
            }
            &--mp3 {
                background: url(app/static/icons/mp3.svg) no-repeat;
            }
            &--pdf {
                background: url(app/static/icons/pdf.svg) no-repeat;
            }
            &--xls {
                background: url(app/static/icons/xls.svg) no-repeat;
            }
            &--avi {
                background: url(app/static/icons/avi.svg) no-repeat;
            }
            &--txt {
                background: url(app/static/icons/txt-file-icon.svg) no-repeat;
            }
            &--xml {
                background: url(app/static/icons/xml.svg) no-repeat;
            }
            &--rar {
                background: url(app/static/icons/rar.svg) no-repeat;
            }
            &--html {
                background: url(app/static/icons/html.svg) no-repeat;
            }
            &--zip {
                background: url(app/static/icons/zip.svg) no-repeat;
            }
            &--gif {
                background: url(app/static/icons/gif.svg) no-repeat;
            }
            &--css {
                background: url(app/static/icons/css.svg) no-repeat;
            }
            &--mp4 {
                background: url(app/static/icons/mp4.svg) no-repeat;
            }
            &--default {
                background: url(app/static/icons/default.svg) no-repeat;
            }
        }
    }
}

.cant-find-customer {
    .search-customer-link {
        float: right;
        margin-top: 1.5625rem;
    }
    .dcf-label {
        display: flex;
        flex-direction: row;
        img {
            margin-top: 0.4rem;
            margin-left: 0.6rem;
        }
    }
}

.address-section,
.new-customer-cont {
    display: flex;
    flex-direction: column;
}

.add-alternate-contact,
.search-customer-link {
    align-self: flex-end;
}

.shrink {
    animation: titleFieldShrink 1.5s ease forwards;
}

.test-btn {
    position: absolute;
    top: 150px;
    right: 200px;
    background: black;
    color: white;
    z-index: 200;
}

.css-case-num-field {
    .ms-TextField-fieldGroup {
        height: 45px;
    }
}

.test-btn1 {
    position: absolute;
    top: 150px;
    right: 400px;
    background: black;
    color: white;
    z-index: 200;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.sales-opportunity-risk {
    .heading {
        color: $gray-200 !important;
        font-size: $fs-14;
        margin-bottom: 0 !important;
        font-weight: normal !important;
    }
    .text {
        color: $black;
        font-size: $fs-16;
    }
}

.expanded-result-cont {
    background: $blue-800;
    @include border-radius($radius);
    border-top: none !important;
    .suggestion-result {
        .dash,
        .result-title {
            font-weight: 600;
        }
    }
}

.scroll-hint-cont {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    .scroll-hint {
        font-size: 0.6875rem;
        color: $gray-200;
        font-style: italic;
    }
}

.contact-name {
    color: $gray-100;
    font-size: $fs-14;
    font-weight: 600;
}

.info-msg {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    .ms-TooltipHost {
        display: inline-block;
        position: relative;
        cursor: pointer;
        top: 0.1875rem;
    }
    .info-text {
        color: $primary-color;
        font-weight: 600;
    }
    .ms-Image {
        margin-left: 0.2rem;
    }
}

.device-error {
    font-size: 14px;
    font-weight: 400;
    color: $gray-240;
}

.custom-row {
    display: flex;
    &.alt-contact-num-cont {
        width: 100%;
        .alt-contact-select-cont,
        .alt-contact-num-cont {
            width: 50%;
        }
        .alt-contact-num,
        .alt-contact-select {
            width: 100%;
        }
    }
    .left-space {
        margin-left: 10px;
    }
}
