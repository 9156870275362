@import '../../../assets/styles/css-assets.scss';
@import '../../../assets/styles/media-queries.scss';

.table-cont {
    .k-grid-header {
        padding: 0;
        .k-grid-header-wrap {
            border: none;
            table {
                width: 100% !important;
            }
        }
    }
    .k-column-resizer {
        border-right: 1px solid $gray-600;
        height: 2.5rem;
        vertical-align: middle;
        margin-top: 1rem;
    }
    .k-grid-table {
        width: 100% !important;
    }
    .k-grid-content {
        overflow-y: hidden;
    }
    .revenue-column-cell {
        text-align: right;
        padding: 0.6rem 1rem;
    }
    .dp-progress-wrap {
        @include border-radius(1.2rem);
        min-width: 2rem;
        min-height: 0.5rem;
        display: inline-flex;
        overflow: hidden;

        .dp-progress {
            display: inline-block;
        }
    }
    .type-a {
        background-color: rgba($color: $green-400, $alpha: 0.5);
        .dp-progress {
            background-color: $green-400;
        }
    }
    .grid-cont {
        .k-grid {
            border-color: $gray-600;
            .k-master-row {
                &:hover {
                    background-color: $gray-700 !important;
                    cursor: pointer;
                }
            }
        }
        table {
            font-family: $font-family;
            color: $gray-100;
            font-size: $fs-14;
            line-height: $fs-20;
            col.k-sorted,
            th.k-sorted {
                background-color: transparent;
            }
            .assigned-to-cell {
                display: flex;

                .ms-Persona {
                    max-width: 25px;
                    margin-right: 10px;
                }

                .ms-Image {
                    min-width: 25px;
                }
                .assigned-to-display-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            tbody {
                white-space: nowrap !important;
                .cell-in-bold {
                    font-weight: 600;
                }
                .right-aligned-column {
                    text-align: right;
                    padding-right: 30px;
                    box-sizing: border-box;
                }
            }
            thead {
                .right-aligned-header {
                    text-align: right;
                }

                th {
                    white-space: nowrap !important;
                    background: $white !important;
                    overflow: visible;
                    vertical-align: middle !important;
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                    color: $gray-100;
                    button {
                        padding: 0px;
                        &:hover,
                        &:focus,
                        span {
                            background: $white;
                            color: $gray-100;
                        }
                    }
                    .ms-ContextualMenu-Callout {
                        width: 7.5rem;
                    }
                    .request-type-header {
                        font-weight: 600;
                        font-size: $fs-14;
                    }
                }
            }
            th {
                .k-link {
                    font-weight: 600 !important;
                }
                .ms-Button-flexContainer {
                    span {
                        font-weight: 600 !important;
                    }
                }
            }
        }
        .k-grid {
            table {
                td {
                    line-height: 2rem;
                }
            }
        }
        .grid-cont {
            overflow: hidden;
            overflow-x: auto;
        }
        @include xs {
            table {
                white-space: normal;
                .k-grid th,
                .k-grid td {
                    text-overflow: normal;
                }
            }
        }
        @include sm {
            table {
                white-space: normal !important;
                td {
                    text-overflow: unset !important;
                }
            }
        }
    }
    .header-cell-btn {
        .admin-header-text {
            font-size: 0.875rem;
        }
        .ms-Button-menuIcon {
            display: none;
        }
        a {
            position: relative;
        }
        .chevron-down-icon {
            font-weight: 900;
            font-size: $fs-10;
        }
        .sort-icon {
            font-weight: 600;
            font-size: $fs-10;
        }
    }
}
.team-menu,
.request-type-menu,
.aging-menu,
.created-on-menu {
    color: $gray-100;
    .is-checked {
        color: $primary-color;
    }
}
.aging-menu {
    li:nth-child(2) {
        span {
            color: $green-400;
        }
    }
    li:nth-child(3) {
        span {
            color: $yellow-100;
        }
    }
    li:nth-child(4) {
        span {
            color: $red-200;
        }
    }
    li {
        .is-checked {
            span {
                color: $primary-color !important;
            }
        }
    }
}
