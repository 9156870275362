@import '../../../assets/styles/css-assets.scss';
.manage-request-cont {
    align-items: center;

    .manage-request-breadcrumb {
        margin-top: 0.25rem;
        margin-bottom: 0.75rem;

        .breadcrumb-home {
            font-size: 0.9rem;
            font-weight: 400;
            padding-right: 8px;
            color: #605e5c;
            cursor: pointer;
        }
    
        .breadcrumb-lb {
            padding-left: 8px;
            display: flex;
            align-items: center;
    
            .breadcrumb-lb-text {
                font-size: 0.9rem;
            }
    
            .breadcrumb-lb-icon {
                height: 18px;
                padding-right: 8px;
            }
        }
    
        .breadcrumb-item {
            font-size: 0.9rem;
            padding-left: 8px;
        }
    }

    .row-flex-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .col-flex-box {
        display: flex;
        flex-direction: column;
    }
    .justify-sb {
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }
    .paginate-cont {
        display: flex;
        justify-content: center;
        margin-top: 1.875rem;
        padding-left: 25%;
        .pagination {
            .active {
                background: $primary-color;
                color: $white;
                @include border-radius($radius/2);
            }
            li.next,
            li.previous {
                font-weight: normal !important;
                @include border-radius($radius/2);
            }
        }
    }
    .table-cont {
        margin-top: $fs-10;
        @include border-radius($radius);
        animation: fadeIn 1s ease-in;
        min-height: 25rem;

        .team-menu-container {
            @include border-radius($radius);
            overflow: hidden;
        }

        .fadeout {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease;
        }
        .request-notification {
            .notify-msg {
                color: $green-100;
            }
        }
        .notify-msg {
            font-style: italic;
        }
        .text-bold {
            font-weight: 600;
        }

        .is-checked {
            .ms-Checkbox-checkbox {
                background: $white;
                border: 1px solid $primary-color !important;

                .ms-Checkbox-checkmark {
                    color: $primary-color;
                }
            }
        }
        .ms-Checkbox {
            span {
                margin-left: 0.5rem;
            }
            .ms-Checkbox-checkbox {
                @include border-radius(0.25rem);
                border: 1px solid $gray-400;
            }
        }
    }
}
