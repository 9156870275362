@import '../../../../assets/styles/css-assets.scss';

.newsfeed-author-banner {
    padding-bottom: 4px;


    .count-sort-section {
        display: flex;
        .article-count {
            margin-top: 20px;
            padding-bottom: 20px;
            flex-grow: 1;
        }
        .sort-dropdown {
            display: flex;
            align-items: center;
            .sort-by{
                padding-right: 10px;
            }
        }
    }
    .card-list {
        margin-bottom: 16px;
    }
    .newsfeed-author-container {
        background-color: $primary-color;
        color: $white;
        padding: 64px 0;
        box-sizing: border-box;
        @include border-radius($radius);
        width: calc(100% - 8px);
        margin:10px auto 0;

        .auth-box {
            display: flex;
        }
        .author-card {
            display: flex;
            margin-right: 30px;
            border-right: 1px solid $blue-500;
            padding-right: 20px;
            .author-img {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                position: static;
                width: 130px;
                height: 120px;
                @include border-radius(60px);
                flex: none;
                order: 0;
                flex-grow: 0;

                margin-right: 10px;
            }
            .author-details {
                margin-top: 20px;
                display: block;
                .author-name {
                    font-weight: 600;
                    padding-bottom: 3px;
                    font-size: 20px;

                    font-family: $font-family;

                    line-height: 28px;

                    display: flex;
                    align-items: flex-end;

                    color: $white;
                }
                .author-position {
                    position: static;

                    font-family: Segoe UI;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;

                    display: flex;
                    align-items: flex-end;

                    color: $white;

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin: 0px 0px;
                }
                .btm-link {
                    border: 1px solid $purple-100;
                    box-sizing: border-box;
                    @include border-radius(100px);
                    background: $blue-800;

                    width: 37px;
                    height: 20px;
                    left: 4px;
                    top: 4px;
                    margin-bottom: $radius;
                    margin-top: 10px;
                    h6 {
                        margin-top: 1px;
                        margin-bottom: 0px;
                        text-align: center;
                        font-weight: 400;
                        color: $gray-100;
                    }
                }
            }
        }

        .newsfeed-author-banner-title {
            font-size:$fs-12;
            line-height:$fs-16;
            margin-bottom:10px;
        }

        .newsfeed-author-banner-icons {
            display: flex;
            flex-direction: column;
            a.ms-Link {
                .ms-Icon{
                    display: inline-block;             
                }
            }
           .author-banner-details {
            padding-left: 5px;
            font-size: $fs-12;
            color: white;
            text-decoration: none;
           }

            .hidden {
                display: none;
            }
        }
        .newsfeed-author-banner-title,
        .newsfeed-author-banner-icons {
            label{
                font-size: $fs-12;
                margin-bottom: 5px;
                display: flex;
                color: $white;
            }
        }
    }
}
