@import '../../../assets/styles/css-assets.scss';

.request-details-container {
    .dummy-space {
        min-height: 30rem;
    }

    .unified-dashboard-container {
        .details-panel-container {
            box-shadow: unset;
            .scroll-panel {
                padding: 0;
            }
            .details-panel-wrapper .dp-content .dp-table {
                width: 80%;
            }
        }
    }
    .loader-container {
        min-height: 500px;
        display: flex;
        justify-content: center;
    }
    .back-request {
        font-weight: 600;
        font-size: $fs-18;
        line-height: 24px;
        color: $gray-100;
        text-decoration: none;
        .arrow-icon {
            background: url(app/static/icons/arrow-black.svg) no-repeat;
            display: inline-block;
            width: 16px;
            height: 14px;
            margin-right: 10px;
            position: relative;
            top: 1px;
        }
    }

    .no-results-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30rem;
        box-sizing: border-box;
        font-size: $fs-20;
        border: 1px solid $gray-500;
        @include border-radius($radius);
        margin-bottom: 1rem;
    }
}
.hidden-request-modal {
    .ms-Dialog-main {
        width: 30rem;
        padding: 3rem 2rem 0 2rem;
        display: flex;
        justify-content: center;
    }
    .modal-content {
        line-height: 1.5rem;
    }

    .modal-footer {
        margin-top: 2rem;
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: center;
        min-width: 0;
        a {
            border: none !important;
        }
    }
}
