@import '../../../../assets/styles/css-assets.scss';

.newsfeed-product-news-banner {
    width: calc(100% - 8px);
    margin: auto;
    border-radius: 8px;
    margin-top: 10px;
    .count-sort-section {
        display: flex;
        justify-content: space-between;
        .article-count {
            flex-grow: 1;
        }
        .sort-dropdown {
            display: flex;
            align-items: center;
            .sort-by {
                padding-right: 10px;
            }
        }
    }
    .card-list {
        margin-bottom: 32px;
    }
    .newsfeed-product-news-container {
        color: $white;
        @include border-radius($radius);
        position: relative;
        overflow: hidden;
        height: 200px;
        box-sizing: border-box;
        .newsfeed-banner {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            img {
                width: 100%;
            }
        }
        .product-news-box {
            margin-top: 2px;
            margin-bottom: 76px;
            .product-news-header-container {
                display: flex;
                h2 {
                    font-weight: 400;
                    font-size: $fs-28;
                    line-height: $fs-30;
                    color: $white;
                    margin-bottom: 32px;
                    font-family: $font-family;
                }
                .icon {
                    width: 24px;
                    height: 24px;
                    padding-top: 30px;
                    margin-right: 10px;
                    svg {
                        width: 24px;
                        height: 24px;
                        fill: $white;
                        margin: -2px;
                    }
                }
                img {
                    width: 20px;
                    height: 20px;
                    padding-top: 35px;
                    margin-right: 15px;
                }
            }
            .newsfeed-product-news-banner-title {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
