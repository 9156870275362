@import '../../../assets/styles/css-assets';
@import './animations';

$VisualFlow: '.visual-flow';
$step: '.step';
$stepIcon: '.step-icon';

#{$VisualFlow} {
    width: 90%;
    position: relative;
    margin: 3.75rem auto;
    min-height: 350px;
    max-width: 1600px;

    &-body {
        height: calc(100vh - 320px);
        min-height: 600px;
    }

    #{$VisualFlow} {
        &__line {
            border-top: 1px solid $primary-color;
            width: 0%;
            position: absolute;
            bottom: 90px;
            height: 1px;
            left: 50%;
            min-width: 128px;

            &:before {
                content: '';
                display: inline-block;
                width: 7px;
                height: 7px;
                border: 6px solid $primary-color;
                @include border-radius(50%);
                position: absolute;
                left: 50%;
                top: -10px;
                background-color: $white;
            }

            &:after {
                content: '';
                display: inline-block;
                border-top: 1px dashed $primary-color;
                width: 100%;
                position: absolute;
                top: -1px;
                left: 100%;
                display: none;
                opacity: 0;
            }
        }

        &__steps {
            &-container {
                display: flex;
                max-width: calc(100% - 178px);
                position: relative;
                overflow: hidden;
                height: 352px;
                margin: 0 50px 0 128px;
            }

            display: flex;
            align-items: flex-end;
            gap: 15px;
            position: absolute;
            bottom: 65px;
            right: 0px;
            width: 100%;
            height: calc(100% - 80px);
            #{$step} {
                width: 100%;
                position: relative;
                opacity: 0;

                &:after {
                    content: ' ';
                    display: flex;
                    border-top: 1px solid $primary-color;
                    width: 110%;
                    position: absolute;
                    bottom: 25px;
                    height: 1px;
                    left: 10%;
                    z-index: -1;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                #{$stepIcon} {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    bottom: 0px;
                }

                #{$step}-date-aging {
                    width: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    position: absolute;
                    bottom: -65px;

                    .date {
                        width: 100%;
                        font-weight: 600;
                        font-size: $fs-12;
                        line-height: 1.063rem;
                        text-align: center;
                    }

                    .badge--status {
                        margin-left: 0px;
                    }
                }

                #{$step}-details {
                    display: flex;
                    align-items: flex-start;
                }

                .verticle-dashed-line {
                    width: 0px;
                    border-top: 1px dashed $primary-color;
                    transform: rotate(90deg);
                    transform-origin: top left;
                    position: absolute;
                    left: 26px;
                    top: 40px;

                    &:after {
                        content: '';
                        width: 0px;
                        height: 0px;
                        @include border-radius(50%);
                        display: inline-block;
                        background-color: $primary-color;
                        position: absolute;
                        right: -3px;
                        top: -3px;
                    }
                }

                &:hover {
                    #{$stepIcon} {
                        &__border {
                            transition: 0.6s ease-in-out;
                            background: $primary-color;
                        }
                    }

                    #{$step}-details {
                        .number {
                            transition: 0.6s ease-in-out;
                            color: $primary-color;
                        }
                    }
                }

                &.closed {
                    .verticle-dashed-line {
                        border-color: $green-400;

                        &:after {
                            background-color: $green-400;
                        }
                    }

                    #{$step}-details {
                        .number {
                            color: $green-400;
                        }
                    }

                    &:hover {
                        #{$step}-details {
                            .number {
                                color: #51ca92;
                            }
                        }
                    }

                    #{$stepIcon} {
                        #{$stepIcon} {
                            &__border {
                                border: 1px solid $green-400;
                            }

                            &__bg {
                                background-color: rgb(211, 229, 225);
                            }
                        }
                    }

                    &:hover {
                        #{$stepIcon} {
                            &__border {
                                transition: 0.6s ease-in-out;
                                background: #51ca92;
                            }
                        }
                    }
                }

                &:nth-child(odd) {
                    height: 100%;
                }

                &:nth-child(even) {
                    height: 70%;
                }

                .arrow {
                    position: absolute;
                    bottom: 23px;
                    left: 60%;
                }

                &:last-child {
                    .arrow {
                        display: none;
                    }
                }

                .flag {
                    position: absolute;
                    left: 50%;
                    bottom: 19px;
                    font-size: $fs-10;
                    line-height: $fs-12;
                    color: #cf4d4d;
                    text-align: center;
                    width: 80px;
                    text-decoration: none;
                    opacity: 0;

                    &:after {
                        content: '';
                        width: 100%;
                        height: 30px;
                        display: inline-block;
                        margin-top: 5px;
                        background: transparent url(app/static/icons/flag.svg) no-repeat center;
                    }
                }

                &:nth-child(3) {
                    .flag {
                        left: 65%;
                    }
                }
            }
        }

        &__steps-row {
            flex-direction: column;
            align-items: center;
            transition: all 0.5s;

            .steps {
                border: none !important;
            }
        }
    }

    &.inFirstSlide {
        #{$step} {
            display: none;
            &:nth-child(3) {
                .arrow {
                    display: none;
                }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: block;
            }
            &:nth-last-child(-n + 3) {
                display: block;
            }
        }
    }

    &.show-all-steps {
        #{$VisualFlow} {
            &__line {
                &:after {
                    display: block;
                }

                &#{$VisualFlow} {
                    &__line--size2 {
                        animation: visualFlowLine2 1.5s ease forwards;

                        &:after {
                            animation: visualFlowDottedLine 0.5s ease forwards reverse;
                        }
                    }
                }
            }
        }
    }

    button.accordion {
        position: absolute;
        bottom: 78px;
        left: calc(42% + 34px);
        border: 0px;
        background: none;
        padding: 0px;
        margin: 0px;
        width: 68px;
        height: 30px;
        cursor: pointer;
        animation: expandCollapseButtonAnimaiton 0.5s ease 0.5s forwards;
        opacity: 0;

        span {
            position: absolute;
            left: -9999px;
        }

        &.expand {
            background: #fff url(app/static/icons/expand.svg) no-repeat;
        }

        &.collapse {
            background: #fff url(app/static/icons/collapse.svg) no-repeat;
        }
    }

    .doubleArrows {
        display: inline-block;
        width: auto;
        position: absolute;
        left: 60px;
        bottom: 84px;
        opacity: 0;
    }
}

body {
    overflow-x: hidden;
}

.arrow {
    border: solid $primary-color;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

#{$stepIcon} {
    #{$stepIcon} {
        &__border {
            background-color: $white;
            border: 1px solid $primary-color;
            @include border-radius(50%);
            width: 48px;
            height: 48px;
            transition: 0.6s ease-in-out;
        }

        &__bg {
            background-color: #d7ebfb;
            @include border-radius(50%);
            width: 40px;
            height: 40px;
            transform: translateX(-50%) translateY(-50%);
            position: absolute;
            left: 50%;
            top: 50%;
            text-align: center;
            line-height: 40px;

            img {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &--next {
        position: absolute;
        right: 0px;
        bottom: 65px;
        cursor: pointer;
        opacity: 0;

        #{$stepIcon} {
            &__border {
                &--dashed-border {
                    border-style: dashed;

                    .arrow {
                        padding: 5px;

                        &:last-child {
                            margin-left: -5px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        &:before {
            content: '';
            width: 1600px !important;
            height: 1px;
            border-top: 1px dashed $primary-color;
            position: absolute;
            right: 0%;
            top: 24px;
            z-index: -1;
            opacity: 0;
            animation: visualFlowDottedLine2 0.5s ease 0.5s forwards;
        }
    }

    &--prev {
        position: absolute;
        left: 0px;
        bottom: 65px;
        cursor: pointer;
        transform: rotate(180deg);
        opacity: 0;

        #{$stepIcon} {
            &__border {
                &--dashed-border {
                    border-style: dashed;

                    .arrow {
                        padding: 5px;

                        &:last-child {
                            margin-left: -5px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        &:before {
            content: '';
            width: 1600px !important;
            height: 1px;
            border-top: 1px dashed $primary-color;
            position: absolute;
            left: 0%;
            top: 26px;
            z-index: -1;
            opacity: 0;
            animation: visualFlowDottedLine2 0.5s ease 0.5s forwards;
        }
    }
}

#{$step}-details {
    opacity: 0;

    .number {
        min-width: 32px;
        font-size: $fs-30;
        line-height: 100%;
        color: #80b7e2;
        font-weight: 500;
        text-align: right;
        @include font-stretch(1.2, 1.5);
        transition: 0.6s ease-in-out;
        cursor: default;
    }

    .details {
        width: auto;
        padding-left: 10px;

        .title {
            font-size: $fs-14;
            font-weight: 400;
            line-height: 1rem;
            cursor: default;
            word-break: break-all;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
        }

        .last-activity {
            font-size: $fs-10;
            line-height: $fs-20;
            color: $gray-300;
            cursor: default;
            word-break: break-all;
        }
    }

    .links {
        display: inline-block;
        width: 100%;
        animation: desLinks 1s ease 1.8s forwards;
        opacity: 0;

        a {
            text-decoration: none;
            color: $gray-100;
            display: inline-block;
            width: auto;
            margin: 0 10px 0 0;
            font-size: $fs-12;
            line-height: 100%;
            padding-left: 15px;
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                width: 12px;
                height: 12px;
                display: inline-block;
                position: absolute;
                left: 0px;
            }

            &.attachments {
                &:before {
                    background: transparent url(app/static/icons/attachment.svg) no-repeat center;
                }
            }

            &.messages {
                &:before {
                    background: transparent url(app/static/icons/comment.svg) no-repeat center;
                }

                margin-bottom: 43px;
            }
        }
    }
}

.badge--status {
    width: auto;
    padding: 3px 6px;
    background-color: #f3f2f1;
    @include border-radius(21px);
    font-size: $fs-10;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    margin-left: 10px;
    letter-spacing: 0.5px;

    &.active {
        background-color: #6b8e23;
        color: $white;
    }

    &.closed {
        background-color: #b22222;
        color: $white;
    }

    &.resolved {
        background: #00bfff;
        color: $white;
    }

    &.new {
        background-color: #9370db;
        color: $white;
    }
}

.message-count {
    font-size: 12px;
    line-height: 16px;
    color: #8a8886;
    margin-bottom: 20px;
}

#{$step} {
    &:nth-child(1) {
        animation: stepsSlidingRightToLeft 1s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 1s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine 1s ease 1.2s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }

    &:nth-child(2) {
        animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 0.7s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine2 1s ease 1s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }

    &:nth-child(3) {
        animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 0.4s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine 1s ease 0.8s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }

    &:nth-last-child(3) {
        animation: stepsSlidingLeftToRIght 0.4s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 0.4s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine2 1s ease 0.8s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }

    &:nth-last-child(2) {
        animation: stepsSlidingLeftToRIght 0.7s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 0.7s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine 1s ease 1s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }

    &:nth-last-child(1) {
        animation: stepsSlidingLeftToRIght 1s ease 0.2s forwards;

        .step-details {
            animation: details 1s ease 1s forwards;
        }

        .verticle-dashed-line {
            animation: verticleDashedLine2 1s ease 1.2s forwards;

            &:after {
                animation: dotAnimation 1s ease 1s forwards;
            }
        }
    }
}

#{$VisualFlow}.inFirstSlide.expandedOnce {
    #{$VisualFlow}__steps {
        #{$step} {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                animation: none;
                opacity: 1;
            }
            &:nth-child(4) {
                display: block;
                animation: stepsSlidingLeftToRIght 0.4s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.4s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine2 1s ease 0.8s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-child(5) {
                display: block;
                animation: stepsSlidingLeftToRIght 0.7s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.7s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine 1s ease 1s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-child(6) {
                display: block;
                animation: stepsSlidingLeftToRIght 1s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 1s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine2 1s ease 1.2s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }
        }
    }
    &.morethan6steps {
        #{$VisualFlow}__steps {
            #{$step} {
                @include layout-max {
                    min-width: 223px;
                }
            }
        }
    }
    &.maximize-view {
        #{$VisualFlow}__steps {
            #{$step} {
                animation: none;
                opacity: 1;
            }
        }
    }
}

#{$VisualFlow}.inFirstSlide.show-less-steps {
    #{$VisualFlow}__steps {
        #{$step} {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                animation: none;
                opacity: 1;
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                display: none;
            }

            &:nth-last-child(3):not(:nth-child(even)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.7s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine 1s ease 1s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-last-child(2):not(:nth-child(odd)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.7s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine2 1s ease 1s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-last-child(1):not(:nth-child(even)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.4s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine 1s ease 0.8s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }
            &:nth-last-child(3):not(:nth-child(odd)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.7s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine2 1s ease 1s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-last-child(2):not(:nth-child(even)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.7s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine 1s ease 1s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }

            &:nth-last-child(1):not(:nth-child(odd)) {
                display: block;
                animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

                .step-details {
                    animation: details 1s ease 0.4s forwards;
                }

                .verticle-dashed-line {
                    animation: verticleDashedLine2 1s ease 0.8s forwards;

                    &:after {
                        animation: dotAnimation 1s ease 1s forwards;
                    }
                }
            }
        }
    }
    &.upto6steps {
        #{$VisualFlow}__steps {
            #{$step} {
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    display: block;
                }
            }
        }
    }
    &.morethan6steps {
        #{$VisualFlow}__steps {
            #{$step} {
                &:nth-last-child(3):not(:nth-child(even)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.7s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine 1s ease 1s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-last-child(2):not(:nth-child(odd)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.7s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine2 1s ease 1s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-last-child(1):not(:nth-child(even)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.4s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine 1s ease 0.8s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-last-child(3):not(:nth-child(odd)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.7s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine2 1s ease 1s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-last-child(2):not(:nth-child(even)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.7s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine 1s ease 1s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-last-child(1):not(:nth-child(odd)) {
                    display: block;
                    animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.4s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine2 1s ease 0.8s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }
            }
        }
    }
    &.onPageLoad {
        #{$VisualFlow}__steps {
            #{$step} {
                opacity: 0;
                &:nth-child(1) {
                    animation: stepsSlidingRightToLeft 1s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 1s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine 1s ease 1.2s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-child(2) {
                    animation: stepsSlidingRightToLeft 0.7s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.7s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine2 1s ease 1s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }

                &:nth-child(3) {
                    animation: stepsSlidingRightToLeft 0.4s ease 0.2s forwards;

                    .step-details {
                        animation: details 1s ease 0.4s forwards;
                    }

                    .verticle-dashed-line {
                        animation: verticleDashedLine 1s ease 0.8s forwards;

                        &:after {
                            animation: dotAnimation 1s ease 1s forwards;
                        }
                    }
                }
            }
        }
    }
    &.maximize-view {
        #{$VisualFlow}__steps {
            #{$step} {
                animation: none !important;
                opacity: 1;
            }
        }
    }
}

//Fit into screen tweeks
#{$VisualFlow} {
    &.fit-into-screen {
        button.accordion {
            display: none;
        }

        #{$VisualFlow}__steps {
            left: 0px !important;
            #{$step} {
                opacity: 1;
                display: block !important;
                min-width: auto !important;
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    opacity: 1;
                }
            }
        }

        #{$VisualFlow} {
            &__line {
                animation: visualFlowLine2 0.2s ease forwards;
            }
        }

        &.show-all-steps,
        &.show-less-steps {
            &.notInFirstSlide,
            &.inFirstSlide {
                #{$VisualFlow}__steps {
                    #{$step} {
                        animation: none !important;
                        opacity: 1 !important;
                    }
                }

                #{$VisualFlow} {
                    &__line {
                        animation: visualFlowLine2 0.2s ease forwards;

                        &:after {
                            display: none !important;
                        }
                    }
                }

                .step-icon--prev,
                .step-icon--next {
                    display: none;
                }
            }
        }
    }

    &.show-all-steps.notInFirstSlide {
        #{$VisualFlow} {
            &__line {
                width: 128px;
            }
        }
        #{$VisualFlow}__steps {
            transition: left 1s ease-out;

            #{$step} {
                animation: none;
                opacity: 1;
                min-width: calc(16.66% - 15px);
                @include layout-max {
                    min-width: 223px;
                }
            }
        }
    }
    &.inFirstSlide.show-all-steps.againInFirstSlide {
        #{$VisualFlow}__steps {
            #{$step} {
                display: block;
                animation: none;
                opacity: 1 !important;
                min-width: calc(16.66% - 15px);
                @include layout-max {
                    min-width: 223px;
                }
            }
        }
        #{$VisualFlow} {
            &__line {
                animation: visualFlowLine2 0.2s ease forwards;
            }
        }
    }
    #{$VisualFlow}__steps {
        transition: left 0.8s ease-out;
    }
}

// Visual Flow Animation after once its expanded.

#{$VisualFlow}.expandedOnce,
#{$VisualFlow}.fit-into-screen {
    #{$VisualFlow}__steps {
        #{$step} {
            #{$step}-details,
            #{$step}-details .links,
            #{$step}-date-aging,
            .verticle-dashed-line,
            .verticle-dashed-line:after,
            .flag {
                animation: none !important;
                opacity: 1 !important;
            }

            .verticle-dashed-line:after {
                width: 5px;
                height: 5px;
            }

            &:nth-child(odd) {
                .verticle-dashed-line {
                    width: 175px;
                }
            }

            &:nth-child(even) {
                .verticle-dashed-line {
                    width: 94px;
                }
            }
        }
    }
}

.show-all-steps.expandedOnce {
    #{$VisualFlow} {
        &__line {
            animation: visualFlowLine2 0.2s ease forwards;

            &:before {
                animation: visualFlowLineCircle2 0.5s ease forwards;
            }

            &:after {
                animation: visualFlowDottedLine 0.5s ease forwards;
            }
        }
    }

    .step-icon--next {
        animation: nextButton 1s ease 0.2s forwards;
    }

    .step-icon--prev {
        animation: prevButton 1s ease 0.2s forwards;
    }
}

.show-less-steps.expandedOnce {
    #{$VisualFlow} {
        &__line {
            animation: visualFlowLine2 1.5s ease forwards;

            &:before {
                animation: visualFlowLineCircle2 0.5s ease forwards;
            }
        }
    }
}

.step-date-aging {
    opacity: 0;
    animation: dateAndAging 1s ease 0.5s forwards;
}

.flag {
    animation: details 0.6s ease 1.5s forwards;
}

.doubleArrows {
    animation: stepsSlidingLeftToRIght 0.5s ease 1s forwards;
}

.show-less-steps {
    #{$VisualFlow} {
        &__line {
            animation: visualFlowLine 1.5s ease forwards;

            &:before {
                animation: visualFlowLineCircle 0.5s ease forwards;
            }
        }
    }
}

.attach-icon {
    width: 10.72px;
    height: 11.86px;
    margin-right: 4.75px;
}

.comment-icon {
    width: 12px;
    height: 11px;
    margin-right: 5.53px;
}

.attachments {
    margin-right: 14.09px;
}

.row-steps-container {
    .custom-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        background: #fff;
        width: 100%;
        position: relative;
        z-index: 999;
    }
}

.expand-msg {
    font-size: $fs-12;
    line-height: $fs-16;
    display: flex;
    align-items: center;
    color: $green-100;
    margin: auto;
    position: absolute;
    top: 60%;
    left: calc(50% - 100px);
    font-style: italic;
    font-weight: normal;
}

.dotted-line {
    width: 15px;
    border: 1px dashed $green-100;
    margin: 0px 2px;
}

.step-icon-row-item {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
}

.row-steps-container {
    margin-top: 20px;
    margin-bottom: 20px;
    animation: rowsToColumn ease 5s forwards;

    .row-number {
        position: relative;
        font-size: 1.875rem;
        color: #80b7e2;
        font-weight: 500;
        background-color: white;
        margin-left: -8px;
        height: 38px;
        cursor: default;
        line-height: 38px;
        margin-top: 5px;
    }

    .custom-row:nth-child(n + 10) {
        .row-number {
            margin-left: -16px;
        }
    }

    .row-steps {
        margin: 8px -20px 0px 20px;
    }

    .verticle-detailed-border {
        border-left: 1px dashed $primary-color;
        margin-left: 74px;
        margin-top: -8px;
    }

    .row-step-details {
        height: max-content;
        margin-left: 40px;
    }

    .step-details-date {
        margin-top: 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #323130;
    }

    .steps-details-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #323130;
        margin-bottom: 4px;
    }

    .steps-details-last-activty {
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #8a8886;
        margin-bottom: 4px;
    }

    .step-icon__bg {
        transform: translateX(-50%) translateY(-50%);
        position: static;
        text-align: center;
        line-height: 40px;
        margin-top: 24px;
        margin-left: 24px;
    }

    .attachment-title {
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #323130;
        background-color: #ffffff;

        border: 1px solid #deecf9;
        box-sizing: border-box;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 11px 12px;
        padding-left: 40px;
        height: 42px;
        background: url('../../../static/icons/pdf-icon.svg') no-repeat;
        background-position: 10px 10px;
    }

    .attachment-view {
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #0078d4;
        padding: 10px;
        cursor: pointer;
    }

    .attachment-icon-section {
        width: 40px;
        height: 42px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background: url('../../../static/icons/attachment-icon-inverted.svg') no-repeat;
        background-position: center;
        background-color: #c7e0f4;
    }

    .comment-row {
        .root-116 {
            display: block;
        }
        .root-94 {
            width: 30px;
            min-width: 30px;
        }
        .root-42 {
            display: block;
        }
        .root-61 {
            display: block;
        }
        .root-65 {
            display: block;
        }
    }

    .profile-pic {
        width: 30px;
        height: 30px;
    }

    .comment-detail {
        .tagged-user {
            color: $primary-color;
            font-weight: 600;
        }
    }

    .comment-display-name {
        font-weight: 600;
        font-size: $fs-14;
        line-height: $fs-20;
        color: $gray-100;
    }

    .comment-text {
        font-weight: normal;
        font-size: $fs-14;
        line-height: $fs-18;

        img {
            max-width: 100%;
        }
        a {
            color: $primary-color;
        }
    }

    .comment-time {
        font-weight: normal;
        font-size: $fs-10;
        line-height: $fs-12;
        color: #8a8886;
        margin-top: 4px;
        margin-bottom: 24px;
    }
    .badge--status {
        margin-top: 8px;
    }

    .step-icon__border {
        transition: none;
    }
}

.transform-steps {
    transform: skewY(20deg);
    transition: all 1s;
    animation: disappear 1s forwards;
}

.child-comment-container {
    display: block;
    border-left: 0.5px solid #c7e0f4;
    margin-top: -35px;
    margin-left: 15px;
    padding-top: 30px;
    padding-left: 40px;
}
