@import '../../../../../assets/styles/css-assets.scss';

.newsfeed-article-content {
    padding: 64px 0px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    .article-content {
        * {
            box-shadow: none;
            font-family: $font-family !important;
        }
        a {
            color: $primary-color;
            &:hover {
                text-decoration: none;
            }
        }
        img {
            max-width: 100%;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0.5em 0;
        }
    }

    .sticky-container-left {
        position: sticky;
        top: 20px;
    }

    .newsfeed-content-left-wrapper {
        padding-right: $base-font-size;
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            list-style: none;
        }
    }

    .sticky-container-right {
        position: sticky;
        top: 155px + 20px;
    }

    .is-current-section {
        a {
            color: $primary-color;
        }
    }

    .article-section-link {
        color: $gray-200;
        &:hover {
            text-decoration: none;
            color: $primary-color;
        }
    }

    .newsfeed-article-divider {
        border-bottom: 1px solid $green-500;
        margin: $base-font-size 0;
    }
    .newsfeed-article-text-highlighted {
        font-size: $base-font-size;
        color: $gray-100;
        font-weight: 600;
        margin-bottom: 48px;
    }

    .newsfeed-article-title-highlighted {
        font-size: 20px;
        color: $gray-100;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .newfeed-article-generic-text {
        font-size: 14px;
        color: $gray-100;
    }
    .newfeed-article-generic-text-small {
        font-size: 12px;
        color: $gray-100;
    }
    .newfeed-article-primary-text-small {
        font-size: 12px;
        color: $primary-color;
    }

    .newsfeed-article-comments {
        background-color: $gray-600;
        padding: 20px;
        box-sizing: border-box;
        margin: 40px 0;

        span {
            border-left: 2px solid $primary-color;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .article-suggestion {
        .article-suggestion-title {
            font-weight: 600;
            font-size: 20px;
            color: $gray-100;
            margin-bottom: 20px;
        }

        .textfield-char-count {
            position: absolute;
            right: 20px;
            bottom: 55px;
            font-size: 12px;
            color: $gray-200;
        }
        textarea {
            padding: 12px;
            @include border-radius($radius);
            background: $gray-700;
        }

        .ms-TextField-fieldGroup {
            @include border-radius($radius);
        }

        button {
            margin-top: 12px;
            @include border-radius($radius);
            span {
                margin-left: $radius;
            }
        }
    }

    .rating-container {
        @include border-radius($radius);
        background: $gray-700;
        padding: 25px;
        margin-bottom: 80px;
        font-size: $base-font-size;
        line-height: 22px;
        color: $gray-200;
        justify-content: space-between;
        display: flex;
        margin-top: 36px;

        .span-txt {
            font-size: $base-font-size;
            line-height: 22px;
            color: $gray-200;
        }

        .btn-cnt {
            margin-left: 25px;
            position: relative;
            top: 12px;

            @keyframes bounce {
                0% {
                    top: 0;
                }
                50% {
                    top: -0.2em;
                }
                70% {
                    top: -0.3em;
                }
                100% {
                    top: 0;
                }
            }

            .like-icon {
                display: inline-block;
                position: relative;
                font-size: 20px;
                color: $green-100;

                &:hover {
                    animation: bounce 0.8s infinite linear;
                }
            }

            .dis-like-icon {
                display: inline-block;
                position: relative;
                margin-left: 20px;
                font-size: 20px;
                color: $gray-200;

                &:hover {
                    animation: bounce 0.8s infinite linear;
                }
            }
        }
        .rating-text-msg {
            font-size: $base-font-size;
            line-height: 22px;
            color: $gray-200;
            .ms-Rating-star {
                display: inline-block;
                position: relative;
                top: 5px;
                .ms-RatingStar-back {
                    color: $yellow-100;
                }
                .ms-RatingStar-front {
                    color: $yellow-100;
                }
            }
        }
    }

    .newsfeed-author-info-card {
        width: 306px;
        background-color: $white;
        position: relative;
        top: -155px;
        right: 0px;
        padding: 24px 22px;
        box-sizing: border-box;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        @include border-radius($radius);
        margin-left: auto;
        margin-right: 0;

        .newsfeed-author-text-written {
            font-size: 12px;
            color: $gray-200;
            margin-bottom: 12px;
        }

        .newsfeed-card-group {
            display: flex;
            flex-wrap: wrap;
            color: $gray-300;
        }

        .newsfeed-author-info-card-avatar {
            img {
                @include border-radius(50%);
            }
        }

        .newsfeed-author-title-group {
            width: 142px;
            margin-left: 20px;

            .newsfeed-author-title {
                color: $gray-100;
                font-size: 18px;
            }

            .newsfeed-author-designation {
                font-size: 14px;
            }

            .newsfeed-author-tag {
                font-size: 12px;
                color: $gray-100;
                background: $cyan-800;
                border: 1px solid $cyan-400;
                box-sizing: border-box;
                @include border-radius(100px);
                display: inline-block;
                text-align: center;
                padding: 0px $radius;
                margin-top: 6px;
            }
        }

        .author-publish-details {
            color: $primary-color;
            margin-top: $base-font-size;
            font-size: 18px;

            .author-publish-title {
                color: $gray-200;
                font-size: 12px;
            }

            .author-views {
                margin-top: $base-font-size;
                align-items: center;
                span {
                    margin-left: 5px;
                }
            }

            .author-read {
                margin-top: $base-font-size;
                align-items: center;
                span {
                    margin-left: 5px;
                }
            }
        }
    }
    @include sm {
        .newsfeed-author-info-card {
            width: auto;
        }
    }
    @include xs {
        .newsfeed-author-info-card {
            width: auto;
        }
    }
    @include md {
        .newsfeed-author-info-card {
            width: auto;
        }
    }
}
.training-title-views {
    display: flex !important;
    justify-content: space-between;
    align-items: start;
    padding-right: 37px !important;
    margin: 20px 0 12px !important;
    .training-title {
        font-size: $fs-28;
    }
    .author-views {
        min-width: 150px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .ms-Icon {
            margin-right: 5px;
        }
    }
}

.training-video {
    @include border-radius($radius);
}
.react-player {
    width: 100% !important;
    height: 608px !important;
    @include border-radius($radius);
    overflow: hidden;
}
