@import '../../../../../../assets/styles/css-assets.scss';

.assignedTo-picker {
    display: flex;
    justify-content: space-around;
    height:38px;
    align-items: center;
    flex-direction: column;
}

.assignedTo-picker:hover {
    cursor: pointer;
}

.assignedTo-dropdown-cont {
    max-height: 30rem;
    max-width:330px;
    overflow-y: auto;
    position: absolute;
    top:90px;
    right:-10px;
    background: $white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    @include border-radius($radius);
    z-index: 999999;
    font-size: $fs-14;
    font-weight: normal;
    margin-top: .5rem;

    .assignedTo-dropdown-item {
        border-top: 1px solid $gray-600;
        box-sizing: border-box;
        padding: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ms-Persona-details {
            display: none;
        }
        .ms-Persona-coin {
            margin-right: 0.625rem;
        }
        &.selected {
            label {
                font-weight: 600;
                color: $gray-100;
            }
        }
        .ms-Checkbox {
            margin-right: 0.625rem;
        }
        &.child-item {
            padding-left: 30px;
        }
        &.parent-item {
            label {
                font-weight: 600;
            }
        }
    }
}

.picker-heading {
    font-size: $fs-12;
    color: $gray-200;
    font-weight: normal;
    min-width: 130px;
}

.assignedTo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: $fs-14;
    color: $primary-color;
    justify-content: space-between;
    margin-right: 10px;
    margin-left:2px;
    min-width: 120px;
    margin-bottom:2px !important;
    position: relative;

    i {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: $fs-10;
        position: absolute;
        right: -10px;
    }

    .assignedTo-label {
        display: inline-block;
        max-width: 6.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .txt-link {
        font-weight: bold;
        font-size: $fs-14;
        line-height: 18px;
        color: $blue-400;
        cursor: pointer;
    }
}

.search-bar-assignedTo {
    padding: 1.25rem 0.625rem;
    cursor: pointer;
    .ms-SearchBox {
        @include border-radius($radius);
        border: 1px solid $blue-700;
        input::placeholder {
            color: $gray-300;
        }
        i {
            font-size: $fs-14;
            color: $gray-300;
        }
        .ms-SearchBox-clearButton {
            i {
                font-size: $fs-14;
            }
        }
        &::after {
            @include border-radius($radius);
        }
    }

    .assignedTo-cont {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.3125rem;
        .assignedTo-item {
            display: flex;
            align-items: center;
            background: $gray-800;
            margin-right: 0.3125rem;
            padding: 0.125rem 0.3125rem;
            color: $gray-250;
            margin-bottom: 0.3125rem;
            @include border-radius(0.1875rem);
            span {
                font-size: $fs-12;
            }
            i {
                cursor: pointer;
                margin-left: 0.625rem;
                font-size: $fs-10;
            }
        }
    }
}