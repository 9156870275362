@import '../../../assets/styles/css-assets.scss';
.overlay {
    width: 100%;
    height: 350px;
    width: calc(100% - 8px);
    margin: auto;
    border-radius: 8px;
    background-color: $primary-color;
    margin-top: 30px;
    background: url(app/static/Newsletter_Background.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    .subscribe-container {
        height: 350px;
        background: none;
        display: flex;
        .subscribe-left {
            width: 25%;
            min-width: 350px;
            margin-top: 95px;
            margin-left: 0px;
            z-index: 999;
            h6 {
                margin-top: 25px;
                margin-bottom: 5px;
                color: $gray-200;
            }
            .subscribe-comment {
                font-size: 28px;
                line-height: 36px;
                color: $white;
            }
            .mail {
                display: flex;
                flex-direction: column;
                padding-top: 25px;
                width: 100%;
                label {
                    font-size: $fs-14;
                    color: $white;
                    display: flex;
                    width: 100%;
                    padding-bottom: 5px;
                    sup {
                        color: $white;
                    }
                }
                .typehead {
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    @include border-radius($radius 0px 0px $radius);
                    .email-input {
                        @include border-radius($radius 0px 0px $radius);
                        color: $gray-100;
                        background: $white;
                        width: 100%;
                        height: 32px;
                        .ms-TextField-fieldGroup {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding: 0px 0px;
                            @include border-radius($radius 0px 0px $radius);
                            background: $white;
                            border: 2px solid $gray-250;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            margin: 0px 0px;
                            .ms-TextField-field {
                                font-size: 14px;
                                @include border-radius($radius 0px 0px $radius);
                                color: $gray-100;
                                background: transparent !important;
                                height: 28px;
                                &:focus,
                                &:active {
                                    background-color: none;
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
                .subscribe-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 6px 15px;
                    color: $white;
                    cursor: pointer;
                    white-space: nowrap;
                    height: 32px;
                    border: none;
                    font-size: $fs-14;
                    background-color: $blue-300;
                    letter-spacing: 0.6px;
                    @include border-radius($radius);
                    .ms-Stack {
                        margin-right: 8px;
                    }
                }
                .unsubscribe-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 6px 15px;
                    color: $white;
                    cursor: pointer;
                    white-space: nowrap;
                    height: 32px;
                    border: none;
                    font-size: $fs-14;
                    background-color: $red-100;
                    letter-spacing: 0.6px;
                    @include border-radius($radius);
                    .ms-Stack {
                        margin-right: 8px;
                    }
                }
                .error-message {
                    position: relative;
                    font-size: $fs-12;
                    padding-top: 2px;
                    color: $red-100;
                    line-height: $fs-16;
                    opacity: 0;
                    z-index: -1;
                    top: -20px;
                    transition: all 0.5s ease;
                }
                &.error {
                    label {
                        sup {
                            color: $red-100;
                        }
                    }
                    .typehead .email-input .ms-TextField-fieldGroup {
                        border-color: $red-100;
                    }
                    .error-message {
                        top: 0;
                        opacity: 1;
                    }
                }
                &.success {
                    .subscribe-button {
                        background-color: $green-100;
                        cursor: auto;
                        .ms-Icon {
                            font-size: $fs-20;
                            margin-right: 5px;
                        }
                    }
                    .unsubscribe-button {
                        background-color: $blue-300;
                        cursor: auto;
                    }
                }
            }
        }
    }
}