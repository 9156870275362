@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/css-assets.scss';

/* Page container */
#div2 {
    animation-timing-function: ease;
}

.list-builder-parent-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eff6fc;
    flex-direction: column;
}

.list-builder-margins-helper {
    margin-top: 40px;
}

/* And Or Plus Icon Animations */

// AND OR DOWN
@keyframes andOrIn {
    from {
        top: -70px;
        right: 0px;
    }
    to {
        top: -50px;
        right: 0px;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.andOrInContainer {
    display: flex;
    position: relative;
    top: -50px;
    right: 0px;
    animation: andOrIn 3s;
    flex-direction: column;
}

// AND OR UP
@keyframes andOrOut {
    from {
        top: -50px;
        right: 0px;
    }
    to {
        top: -70px;
        right: 0px;
    }
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.andOrOutContainer {
    display: flex;
    position: relative;
    top: -75px;
    right: 0px;
    animation: andOrOut 3s;
    flex-direction: column;
}

/* And Or Styles */
.andStyles {
    width: 81px;
    height: 38px;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    z-index: 300;
    position: relative;
    justify-content: center;
}

.andStyles:hover {
    cursor: pointer;
}

.activeAnd {
    background-color: #8378de;
    color: white;
    font-family: 'Segoe UI';
    font-style: normal;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.active-initial-and {
    background-color: #8378de;
    color: white;
    font-family: 'Segoe UI';
    font-style: normal;
    border-radius: 8px;
}

.andOrStyles {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}
.pipeStyles {
    height: 37px;
    margin-left: 26%;
    border-left: 1.5px solid #0078d4;
}

.orStyles {
    width: 81px;
    height: 38px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid #c8c6c4;
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}
.activeOr {
    background-color: #00b7c3;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Grey palette / White */

    color: #ffffff;
}
.orStyles:hover {
    cursor: pointer;
}
/* Header Styles */
.savedLink {
    width: 50px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #0078d4;
}
.savedLink:hover {
    cursor: pointer;
}

.favooritesLink {
    margin-left: 10px;
    width: 50px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #0078d4;
}

.favooritesLink:hover {
    cursor: pointer;
}

.building-list-container {
    width: 100%;
    height: 54px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 9px 4px 29px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1000;
}

.arrowLabelStyles {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    display: flex;
    align-items: flex-end;

    color: #0078d4;
}

.arrowStyles {
    width: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.arrow-btn-container {
    display: flex;
    width: 48%;
    margin-left: 7%;
    justify-content: space-between;
    flex-direction: row;
}

.favorites-saved-container {
    display: flex;
    width: 23%;
    justify-content: center;
    flex-direction: row;
}

.loadMore {
    height: 40px;
}

.teamBuilderContainer {
    display: flex;
    flex-direction: row;
    //justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

/* Save As */

.saveAsStyles {
    display: flex;
    justify-content: end;
    width: fit-content;
    height: fit-content;
    .ms-Icon {
        color: white;
    }
}

.chev-save-btn {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

// Success Banner

.justSaved {
    border: 2px solid black;
    display: flex;
}

.editBannerIn {
    position: absolute;
    top: 183px;
    left: 0px;
    animation: successBannerIn 4s;
    width: 100%;
    display: flex;
    height: 100px;
    align-items: center;
    z-index: 1;
    background: #fff6e6;
}

// Large Monitors
@media only screen and (min-width: 1824px) {
    .successBannerIn {
        position: absolute;
        top: 183px;
        left: 0px;
        animation: successBannerIn 4s;
        width: 100%;
        display: flex;
        height: 4.5em;
        align-items: center;
        z-index: 1;
        background: #fff6e6;
    }
    .successContainer {
        margin-left: 8%;
        align-items: center;
        display: flex;
        padding-top: 20px;
        flex-direction: row;
    }
}

// laptop screens
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .successBannerIn {
        position: absolute;
        top: 183px;
        left: 0px;
        animation: successBannerIn 4s;
        width: 100%;
        display: flex;
        height: 90px;
        align-items: center;
        z-index: 1;
        background: #fff6e6;
    }

    .successContainer {
        margin-left: 8%;
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

// default screen rules
.successBannerIn {
    position: absolute;
    top: 183px;
    left: 0px;
    animation: successBannerIn 4s;
    width: 100%;
    display: flex;
    height: 4.5em;
    align-items: center;
    z-index: 1;
    background: #fff6e6;
}
.successContainer {
    margin-left: 8%;
    align-items: center;
    display: flex;
    flex-direction: row;
}

@keyframes successBannerIn {
    from {
        top: 120px;
        right: 0px;
    }
    to {
        top: 183px;
        right: 0px;
    }
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.toggle-fav-cont {
    margin-top: 4px;
    display: flex;
    align-items: center;
}

.saved-icon-styles-new {
    width: auto;
    min-width: 120px;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
}

.saved-icon-styles-edit {
    width: auto;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
}

.listNameStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    margin-right: 15px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    width: auto;
}

.dash-indicator {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    width: 20px;
    display: flex;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .editBannerIn {
        position: absolute;
        top: 183px;
        left: 0px;
        animation: successBannerIn 4s;
        width: 100%;
        display: flex;
        height: 75px;
        align-items: center;
        z-index: 1;
        background: #fff6e6;
    }
}

.edit-banner {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff6e6;
    margin-left: 10%;
    padding-top: 12px;
    width: 15rem;

    .editQuery-cont {
        display: flex;
        width: 100%;
        padding-top: 10px;

        .lb-query-edit-icon {
            margin-left: 2rem;
        }
    }
    .editFolder-cont {
        width: 100%;
        display: flex;
        align-items: flex-end;
        height: 5%;
    }
}

.icon-edit-mode {
    max-height: 20px;
    width: auto;
    margin-right: 10px !important;
    object-fit: contain;
}

.nameFavIconStyles {
    display: flex;
    width: auto;
    min-width: 125px;
    align-items: center;
    justify-content: space-between;
}

.title-148 {
    display: inline-block;
    vertical-align: middle !important;
    border-color: #ceccca;
}

.title-148:active,
.title-148:after,
.dropdown-138:focus {
    border-color: none !important;
}

.dropdown-137:active,
.dropdown-137:after,
.dropdown-137:focus {
    border-color: none !important;
    box-shadow: 0px 0px 15px #0078d4;
}

.paginate-cont {
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
}
