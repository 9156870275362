@import '../../assets/styles/css-assets.scss';

.dropzone-container {
    border: 3px dashed $gray-400;
    @include border-radius(10px);
    overflow: hidden;

    .add-more-link {
        text-decoration: underline;
    }

    .add-more-attachments {
        display: flex;

        .add-more-link {
            margin-right: 1rem;
        }
    }

    .dropzone-content {
        padding: 4rem 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .btn-upload {
            padding: 1rem;
            align-self: center;
            margin: 1rem 0;
        }

        .file-attach-icon {
            margin: 1.5rem 0 1rem;
            align-self: center;
        }

        .title {
            margin-top: 0.5rem;
        }

        .sub-title {
            margin-top: 0.5rem;
        }

        .managed-request-attachment-btns {
            .btn-upload {
                padding: 1rem;
                align-self: center;
                margin: 1rem 0.5rem;
            }
            .btn-internal-upload {
                background-color: $internal;
                border: 1px solid rgba($color: $internal, $alpha: 1);
                box-shadow: 0px 0px 10px 0px rgba($color: $internal, $alpha: 0.3);
            }
            .btn-external-upload {
                background-color: $external;
                border: 1px solid rgba($color: $external, $alpha: 1);
                box-shadow: 0px 0px 10px 0px rgba($color: $external, $alpha: 0.3);
            }
        }

        .managed-request-sub-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 6rem;
            font-style: italic;
        }
    }

    .attachment-list-container {
        min-height: 300px;
        padding: 0 1rem 0.5rem;
        box-sizing: border-box;

        .attachment-list {
            background: $white;
            margin-bottom: 0.6rem;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
            @include border-radius(10px);
            padding: 1rem 1rem;
            position: relative;

            .attach-icon {
                background: url(app/static/icons/image-icon.svg) no-repeat;
                display: inline-block;
                width: 27px;
                height: 20px;

                margin-right: 15px;
            }

            .file-txt {
                position: relative;
                top: 1px;
            }

            .attachment-group {
                display: flex;
                align-items: center;
                .ms-Toggle-background {
                    border-color: $white;
                    background-color: $gray-300;
                }
                .attachment-classification {
                    margin: 0 0.5rem;
                }
            }
            .attachment-center-group {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;
            }
            .attachment-file-group {
                display: flex;
                overflow: hidden;
            }
            .attachment-remove-group {
                display: flex;
            }
            .attachment-row-divider {
                height: 2rem;
                margin: 0 1rem;
            }
        }
    }

    .dropzone-error-msg {
        color: $red-200;
        margin: 0.6rem 0;
        font-size: 18px;
        padding: 0 0.5rem;
        box-sizing: border-box;
    }
}
