@import '../../../../assets/styles/css-assets.scss';
.notification-overlay {
    position: absolute;
    top: 40px;
    z-index: 100;
    width: 380px;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    right: 300px;
    z-index: 9999;

    .custom-row {
        display: flex;
        flex-direction: row;
    }

    .notification-header {
        margin-left: 20px;
        margin-top: 14px;
        font-size: $fs-16;
        line-height: $fs-22;
    }

    .mark-read {
        margin-top: 14px;
        margin-left: auto;
        margin-right: 20px;
        font-size: $fs-12;
        line-height: $fs-16;
        color: $blue-400;
        text-decoration: underline;
        cursor: pointer;
    }

    .notification-item-row {
        padding: 13px 20px;
        display: flex;
        flex-direction: row;
        border-bottom: $gray-600 1px solid;
        cursor: default;
    }

    .notification-icon {
        width: 32px;
        height: 32px;
    }

    .notification-message {
        font-size: $fs-14;
        line-height: $fs-20;
        cursor: pointer;
    }

    .notification-time {
        font-size: $fs-10;
        line-height: $fs-20;
        color: $gray-200;
    }

    .notification-message-container {
        margin-left: 10px;
    }

    .view-all-notifications {
        height: 30px;
        border-top: 1px solid $gray-600;
        background: $white;
        text-align: center;
        color: $blue-400;
        font-size: $fs-12;
        line-height: $fs-24;
        text-align: center;
        margin: auto;
        a {
            color: $blue-400;
        }
    }

    .no-unread {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        color: $gray-200;
    }
}
