@keyframes numberColor {
    0% {
        color: $green-400;
    }

    100% {
        color: $primary-color;
    }
}

@keyframes expandCollapseButtonAnimaiton {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes desLinks {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dateAndAging {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes visualFlowDottedLine {
    0% {
        opacity: 0;
        width: 0%;
        left: 0%;
    }

    100% {
        opacity: 1;
        width: 100%;
        left: 100%;
    }
}

@keyframes visualFlowDottedLine2 {
    0% {
        opacity: 0;
        width: 0%;
        left: 0%;
    }

    100% {
        opacity: 1;
        width: 100%;
        left: 100%;
    }
}

@keyframes visualFlowLine {
    0% {
        left: 50%;
        width: 0px;
    }

    100% {
        left: 0%;
        width: 85%;
    }
}

@keyframes visualFlowLine2 {
    0% {
        left: 0%;
    }

    100% {
        left: 0%;
    }
}
@keyframes visualFlowLineCircle {
    0% {
        left: 50%;
    }

    100% {
        left: 0%;
    }
}

@keyframes visualFlowLineCircle2 {
    0% {
        left: 0%;
    }

    100% {
        left: 0%;
    }
}

@keyframes stepsSlidingLeftToRIght {
    0% {
        opacity: 0;
        transform: translateX(-60%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes stepsSlidingRightToLeft {
    0% {
        opacity: 0;
        transform: translateX(60%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes details {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes nextButton {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes prevButton {
    0% {
        opacity: 0;
        left: 150px;
    }

    100% {
        opacity: 1;
        left: 0px;
    }
}

@keyframes verticleDashedLine {
    0% {
        width: 0px;
    }

    30% {
        width: 175px;
    }

    50% {
        width: 160px;
    }

    60% {
        width: 175px;
    }

    70% {
        width: 165px;
    }

    80% {
        width: 175px;
    }

    90% {
        width: 170px;
    }

    100% {
        width: 175px;
    }
}

@keyframes verticleDashedLine2 {
    0% {
        width: 0px;
    }

    30% {
        width: 94px;
    }

    50% {
        width: 79px;
    }

    60% {
        width: 94px;
    }

    70% {
        width: 84px;
    }

    80% {
        width: 94px;
    }

    90% {
        width: 89px;
    }

    100% {
        width: 94px;
    }
}

@keyframes dotAnimation {
    0% {
        width: 0px;
        height: 0px;
    }

    10% {
        width: 5px;
        height: 5px;
    }

    100% {
        width: 5px;
        height: 5px;
    }
}
@keyframes slideLeft {
    0% {
        opacity: 1;
        left: 0;
    }

    100% {
        opacity: 0;
        left: -100%;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(60%);
        min-width: 0px;
        width: 0px;
    }
}
@keyframes onlyFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


@keyframes rowsToColumn {
    0% {
        
    }

    100% {
        
    }
}