/* Plus Icon */

#div2 { 
    animation-timing-function: ease; 
    --icon-position: 0px;
    --icon-previous-position: 0px;
}

// Plus Icon IN to view

.iconPlusIn {
    display: flex;
    z-index: -1;
    position: relative;
    left: -30px; top: var(--icon-position);
    animation: plusIconIn 3s;
    flex-direction: column;
}

@keyframes plusIconIn {
    from {left: 0px; top: 0px;}
    to {left: -30px; top: var(--icon-position);}
    0% {opacity: 0;}
    100% {opacity: 1;}
}

// Plus Icon OUT of view

.iconPlusOut {
    display: flex;
    z-index: -1;
    position: relative;
    left: 0px; top: 0px;
    animation: plusIconOut 3s;
    flex-direction: column;
}

@keyframes plusIconOut {
    from { left:-30px; top: 0px;}
    to { left: 0px; top: 0px; }
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.plusIconContainer:hover {
    cursor: pointer;
}
.plusIconContainer {
    position: relative;
}

.plusIconUp {
    display: flex;
    z-index: -1;
    position: relative;
    left: -30px; top: var(--icon-previous-position);
    animation: plusIconUp 3s;
    flex-direction: column;
}

.plusIconDown {
    display: flex;
    z-index: -1;
    position: relative;
    left: -30px; top: var(--icon-position); 
    animation: plusIconDown 3s;
    flex-direction: column;
}


@keyframes plusIconDown {
    from {left: -30px; top: var(--icon-previous-position)}
    to { left: -30px; top: var(--icon-position) }
}

@keyframes plusIconUp {
    from { left: -30px; top: var(--icon-position)}
    to { left: -30px; top: var(--icon-previous-position) }
}
