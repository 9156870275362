@import '../../assets/styles/css-assets';

.k-textbox-container {
    .k-datepicker,
    .k-datetimepicker,
    .k-timepicker {
        .k-picker-wrap {
            padding: 0 $radius !important;
            @include border-radius($radius);
            border: 1px solid $gray-200 !important;
            transition: none !important;
            border-top-color: $gray-200 !important;
           
        }
    }
    &.k-state-focused,
    &.k-state-active {
        &:after{
            display: none;
        }
    }
}
.k-calendar-container,
.k-datetime-container,
.k-animation-container {
    z-index: 9999999 !important;
}

.h-max-content {
    height: max-content;
}
.date-time-picker {
    position: relative;
    .k-select {
        display: none !important;
    }
    .k-icon {
        position: absolute;
        bottom: 10px;
        right: 8px;
        cursor: pointer;
    }
}
