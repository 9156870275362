@import './css-assets';
@import './loader';
@import './utilities';
@import './tags';
@import './capsules';
@import 'suneditor/dist/css/suneditor.min.css';
@import './modal';
@import './forms';
@import './ms-fluent-ui';
@import './kendo';
html,
body {
    font-family: $font-family;
    font-size: $fs-16;
    scroll-behavior: smooth;
    @include gt-lg {
        font-size: $fs-18;
    }
}
body {
    padding: 0;
    margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}
h1 {
    font-size: $fs-42;
}
h2 {
    font-size: $fs-32;
}
h3 {
    font-size: $fs-28;
}
h4 {
    font-size: $fs-20;
}
h5 {
    font-size: $fs-18;
}
h6 {
    font-size: $fs-16;
}
button {
    font-family: $font-family;
}
.container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    @include layout-max {
        width: 85%;
    }

    @include sm {
        width: 94%;
    }
}

.c-default {
    cursor: default;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.login-page {
    button {
        border-radius: 10px;
        padding: 20px 30px;
        margin-top: 9px;
        min-width: 150px;
        background-color: rgb(0, 120, 212);
        color: rgb(255, 255, 255);
        border: none;
        margin: 30px auto;
        display: block;
        font-weight: 600;
        font-family: Segoe UI;
        font-size: 18px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .navbar-login-header {
        text-align: center;
        padding: 0.9rem 2.5rem;
        box-shadow: 0px 2px 2px #ccc;
        margin-bottom: 20px;

        .navbar-brand {
            font-size: 26px;
            font-weight: 600;
            color: #0078d4;
            text-decoration: none;
        }
    }
}

.scrollableContent-116 {
    overflow: hidden;
}

//custom scroll bar

.custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $gray-600;
    border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: $gray-200;
    border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: $gray-600;
    border-radius: 20px;
}
.more-links,
.pos-relative {
    position: relative;
}

.message {
    font-weight: bold;
    font-size: $fs-32;
    color: $gray-300;
    a {
        color: $primary-color;
        text-decoration: none;
    }
}

.jc-center {
    justify-content: center;
}

.ai-center {
    align-items: center;
}

.ai-baseline {
    align-items: baseline;
}

.height {
    &-60 {
        height: 60vh;
    }
    &-70 {
        height: 70vh;
    }
}
.device-list {
    list-style: none;
    padding: 0 0 0 0;
    margin: 0;

    li {
        padding: 15px 10px 15px;
        border-bottom: 1px solid $gray-600;
        font-size: $fs-14;
        line-height: 20px;
        color: $gray-100;
        display: flex;
        align-items: center;
       
        img {
            margin-right: 10px;
            width: 37px;
            height: auto;
        }
        .device-txt {
            position: relative;
            //top: 5px;
        }
    }

    li:last-child {
        border-bottom: 0;
    }
}

.tags-list {
    list-style: none;
    padding: 0 0 0 0;
    margin: 0;

    li {
        padding: 15px 10px 15px;
        border-bottom: 1px solid $gray-600;
        font-size: $fs-14;
        line-height: 20px;
        color: $gray-100;

        .tags-txt {
            position: relative;
        }
    }

    li:last-child {
        border-bottom: 0;
    }
}

.customRaido {
    .ms-Checkbox-checkbox,
    &.is-checked .ms-Checkbox-checkbox,
    .ms-Checkbox-checkbox:hover {
        background: #ffffff;
        border-radius: 50% !important;
        border-color: #0078d4;
    }
    .ms-Checkbox-checkbox i,
    .ms-Checkbox-checkbox:hover i {
        color: transparent !important;
        background: #0078d4 !important;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: relative;
        top: 0.3px;
        left: -0.3px;
    }
}

.countdown {
    display: flex;
    flex-direction: column;
    .countdown {
        &-header {
            justify-content: center;
            color: $primary-color;
            display: flex;
            letter-spacing: 10px;
            margin: 20px auto;
            font-size: $fs-30;
            font-weight: 600;
            @media screen and (min-width: 600px) {
                font-size: $fs-42;
                letter-spacing: 20px;
            }
            @media screen and (min-width: 1200px) {
                font-size: $fs-68;
            }
        }
        &-time {
            justify-content: center;
            display: flex;
            gap: 10px;
            align-items: flex-start;
            @media screen and (min-width: 600px) {
                gap: 40px;
            }
            .ellipses {
                font-size: 80px;
                font-weight: 600;
                line-height: 120px;
                color: $gray-200;
                @media screen and (min-width: 600px) {
                    font-size: 110px;
                    line-height: 145px;
                }
                @media screen and (min-width: 1200px) {
                    font-size: 150px;
                    line-height: 170px;
                }
            }
            .timer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                color: $gray-200;
                width: 90px;

                @media screen and (min-width: 600px) {
                    min-width: 120px;
                }
                @media screen and (min-width: 1200px) {
                    min-width: 170px;
                }
                .label {
                    font-size: $fs-20;
                    width: 100%;
                    text-align: center;
                }

                .time {
                    font-size: 80px;
                    font-weight: 600;
                    line-height: 80px;
                    width: 100%;
                    text-align: left;
                    @media screen and (min-width: 600px) {
                        font-size: 110px;
                        line-height: 110px;
                    }
                    @media screen and (min-width: 1200px) {
                        font-size: 150px;
                        line-height: 150px;
                    }
                }
            }
        }
        &-videos {
            display: flex;
            flex-direction: column;
            margin-top: 50px;

            .videos {
                display: flex;
                flex-direction: column;
                gap: 40px;
                margin: auto;
                max-width: 80%;
                @media screen and (min-width: 880px) {
                    flex-direction: row;
                }
                @media screen and (min-width: 1360px) {
                    gap: 80px;
                }
                .video {
                    width: 100%;
                    border: 1px solid $gray-600;
                    width: 400px !important;
                    height: 226px !important;
                    @media screen and (min-width: 1201px) and (max-width: 1359px) {
                        width: 580px !important;
                        height: 328px !important;
                    }
                    @media screen and (min-width: 1360px) {
                        width: 640px !important;
                        height: 360px !important;
                    }

                    button {
                        width: 100px;
                        height: 100px;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.panel-separator {
    border: 1px solid $blue-800;
    border-width: 1px 0;
    padding: 15px 0px;
}

.internal-nav {
    ul {
        margin: 0px;
        li {
            padding: 0 0.25rem;
            color: $gray-100;
            cursor: pointer;
            &.active {
                color: $primary-color;
                font-weight: 600;
                border-bottom: 2px solid $primary-color;
                display: inline-block;
                color: $primary-color;
            }
        }
    }
}


.ms-Callout .ms-DatePicker-goToday{
    right: 11px;
}