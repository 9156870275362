@import '../../../../assets/styles/css-assets.scss';
@import '../animations.scss';
.unified-dashboard-container {
    .list-panel-container {
        background-color: $white;
        @include border-radius($radius);
        padding: 0.5rem 0.3rem 0.5rem 0;
        box-sizing: border-box;
        height: 100%;
        .ms-Shimmer-container {
            margin-bottom: 0.5rem;
        }
        .list-view-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            margin-top: 0.2rem;
            span {
                margin: 0 0.5rem;
                font-size: $fs-12;
                &.active {
                    color: $primary-color;
                }
            }
            .lv-toggle-wrap {
                display: flex;
                background: #edebe9;
                border-radius: 40px;
                box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.05);
                border: 1px solid #ddd;
                color: $gray-300;
                .lv-toggle {
                    padding: 0.3rem 1.3rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
                .handle-left {
                    border-radius: 40px;
                    background: #fff;
                    box-shadow: 0px 0px 6px #00000014;
                    color: $primary-color;
                    cursor: pointer;
                    animation: toggleLeft 0.2s ease 0s 1 normal forwards;
                }
                .handle-right {
                    border-radius: 40px;
                    background: #fff;
                    box-shadow: 0px 0px 6px #00000014;
                    color: $primary-color;
                    cursor: pointer;
                    animation: toggleRight 0.2s ease 0s 1 normal forwards;
                }
            }
        }
        .list-search {
            margin-bottom: 1rem;
            padding: 0 0.5rem;
            .ms-SearchBox {
                border: 1px solid $gray-600;
                @include border-radius($radius);
                &.is-active::after {
                    @include border-radius($radius);
                }
                .ms-SearchBox-field {
                    &::placeholder {
                        color: $gray-300;
                    }
                }
            }
        }
        .scroll-panel {
            height: 100% !important;
            overflow-y: scroll;
            padding: 0 0.5rem 0 0;
            padding-right: 0.25rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .list-item {
            margin-bottom: 0.8rem;
            @include border-radius($radius);
            border: 1px solid $blue-750;
            padding: 1rem;
            box-sizing: border-box;
            margin-right: 0.3rem;
            cursor: pointer;
            .star-icon {
                font-size: $fs-13;
            }
            .list-title {
                display: flex;
                margin-bottom: 0.3rem;
                span {
                    font-weight: 600;
                    margin-right: auto;
                }
                .link-list-add {
                    font-size: $fs-12;
                    margin-left: auto;
                    text-decoration: underline;
                    color: $blue-200;
                }
                .icon {
                    color: $yellow-100;
                }
            }
            .list-desc {
                .title-field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.25rem;
                    max-height: 2rem;
                    -webkit-line-clamp: 1;
                    /* Write the number of 
                                              lines you want to be 
                                              displayed */
                    -webkit-box-orient: vertical;
                    color: $gray-100;
                }
                span {
                    font-weight: 600;
                }
                &.text-normal {
                    span {
                        font-weight: normal;
                    }
                }
            }
            .list-head {
                margin-top: 0.5rem;
                span {
                    font-size: $fs-12;
                }
            }
            .list-value {
                span {
                    font-size: $fs-14;
                    font-weight: 600;
                }
            }
            &.active {
                background-color: $gray-800;
                border: 1px solid $primary-color;
                border-left: 3px solid $primary-color;
            }
        }
    }
}
