@import '../../../assets/styles/css-assets.scss';

.parent-table-btn-wrap {
    margin-top: 24px;

    button {
        @include border-radius($radius);
        font-size: $fs-14;
        padding: 1.1rem 1.5rem;
        cursor: pointer;
        margin-right: 1rem;
    }

    button.primary {
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: $white;

        &[aria-disabled='true'] {
            border: 1px solid rgba($primary-color, 0.1);
            background-color: rgba($primary-color, 0.5);
        }
    }

    button.default {
        border: 1px solid $gray-300;
        background-color: $white;
        color: $gray-300;
    }
}

.dp-content {
    .associate-option-container {
        display: flex;
        flex-wrap: wrap
    }

    .ao-right {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        align-self: center;
    }
}

.table-content {

    margin-top: $fs-10;
    @include border-radius($radius);
    animation: fadeIn 1s ease-in;

    .team-menu-container {
        @include border-radius($radius);
        overflow: hidden;
    }
    .k-grid-header {
        padding: 0;
        .k-grid-header-wrap {
            border: none;
            table {
                width: 100% !important;
            }
        }
    }
    .k-column-resizer {
        border-right: 1px solid $gray-600;
        height: 2.5rem;
        vertical-align: middle;
        margin-top: 1rem;
    }
    .k-grid-table {
        width: 100% !important;
    }

    .revenue-column-cell {
        text-align: right;
        padding: 0.6rem 1rem;
    }
    .dp-progress-wrap {
        @include border-radius(1.2rem);
        min-width: 2rem;
        min-height: 0.5rem;
        display: inline-flex;
        overflow: hidden;

        .dp-progress {
            display: inline-block;
        }
    }
    .type-a {
        background-color: rgba($color: $green-400, $alpha: 0.5);
        .dp-progress {
            background-color: $green-400;
        }
    }

    .grid-cont {
        .m-t-50 {
            width: 1000px;
        }

        .k-grid {
            border-color: $gray-600;
            max-height: 25rem;
            
            .k-master-row {
                &:hover {
                    background-color: $gray-700 !important;
                    cursor: pointer;
                }
                >td:focus{
                    background-color: inherit;
                }
            }

            .k-state-selected {
                background-color: $blue-800;
                >td{   
                    border-color:#0078d4;
                    border-top-width: 2px;
                    border-left: unset;
                    border-bottom-width: 2px;
                }
                >td:first-child{
                    border-left:2px solid #0078d4;
                }
            }            
        }

        table {
            font-family: $font-family;
            color: $gray-100;
            font-size: $fs-14;
            line-height: $fs-20;
            col.k-sorted,
            th.k-sorted {
                background-color: transparent;
            }
            .assigned-to-cell {
                display: flex;

                .ms-Image {
                    min-width: 25px;
                }
                .assigned-to-display-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            tbody {
                white-space: nowrap !important;
                .cell-in-bold {
                    font-weight: 600;
                }
                .right-aligned-column {
                    text-align: right;
                    padding-right: 30px;
                    box-sizing: border-box;
                    border-right-width: 2px;
                }
            }
            thead {
                .right-aligned-header {
                    text-align: right;
                }

                th {
                    white-space: nowrap !important;
                    background: $white !important;
                    overflow: visible;
                    vertical-align: middle !important;
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                    color: $gray-100;
                    button {
                        padding: 0px;
                        &:hover,
                        &:focus,
                        span {
                            background: $white;
                            color: $gray-100;
                        }
                    }
                    .ms-ContextualMenu-Callout {
                        width: 7.5rem;
                    }
                    .request-type-header {
                        font-weight: 600;
                        font-size: $fs-14;
                    }
                }
            }
            th {
                .k-link {
                    font-weight: 600 !important;
                }
                .ms-Button-flexContainer {
                    span {
                        font-weight: 600 !important;
                    }
                }
            }
        }
        .k-grid {
            table {
                td {
                    line-height: 2rem;
                }
            }
        }
        .grid-cont {
            overflow: hidden;
            overflow-x: auto;
        }
        @include xs {
            table {
                white-space: normal;
                .k-grid th,
                .k-grid td {
                    text-overflow: normal;
                }
            }
        }
        @include sm {
            table {
                white-space: normal !important;
                td {
                    text-overflow: unset !important;
                }
            }
        }

        .k-checkbox:checked {
            border-color: #0078D4;
            color: #0078D4;
            background-color: transparent;
        }
    
        .k-checkbox {
            border-width: 1px;
            border-color: #C7E0F4;
        }
    }
}