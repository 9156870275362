@import '../../../../assets/styles/css-assets';

$resource: 'resource';

.helpful-resources{
    padding: 80px 0;
    background-color: $gray-700;    
}

.#{$resource}{
    display: flex;
    flex-direction: column;
    margin: 20px 0 0;
    min-height: 100px;
    &-modal{
        max-width: 640px;
    }
    .#{$resource} {      
        &__data{
            display: flex;
            flex-direction: row;
        }  
        &__icon{
            width: 32px;
            height: 32px;
            margin-right: 12px; 
            margin-top:45px;
            .ms-Image{
                width: 32px;
            }
            img{
                width: 100%;
                height: auto;
            }
        }
        &__details{
            flex-grow: 1;
            .#{$resource}{
                &__title{
                    
                }
                &__description{
                    padding: 0 8px;
                    .border--none{
                        .ms-TextField-fieldGroup{
                            border: none !important;

                            textarea{
                                padding:0px;
                            }
                        }
                    }
                }
            }

        }
    }
}