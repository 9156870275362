@import '../../../assets/styles/css-assets.scss';

.my-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    h3 {
        font-size: $fs-18;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        .profile {
            min-width: 230px;
            margin-right: 10px;
            @include border-radius($radius);
            background-color: $gray-700;
            padding: 20px;
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: $fs-14;
            gap: 12px;
            flex-grow: 1;
            .profile-pic {
                margin: 20px auto;
                width: 100px;
                img {
                    width: 100px;
                    height: 100px;
                }
            }
        }
        .update-details {
            flex-grow: 20;
            @include border-radius($radius);
            background-color: $gray-700;
            padding: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
        }
        .profile-footer {
            margin-top: auto;
            text-align: right;
        }
    }
    .edit-icon {
        margin-left: 10px;
        cursor: pointer;
        float: right;
        position: absolute;
        font-weight: normal;
        top: 10px;
        right: 10px;
        .ms-Image {
            display: inline-block;
            margin: 0 10px 0 0;
        }
    }
    .social-share {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .social-icon {
            display: inline-flex;
            margin-right: 10px;
            width: 20px;
        }
        .ms-TextField {
            flex-grow: 1;
        }

        a {
            overflow: hidden;
            white-space: nowrap;
            width: 40rem;
            text-overflow: ellipsis;
        }
    }
    .ms-TextField-fieldGroup {
        border-color: $gray-500;
        @include border-radius($radius);
    }
}
