@import '../../../assets/styles/css-assets.scss';

.home-admin-cards-container {
    .home-cards {
        display: flex;
        padding: 0;
        justify-content: center;
        margin-bottom: 50px;
        gap:30px;
        flex-wrap: wrap;
        a {
            text-decoration: none;
            @mixin lt-md {
                margin-right: 50px;
            }
            &:hover {
                text-decoration: none;
                -webkit-transform: scale(1.08);
                -moz-transform: scale(1.08);
                -ms-transform: scale(1.08);
                -o-transform: scale(1.08);
                transform: scale(1.08);
                .home-card {
                    box-shadow: 0px 2px 4px rgba(0, 120, 212, 0.2);
                }
            }
        }
        @include lt-md{
            flex-direction:column;
            gap: 20px;
            a{
                margin:0px;
            }
        }
        .home-card {
            background: $white;
            box-shadow: 0px 1px 2px rgba(0, 120, 212, 0.2);
            @include border-radius(8px);
            min-width: 350px;
            text-align: left;
            min-height: 137px;
            padding: 40px;
            box-sizing: border-box;
            display: flex;
          
            span {
                display: block;
            }
            .home-card-txt {
                .home-card-head {
                    font-weight: 600;
                    font-size: $fs-20;
                    line-height: 27px;
                    color: $primary-color;
                }
                .home-card-desc {
                    font-size: $fs-13;
                    line-height: 17px;
                    color: $gray-200;
                }
            }
            .home-card-icon {
                background-size: 48px 48px;
                display: inline-block;
                content: '';
                width: 48px;
                height: 48px;
                margin-right: 15px;

                &.triage-icon {
                    background: url(app/static/icons/triage_request.svg) no-repeat;
                }
                &.request-icon {
                    background: url(app/static/icons/view_request.svg) no-repeat;
                    width: 45px;
                    height: 45px;
                }
                &.manage-icon {
                    background: url(app/static/icons/manage_content.svg) no-repeat;
                }
                &.admin-training-icon {
                    background: url(app/static/icons/admin_portal_training.svg) no-repeat;
                }
            }
        }
    }
}
