@import '../../../../../../assets/styles/css-assets.scss';

.tags-header {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.625rem;
    min-width:200px;
    background: $gray-800;
    color: $primary-color;
    i {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: $fs-10;
    }
    .ms-Link {
        text-decoration: underline;
        font-size: $fs-12;
    }
}

.spacing-container {
    display: flex;
    .tag-spacing {
        letter-spacing: 1px;
    }
}

.tags-dropdown-cont-lb {
    max-height: 30rem;
    max-width:350px;
    overflow-y: auto;
    position: absolute;
    background: $white;
    left:20;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    @include border-radius($radius);
    z-index: 999999;
    font-size: $fs-14;
    font-weight: normal;
    margin-top: 2.5rem;

    .tags-dropdown-item-lb {
        border-top: 1px solid $gray-600;
        box-sizing: border-box;
        padding: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ms-Persona-details {
            display: none;
        }
        .ms-Persona-coin {
            margin-right: 0.625rem;
        }
        &.selected {
            label {
                font-weight: 600;
                color: $gray-100;
            }
        }
        .ms-Checkbox {
            margin-right: 0.625rem;
        }
        &.child-item {
            padding-left: 30px;
        }
        &.parent-item {
            label {
                font-weight: 600;
            }
        }
    }
}

.tags-picker-cont {
    position: relative;
    padding-left: .5rem;
    height: 38px;
    display: flex;
}

.tags-title-lb {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height:38px;
    font-size: $fs-14;
    color: $primary-color;
    justify-content: space-between;
    margin-right: 10px;
    margin-left:2px;
    margin-bottom: 2px !important;
    min-width: 120px;
    position: relative;

    i {
        font-weight: 600;
        font-size: $fs-10;
        position: absolute;
        right: -10px;
    }
    .tags-label-lb {
        display: inline-block;
        max-width: 6.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .txt-link {
        font-weight: bold;
        font-size: $fs-14;
        line-height: 18px;
        color: $blue-400;
        cursor: pointer;
    }
}

.search-bar-tags-picker {
    padding: 1.25rem 0.625rem;
    .ms-SearchBox i {
        transform: rotate(270deg);
    }
    .tags-cont-lb {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.3125rem;
        .tags-item-lb {
            display: flex;
            align-items: center;
            background: $gray-800;
            margin-right: 0.3125rem;
            padding: 0.125rem 0.3125rem;
            color: $gray-100;
            margin-bottom: 0.3125rem;
            @include border-radius(0.1875rem);
            span {
                font-size: $fs-12;
            }
            i {
                cursor: pointer;
                margin-left: 0.625rem;
                font-size: $fs-10;
            }
        }
    }
    .ms-SearchBox {
        @include border-radius($radius);
        border: 1px solid $blue-700;
        input::placeholder {
            color: $gray-300;
        }
        i {
            font-size: $fs-14;
            color: $gray-300;
        }
        .ms-SearchBox-clearButton {
            i {
                font-size: $fs-14;
            }
        }
        &::after {
            @include border-radius($radius);
        }
    }
}