@import '../../../../assets/styles/variables.scss';
@import '../../../../assets//styles/media-queries.scss';
@import '../../../../assets/styles/mixins.scss';

.fc-content {
    padding: 1.5rem 6rem;
    transition: 0.5s;

    .fc-title {
        font-size: $fs-28;
        text-align: center;
        margin-bottom: 20px;
    }

    .fc-icons-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-bottom: 40px;
        .fc-icon-wrap {
            cursor: pointer;
            margin-right: 28px;
            img {
                height: 50px;
                width: 50px;
            }
            .fc-icon {
                display: block;
                opacity: 1;
                transition: 0.5s;
                &:hover {
                    transform: scale(1.3);
                }
            }
            .fc-icon-desc {
                font-size: $fs-10;
                text-align: center;
                margin-top: 6px;
            }
        }
    }

    .fc-category {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        display: flex;
        button {
            background-color: $white;
            @include border-radius($radius);
            white-space: nowrap;
            border: 1px solid $gray-240;
            padding: 1.5rem 2rem;
            margin-right: 30px;
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 8px;
            }
        }
        .active {
            background: linear-gradient(260.63deg, $primary-color 5.91%, #3e9fe9 46.33%, $primary-color 94.09%);
            color: $white;
        }
    }

    .fc-textfield-wrap {
        margin-top: 1rem;
        textarea {
            border: 1px solid $gray-240;
            @include border-radius($radius);
            font-size: $fs-14;
            padding: 1rem;
            width: 100%;

            &::placeholder {
                color: $gray-240;
            }
        }
    }

    .fc-form-btn-wrap {
        margin-top: 24px;
        button {
            @include border-radius($radius);
            font-size: $fs-14;
            padding: 1.2rem 3rem;
            cursor: pointer;
            margin-right: 20px;
        }
        button.primary {
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: $white;
        }
        button.default {
            border: 1px solid $gray-240;
            background-color: $white;
            color: $gray-200;
        }
    }

    @include sm {
        padding: 1.5rem 2rem;

        .fc-icon-wrap {
            margin-bottom: 1rem;
        }

        .fc-category {
            button {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        .fc-form-btn-wrap {
            display: flex;
            flex-wrap: wrap;
            button {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }

    @include xs {
        padding: 1.5rem 2rem;

        .fc-icons-container {
            display: flex;
            justify-content: center;
        }

        .fc-icon-wrap {
            margin-bottom: 1rem;
            margin: 0 0.5rem 1rem;
        }

        .fc-category {
            button {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        .fc-form-btn-wrap {
            display: flex;
            flex-wrap: wrap;
            button {
                width: 100%;
                margin: 0;
                margin-bottom: 1rem;
            }
        }
    }
}

.ms-Dialog-main {
    @include border-radius($radius);

    .ms-Button {
        @include border-radius($radius);
    }
}
