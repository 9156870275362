@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.unified-dashboard-container {
    color: $gray-100;
    animation: fadeIn 1s ease-in;

    .unified-dashboard-filter {
        border: 1px solid $gray-600;
        box-sizing: border-box;
        @include border-radius($radius);
        padding: 0.2rem 1rem;
        color: $gray-200;
        .placeholder-label {
            max-width: none;
            width: 9rem;
        }
    }
    .cards-container {
        .menu-card {
            width: 23%;
            &.active {
                img {
                    filter: invert(100%) saturate(0%) hue-rotate(201deg) brightness(200%) contrast(106%);
                }
            }

            &:first-child {
                .label {
                    display: block;
                    font: $fs-14;
                    word-wrap: break-word;
                    max-width: 4rem;
                }
            }
        }
    }

    .details-container {
        @include border-radius($radius);
        margin-top: 1.5rem;
        margin-bottom: 4rem;

        .filter-panel {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $blue-800;
            border-top: 1px solid $blue-800;
            padding: 0.8rem 0rem;
            box-sizing: border-box;

            .filter-links {
                margin-right: auto;
                button {
                    color: $gray-200;
                    margin-right: 1.5rem;
                    @include border-radius(0);
                    padding-bottom: 3px;
                    border-bottom: 2px solid transparent;

                    &.active {
                        color: $primary-color;
                        border-bottom: 2px solid $primary-color;
                        text-decoration: none;
                    }

                    &.active:hover {
                        text-decoration: none;
                        border-bottom: 2px solid $primary-color;
                    }

                    &:hover {
                        text-decoration: none;
                        border-bottom: 2px solid $primary-color;
                    }
                }
            }

            .filter-controls {
                display: flex;
                margin-left: auto;
                align-items: center;

                .icon {
                    margin-right: 0.5rem;
                }

                & > span {
                    margin-right: 0.5rem;
                }

                .ms-Dropdown {
                    &::after {
                        @include border-radius($radius);
                    }
                }

                .ms-Dropdown-title {
                    border: 1px solid $gray-600;
                    @include border-radius($radius);
                    width: 250px;
                }
            }
        }

        .ud-contents-wrap {
            display: flex;
            flex-wrap: nowrap;
            min-height: 50rem;
            padding: 0.5rem 0rem;
            box-sizing: border-box;

            .grid-col-left {
                padding-right: 0.3rem;
                box-sizing: border-box;
                width: 30%;
                min-width: 30%;
                min-height: 50rem;
            }
            .grid-col-right {
                box-sizing: border-box;
                padding-left: 0.3rem;
                width: 70%;
                min-width: 70%;
                height: fit-content;
                min-height: 50rem;
            }
        }

        .pagination-wrapper {
            margin-top: 1rem;
            .paginate-cont {
                justify-content: center;
            }
            width: 100%;
        }

        td {
            font-weight: normal;
            font-size: $fs-12;
        }
    }
}
#dropdown-callout-id-list {
    padding: 0.3125rem;
    .ms-Checkbox {
        &.is-checked {
            background-color: $white;
            color: $primary-color;
            span {
                color: $primary-color;
            }
            &:hover {
                span {
                    color: $black;
                }
            }
        }
    }

    .ms-Dropdown-item {
        input {
            &:focus {
                outline: none !important;

                & ~ .ms-Checkbox-label {
                    outline: 1px solid black;
                    &::before {
                        outline: none !important;
                    }
                }
            }
        }
    }
}
