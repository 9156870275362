@import 'app/assets/styles/css-assets';
.top-strip {
    padding: 0.5rem 0;
    @include border-radius(4px);
    background: #fef8eae6;
    position: relative;
    top: 8px;
    margin: 0 10px 10px;
    min-height: 32px;
    .btn-rht {
        border: 2px solid $primary-color;
        box-sizing: border-box;
        @include border-radius(5px);
        padding: 6px 12px;
        background: $white;
        color: $primary-color;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        &:hover {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.1);
        }
        &:focus {
            outline: none;
        }
    }
    .text-right {
        text-align: right;
    }
}

.btn-right-animation {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;
}

.btn-right-animation:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary-color;
    @include border-radius($radius);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
}

.btn-right-animation:hover:before,
.btn-right-animation:focus:before,
.btn-right-animation:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.top-article,
.strip-txt {
    display: flex;
    align-items: center;
    label {
        width: 100%;
    }
    .content {
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(85% - 15px);
        text-overflow: ellipsis;
        display: inline-block;
        p {
            margin: 0;
        }
    }
    .title {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        max-width: 15%;
        margin-right: 10px;
        text-overflow: ellipsis;
        display: inline-block;
    }
}

.strip-txt {
    flex-direction: column;
    .view-all-link {
        font-size: $fs-12;
        color: $gray-100;
        letter-spacing: 0.3px;
    }
}