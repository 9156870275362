@import 'app/assets/styles/css-assets';

.search-list {
    margin-bottom: 2.5rem;

    .badge-pill.badge-primary {
        font-size: $fs-12;
        line-height: 16px;
        border-radius: 18px;
        margin-bottom: 5px;
        padding: 2px 10px;
        text-decoration: none;
        border: 1px solid $yellow-400;
        background: $yellow-400;
        cursor: initial;

        &.badge-article {
            color: $gray-100;
            background: rgba($green-300, 0.2);
            border-color: rgba($green-300, 0.2);
        }

        &.badge-help {
            background-color: rgba($yellow-400, 0.2);
            color: $gray-100;
        }
    }

    .results-list {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
                opacity: 0.5;
            }
        }
        h3 {
            font-size: $fs-18;
            line-height: 36px;
            color: $gray-100;
            margin: 0;
            font-weight: 400;

            &:hover {
                text-decoration: none;
                opacity: 0.5;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 1.75rem;
            max-height: 4rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-top: 0.5rem;
        }
        .results-description {
            font-size: $fs-14;
            line-height: 20px;
            margin: 0 0 0px;
            color: $gray-200;
            width: 90%;
            text-align: justify;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          
           
         
        }
    }

    .results-social {
        font-size: $fs-12;
        line-height: 16px;
        color: $black;
        &.row-flex-box {
            display: flex;
        }
        .results-social-list{
            display: inline;
        }
        .results-type {
            background: $gray-800;
            border: 1px solid #deecf9;
            box-sizing: border-box;
            border-radius: 18px;
           
            margin-right: 21px;
            padding: 3px 10px 6px;

            .article-icon {
                background: url(app/static/icons/article-icon.svg) no-repeat;
                display: inline-block;
                width: 13px;
                height: 13px;
                position: relative;
                margin-right: 5px;
                top: 3px;
                background-size: 13px 13px;
            }
        }

        .text-article {
            position: relative;
            top: 5px;
            display: inline-block;
            margin: 10px 0;

            .rating-icon {
                background: url(app/static/icons/star-active-icon.svg) no-repeat;
                display: inline-block;
                height: 22px;
                width: 22px;
                position: relative;
                top: 4px;
                margin: 0 2px;
                right: 0;
            }
        }
        .results-date {
            top: 2px;
            position: relative;
            font-size: $fs-10;
            line-height: 12px;
            color: $gray-200;
            margin-right: 15px;
        }
    }
}
