@import '../../../assets/styles/css-assets.scss';

.search-box-top {
    position: relative;

    .voice-icon {
        position: absolute;
        z-index: 100;
        top: 19px;
        right: 22px;
        width: 14px;
        cursor: pointer;
    }
    .search-input {
        height: 34px;
        background-image: url(../../../static/icons/search-icon.svg);
        background-position: 19.37px 7.38px;
        background-repeat: no-repeat;
        z-index: 20;
        position: relative;
        margin: 10px 0px;
        padding: 5px 45px;
        line-height: $fs-20;
        border: none;
        box-sizing: border-box;
        @include border-radius(36px);
        cursor: pointer;
        font-weight: 300;
        font-size: $fs-14;
        font-family: $font-family;
        line-height: 20px;
    }

    .search-input:focus {
        outline: none;
    }

    .search-animation {
        border-color: $blue-400;
        animation: inputGrow 1s ease 0s forwards;
    }

    .search-shrink-animation {
        animation: inputShrink 1s ease 0s forwards;
    }

    .search-input-grown {
        width: 420px;
        height: 34px;
        background-image: url(../../../static/icons/search-icon.svg);
        background-position: 19.37px 7.38px;
        background-repeat: no-repeat;
        z-index: 20;
        position: relative;
        margin: 10px 0px;
        padding: 5px 45px;
        line-height: $fs-20;
        box-sizing: border-box;
        @include border-radius(36px);
        cursor: default;
    }

    .search-input-grown:focus {
        outline: none;

        &.show-suggestions {
            border-bottom: none !important;
            border-radius: 16px 16px 0px 0px !important;
        }
    }

    @keyframes inputGrow {
        from {
            width: 150px;
        }
        to {
            width: 420px;
        }
    }

    @keyframes inputShrink {
        from {
            width: 420px;
        }
        to {
            width: 150px;
        }
    }

    .search-input,
    .search-input:focus {
        border: 1px solid $blue-400;

    }

    .search-input-focus {
        outline: none;
        box-sizing: border-box;
        background-color: $white;
        width: 420px;
    }

    .search-box-suggestions {
        display: none;

        hr {
            display: none;
        }

        p {
            padding-right: 0.5rem;
            a {
                color: $black;
                text-decoration: none;
                font-size: $fs-14;
            }
        }
    }

    .search-box-seperator {
        height: 1px;
        background-color: $blue-400;
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .search-box-suggestions li {
        list-style: none;
        font-style: normal;
        font-weight: 300;
        font-size: $fs-12;
        line-height: $fs-20;
        color: $gray-100;
        margin: 0px;
        margin-top: 10px;
        cursor: pointer;
    }
}

.recording-position {
    position: absolute;
    z-index: 100;
    top: 19px;
    right: 22px;
    width: 14px;
}

.recording-row {
    display: flex;
    flex-direction: row;

    .first-line {
        height: 5px;
        width: 2px;
        background-color: $primary-color;
        margin-top: 7.5px;
        @include border-radius(4px);
        animation: voiceGrow-1 1s ease 0s infinite;
    }

    .second-line {
        height: 12px;
        width: 2px;
        background-color: $primary-color;
        margin-left: 1.5px;
        margin-top: 4px;
        @include border-radius(4px);
        animation: voiceGrow-2 1s ease 0s infinite;
    }

    .third-line {
        height: 20px;
        width: 2px;
        background-color: $primary-color;
        margin-left: 1.5px;
        @include border-radius(4px);
        animation: voiceGrow-3 1s ease 0s infinite;
    }

    .fourth-line {
        height: 12px;
        width: 2px;
        background-color: $primary-color;
        margin-left: 1.5px;
        margin-top: 4px;
        @include border-radius(4px);
        animation: voiceGrow-2 1s ease 0s infinite;
    }

    .fifth-line {
        height: 5px;
        width: 2px;
        background-color: $primary-color;
        margin-top: 7.5px;
        margin-left: 1.5px;
        @include border-radius(4px);
        animation: voiceGrow-1 1s ease 0s infinite;
    }
}

@keyframes voiceGrow-1 {
    0% {
        height: 5px;
        margin-top: 7.5px;
    }

    25% {
        height: 12px;
        margin-top: 4px;
    }

    50% {
        height: 20px;
        margin-top: 0px;
    }

    75% {
        height: 12px;
        margin-top: 4px;
    }

    100% {
        height: 5px;
        margin-top: 7.5px;
    }
}

@keyframes voiceGrow-2 {
    0% {
        height: 12px;
        margin-top: 4px;
    }

    25% {
        height: 5px;
        margin-top: 7.5px;
    }

    50% {
        height: 12px;
        margin-top: 4px;
    }

    75% {
        height: 20px;
        margin-top: 0px;
    }

    100% {
        height: 12px;
        margin-top: 4px;
    }
}

@keyframes voiceGrow-3 {
    0% {
        height: 20px;
        margin-top: 0px;
    }

    25% {
        height: 12px;
        margin-top: 4px;
    }

    50% {
        height: 5px;
        margin-top: 7.5px;
    }

    75% {
        height: 12px;
        margin-top: 4px;
    }

    100% {
        height: 20px;
        margin-top: 0px;
    }
}

.suggestions {
    background: $white;
    border: 0.3px solid $blue-400;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 0px 4px 11px rgba(0, 120, 212, 0.17);
    @include border-radius(16px);
    margin-top: -40px;
    min-height: 200px;
    width: 420px;
    position: absolute;
    z-index: 10;
    padding-top: 20px;
    ul{
        padding:0 40px;
        li {
            list-style: none;
            padding:10px 0;
            
        }
    }

    hr {
        border-top: 1px solid $gray-200;
        margin: $fs-20 $fs-12 0 $fs-12;
    }

    p {
        padding-right: 0.5rem;
        a {
            color: $black;
            text-decoration: none;
            font-size: $fs-14;
        }
    }
}
