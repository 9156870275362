@import '../../../assets/styles/css-assets.scss';


.my-account{
    .sidebar{
        display: flex;
        flex-direction: column;
        width:230px;
        background-color: $white;
        margin-right: 20px;
        @include border-radius($radius);
        padding:10px;
        h2{
            margin: 0px 0 20px;
            padding:10px 20px 10px;
            border-bottom: 1px solid $gray-600;
            font-size: $fs-22;
        }
        ul{
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            li{
                padding: 15px 20px;
                list-style-type: none;
                @include border-radius($radius);
                margin-bottom: 1px;     
                &:hover, &.active{
                    background-color: $primary-color;
                    color: $white;
                    cursor: pointer;
                }           
            }
        }
    }
}