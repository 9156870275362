@import 'app/assets/styles/css-assets';

.addImpact-container {
    .error-msg {
        position: relative;
        z-index: 99;
        margin-top: 1rem;
    }
    .event-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    
    .cancel-update-container {
        display: flex;
        margin-right: 50px;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 18px;
    }
    .event-section-no-back-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    .cancel-btn {
        min-width: 15%;
        color: #8a8886;
        font-size: 0.75rem;
        cursor: pointer;
        margin-right: 20px;
        margin-left: auto;
        margin-top: 8px;
    }

    .add-cust-btn {
        min-width: 40%;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: $white;
        @include border-radius($radius);
        font-size: $fs-12;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        margin-right: 15px;
    }

    .cart-section {
        .added-device-icon {
            img {
                max-width: 40px;
            }
        }
        width: 287px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        animation: slideRightToLeft 0.5s ease forwards;
        border-left: 4px solid #eff6fc;
        margin-left: -20px;
        padding-left: 20px;
        padding-bottom: 10px;
        margin-top: 30px;
        margin-bottom: 20px;

        &.animateToRight {
            animation: animateToRight 0.5s ease forwards;
        }

        h2 {
            font-size: $fs-18;
            font-weight: 400;
            border-bottom: 1px solid #dcdbdb;
            line-height: 35px;
            color: #323130;
            margin: 0;
            height: 40px;
        }

        h4 {
            font-size: $fs-14;
        }

        .item {
            display: block;
            border-bottom: 1px solid #dcdbdb;
            padding: 15px 25px;

            h4 {
                margin: 15px 0;
                font-size: $fs-14;
                font-weight: 600;
            }

            .added-device-details {
                display: flex;
                align-items: center;
                width: 100%;
                img {
                    max-width: 40px;
                }

                .text-field {
                    flex-grow: 1;
                }

                .trash-icon {
                    min-width: 30px;
                    cursor: pointer;
                }
            }
        }

        &.cart-preview {
            background: $white;
            .more-link {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: $blue-400;
                margin-top: 8px;

                cursor: pointer;
                text-decoration: underline;
            }
            .added-devices-container {
                .item:first-child {
                    padding-top: 0;
                }
            }
        }
    }
    .cart-items-list {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 10px;
        margin-right: 5px;

        &::-webkit-scrollbar {
            width: 0.3rem;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba($color: $gray-300, $alpha: 0.4);
            @include border-radius(4px);
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba($color: $gray-300, $alpha: 0.6);
        }
        &::-webkit-scrollbar-track {
            @include border-radius(0);
        }
    }

    .modal-cart-heading {
        font-size: $fs-20;
        text-align: center;
        margin-bottom: 5px;
    }

    .device-title {
        font-size: $fs-20 !important;
        line-height: 26px !important;
        font-weight: normal !important;
        color: $gray-100 !important;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .only-product-list {
        padding-left: 100px !important;
        padding-right: 100px !important;
        .product-list-header {
            font-size: $fs-20;
            margin-bottom: 20px;
            margin-left: 15px;
        }
    }

    .selected-product-list {
        width: 70%;
        padding-left: 50px !important;
        padding-right: 50px !important;
        .product-list-header {
            font-size: $fs-20;
            margin-bottom: 20px;
            margin-left: 15px;
        }
    }

    .products-list-container {
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;
        min-height: 170px;
        display: flex;
        flex-direction: row;
        .left,
        .right {
            margin-top: 60px;
            cursor: pointer;
        }

        .right {
            margin-left: 20px;
            cursor: pointer;
        }

        .left {
            cursor: pointer;
            margin-right: 20px;
        }
    }

    .custom-product-list {
        display: flex;
        flex-direction: row;
    }

    .select-model {
        position: relative;

        .model-list-container {
            position: relative;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            .model-list {
                z-index: 1;
                padding-bottom: 20px;
                width: 100%;
                overflow-x: auto;
            }
            .left,
            .right {
                margin-top: 60px;
                cursor: pointer;
            }

            .right {
                margin-left: 20px;
                cursor: pointer;
            }

            .left {
                margin-right: 20px;
                cursor: pointer;
            }
        }

        .model-list-container::-webkit-scrollbar {
            visibility: hidden;
        }
        .model-list-container::-webkit-scrollbar-track {
            visibility: hidden;
        }
        .model-list-container::-webkit-scrollbar-thumb {
            visibility: hidden;
        }
    }

    .slide-indicator-container {
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: -40px;
        div {
            z-index: 9;
            position: relative;
            cursor: pointer;
            margin: 2px;
        }
    }

    .shift-icons {
        justify-content: left;
        display: flex;
        max-width: 100%;
        position: relative;
        flex-direction: row;
        .model-name {
            height: 3.75rem;
            max-width: 6.25rem;
        }
        .model-field {
            .ms-TextField-fieldGroup {
                @include border-radius($fs-18);
            }
        }
        .sm-txt {
            margin-top: 15px;
            display: inline-block;
        }

        .btn-add-product-modal {
            display: inline-block;
            width: 100%;
            padding-bottom: 5px;
            padding-top: 4px;
            margin-top: 4px;
        }

        .ms-TextField-wrapper {
            .ms-TextField-fieldGroup {
                width: 86px !important;
                min-width: 86px !important;
                margin: 20px auto 0;
                border: 1px solid #cdcdcd;
                box-sizing: border-box;
                height: 27px !important;
                padding: 0;

                input {
                    padding-left: 8px;
                }
            }
        }
    }

    .back-btn {
        width: 200px;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .hide {
        display: none;
    }

    .mar-rt {
        display: flex;
        flex-direction: column;
        padding: 40px 20px 20px 20px;
        box-sizing: border-box;
        @include border-radius($radius);
        text-align: center;
        position: relative;
        font-size: $fs-13;
        cursor: pointer;
        position: relative;
        min-width: 7.5rem;
        margin: 0 1.125rem;
        background-color: rgba($white, 0.3);

        @include layout-max {
            width: 148px;
            min-width: 148px;
        }
        .quantity-field {
            input {
                text-align: center;
                &::placeholder {
                    font-size: $fs-10;
                }
            }
        }
        .quantity-field ::-webkit-outer-spin-button,
        .quantity-field ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        img {
            margin: 0 auto;
            height: 38px;
            width: auto;
            max-width: 57px;
        }

        .show {
            display: block;
        }

        .hide {
            display: none;
        }

        &.selected-product {
            box-shadow: 0px 4px 10px rgba(0, 120, 212, 0.5);
            background: $white;
            font-weight: 600;
            color: $primary-color;
            overflow: hidden;
            @include layout-max {
                width: 148px;
                min-width: 148px;
            }

            span {
                font-weight: 600;
            }
            &::before {
                content: '';
                position: absolute;
                width: 45px;
                height: 65px;
                background-color: #0178d4;
                transform: rotate(45deg);
                animation: selectedProduct 0.5s ease forwards;
            }

            @keyframes selectedProduct {
                0% {
                    left: -40px;
                    top: -50px;
                }

                100% {
                    left: -20px;
                    top: -30px;
                }
            }

            &::after {
                background: url(app/static/icons/tick-icon.svg) no-repeat;
                content: '';
                position: absolute;
                left: 6px;
                top: 7px;
                width: 42px;
                height: 42px;
                transform: scale(1);
                animation: tickMark 0.5s ease 0.3s forwards;
            }

            @keyframes tickMark {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
            &.added {
                border-color: $green-100;
                box-shadow: 0px 4px 10px rgba(16, 124, 16, 0.3);
                &::before {
                    background-color: $green-100;
                }
            }
        }

        .css-40 {
            margin-top: 20px;
        }

        .surface-icon {
            background: url(app/static/icons/surface-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 28px;
            margin-bottom: 21px;
            position: absolute;
            top: 30%;
        }

        .surfaceBook-icon {
            background: url(app/static/icons/surfaceBook-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 28px;
            margin: 0 auto 13px;
            position: absolute;
            right: 30%;
            top: 30%;
        }

        .surfacePro-icon {
            background: url(app/static/icons/surfacePro-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 31px;
            margin: 0 auto 18px;
            position: absolute;
            right: 25%;
            top: 28%;
        }

        .surfaceStudio-icon {
            background: url(app/static/icons/surfaceStudio-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 36px;
            margin: 0 auto 13px;
            position: absolute;
            left: 30%;
            top: 28%;
        }

        .surfaceHub-icon {
            background: url(app/static/icons/surfaceHub-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 36px;
            margin: 0 auto 13px;
            position: absolute;
            top: 23%;
            left: 25%;
        }

        .accessories-icon {
            background: url(app/static/icons/accessories-icon.svg) no-repeat;
            display: inline-block;
            width: 53px;
            height: 38px;
            margin: 0 auto 13px;
            position: absolute;
            top: 24%;
            left: 33%;
        }
    }

    .products-list {
        overflow-x: scroll;
        cursor: pointer;
        display: flex;
        margin-bottom: 30px;
    }

    .products-list,
    .select-model {
        margin-left: 15px;
        z-index: 1;
        .device-animate {
            &:hover {
                span {
                    animation: productTextHover 0.5s ease forwards;
                }
            }
            &.add-align-prop {
                align-self: flex-start;
            }
        }
        .mar-rt {
            .ms-Image {
                animation: productHoverOut 0.5s ease forwards;
                img {
                    max-width: 43px;
                    max-height: 36px;
                }
            }

            &:hover {
                .ms-Image {
                    animation: productHover 0.5s ease forwards;
                }
            }

            &.selected-product {
                .ms-Image {
                    animation: none;
                }
            }
        }

        .selected-product {
            .ms-Link,
            .added-text {
                font-size: $fs-14;
                line-height: 22px;
                text-decoration: underline;
                font-weight: 600;
               margin: 10px auto 0;
            }

          .added-text {
                 text-decoration: none;
            }
        }
    }

    .products-list::-webkit-scrollbar,
    .model-list::-webkit-scrollbar {
        height: 6px;
    }

    .products-list::-webkit-scrollbar-thumb,
    .model-list::-webkit-scrollbar-thumb {
        background: #c8c6c4;
        width: 10px;
        @include border-radius($radius);
    }

    .products-list::-webkit-scrollbar-track,
    .model-list::-webkit-scrollbar-track {
        margin-left: 25px;
        margin-right: 8px;
        background: #c8c6c4;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        background-clip: padding-box;
    }

    .row-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include lt-md {
            flex-wrap: wrap;
        }
    }

    .arrow-container {
        position: relative;
        margin-top: -27px;
        display: flex;
        z-index: -1;

        .right-arrow {
            margin-left: auto;
            margin-right: -28px;
            margin-top: -3px;
        }
    }

    .scroll-left-arrow {
        position: absolute;
        margin-left: 6px;
        margin-top: 146px;
        cursor: pointer;
    }

    .scroll-right-arrow {
        position: absolute;
        margin-left: calc(100% - 287px);
        margin-top: -29px;
        cursor: pointer;
    }

    .scroll-left-arrow-active {
        position: absolute;
        margin-left: -13px;
        margin-top: 130px;
        z-index: 100;
        cursor: pointer;
    }

    .scroll-right-arrow-disabled {
        position: absolute;
        margin-left: calc(100% - 266px);
        margin-top: -13px;
        cursor: pointer;
    }
    .scroll-right-arrow-model {
        right: -14px;
        bottom: -24px;
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }
    .scroll-left-arrow-model {
        bottom: -11px;
        left: 6px;
        position: absolute;
        cursor: pointer;
    }
    .scroll-right-arrow-model-disabled {
        bottom: -11px;
        right: 0px;
        z-index: 100;
        position: absolute;
        cursor: pointer;
    }

    .scroll-left-arrow-model-active {
        bottom: -25px;
        left: -12px;
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }
}

@keyframes nextSlideAnimation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-885px);
    }
}

@keyframes previousSlideAnimation {
    0% {
        transform: translateX(-885px);
    }
    100% {
        transform: translateX(0);
    }
}

.bg-white {
    background: white !important;
}
