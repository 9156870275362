@import 'app/assets/styles/css-assets';

.search-container {
    width: 780px;
    margin: 80px auto 0;
    position: relative;
    transition: width 1s;
    max-width: 100%;
    &.search-width {
        width: 100%;
    }
    .col-flex-box {
        display: flex;
        flex-direction: column;
    }
    .search-box {
        &.suggest-show {
            position: relative;
            top: 0;
            z-index: 999;
            width: calc(100% - 2px);
            margin: 0 auto;
            display: inline;
            .ms-TextField-wrapper {
                box-shadow: none;
                .ms-TextField-fieldGroup {
                    box-shadow: none;
                    border-radius: 8px 8px 0 0;
                    border-bottom: 1px solid #e0e0e0;
                }
            }
            .search-input {
                box-shadow: none;
            }
            .ms-TextField-fieldGroup {
                &::after {
                    border: none;
                }
            }
        }
        .auto-suggestion-container {
            position: relative;
            background: #fff;
            width: auto;
            border-radius: 8px;
            padding: 0 15px;
            display: none;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.1);
            width: 600px;

            &.show-auto-suggest {
                display: inline-block;
                z-index: 999;
            }
            ul {
                padding-left: 8px;
                list-style: none;
                li {
                    margin: 5px 0;
                    cursor: pointer;
                }
            }
        }
    }

    .search-typeahead {
        position: relative;
        z-index: 999;
        .placeholder-search-icon {
            font-size: 25px;
            position: relative;
            bottom: 45px;
            left: 30px;
            color: $gray-100;
            font-weight: normal;
        }
        .auto-suggest-text {
            font-size: 1.25rem;
            line-height: 28px;
            color: $gray-300;
            position: relative;
            top: -47px;
            left: 24px;
            display: inline-block;
        }
        .micro-icon {
            background: url(app/static/icons/microVoice.svg) no-repeat;
            background-size: 17px 24px;
            width: 17px;
            height: 24px;
            display: inline-block;
            top: 20px;
            right: 24px;
            position: absolute;
            cursor: pointer;
            z-index: 3;
        }

        .recording-position {
            top: 20px;
            right: 24px;
            position: absolute;
            cursor: pointer;
        }

        .recording-row {
            display: flex;
            flex-direction: row;

            .first-line {
                height: 5px;
                width: 2px;
                background-color: $primary-color;
                margin-top: 7.5px;
                border-radius: 4px;
                animation: voiceGrow-1 1s ease 0s infinite;
            }

            .second-line {
                height: 12px;
                width: 2px;
                background-color: $primary-color;
                margin-left: 1.5px;
                margin-top: 4px;
                border-radius: 4px;
                animation: voiceGrow-2 1s ease 0s infinite;
            }

            .third-line {
                height: 20px;
                width: 2px;
                background-color: $primary-color;
                margin-left: 1.5px;
                border-radius: 4px;
                animation: voiceGrow-3 1s ease 0s infinite;
            }

            .fourth-line {
                height: 12px;
                width: 2px;
                background-color: $primary-color;
                margin-left: 1.5px;
                margin-top: 4px;
                border-radius: 4px;
                animation: voiceGrow-2 1s ease 0s infinite;
            }

            .fifth-line {
                height: 5px;
                width: 2px;
                background-color: $primary-color;
                margin-top: 7.5px;
                margin-left: 1.5px;
                border-radius: 4px;
                animation: voiceGrow-1 1s ease 0s infinite;
            }
        }
    }

    .search-input {
        font-size: $fs-22;
        line-height: 28px;
        color: $gray-100;
        background: $white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: $radius;
        background: transparent;

        .ms-TextField-fieldGroup {
            border: 0;
            height: auto;
            border-radius: $radius;

            .ms-TextField-field {
                padding: 18px 24px;
                font-size: $fs-20;
                line-height: 28px;
                color: $gray-100;
                z-index: 2;
                &::placeholder {
                    color: $gray-300;
                    font-size: 22px;
                }
            }

            i {
                bottom: 18px;
                right: 10px;
                font-size: $fs-20;
                line-height: 18px;
                color: $primary-color;
            }
        }

        &.search-input-icon {
            .ms-TextField-fieldGroup {
                .ms-TextField-field {
                    padding: 18px 24px 18px 70px;
                }
            }
        }
    }
}

.search-results-suggestion {
    background: $white;
    width: 100%;
    padding: 24px;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px $gray-200, 0px 2px 8px $gray-200;
    border-radius: 8px;
    position: absolute;
    top: -1px;
    z-index: 99;
    padding-top: 80px;
    height: 500px;
    overflow-y: auto;

    .grid-box {
        display: flex !important;

        .grid-left {
            flex: 3;
            .request-container {
                width: 90%;
                margin-top: 10px;
            }
        }

        .grid-right {
            flex: 2;
            padding-top: 8px;
        }
    }

    .chips-container {
        list-style: none;
        padding: 0;
        background: transparent;
        margin-bottom: 0px;
        margin-top: 5px;
        .chip-item {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 1.25rem;
            max-height: 1.2rem;
            -webkit-line-clamp: 1; /* Write the number of 
                              lines you want to be 
                              displayed */
            -webkit-box-orient: vertical;
        }
        li {
            display: inline-block;
            margin: 0px 5px 15px 0;

            span {
                color: $white;
                font-size: $fs-12;
                line-height: 16px;
                background: $primary-color;
                border-radius: 16px;
                padding: 6px 10px;
                border: 1px solid $blue-700;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    h6 {
        font-size: $fs-14;
        line-height: 20px;
        margin: 26px 0 10px 0;
    }
    .web-links {
        list-style: none;
        padding: 0;
        a {
            color: $blue-400;
            display: inline-block;
            font-size: $fs-14;
            line-height: 19px;
            margin-bottom: 4px;
            text-decoration: underline;
        }
    }

    .request-container {
        background: $gray-700;
        border-radius: 8px;
        padding: 24px 24px 16px;
        margin-bottom: 18px;
        h6 {
            font-size: 14px;
            line-height: 19px;
            margin: 0 0 18px;
            text-transform: uppercase;
        }
        .request-links-container {
            margin-bottom: 14px;
            .webSearch-snippet {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                line-height: 1.25rem;
                max-height: 2.5rem;
                -webkit-line-clamp: 2; /* Write the number of 
                                          lines you want to be 
                                          displayed */
                -webkit-box-orient: vertical;
                font-size: $fs-14;
                letter-spacing: 0.03125rem;
                color: $gray-100;
                word-break: break-all;
            }
            .request-links {
                margin-bottom: 0px;
                a {
                    color: $primary-color;
                    font-size: $fs-16;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    text-decoration: none;
                    word-wrap: break-word;
                    max-width: 600px;
                    display: inline-block;
                }

                .icon {
                    width: 30px;
                    height: 22px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-position: left top;
                    position: relative;
                    top: 4px;
                    &.icon {
                        &--png {
                            background: url(app/static/icons/png.svg) no-repeat;
                        }
                        &--jpg {
                            background: url(app/static/icons/jpg.svg) no-repeat;
                        }
                        &--ppt {
                            background: url(app/static/icons/ppt.svg) no-repeat;
                        }
                        &--doc {
                            background: url(app/static/icons/doc.svg) no-repeat;
                        }
                        &--mp3 {
                            background: url(app/static/icons/mp3.svg) no-repeat;
                        }
                        &--pdf {
                            background: url(app/static/icons/pdf.svg) no-repeat;
                        }
                        &--xls {
                            background: url(app/static/icons/xls.svg) no-repeat;
                        }
                        &--avi {
                            background: url(app/static/icons/avi.svg) no-repeat;
                        }
                        &--txt {
                            background: url(app/static/icons/txt-file-icon.svg) no-repeat;
                        }
                        &--xml {
                            background: url(app/static/icons/xml.svg) no-repeat;
                        }
                        &--rar {
                            background: url(app/static/icons/rar.svg) no-repeat;
                        }
                        &--html {
                            background: url(app/static/icons/html.svg) no-repeat;
                        }
                        &--zip {
                            background: url(app/static/icons/zip.svg) no-repeat;
                        }
                        &--gif {
                            background: url(app/static/icons/gif.svg) no-repeat;
                        }
                        &--css {
                            background: url(app/static/icons/css.svg) no-repeat;
                        }
                        &--mp4 {
                            background: url(app/static/icons/mp4.svg) no-repeat;
                        }
                        &--default {
                            background: url(app/static/icons/default.svg) no-repeat;
                        }
                    }
                }
            }
            .des-text {
                letter-spacing: 0.5px;
                font-size: $fs-12;
                margin: 0;
                display: block;
                word-break: break-all;
            }
        }
    }
}

@keyframes voiceGrow-1 {
    0% {
        height: 5px;
        margin-top: 7.5px;
    }

    25% {
        height: 12px;
        margin-top: 4px;
    }

    50% {
        height: 20px;
        margin-top: 0px;
    }

    75% {
        height: 12px;
        margin-top: 4px;
    }

    100% {
        height: 5px;
        margin-top: 7.5px;
    }
}

@keyframes voiceGrow-2 {
    0% {
        height: 12px;
        margin-top: 4px;
    }

    25% {
        height: 5px;
        margin-top: 7.5px;
    }

    50% {
        height: 12px;
        margin-top: 4px;
    }

    75% {
        height: 20px;
        margin-top: 0px;
    }

    100% {
        height: 12px;
        margin-top: 4px;
    }
}

@keyframes voiceGrow-3 {
    0% {
        height: 20px;
        margin-top: 0px;
    }

    25% {
        height: 12px;
        margin-top: 4px;
    }

    50% {
        height: 5px;
        margin-top: 7.5px;
    }

    75% {
        height: 12px;
        margin-top: 4px;
    }

    100% {
        height: 20px;
        margin-top: 0px;
    }
}
