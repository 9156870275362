@import 'app/assets/styles/css-assets';

.sub-header {
    padding: 24px 0;
    width: calc(100% - 8px);
    @include border-radius($radius);
    margin: auto;

    &--sc {
        padding-bottom: 0px;
        padding: 0;
        display: flex;
    }
    .new-post {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;
        justify-content: flex-end;
        float: right;
        background: $primary-color;
        @include border-radius($radius);
        text-decoration: none;
        font-size: $fs-14;
        &:hover:active {
            text-decoration: none;
        }
        &--publish {
            background-color: $green-100;
        }
        .more-options {
            margin-top: 1px;
        }
        .ms-Dropdown {
            padding: 0 12px;
            .ms-Dropdown-title {
                border: none;
                padding-left: 2px;
                padding-right: 20px;
                background-color: transparent;
                @include border-radius($radius);
                color: $white;
                > .dropdown-placeholder {
                    display: flex;
                }
                i {
                    margin-right: 8px;
                }
            }
            .ms-Dropdown-caretDownWrapper {
                right: 12px;
                i {
                    color: $white;
                }
            }
        }
    }
    .draft-published-section {
        display: flex;
        justify-content: left;
        width: fit-content;
        .ms-Icon {
            margin-right: 6px;
            width: 14px;
            height: 14px;
            margin-bottom: 4px;
            svg {
                width: 14px;
                height: 14px;
            }
        }
        .active-tab {
            border-bottom: 2px solid $primary-color;
            font-weight: 600;
            color: $primary-color;
            > span.draft-tab {
                color: $primary-color;
                font-weight: 600;
            }
            .ms-Icon svg {
                fill: $primary-color;
            }
        }
        .draft {
            display: flex;
            margin-right: 40px;
            cursor: pointer;
            padding: 0 0.25rem;
            align-items: center;
            .draft-tab {
                font-size: $fs-16;
                cursor: pointer;
                white-space: nowrap;
                padding-bottom: 2px;
            }
        }
        .published {
            display: flex;
            cursor: pointer;
            .published-tab {
                font-size: 18px;
                cursor: pointer;
                color: $gray-200;
            }
        }
        .review-requests {
            display: flex;
            cursor: pointer;
            .review-requests-tab {
                font-size: 18px;
                cursor: pointer;
                color: $gray-200;
            }
        }
        .ms-Image-image {
            max-width: $fs-14;
            max-height: $fs-14;
        }
    }
}

.article-listview-container {
    .menu-icon {
        background: url(app/static/icons/menu_icon.svg) no-repeat;
        background-size: 15px 15px;
        height: 15px;
        width: 15px;
        position: relative;
        cursor: pointer;
        margin: 0px 12px 0 0;
        float: left;
        &:hover {
            .menu {
                .MenuComponent {
                    display: block;
                }
            }
        }
    }
    .header-tab {
        display: flex;
        align-items: center;
        .title {
            font-size: 32px;
            font-weight: 400;
            width: 70%;
            flex-grow: 1;
        }
        .right-content {
            display: flex;
            align-items: center;

            .sort-dropdown {
                display: flex;
                align-items: center;
                width: 50%;
                margin-right: 15px;
                flex-grow: 1;
                justify-content: space-around;
                .sort-by {
                    text-align: right;
                    padding-right: 10px;
                }
            }
        }
    }
    .card-grid {
        margin-bottom: 40px;
    }
}
.se-video-container {
    figure {
        iframe {
            height: 400px !important;
        }
    }
}
.card-list {
    margin-bottom: 16px;
}
.template-Preview {
    display: flex;
    height: calc(100% - 40px);
    .templates {
        min-width: 300px;
        max-width: 300px;
        overflow-y: auto;
        height: calc(100% - 40px);
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            li {
                list-style-type: none;
                padding: 15px;
                cursor: pointer;
                margin-bottom: 1px;
                @include border-radius($radius);
                position: relative;
                span.ms-Icon {
                    padding: 16px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
                &:hover {
                    background-color: $gray-700;
                }
                &.active {
                    background-color: $primary-color;
                    color: $white;
                    font-weight: 600;
                }
            }
        }
    }
    .preview {
        flex-grow: 1;
        height: calc(100% - 40px);
        overflow: hidden;
        padding-left: 35px;
        .content {
            padding: 0px;
            overflow-y: auto;
            width: 100%;
            border-radius: 0 8px 8px 0;
            box-sizing: border-box;

            height: 100%;
        }
    }
}
.template-preview-modal {
    .ms-Modal-scrollableContent,
    .modal-body {
        overflow: hidden;
        padding-bottom: 0px;
    }
    .modal-body {
        max-height: none;
        flex-grow: 1;
    }
}
#category-drop-list {
    .ms-Dropdown-item {
        &:hover {
            background: $primary-color;
            color: $white;
        }
    }
}
