@import '../../../../../assets/styles/css-assets.scss';

 .icon-backround {
     border: 1px solid rgba($color: $primary-color, $alpha: 0.1);
     padding-left: 0.2rem;
     padding-right: 0.2rem;
     border-radius: 100%;
     background-color: $blue-350;
     cursor: pointer;
     align-items: center;
    .chevron {
        color: $white;
        font-size: 0.8rem;
    }
 }
 label.ms-Label {
    padding-right: 20px;
}