@import '../../assets/styles/css-assets.scss';

.cmt-container-wrapper {
    padding-top: 1rem !important;

    &.internal-highlight {
        border: 1px solid rgba($color: $green-300, $alpha: 1);
        padding: 0 1rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba($color: $green-300, $alpha: 0.3);
        @include border-radius(0 $radius 0);
    }

    &.external-highlight {
        border: 1px solid rgba($color: $red-300, $alpha: 1);
        padding: 0 1rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba($color: $red-300, $alpha: 0.3);
        @include border-radius(0 $radius 0);
    }

    .show-more-btn {
        font-size: $fs-14;
        text-decoration: underline;
        text-align: center;
        margin-left: 4rem;
        margin-bottom: 1rem;
    }

    .cmt-details {
        img {
            max-width: 100%;
        }

        a {
            color: $primary-color;
        }
    }
}
