@import '../../../assets/styles/css-assets.scss';
.admin-tags-container {
    min-height: 600px;
    padding: 0 40px;
    .admin-tags-head {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
    }
    .page-head {
        font-size: $fs-14;
        line-height: 20px;
        color: $gray-100;
        font-weight: 400;
        margin: 5px 0;
    }
    .tag-list {
        background: $blue-800;
        border-radius: 2px;
        display: inline-block;
        padding: 7px;
        margin: 5px 16px 15px 0px;
        cursor: pointer;
        color: $blue-300;
        font-size: $fs-14;
        line-height: 20px;
        .cross-icon {
            background: url(app/static/icons/cross-fill.svg) no-repeat;
            width: 12px;
            height: 12px;
            display: inline-block;
            position: relative;
            top: 2px;
        }
    }
}

.tags-impact-model {
    .ms-Dialog-main {
        @include border-radius(4px);
        background: $white;
        min-height: auto;
        min-width: 925px;
        .ms-Modal-scrollableContent {
            .modal-heading {
                background: $primary-color;
                color: $white;
                text-align: center;
                @include border-radius(1px 1px 0 0);
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 12px;
            }
        }
    }
}