@import '../../../assets/styles/variables.scss';
.insights-container {
    height: 100vh;
    width: 100%;
    iframe {
        border: 0;
    }
    .page-head {
        font-size: $fs-24;
        line-height: 32px;
        margin-bottom: 20px;
        display: inline-block;
    }
    .page-txt-link {
        a {
            font-size: $fs-14;
            line-height: 19px;
            color: $primary-color;
            display: inline-block;
            margin-top: 12px;
            text-decoration: underline;
        }
    }

    .report {
        width: 100%;
        height: 100%;
    }
}
