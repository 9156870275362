@import '../../../../assets/styles/css-assets.scss';

.author-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    background: $white;
    border-radius: $radius;
    height: 100%;
    box-sizing: border-box;

    .author-img {
        margin-right:14px;

        .ms-Image {
            min-width: 120px;
            max-width: 120px;
            min-height: 120px;
            max-height: 120px;
            border-radius: 60px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .about-author {
        display: flex;
        flex-direction: column;
        height: 100%;
        .author-name {
            padding-bottom: 3px;
            font-size: $fs-20;
            line-height: $fs-26;
            color: $black;
        }
        .author-position {
            font-size: $fs-14;
            line-height: $fs-20;
            color: $gray-200;
        }
        .team {
            align-self: flex-start;
            margin: 3px 0 10px;
        }
        .view-all {
            margin-top: auto;
            a {
                font-size: 12px;
               
            }
        }
    }
}
