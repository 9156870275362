@import '../../../assets/styles/css-assets.scss';
@import './animations.scss';

.view-container {
    .view-wrapper {
        padding-top: 1rem;
        box-sizing: border-box;
        min-height: 40rem;

        .pivot-container {
            padding: 1rem 0 4rem 0;
            box-sizing: border-box;
            .experience-header {
                text-align: center;
            }
            .ms-Pivot-link {
                color: $gray-400;
                &.is-selected {
                    color: $primary-color;
                }
            }
        }

        .default-container {
            border: 2px solid $gray-700;
            padding: 10rem 0;
            box-sizing: border-box;
            margin-top: 20px;
            @include border-radius($radius);
        }
    }

    .hidden {
        display: none;
    }
}
