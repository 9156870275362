@import 'app/assets/styles/css-assets';

.success-body {
    padding: 30px;
    color: $gray-200;
    margin: 40px auto;
    a {
        color: $primary-color;
    }
    .success-title {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: $primary-color;
        margin: 10px auto 36px;
        justify-content: center;

        .success-happy-icon {
            background: url(app/static/icons/happy-face.svg) no-repeat;
            display: inline-block;
            width: 80px;
            height: 80px;
            margin-right: 37px;
        }
    }
    .text-body {
        color: $gray-100;
        font-size: 20px;
        line-height: 22px;
        display: block;
        font-weight: 400;
        margin: 10px 0;
        .txt-bld {
            font-weight: 600;
            font-size: $fs-20;
        }
        .bld {
            font-weight: 600;
        }
    }
    .add-another-customer {
        display: flex;
        justify-content: center;
        .add-cus {
            margin: 10px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-decoration: underline;
        }
    }

    .text-body-bold {
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        display: block;
        margin: 40px 0 32px;
        span {
            color: $primary-color;
        }
    }
    .text-body-small {
        font-size: 20px;
        line-height: 22px;
        display: block;
        a {
            color: $primary-color;
            text-decoration: underline;
        }
    }
}
