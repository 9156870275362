@import 'app/assets/styles/css-assets';

.admin-search-container {
    .custom-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: none;
    }

    .result-info-header {
        margin-top: 16px;
        font-size: $fs-18;
        line-height: $fs-24;
        display: flex;
        align-items: flex-end;
        color: $gray-100;
    }

    .advanced-search {
        font-size: 12px;
        line-height: $fs-18;
        text-decoration-line: underline;
        color: $blue-400;
        margin-top: 8px;
        cursor: pointer;
    }

    .right-section {
        margin-left: auto;
        display: flex;
        align-items: flex-start;
        button {
            border-radius: 8px;
        }

        .ms-Stack {
            margin-right: 0.5rem;
            margin-top: 0.5rem;
        }
    }

    .search-list-container {
        margin: 30px 0;
        border: 1px solid $gray-600;
        box-sizing: border-box;
        @include border-radius($radius);
        padding: 1.3rem 0.5rem 1.3rem 1.3rem;
        min-height: 40rem;

        .scroll-panel {
            height: 100%;
            overflow-y: scroll;
            padding: 0 0.3rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }

        .result-description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}
