@import '../../assets/styles/css-assets.scss';

.share-request-modal {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 60%;
    max-width: 60%;

    .modal-head {
        display: flex;
        align-items: center;
        padding: 1rem 0.8rem;
        background-color: $blue-400;
        color: $white;
        justify-content: center;
        position: relative;
        font-size: $fs-18;
        span {
            font-size: $fs-18;
        }

        .modal-close {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
        }
    }

    .share-request-modal-body {
        .scroll-panel {
            max-height: 35rem;
            overflow-y: auto;
            padding: 0 0.3rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.2);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.5);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .dp-header {
            background-color: $gray-800;
            padding: 1rem 1.5rem 0.5rem;

            .dp-head-title {
                margin-right: 0.5rem;
                font-weight: 600;
            }

            .dp-head-desc {
                margin: 0.5rem 0;
                .title-field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.25rem;
                    max-height: 2rem;
                    -webkit-line-clamp: 1; /* Write the number of 
                                              lines you want to be 
                                              displayed */
                    -webkit-box-orient: vertical;
                }
                span {
                    font-size: $fs-18;
                    font-weight: 600;
                }
            }
        }

        .dp-content {
            padding: 1rem 2rem 2rem;
            box-sizing: border-box;
            .text-field-wrap {
                .ms-BasePicker-text {
                    @include border-radius($radius);
                    border-color: $gray-200;
                    &::after {
                        @include border-radius($radius);
                    }
                }

                .ms-TextField {
                    .ms-TextField-fieldGroup {
                        @include border-radius($radius);

                        &::after {
                            @include border-radius($radius);
                        }
                    }
                }

                .ms-TagItem {
                    background-color: $blue-800;
                    @include border-radius($radius);
                    &.is-selected {
                        background-color: $blue-400;
                    }
                }

                label {
                    font-weight: 600;
                    margin-left: 1rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                input {
                    &::placeholder {
                        color: $gray-400;
                    }
                }
            }

            .icon-link {
                display: flex;
                align-items: center;
                color: $gray-200;

                .ms-Icon {
                    margin-right: 0.3rem;
                }

                &.active {
                    color: $primary-color;
                }
            }

            .attachment-container {
                margin-top: 1.5rem;
                label {
                    font-weight: normal;
                    margin: 0 0.5rem 0.3rem;
                }
            }

            .form-btn-wrap {
                margin-top: 2rem;
                button {
                    @include border-radius($radius);
                    font-size: $fs-14;
                    padding: 1.1rem 1.5rem;
                    cursor: pointer;
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                }

                button.default {
                    border: 1px solid $gray-300;
                    background-color: $white;
                    color: $gray-300;
                }
            }

            .error-message {
                span {
                    color: $red-200;
                    font-size: $fs-14;
                }
            }
        }
    }

    .share-request-success {
        .modal-head {
            display: flex;
            align-items: center;
            padding: 1rem 0.8rem;
            background-color: $white;
            color: $white;
            justify-content: center;
            position: relative;
            font-size: $fs-18;
            span {
                font-size: $fs-18;
            }

            .modal-close {
                position: absolute;
                right: 0.8rem;
                top: 0.5rem;
                color: $primary-color;
                &:hover {
                    background-color: unset;
                }
            }
        }

        .fc-response {
            min-height: 200px;
        }
        .fc-res-text {
            span {
                font-size: $fs-18;
                font-weight: 600;
            }
        }
    }

    .share-request-failure {
        .modal-head {
            display: flex;
            align-items: center;
            padding: 1rem 0.8rem;
            background-color: $white;
            color: $white;
            justify-content: center;
            position: relative;
            font-size: $fs-18;
            span {
                font-size: $fs-18;
            }

            .modal-close {
                position: absolute;
                right: 0.8rem;
                top: 0.5rem;
                color: $primary-color;
                &:hover {
                    background-color: unset;
                }
            }
        }

        .fc-response {
            min-height: 200px;
        }
        .fc-res-text {
            span {
                font-size: $fs-18;
                font-weight: 600;
            }
        }

        .fc-response-icon {
            i {
                color: $red-200;
            }
        }
    }
}
