@import '../../../assets/styles/variables.scss';

.feedback-view-container {
    background: url(app/static/feedback_bg.jpg) no-repeat;
    background-position: bottom right;
    background-size: cover;
    min-height: 45rem;

    .feedback-wrapper {
        max-width: 50rem;
        margin: 0 auto;
    }
}
