@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';
@import '../animations.scss';

.response-form-wrapper {
    display: flex;
    font-size: $fs-14;
    padding: 2rem 0;
    box-sizing: border-box;
    color: $gray-200;

    .back-panel {
        padding: 1rem 1rem 0;
        font-size: $fs-16;
    }

    .response-container {
        min-width: 60%;
        animation: slideRightToLeft 0.5s ease forwards;
        .validate-msg {
            color: $red-100;
        }
        .response-content {
            background-color: $gray-800;
            @include border-radius($radius);
            padding: 1rem 1.5rem;
            margin-bottom: 1.2rem;

            .response-title {
                font-weight: 600;
                color: $gray-100;
                margin-bottom: 0.5rem;
            }

            .response-footer {
                display: flex;
                margin-top: 1rem;

                .response-icon {
                    margin-right: 1rem;
                    img {
                        height: 30px;
                        width: 30px;
                    }
                    span {
                        font-size: $fs-12;
                    }
                }

                .response-category {
                    margin-right: 1rem;
                    span {
                        background-color: rgba(247, 179, 4, 0.2);
                        padding: 0.3rem 0.5rem 0.5rem;
                        box-sizing: border-box;
                        display: block;
                        @include border-radius(4px);
                    }
                }
            }
        }

        .textfield-wrap {
            margin-top: 1rem;

            textarea {
                border: 1px solid $gray-600;
                @include border-radius($radius);
                font-size: $fs-14;
                padding: 1rem;
                width: 100%;

                &::placeholder {
                    color: $gray-600;
                }
            }
        }

        .form-btn-wrap {
            margin-top: 24px;
            button {
                @include border-radius($radius);
                font-size: $fs-14;
                padding: 1.2rem 3rem;
                cursor: pointer;
                margin-right: 20px;
            }
            button.primary {
                border: 1px solid $primary-color;
                background-color: $primary-color;
                color: $white;
            }
            button.default {
                border: 1px solid $gray-600;
                background-color: $white;
                color: $gray-200;
            }
        }
    }
}
