@import '../../../assets/styles/media-queries.scss';
@import '../../../assets/styles/css-assets.scss';
@import '../../../modules/user/newReqExperience/animations.scss';

// Header Styles
.manageList-header {
    height: 48px;
    background: #eff6fc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

// Manage list Header Buttons Active
.manage-list-active {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    width: auto;
    /* identical to box height, or 138% */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Theme / Primary */
    border-bottom: 2px solid #0078d3;
    color: #0078d4;
}

// When Manage Lists- Header Button is inactive this is called
.inactive {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
}

.add-folder-btn:hover {
    cursor: pointer;
}

// Header Buttons
.manageList-header-buttons {
    width: 350px;
    display: flex;
    align-items: center;
    margin-left: 6%;
    justify-content: space-evenly;
}

.header-buttons:hover {
    cursor: pointer;
}

// Detail Component
.listStyles {
    .k-detail-cell {
        padding-left: 15px;
        background-color: white !important;
    }
    .box .k-detail-cell {
        width: 100% !important;
        padding: 0;
        padding-left: 15px;
    }
    .k-grid-header-wrap {
        border-bottom: 1px solid #deecf9;
    }
    .k-widget,
    .k-grid {
        display: block;
        overflow-y: auto;
        border: none !important;
    }

    .k-grid-content,
    .k-virtual-content {
        overflow: auto !important;
    }
    .k-grid-header-wrap {
        display: none;
    }
    .k-hierarchy-cell {
        .k-icon,
        .k-i-plus {
            position: relative;
            left: 290px;
            top: 10px;
            z-index: 1500;
            color: white !important;
        }
    }
}

.detailComponent {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 40px;
    user-select: none;
}

.list-drag-cell {
    min-width: 1.6%;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 14px;
    align-content: center;
}

// no lists available icon
.no-lists-img-styles {
    height: 15px;
    width: auto;
    object-fit: contain;
    margin-right: 6px;
}

// no lists available container
.no-lists-available {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 3.5%;
    height: 60px;
}

// no lists font styles
.no-lists-text {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 0.7%;
}

// Icon Container
.icon-spacing {
    width: 40px !important;
    cursor: pointer;
}

// Icon Container but without the Cursor Pointer
.disabled-icon-spacing {
    width: 40px !important;
}

.pencil-margins {
    margin-left: 15px;
}

// Icon Hover Animations
.list-icon-animations {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .device-animate-lb {
        &:hover {
            span {
                animation: productTextHover 0.5s ease forwards;
            }
        }
        &.add-align-prop {
            align-self: flex-start;
        }
    }

    .action-icons-lb {
        object-fit: contain;
        height: 16px;
        width: auto;
        display: flex;
        &:hover {
            img {
                animation: productHover 0.5s ease forwards;
            }
        }
    }
}

.folder-icon-animations {
    z-index: 1;
    display: flex;
    max-width: 90px;
    justify-content: center;
    align-items: center;
    .device-animate-lb {
        &:hover {
            span {
                animation: productTextHover 0.5s ease forwards;
            }
        }
        &.add-align-prop {
            align-self: flex-start;
        }
    }

    .action-icons-lb {
        object-fit: contain;
        height: 16px;
        width: auto;
        display: flex;
        &:hover {
            img {
                animation: productHover 0.5s ease forwards;
            }
        }
    }
}

.effect:hover img.image {
    display: none;
}
.effect img.hover {
    display: none;
}
.effect:hover img.hover {
    display: block;
}

// Table Container for Kendo
.box {
    .k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
    .k-grid .k-command-cell,
    .k-grid .k-edit-cell {
        padding: calc(2px + 0.285714em) 2px;
    }
    .k-grid-header,
    .k-grid-header-wrap,
    .k-widget,
    k.grid,
    .k-link,
    .k-header {
        background-color: #faf9f8 !important;
        text-decoration: none;
    }
    .k-grid-header:hover,
    .k-grid-header-wrap:hover,
    .k-widget:hover,
    k.grid:hover,
    .k-link:hover,
    .k-header:hover {
        background-color: #faf9f8 !important;
        text-decoration: none;
    }
    .k-link,
    .k-link-hover {
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 143% */

        display: flex;
        align-items: flex-end;

        /* Type colors / Primary */

        color: #323130;
        cursor: default !important;
    }
    .k-grid-header .k-header > .k-link {
        padding: 16px 19px;
    }

    .k-grid-header,
    .k-grid-footer {
        padding: 0 !important;
    }
    .k-grid {
        font-family: 'Segoe UI' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        border: none;
    }
    .k-grid-header,
    .k-grid-header-wrap,
    .k-widget,
    k.grid,
    .k-link,
    .k-header {
        border: none !important;
        height: 56px;
    }
    .k-grid-header:hover,
    .k-grid-header-wrap:hover,
    .k-widget:hover,
    k.grid:hover,
    .k-link:hover,
    .k-header:hover {
        border: none !important;
    }
    tr:hover {
        background-color: white !important;
    }
    td:hover {
        background-color: white !important;
    }
    .k-grid-table {
        width: 100% !important;
    }

    .k-grid-header-wrap > table {
        width: 100% !important;
    }

    .k-detail-cell {
        width: 100% !important;
        padding: 0;
    }

    .folder-droppable {
        padding: 10px 24px;
    }

    .hierarchy-cell-dragging {
        background-color: #f2ffd9 !important;
    }

    .hierarchy-cell-exit {
        background-color: none;
    }

    .k-master-row,
    .k-grid-edit-row {
        background: #eff6fc !important;
        tr:hover,
        td:hover {
            background: #eff6fc !important;
        }
    }
    .k-master-row:hover,
    .k-grid-edit-row:hover {
        background: #eff6fc !important;
    }
}

// Chevron next to folder name that expands detail component
.renderChevStyles {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.chevronDown {
    display: flex;
}

.k-icon .k-i-reorder {
    border: 2px solid black !important;
}

.favoritesIconList {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.favoriteFontStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.list-icon-ml {
    width: 50px;
}

.list-name-ml {
    width: auto;
}

.k-grid-content {
    overflow-y: auto;
}

.favoritesTable {
    .ms-Link {
        color: #323130 !important;
    }
    .k-master-row tr:hover,
    .k-master-row td:hover,
    .k-grid-edit-row tr:hover,
    .k-grid-edit-row td:hover {
        background-color: white !important;
    }
    td {
        border: none;
    }
    .k-master-row,
    td {
        background-color: white !important;
    }
    .k-master-row:hover,
    .k-grid-edit-row:hover,
    td:hover {
        background: white !important;
    }
    .k-grid,
    .k-grid-container {
        height: auto;
        min-height: 700px;
        width: 100% !important;
        overflow-y: auto !important;
        border: none !important;
    }

    .k-widget,
    .k-grid,
    .k-grid-content,
    tr,
    .k-link {
        overflow-y: auto !important;
        border: none !important;
    }
    .k-grid-header,
    .k-grid-header-wrap {
        background: #faf9f8 !important;
    }
    .k-widget,
    .k-grid {
        display: block;
        overflow-y: auto;
        border: none !important;
    }

    .k-grid-content,
    .k-virtual-content {
        overflow: hidden;
        overflow-y: hidden;
    }
}

.box .k-master-row,
.box .k-grid-edit-row,
.box tr,
.box td {
    border: none !important;
    height: 56px;
}

div.k-grid-header,
div.k-grid-footer {
    border: none !important;
}

.bodyStyles {
    margin-top: 0px;
    margin-bottom: 200px;

    .k-grid,
    .k-grid-container {
        height: 700px !important;
        border: none !important;
    }

    .k-hierarchy-cell {
        display: none;
    }

    .k-widget,
    .k-grid,
    .k-grid-header-wrap,
    .k-grid-content {
        border: none !important;
        background-color: #faf9f8;
        height: 900px !important;
        overflow-y: auto !important;
    }

    .k-link {
        border: 2px solid black !important;
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        display: flex;
        align-items: flex-end;

        /* Type colors / Primary */

        color: #323130;
    }
    .k-detail-row {
        background-color: white !important;
    }
    .k-detail-cell {
        padding: 0;

        .k-grid th,
        .k-grid td {
            height: 60px;
            hover: {
                border: none;
            }
        }
    }
}

.createdByTextField {
    .ms-TextField-wrapper {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ms-TextField-fieldGroup {
            width: 200px !important;
            border: 1px solid #cdcdcd;
            box-sizing: border-box;
            margin-left: 10%;
            padding: 0;

            input {
                padding-left: 8px;
            }
        }
    }
}

.list-menu-cont {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.drop-down-styles:hover {
    cursor: pointer;
}

.managing-lists-container {
    width: 100%;
    height: 50px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eff6fc;
    position: relative;
    z-index: 1000;
}

// created by Folder styles
.createdByFolderStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    text-align: start !important;
    line-height: 20px;
    padding-left: 15px !important;
}

// Modified On Folder
.modifiedOnFolderStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    text-align: start !important;
    line-height: 20px;
    padding-left: 30px !important;
}

// Created On Folder
.createdOnFolderStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    text-align: start !important;
    line-height: 20px;
    padding-left: 20px !important;
}

// Parent container for List Name + Icon
.iconQueryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

// Folder Icon
.folderIconStyles {
    margin-right: 10px;
    height: 15px;
    width: auto;
    object-fit: contain;
}

// Folder Name
.folder-name {
    display: flex;
    height: 50px;
    font-family: 'Segoe UI' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 20px;
    justify-content: left;
    align-items: center;
}

// Detail Component List Styles
.query-name-container {
    min-width: 30.6%;
}

.iconStyles {
    width: 40px;
    display: flex;
    align-items: center;
}

.icon {
    height: 20px;
}

// Detail Component Created On List
.createdOnListStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #000000;
    min-width: 12.3%;
    max-width: 14.2%;
}

// Created By LIST styles
.createdByListStyles {
    /* identical to box height, or 143% */
    min-width: 22.6%;
    max-width: 24%;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
}

// Detail Component Last Modified Lists
.modifiedOnListStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #000000;
    min-width: 13.4%;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .modifiedOnListStyles {
        font-family: 'Segoe UI' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px;
        color: #000000;
        min-width: 13%;
    }
}

// List name font styles in the Detail Component
.queryNameStyles {
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #323130;
}

// used throughtout ManageLists to indicate text is disabled
.text-disabled {
    color: #cecaca !important;
}

// Fluent UI Related
.drop-down-styles {
    display: flex;
    align-items: center;
    justify-content: center;
    .ms-Callout {
        .ms-Dropdown-container {
            min-width: 180px !important;
        }
    }
    .ms-Dropdown-container {
        width: 225px !important;
        display: flex;
        align-items: center;
        .ms-Dropdown-title,
        .ms-Dropdown-titleIsPlaceHolder {
            display: none;
        }
        .ms-Dropdown-caretDownWrapper {
            width: 5px;
            height: 50px;
            position: relative;
            top: 1;
            right: 8;
            .ms-Dropdown-caretDown {
                color: transparent !important;
            }
        }
        .ms-Dropdown::after {
            border: none;
        }
        .ms-Dropdown-titleIsPlaceHolder {
            width: 150px;
        }
        .ms-TextField-fieldGroup {
            @include border-radius(8);
            width: 150px !important;
            border: 1px solid #cdcdcd;
            box-sizing: border-box;
            margin-left: 10%;
            padding: 0;
            input {
                &::placeholder {
                    color: black;
                }
            }
        }
    }
}

.queryNameTextField {
    .ms-TextField-wrapper {
        width: 350px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .ms-TextField-fieldGroup {
            width: 150px !important;
            border: 1px solid #cdcdcd;
            box-sizing: border-box;
            margin-left: 10%;
            padding: 0;

            input {
                padding-left: 8px;
            }
        }
    }
}

.createdOnTextField {
    .ms-DatePicker {
        .ms-TextField-wrapper {
            width: 225px;
            display: flex;
            align-items: center;
            justify-content: center;
            .ms-TextField-fieldGroup {
                @include border-radius(8);
                width: 150px !important;
                border: 1px solid #cdcdcd;
                box-sizing: border-box;
                margin-left: 10%;
                padding: 0;
                input {
                    &::placeholder {
                        color: black;
                    }
                }
            }
        }
    }
}
