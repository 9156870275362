@import '../../../../assets/styles/css-assets.scss';

.listBuiderFieldCont{
  width: 945px;
  min-height:95px;
  display: flex;
  flex-direction: column;
}

.listBuilder2 {
    box-sizing: border-box;
    width: 860px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #0078D4;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.x-button-styles {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.x-button-styles:hover {
    cursor: pointer;
}

.x-button-disabled:hover {
  cursor: default;
}

.line-bar-container {
    display: flex;
    border-left: 1px solid #0078D4;
    height: 38px;
    margin-top: 6px;
    margin-left: 20px;
    margin-right: 5px;
}
.add-to-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    margin-right: 4px;
}

.fieldWigetIn {
  display: flex;
  position: relative;
  top: -48px; right: 0px;
  animation: fieldWidgetIn 3s;
  flex-direction: column;
}
@keyframes fieldWidgetIn {
    from {top: -75px; left:0px;}
    to {top: -48px; left: 0px;}
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fieldWidgetOut {
    from {top: -50px; left: 0px;}
    to {top: -75px; left:0px;}
    0% {opacity: 1;}
    100% {opacity: 0;}
}
.fieldWigetOut {
    display: flex;
    position: relative;
    top: -75px; left:0px;
    animation: fieldWidgetOut 3s;
    flex-direction: column;
}

.field-container {
    width: 860px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pipeStyles-and {
    height: 39px;
    margin-left: 3.5%;
    border-left:1.5px solid #0078D4;
}

.pipeStyles-initial {
    height: 39px;
    margin-left: 48%;
    border-left:1.5px solid #0078D4;
}

.pipeStyles-and-initial {
  height: 45px;
  margin-left: 16%;
  border-left:1.5px solid #0078D4;
}

.pipeStyles-or {
    height: 39px;
    margin-left: 10%;
    border-left:1.5px solid #0078D4;
}

.pipeStyles-noDisplay {
  display: none;
}

.dropDown-container {
    overflow-y: scroll;
    height: 180px;
    margin-top: 10px;
    width:160px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #0078D4;
}
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}

  .dropdown-items {
    box-sizing: border-box;
    width: 160px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 8px;
    min-height: 40px;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 0px 8px;
    margin-top: 2px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
  }
  .dropdown-items:hover {
      color:white;
      background-color: #0078D4;
  }

  .dropdown-items:active {
      color: rgb(0, 120, 212);
      width: 180px;

      background-color: #d3d3d3;
  }

.option-styles {
  cursor: pointer;
}

.selected-option {
  color: rgb(0, 120, 212);
  width: 100%;
  background-color: #d3d3d3;
}

.search-cond-cont {
  display: flex;
  flex-direction: row;
  width: 600px;
  height:40px;
}

.field-builder-search-bar {
  width:190px;
  padding: 1.25rem 0.625rem;
  .ms-SearchBox i {
      transform: rotate(270deg);
  }
  .tags-cont {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.3125rem;
      .tag-item {
          display: flex;
          align-items: center;
          background: $gray-800;
          margin-right: 0.3125rem;
          padding: 0.125rem 0.3125rem;
          color: $gray-100;
          margin-bottom: 0.3125rem;
          @include border-radius(0.1875rem);
          span {
              font-size: $fs-12;
          }
          i {
              cursor: pointer;
              margin-left: 0.625rem;
              font-size: $fs-10;
          }
      }
  }
  .ms-SearchBox {
      @include border-radius($radius);
      border: 1px solid $blue-700;
      input::placeholder {
          color: $gray-300;
      }
      i {
          font-size: $fs-14;
          color: $gray-300;
      }
      .ms-SearchBox-clearButton {
          i {
              font-size: $fs-14;
          }
      }
      &::after {
          @include border-radius($radius);
      }
  }
}
.search-condition-cont {
  width: 200px;
}

.option-styles:hover {
    cursor: pointer;
    color:white;
    background-color: #0078D4;
}
.dropdown-cont-tb {
  margin-top: 45px;
  max-height: 17.2rem;
  overflow-y: auto;
  position: absolute;
  background: $white;
  
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  @include border-radius($radius);
  z-index: 999999;
  font-size: $fs-14;
  font-weight: normal;
  &.position-cont {
      top: 2.8125rem;
  }
  .dropdown-item {
      border-top: 1px solid $gray-600;
      box-sizing: border-box;
      font-size: small;
      padding: 0.625rem;
      &.child-item {
          padding-left: 30px;
      }
      &.parent-item {
          label {
              font-weight: 600;
          }
      }
  }
}

.that-field {
  width:80px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.to-this-field {
  width:80px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.parentContainer {
  display: flex;
  height:35px;
  align-items: center;
  justify-content: space-between;
}

.chevron-styles {
  font-size: 11px;
  margin-right:10px;
  margin-left:5px;
  display: flex;
  align-items: center;
}
.optionStyles-sm {
  margin-left:20px;
  margin-top:10px;
  line-height:18px;
  margin-bottom:10px;
}

.optionStyles-md {
  line-height:18px;
}

.optionStyles-lg {
  margin-left:5px;
  margin-top:10px;
  line-height:18px;
  margin-bottom:10px;
}
.chevron-option-styles-sm {
  display: flex;
  width:140px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chevron-option-styles-md {
  display: flex;
  width:170px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chevron-option-styles-lg {
  display: flex;
  width:200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.any-field-sm {
  width: 140px;
  height: 39px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #A19F9D;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.any-field-md {
  width: 190px;
  height: 38px;
  background: #FFFFFF;
  font-size: smaller;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #A19F9D;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.any-field-lg {
  width: 200px;
  height: 39px;
  background: #FFFFFF;
  font-size: smaller;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #A19F9D;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.any-field:hover {
  box-shadow: 0px 4px 20px rgba(0, 120, 212, 0.5);
}

.field-active {
  box-shadow: 0 4px 10px rgba(0, 120, 212, 0.5);
  border: none;
  border-radius: 8px;
}

.field-active .title-171, .field-active .ms-TextField-fieldGroup, .field-active .num-format, .field-active .ms-Dropdown {
  border: none !important;
}

.field-active  *:not(.dropdown-cont *, .position-cont *) {
  border: none !important;
}