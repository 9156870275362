.choiceBtn {
    margin-bottom: 54px;
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        .ms-ChoiceField {
            width: 200px;
            .ms-ChoiceField-field {
                line-height: 32px;
                &::before {
                    width: 30px;
                    height: 30px;
                }
                &::after {
                    width: 18px;
                    height: 18px;
                    left: 6px;
                    border-width: 9px;
                    top: 6px;
                }
                .ms-ChoiceFieldLabel {
                    padding-left: 37px;
                    font-size: 20px;
                }
            }
        }
    }
}
