@import '../../../../assets/styles/css-assets.scss';

.contact-table-container {
    background: $gray-700;
    @include border-radius($radius);
    .table-inner-cont {
        background: $white;
        @include border-radius($radius);
        .ct-header {
            box-sizing: border-box;
            font-size: $fs-14;
        }
        .ct-row {
            border-top: 1px solid $gray-600;
            font-size: $fs-14;
        }
        .col-0,
        .col-1 {
            width: 40%;
        }

        .col-2 {
            width: 15%;
        }
    }
}
