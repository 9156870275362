@import '../../../../assets/styles/css-assets.scss';
.author-section {
    background-color: $purple-100;
    @include border-radius(4px);
    margin:20px 4px 4px;
    padding:40px 0;
    .header-author-container {
        display: flex;
        h2 {
            font-weight: 400;
            font-size: $fs-32;
            line-height: $fs-42;
            color: $white;
            margin-bottom: 32px;
            font-family: $font-family;
            margin-top:0px;
        }
        img {
            width: 25px;
            height: 25px;
            padding-top:10px;
            margin-right: 15px;
        }
    }
}
