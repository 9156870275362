@import '../../assets/styles/css-assets.scss';

.my-account{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color:$gray-700;
    padding:20px;
    .content{
        display: flex;
        flex-grow: 1;
        background-color: $white;
        @include border-radius($radius);
        padding:10px;
    }
}

