@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.manage-request-detailed-container {
    color: $gray-100;
    animation: fadeIn 1s ease-in;

    .menu-card {
        .mangeMy-icon {
            background: url(app/static/icons/manageMy-grey-icon.svg) no-repeat;
            display: inline-block;
            width: 2.5rem;
            height: 2.7rem;
        }
        .mangeAll-icon {
            background: url(app/static/icons/manageAll-grey-icon.svg) no-repeat;
            display: inline-block;
            width: 2.5rem;
            height: 2.7rem;
        }
        .closedAll-icon {
            background: url(app/static/icons/closedAll-grey-icon.svg) no-repeat;
            display: inline-block;
            width: 2.5rem;
            height: 2.7rem;
        }
        &.active {
            .mangeMy-icon {
                background: url(app/static/icons/manageMy-white-icon.svg) no-repeat;
            }
            .mangeAll-icon {
                background: url(app/static/icons/manageAll-white-icon.svg) no-repeat;
            }
            .closedAll-icon {
                background: url(app/static/icons/closedAll-white-icon.svg) no-repeat;
            }
        }
    }

    .mangeMy-icon {
        &.active {
            background: url(app/static/icons/manageMy-white-icon.svg) no-repeat;
        }

        background: url(app/static/icons/manageMy-grey-icon.svg) no-repeat;
        display: inline-block;
        width: 2.5rem;
        height: 2.7rem;
    }
    .mangeAll-icon {
        &.active {
            background: url(app/static/icons/manageAll-white-icon.svg) no-repeat;
        }

        background: url(app/static/icons/manageAll-grey-icon.svg) no-repeat;
        display: inline-block;
        width: 2.5rem;
        height: 2.7rem;
    }
    .closedAll-icon {
        &.active {
            background: url(app/static/icons/closedAll-white-icon.svg) no-repeat;
        }
        background: url(app/static/icons/closedAll-grey-icon.svg) no-repeat;
        display: inline-block;
        width: 2.5rem;
        height: 2.7rem;
    }

    .details-container {
        @include border-radius($radius);
        margin-top: 1.5rem;
        .filter-panel {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 5px solid $white;
            padding: 0.5rem 2rem 0.5rem;
            box-sizing: border-box;

            .filter-links {
                margin-right: auto;
                button {
                    color: $gray-200;
                    margin-right: 1.5rem;

                    &.active {
                        color: $primary-color;
                        text-decoration: underline;
                    }

                    &.active:hover {
                        text-decoration: underline;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .filter-controls {
                display: flex;
                margin-left: auto;
                align-items: center;

                .icon {
                    margin-right: 0.5rem;
                }

                & > span {
                    margin-right: 0.5rem;
                }

                .ms-Dropdown-title {
                    border: 1px solid $gray-600;
                    @include border-radius($radius);
                    min-width: 250px;
                }
            }
        }

        .ud-contents-wrap {
            display: flex;
            flex-wrap: nowrap;
            min-height: 50rem;
            box-sizing: border-box;

            .grid-col-left {
                padding-right: 0.3rem;
                box-sizing: border-box;
                width: 30%;
                min-width: 30%;
                min-height: 50rem;
            }
            .grid-col-right {
                box-sizing: border-box;
                padding-left: 0.3rem;
                width: 70%;
                min-width: 70%;
                height: fit-content;
                min-height: 50rem;

                &.hidden {
                    display: none;
                }
            }
        }

        td {
            font-weight: normal;
            font-size: $fs-12;
        }
    }
}
