@import './css-assets';
$tag: 'tag';
$blue: 'primary';
$orange: 'orange';
$blue--shade2: 'blue--shade2';
$cyan: 'cyan';
$redrafted: 'Redrafted';
.tags {
    .#{tag} {
        display: inline-block;
        @include border-radius($radius + 4);
        font-size: $fs-12;
        padding: 4px 10px;
        border: none;
        background-color: $gray-600;
        color: $gray-100;
        line-height: 16px;
        text-decoration: none;
        &:focus,
        &:active {
            text-decoration: none;
        }
        .icon {
            margin-right: 4px;
            max-width: 16px;
            max-height: 16px;
            float: left;
            margin-top: 3px;
            img {
                width: 100%;
            }
            &--right {
                float: right;
            }
        }
        +.#{tag} {
            margin-left: 20px;
        }
        &--sc {
            @include border-radius($radius);
            font-size: $fs-14;
            padding: 9px 8px;
            margin: 2px 5px 2px 0;
            .icon {
                margin-top: 0px;
            }
            +.#{tag} {
                margin-left: 0px;
            }
        }
        &--close {
            @include border-radius($radius/2);
            font-size: $fs-14;
            padding: 5px 8px;
            margin: 3px 10px 3px 0px;
            .icon {
                font-size: $fs-16;
                line-height: $fs-12;
                margin: -5px -8px -5px 0px;
                cursor: pointer;
                padding: 5px 8px;
                color: $gray-300;
            }
            +.#{tag} {
                margin-left: 0px;
                margin-right: 5px;
            }
        }
        &.#{$tag} {
            &--#{$blue} {
                background-color: $primary-color;
                color: $white;
            }
            &--#{$orange} {
                background-color: $orange-300;
                color: $white;
            }
            &--#{$blue--shade2} {
                background-color: $blue-300;
                color: $white;
            }
            &--#{$cyan} {
                background-color: $cyan-150;
                color: $white;
            }
            &--#{$redrafted} {
                background-color: $cyan-150;
                color: $white;
            }
            &--transparent {
                background-color: transparent;
            }
            &--color-green {
                color: $green-200;
            }
            &--more {
                font-weight: 700;
                padding: 7px 10px 17px;
                height: 10px;
            }
            &--color-red {
                color: $red-200;
            }
        }
    }
}