.successPopUpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 660px;
    height: 285px;
}

.labelStyles {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* identical to box height, or 138% */
    width: 600px;
    margin-right:30px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000000;
}