@import '../../assets/styles/css-assets.scss';

.link-children-request-modal {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 60%;

    .ms-MessageBar {
        @include border-radius($radius);
    }

    .modal-head {
        display: flex;
        align-items: center;
        padding: 1rem 0.8rem;
        background-color: $blue-400;
        color: $white;
        justify-content: center;
        position: relative;
        font-size: $fs-18;
        span {
            font-size: $fs-18;
        }

        .modal-close {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
        }
    }

    .add-attachment-modal-body {
        max-width: fit-content;
        align-self: center;

        .scroll-panel {
            height: 100%;
            max-height: 20rem;
            overflow-y: auto;
            padding: 0 0.3rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.4);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.6);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }

        .lr-header {
            display: flex;
            align-items: center;

            .head-col-right {
                display: flex;
                flex-wrap: wrap;
                margin-left: auto;
                align-self: center;
                margin-bottom: 1rem;

                .ms-TextField-fieldGroup {
                    padding: 0 15px;
                    border: 1px solid #C7E0F4;
                    min-height: 35px;
                    width: 350px;
                    input {
                        &::placeholder {
                            color: $gray-400;
                        }
                    }
                    &::after {
                        border: hidden;
                    }
                }
            }
        }

        .dp-header {
            background-color: $gray-800;
            padding: 1rem 1.5rem 1.5rem;

            .cust-details {
                font-size: $fs-14;
                margin: 0.5rem 0;

                .secondary-text {
                    line-height: 20px;
                    border-right: 1px solid $gray-400;
                    padding-right: 15px;
                    color: $gray-200;
                    padding-left: 15px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        border: 0;
                    }
                    .text-bold {
                        font-weight: 600;
                        color: $gray-100;
                    }
                }
            }

            .dp-head-title {
                margin-right: 0.5rem;
                font-weight: 600;
            }

            .dp-head-desc {
                margin: 1rem 0 0.5rem;
                span {
                    font-size: $fs-18;
                    font-weight: 600;
                }
            }
            .ms-Link {
                text-decoration: underline;
            }
        }

        .dp-content {
            padding: 1.5rem;
            padding-top: 0;
            box-sizing: border-box;
            min-width: 400px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .text-field-wrap {
                .ms-BasePicker-text {
                    @include border-radius($radius);
                    border-color: $gray-200;
                    &::after {
                        @include border-radius($radius);
                    }
                }

                .ms-TextField {
                    .ms-TextField-fieldGroup {
                        @include border-radius($radius);

                        &::after {
                            @include border-radius($radius);
                        }
                    }
                }

                .ms-TagItem {
                    background-color: $blue-800;
                    @include border-radius($radius);

                    &.is-selected {
                        background-color: $blue-400;
                    }
                }

                label {
                    font-weight: 600;
                    margin-left: 1rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                input {
                    &::placeholder {
                        color: $gray-400;
                    }
                }
            }

            .icon-link {
                display: flex;
                color: $gray-200;

                .ms-Icon {
                    margin-right: 0.3rem;
                }

                &.active {
                    color: $primary-color;
                }
            }

            .attachment-container {
                margin-top: 1.5rem;
                label {
                    font-weight: normal;
                    margin: 0 0.5rem 0.3rem;
                }
            }

            .form-btn-wrap {
                margin-top: 2rem;
                button {
                    @include border-radius($radius);
                    font-size: $fs-14;
                    padding: 1.1rem 1.5rem;
                    cursor: pointer;
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                }

                button.default {
                    border: 1px solid $gray-300;
                    background-color: $white;
                    color: $gray-300;
                }
            }

            .attachment-list-container {
                min-height: 300px;
                padding: 0 1rem 0.5rem;
                box-sizing: border-box;

                .attachment-list {
                    background: $white;
                    margin-bottom: 0.6rem;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                    box-sizing: border-box;
                    @include border-radius(10px);
                    padding: 1rem 1rem;
                    position: relative;
                    width: 100%;
                    color: $gray-200;
                    text-decoration: none;
                    cursor: pointer;

                    .ms-Button--icon {
                        color: $gray-300;
                    }

                    .attach-icon {
                        background: url(app/static/icons/image-icon.svg) no-repeat;
                        display: inline-block;
                        width: 27px;
                        height: 20px;

                        margin-right: 15px;
                    }

                    .ms-Button {
                        position: absolute;
                        right: 10px;
                    }

                    .file-txt {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            .error-message {
                span {
                    color: $red-200;
                    font-size: $fs-14;
                }
            }
        }

        .dropzone-container {
            &.list-view {
                .attached-file {
                    box-shadow: none;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                    .ms-Button--icon {
                        color: $gray-300;
                    }
                }
            }
        }
    }

    .share-request-success {
        .modal-head {
            display: flex;
            align-items: center;
            padding: 1rem 0.8rem;
            background-color: $white;
            color: $white;
            justify-content: center;
            position: relative;
            font-size: $fs-18;
            span {
                font-size: $fs-18;
            }

            .modal-close {
                position: absolute;
                right: 0.8rem;
                top: 0.5rem;
                color: $primary-color;
                &:hover {
                    background-color: unset;
                }
            }
        }
    }
}

.associate-option-container {       
    display: flex;
    justify-content: center;
    flex-wrap: nowrap !important;
    padding-top: 1.5rem;

    div.ao-left, div.ao-right {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        align-items: center;    
        background-color: $gray-650;
        border-radius: 8px;
        min-width: calc(55%);
    }

    div.ao-left {
        margin-right: 2rem;
    }

    image {
        width: 100%;
    }

    button {
        margin: 1rem;
        width: fit-content;
    }
    
    @media screen and (min-width: 900px) and (max-width: 1426px){
        .ao-left, .ao-right {
            min-width: calc(75%);
        }
    }

    @media screen and (max-width: 768px){
        .ao-left, .ao-right {
            min-width: 80%;
            overflow: hidden;
        }
    }
}