@import '../../../../assets/styles/css-assets';
.breadcrumb {
    font-size: $fs-16;
    margin: 0px;
    ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: flex;
        justify-content: left;
        li {
            padding-right: 13px;
            margin-right: 7px;
            position: relative;
            &:after {
                content: '';
                width: 5px;
                height: 8px;
                background: url(.././../../../static/icons/arrow-breadcrumb.svg);
                position: absolute;
                right: 0px;
                top: 8px;
            }
            &:last-child:after {
                display: none;
            }
            a {
                text-decoration: none;
                color: $gray-200;
                &:hover {
                    color: $primary-color;
                }
            }
            &.hide-arrow {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.schedule-to-publish {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 50px;
    .k-label {
        transform: scale(1) !important;
        font-size: $fs-14 !important;
    }
}

.more-links {
    .ms-Dropdown {
        margin-left: 10px;
        .ms-Dropdown-title {
            border: none;
            padding: 0px 5px;
            @include border-radius($radius);
        }
    }
    .ms-Dropdown-container {
        display: flex;
        align-items: flex-end;
    }
    .ms-Dropdown-caretDownWrapper {
        display: none;
    }
}

.notification-modal {
    .ms-Icon--WarningSolid {
        font-size: $fs-42;
        color: $red-100;
    }
}