@import '../../../assets/styles/variables.scss';

.admin-home-banner-container {
    background: url(app/static/surface_family_cropped.jpg) no-repeat;
    background-size: 100%;
    min-height: 25rem;
    width: 100%;
    position: relative;
    padding-top: 10.4%;
    z-index: 0;

    // default
    .admin-data-container {
        position: relative;
        z-index: 1;
        min-height: 20vh;
    }

    // Laptop screens < 24in
    @media screen and (min-device-width: 1000px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
        .admin-data-container {
            position: relative;
            z-index: 1;
            min-height: 50vh;
        }
    }

    // 24in - 32in Monitors
    @media only screen and (min-width: 1824px) {
        .admin-data-container {
            min-height: 57vh;
        }
    }

    // 32in monitor and greater
    @media only screen and (min-width: 3004px) {
        .admin-data-container {
            min-height: 65vh;
        }
    }

    .container-move-up {
        position: relative;
        top: -100px;
    }
}
