@import '../../../assets/styles/css-assets.scss';

.admin-summary-card-container {
    color: $gray-200;

    .row-flex-box {
        display: flex;
    }
    .align-center {
        align-items: center;
    }
    .calendar-head {
        display: flex;
        margin: 0.5rem 0 1rem;
        &.minimized {
            max-height: 0;
            overflow: hidden;
        }
        .title {
            font-size: $fs-18;
        }

        .cal-container {
            margin-left: auto;
            display: flex;

            .cal_icon {
                i {
                    color: $gray-100;
                }
            }

            .date-picker-block {
                display: flex;
                border: 1px solid $blue-700;
                @include border-radius($radius);
                overflow: hidden;

                .ms-DatePicker-event--without-label {
                    display: none;
                }

                .ms-DatePicker {
                    .ms-TextField-fieldGroup {
                        @include border-radius(0);
                        border: none;
                        width: 210px;
                        input {
                            &::placeholder {
                                color: $gray-300;
                            }
                        }
                    }

                    &:not(:last-child) {
                        .ms-TextField-fieldGroup {
                            border-right: 1px solid $blue-700 !important;
                        }
                    }
                }
            }

            .cal-tabs {
                @include border-radius(0.3125rem);
                overflow: hidden;
                border: 1px solid $blue-750;
                margin-left: 0.5rem;
                .alter-position {
                    position: relative;
                    top: 0.125rem;
                }
                .date-range {
                    border-left: 1px solid $blue-750;
                    padding: 0.2rem 0.6rem;
                    cursor: pointer;
                    box-sizing: border-box;
                    height: 100%;

                    &.active {
                        border-left: none;
                        color: $white;
                        background: $primary-color;
                        @include border-radius($radius/2);
                    }
                }
                .date-filters:first-child {
                    border-left: none;
                }
            }
        }
    }
    .cards-container {
        max-height: 350px;
        transition: max-height 0.5s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include border-radius($radius);

        .menu-card {
            min-height: 103px;
            @include border-radius($radius);
            border: 1px solid $blue-750;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $gray-200;
            cursor: pointer;
            width: 15%;
            height: 120px;
            .icon {
                display: inline-block;
                width: 2.5rem;
                height: 2.7rem;
            }
            .manage_request-icon {
                content: url(app/static/icons/manage-request-icon.svg);
                &.active {
                    content: url(app/static/icons/manage-request-active-icon.svg);
                }
            }
            .starred_request-icon {
                content: url(app/static/icons/starred-request-icon.svg);
                &.active {
                    content: url(app/static/icons/starred-request-active-icon.svg);
                }
            }
            .shared_request-icon {
                content: url(app/static/icons/shared-request-icon.svg);
                &.active {
                    content: url(app/static/icons/shared-request-active-icon.svg);
                }
            }
            .hidden_request-icon {
                content: url(app/static/icons/hidden-request-icon.svg);
                &.active {
                    content: url(app/static/icons/hidden-request-active-icon.svg);
                }
            }
            &.active {
                background: linear-gradient(260.63deg, #0078d4 5.91%, #3e9fe9 46.33%, #0078d4 94.09%);
                box-shadow: 0px 4px 20px rgba(0, 120, 212, 0.5);
                color: $white;
                animation: fadeIn 1s ease 0s 1 normal forwards;
            }

            .icon {
                font-size: $fs-32;
            }

            .mc-details {
                margin-left: 0;
                display: flex;
                align-items: flex-start;

                .count {
                    display: block;
                    font-size: $fs-32;
                    font-weight: 600;      
                    padding-left: 0.7rem; 
                }
            }

            .label {
                display: block;
                font: $fs-14;
                padding-top: 0.4rem;
            }
        }

        .lb-card {
            width: 32%;
            height: 300px;
            min-height: 103px;
            @include border-radius($radius);
            border: 1px solid $blue-750;
            color: $gray-200;
            padding: 1rem;
            padding-bottom: 0.25rem;

            &.card-expanded {
                height: fit-content;
                background-color: white;
                z-index: 99999;
                align-self: flex-start;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
            }

            .lb-card-header {
                .lb-card-active-icon {
                    color: #34CDA8;
                }

                .lb-card-menu {
                    float: right;
                }

                .ms-Button-menuIcon {
                    display: none;
                }
            }

            .lb-card-body {
                max-height: 200px;
                overflow-y: clip;

                &.card-expanded {
                    max-height: 40rem;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0.3rem;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: rgba($color: $gray-300, $alpha: 0.2);
                        @include border-radius(4px);
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: rgba($color: $gray-300, $alpha: 0.5);
                    }
                    &::-webkit-scrollbar-track {
                        @include border-radius(0);
                    }
                }

                .lb-card-folder-name {
                    display: flex;
                    color: #0078D4;

                    .lb-folder-icon {
                        margin-right: 1rem;
                        font-size: 20px;
                    }
    
                    .lb-card-folder-text {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .lb-card-no-items {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 150px;
                    justify-content: space-around;

                    .lb-card-welcome {
                        color: $blue-400;
                        font-weight: 500;
                    }
                }

                .lb-card-body-loading {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 150px;
                }

                .lb-list-container {
                    padding: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-top: 0.1rem;
            
                    .lb-card-list {
                        padding: 0.3rem;
                        display: flex;
                        border: 1px solid #C7E0F4;
                        border-radius: 8px;
                        cursor: pointer;
                        margin-top: 20px;
                        justify-content: center;
                        align-items: center;

                        &.active {
                            background: linear-gradient(260.63deg, #0078d4 5.91%, #3e9fe9 46.33%, #0078d4 94.09%);
                            box-shadow: 0px 4px 20px rgba(0, 120, 212, 0.5);
                            color: $white;
                            animation: fadeIn 1s ease 0s 1 normal forwards;
                        }

                        .lb-card-list-name {
                            width: 90%;
                        }

                        .lb-card-list-count {
                            font-weight: bold;
                        }

                        .lb-card-list-icon {
                            height: 20px;
                            margin-right: 1rem;
                        }
                    }

                    .lb-no-list {
                        cursor: default;
                        border: none;
                        margin-top: 20px;
                        padding: 0.3rem;
                        display: flex;
                        align-items: center;

                        .lb-card-no-content {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            .lb-card-footer {
                height: 40px;
                width: 100%;
                background-color: #FAF9F8;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                text-align: center;
                color: #0078D4;
                cursor: pointer;
                justify-content: center;
                display: flex;
                align-items: center;

                .lb-card-footer-icon {
                    font-size: 12px;
                    margin-left: 10px;
                }
            }
        }

        &.minimized {
            max-height: 0;
            overflow: hidden;
        }
    }
}
.cards-admin-toggle {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;

    button {
        display: flex;
        align-items: center;
        background-color: rgba($primary-color, 0.1);
        color: $primary-color;
        @include border-radius(44px);
        padding: 0.3125rem 2.75rem;
        box-sizing: border-box;
        i {
            font-weight: 600;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
