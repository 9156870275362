@import '../../assets/styles/css-assets.scss';

.add-attachment-request-modal {
    @include border-radius($radius);
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-width: 60%;
    max-width: 60%;

    .ms-MessageBar {
        @include border-radius($radius);
    }

    .modal-head {
        display: flex;
        align-items: center;
        padding: 1rem 0.8rem;
        background-color: $blue-400;
        color: $white;
        justify-content: center;
        position: relative;
        font-size: $fs-18;
        span {
            font-size: $fs-18;
        }

        .modal-close {
            position: absolute;
            right: 0.8rem;
            color: $white;
            &:hover {
                background-color: unset;
            }
        }
    }

    .add-attachment-modal-body {
        .scroll-panel {
            height: 100%;
            max-height: 20rem;
            overflow-y: auto;
            padding: 0 0.3rem;
            &::-webkit-scrollbar {
                width: 0.3rem;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba($color: $gray-300, $alpha: 0.4);
                @include border-radius(4px);
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $gray-300, $alpha: 0.6);
            }
            &::-webkit-scrollbar-track {
                @include border-radius(0);
            }
        }
        .dp-header {
            background-color: $gray-800;
            padding: 1rem 1.5rem 1.5rem;

            .cust-details {
                font-size: $fs-14;
                margin: 0.5rem 0;

                .secondary-text {
                    line-height: 20px;
                    border-right: 1px solid $gray-400;
                    padding-right: 15px;
                    color: $gray-200;
                    padding-left: 15px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        border: 0;
                    }
                    .text-bold {
                        font-weight: 600;
                        color: $gray-100;
                    }
                }
            }

            .dp-head-title {
                margin-right: 0.5rem;
                font-weight: 600;
            }

            .dp-head-desc {
                margin: 1rem 0 0.5rem;
                .title-field {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 1.25rem;
                    max-height: 2rem;
                    -webkit-line-clamp: 1; /* Write the number of
                                              lines you want to be
                                              displayed */
                    -webkit-box-orient: vertical;
                }
                span {
                    font-size: $fs-18;
                    font-weight: 600;
                }
            }
            .ms-Link {
                text-decoration: underline;
            }
        }

        .dp-content {
            padding: 1rem 2rem 2rem;
            box-sizing: border-box;
            .no-attachments-cont {
                padding: 2rem 1rem;
                display: flex;
                border: 2px dashed $blue-700;
                justify-content: center;
                margin: 2rem 0;
                @include border-radius(8px);
                span {
                    font-weight: 600;
                    font-size: $fs-26;
                    color: $gray-300;
                }
            }
            .text-field-wrap {
                .ms-BasePicker-text {
                    @include border-radius($radius);
                    border-color: $gray-200;
                    &::after {
                        @include border-radius($radius);
                    }
                }

                .ms-TextField {
                    .ms-TextField-fieldGroup {
                        @include border-radius($radius);

                        &::after {
                            @include border-radius($radius);
                        }
                    }
                }

                .ms-TagItem {
                    background-color: $blue-800;
                    @include border-radius($radius);

                    &.is-selected {
                        background-color: $blue-400;
                    }
                }

                label {
                    font-weight: 600;
                    margin-left: 1rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                input {
                    &::placeholder {
                        color: $gray-400;
                    }
                }
            }

            .icon-link {
                display: flex;
                align-items: center;
                color: $gray-200;

                .ms-Icon {
                    margin-right: 0.3rem;
                }

                &.active {
                    color: $primary-color;
                }
            }

            .attachment-container {
                margin-top: 1.5rem;
                label {
                    font-weight: normal;
                    margin: 0 0.5rem 0.3rem;
                }
            }

            .form-btn-wrap {
                margin-top: 2rem;
                .primary {
                    background: $primary-color;
                    color: $white;
                }
                button {
                    @include border-radius($radius);
                    font-size: $fs-14;
                    padding: 1.1rem 1.5rem;
                    cursor: pointer;
                }

                button.primary {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $white;
                }

                button.default {
                    border: 1px solid $gray-300;
                    background-color: $white;
                    color: $gray-300;
                }

                .upload-attachment-spinner {
                    display: flex;
                    align-items: center;
                    .upload-attachment-spinner-text {
                        margin: 1rem;
                    }
                }
            }

            .attachment-list-container {
                min-height: 300px;
                padding: 0 1rem 0.5rem;
                box-sizing: border-box;
                .attachment-list {
                    display: flex;
                    background: $white;
                    margin-bottom: 0.6rem;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                    box-sizing: border-box;
                    @include border-radius(10px);
                    padding: 1rem 1rem;
                    position: relative;
                    width: 100%;
                    color: $gray-200;
                    text-decoration: none;
                    cursor: pointer;

                    .ms-Button--icon {
                        color: $gray-300;
                    }
                    .attach-icon {
                        background: url(app/static/icons/image-icon.svg) no-repeat;
                        display: inline-block;
                        width: 27px;
                        height: 20px;

                        margin-right: 15px;
                    }
                    .icon {
                        width: 30px;
                        height: 22px;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-position: left top;
                        &.icon {
                            &--png {
                                background: url(app/static/icons/png.svg) no-repeat;
                            }
                            &--jpg {
                                background: url(app/static/icons/jpg.svg) no-repeat;
                            }
                            &--ppt {
                                background: url(app/static/icons/ppt.svg) no-repeat;
                            }
                            &--doc {
                                background: url(app/static/icons/doc.svg) no-repeat;
                            }
                            &--mp3 {
                                background: url(app/static/icons/mp3.svg) no-repeat;
                            }
                            &--pdf {
                                background: url(app/static/icons/pdf.svg) no-repeat;
                            }
                            &--xls {
                                background: url(app/static/icons/xls.svg) no-repeat;
                            }
                            &--avi {
                                background: url(app/static/icons/avi.svg) no-repeat;
                            }
                            &--txt {
                                background: url(app/static/icons/txt-file-icon.svg) no-repeat;
                            }
                            &--xml {
                                background: url(app/static/icons/xml.svg) no-repeat;
                            }
                            &--rar {
                                background: url(app/static/icons/rar.svg) no-repeat;
                            }
                            &--html {
                                background: url(app/static/icons/html.svg) no-repeat;
                            }
                            &--zip {
                                background: url(app/static/icons/zip.svg) no-repeat;
                            }
                            &--gif {
                                background: url(app/static/icons/gif.svg) no-repeat;
                            }
                            &--css {
                                background: url(app/static/icons/css.svg) no-repeat;
                            }
                            &--mp4 {
                                background: url(app/static/icons/mp4.svg) no-repeat;
                            }
                            &--default {
                                background: url(app/static/icons/default.svg) no-repeat;
                            }
                        }
                    }
                    .file-txt {
                        position: relative;
                        top: 1px;
                    }
                }

                .attachment-toggle-group {
                    display: flex;
                    align-items: center;
                    .ms-Toggle-background {
                        border-color: $white;
                        background-color: $gray-300;
                    }
                    .external-attachment {
                        margin-left: 0.5rem;
                    }
                    .internal-attachment {
                        margin-right: 0.5rem;
                    }
                    .attachment-classification-toggle {
                        margin: 0;
                        .ms-Toggle-thumb {
                            background-color: $white;
                        }
                    }
                }
                .attachment-center-group {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    overflow: hidden;
                }
                .attachment-file-group {
                    display: flex;
                    overflow: hidden;
                }
                .attachment-remove-group {
                    display: flex;
                }
                .attachment-row-divider {
                    height: 2rem;
                    margin: 0 1rem;
                }
            }

            .error-message {
                span {
                    color: $red-200;
                    font-size: $fs-14;
                }
            }
        }

        .dropzone-container {
            &.list-view {
                .attached-file {
                    box-shadow: none;
                    border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
                    .ms-Button--icon {
                        color: $gray-300;
                    }
                }
            }
        }
    }

    .share-request-success {
        .modal-head {
            display: flex;
            align-items: center;
            padding: 1rem 0.8rem;
            background-color: $white;
            color: $white;
            justify-content: center;
            position: relative;
            font-size: $fs-18;
            span {
                font-size: $fs-18;
            }

            .modal-close {
                position: absolute;
                right: 0.8rem;
                top: 0.5rem;
                color: $primary-color;
                &:hover {
                    background-color: unset;
                }
            }
        }
    }
}
