@import '../../assets/styles/css-assets.scss';

.cmt-container {
    .ms-Shimmer-container {
        margin-bottom: 0.5rem;
    }

    .tagged-user {
        color: $primary-color;
        font-size: $fs-14;
        font-weight: 600;
    }

    .cmt-count {
        margin-bottom: 1rem;
    }

    .cmt-block {
        position: relative;

        .cmt-contents {
            display: flex;
            position: relative;
            box-sizing: border-box;

            .cmt-wrap {
                padding-bottom: 1rem;
                box-sizing: border-box;
                width: 100%;
            }
            .reply-wrap {
                padding-bottom: 1rem;
                box-sizing: border-box;
            }

            .cmt-emoticon {
                position: relative;

                .emoji-dialog-space {
                    position: absolute;
                    bottom: 1.3rem;
                    height: 3.5rem;
                    background: transparent;
                    width: 14.8rem;
                }

                .emoji-suggestions {
                    width: fit-content;
                    display: flex;
                    position: absolute;
                    bottom: 1.9rem;
                    border: 1px solid #0078D4;
                    border-radius: 3px;
                    padding: 0.25rem;
                    margin-top: 5px;
                    box-shadow: -3px 1px 18px -4px #0078D4;
                    background: white;
                    z-index: 10;
        
                    button {
                        border: none;
                        background-color: white;
                        font-size: 24px;
                    }
        
                    button:hover {
                        background: none;
                        transform: scale(1.3);
                    }
                }
        
                .emoji-suggestions:after,
                .emoji-suggestions:before {
                    content: '';
                    display: block;
                    position: absolute;
                    //top: 2.23rem;
                    top: 40px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                }
        
                .emoji-suggestions:after {
                    border-color: white transparent transparent transparent;
                    border-width: 12px;
                }
        
                .emoji-suggestions:before {
                    //top: 2.27rem;
                    top: 40.5px;
                    border-color: #0078D4 transparent transparent transparent;
                    border-width: 12px;
                }

                .cmt-emote {
                    .emote-wrap {
                        display: flex;
                        width: max-content;
    
                        .emote-reaction {
                            font-size: 13px;
                            color: #0078D4;
                            text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.25)
                        }
                    }
    
                    .emote-reaction {
                        padding: 0.25rem;
                    }

                    .emote-reaction:hover {
                        transform: scale(1.3);
                    }
    
                    .emote-users {
                        margin-top: 0.5rem;
                        border-radius: 5px;
                        box-shadow: -3px 1px 18px -4px rgba(0, 0, 0, 0.5);
                        padding: 0.5rem;
                        position: absolute !important;
                        background: white !important;
                        z-index: 10000000;
    
                        .reaction-title {
                            font-size: 14px;
                            margin-bottom: 1rem;
                        }
                    }
    
                    .emote-user-item {
                        display: flex;
                        width: max-content;
                    }

                    .emote-user-item:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }
            }

            .cmt-connector {
                border-left: 1px solid $blue-700;
                width: 1px;
                position: relative;
                left: 1rem;
                top: 0;
            }

            .cmt-details {
                .cmt-usr-name {
                    font-weight: 600;
                    margin-right: 0.5rem;
                }
                .cmt-text {
                    font-weight: normal;
                    font-size: $fs-14;
                }
            }

            .cmt-action {
                display: flex;
                align-items: center;
                font-size: $fs-12;
                margin-top: 0.3rem;
                margin-bottom: 1rem;

                .action-items {
                    margin-right: 0.5rem;
                    i {
                        font-size: $fs-10;
                    }
                }

                .ms-Link {
                    color: $primary-color;
                    text-decoration: underline;
                }

                span {
                    font-size: $fs-12;
                }
            }

            .cmt-action-links {
                font-size: $fs-12;
                margin-top: 0.3rem;

                .ms-Link {
                    color: $gray-200;
                    color: $primary-color;
                    text-decoration: underline;
                }
            }

            .reply-container {
                margin-top: 1rem;

                .reply-wrap {
                    display: flex;
                }

                .reply-block {
                    margin-bottom: 1rem;

                    .reply-details {
                        display: flex;
                        align-items: center;

                        .reply-usr-name {
                            font-weight: 600;
                            margin-right: 0.5rem;

                            .reply-text {
                                font-weight: normal;
                            }
                        }
                    }

                    .mentioned-user {
                        color: #0078d4;
                    }

                    .reply-action {
                        display: flex;
                        align-items: center;
                        font-size: $fs-12;
                        margin-top: 0.3rem;
                        margin-left: 3.5rem;

                        .action-items {
                            margin-right: 0.5rem;
                            i {
                                font-size: $fs-10;
                            }
                        }

                        span {
                            font-size: $fs-12;
                        }
                        .ms-Link {
                            color: $primary-color;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        &:last-child {
            .cmt-connector {
                border: none;
            }
        }

        .cmt-contents:hover:not(last-child) {
            background-color: #EFF6FC;
            padding: 0.5rem;
            padding-bottom: 0;
            border-radius: 8px;
            margin-bottom: 0.5rem;

            .cmt-connector {
                border: none;
            }
        }

        .reply-condensed {
            box-sizing: border-box;

            .reply-details {
                display: flex;
                padding-top: 0.5rem;
                box-sizing: border-box;

                $start: 2;
                $end: 2;
                $val: 1;
                .reply-persona {
                    display: flex;
                    position: relative;

                    .ms-Persona {
                        display: inline;
                        position: relative;
                        display: none;

                        .ms-Persona-coin {
                            border: 2px solid $white;
                            @include border-radius(50%);
                        }

                        &:nth-child(1) {
                            display: inline;
                        }
                        @while $start <= $end {
                            &:nth-child(#{$start}) {
                                left: -#{$val}rem;
                                display: inline;
                            }
                            $start: $start + 1;
                            $val: $val + 1;
                        }
                    }
                }
                .reply-link {
                    display: inline;
                    position: relative;
                    left: -#{$val - 1.5}rem;
                    align-self: flex-end;
                    font-size: $fs-12;
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
        }
    }
}
