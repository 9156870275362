@import '../../../assets/styles/css-assets';
.content-area {
    .people-picker-cont .picker-combo-box {
        margin-top: 0px;
        border: 1px solid #e1dfdd;
        box-sizing: border-box;
        padding: 2px;
    }
    label.ms-Label {
        min-width: 80px;
    }
    .subject {
        font-size: $fs-14;
        line-height: $fs-28;
        color: $gray-100;
        width: 100%;
        border: 1px solid $gray-500;
        box-sizing: border-box;
        padding: 5px 10px;
        @include border-radius($radius);
    }
}