@import '../../../../assets/styles/css-assets';

.training-articles {
    padding: 0 0 15px 0px;
    .container {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        .newsfeed-article-content {
            padding-top: 0px;
        }
        .training-article-details {
            width: 70%;
        }
        .training-video-container {
            padding-right: 37px;
            border-right: 1px solid #e1dfdd;
            .react-player {
                background-color: $gray-100;
                iframe{
                    width: 100%;
                    height:100%;
                }
            }
        }
        .training-article-sidebar {
            width: 30%;
            .other-training-videos {
                @include border-radius($radius);
                margin-bottom: 50px;
                h4.title {
                    font-size: $fs-20;
                    font-weight: 400;
                    line-height: $fs-24;
                    margin: 0 8px 20px 8px;
                }
                .training-videos-list {
                    .slick-slider {
                        .slick-track {
                            height: auto !important;
                        }
                        .slick-prev,
                        .slick-next {
                            right: 0px !important;
                            top: -40px;
                            width: 20px;
                            height: 20px;
                            transform: rotate(90deg);
                            transform-origin: center;
                            background-image: url(../../../../static/icons/arrow-white.svg);
                            background-color: $primary-color;
                            background-repeat: no-repeat;
                            background-size: 6px;
                            background-position: center;
                            border-radius: 50%;

                            &:before {
                                color: $primary-color;
                                opacity: 1;
                                font-size: $fs-24;
                                display: none;
                            }
                            &.slick-disabled {
                                border: 1px solid $gray-300;
                                background-image: url(../../../../static/icons/arrow-gray.svg);
                                background-color: transparent;
                            }
                        }
                        .slick-prev {
                            left: inherit;
                            right: 25px !important;
                            transform: rotate(-90deg);
                        }
                    }
                }
                .item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 12px;
                    transition: all 0.4s ease-in-out;
                    @include border-radius($radius);
                    margin-bottom: 10px;
                    cursor: pointer;
                    background-color: $white;
                    box-sizing: border-box;
                    border: 1px solid $gray-600;
                    width: calc(100% - 2px) !important;

                    &:hover {
                        background-color: rgba($primary-color, 0.09);
                    }
                    button.ms-Link {
                        display: flex;
                        gap: 16px;
                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                    &.active {
                        background-color: rgba($primary-color, 0.09);
                        transition: none !important;
                        &:hover {
                            transform: none;
                        }
                    }
                    .video-thumb {
                        position: relative;
                        display: flex;
                        width: 120px;
                        @include border-radius($radius);
                        height: 57px;
                        background-color: $gray-600;
                        background-size: cover;
                        background-position: center;

                        .video-thumb {
                            border: 1px solid $gray-600;
                        }
                        .play-button {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            overflow: hidden;
                            width: 25px;
                            height: 25px;
                            @include border-radius(50%);
                            .ms-Icon {
                                font-size: 30px;
                                transform: translate(-50%, -50%);
                                left: 62%;
                                top: 50%;
                                position: relative;
                                color: $white;
                                opacity: 0.9;
                            }
                        }
                    }
                    .details {
                        flex-grow: 1;
                        font-size: $fs-12;
                        color: $gray-200;
                        h5 {
                            margin: 0px 0 5px;
                            font-size: $fs-14;
                            font-weight: 600;
                            color: $primary-color;
                            text-decoration: none;
                        }
                        p {
                            margin: 0 0 10px;
                            color: $gray-100;
                            text-decoration: none;
                            &:last-child {
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
