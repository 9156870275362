@import '../../assets/styles/css-assets.scss';

.aging-progress-container {
    .aging-progress-wrap {
        @include border-radius(1.2rem);
        min-width: 2rem;
        min-height: 0.5rem;
        display: inline-flex;
        overflow: hidden;

        .aging-progress {
            display: inline-block;
        }
    }

    .aging-type-a {
        background-color: rgba($color: $green-400, $alpha: 0.5);
        .aging-progress {
            background-color: $green-400;
        }
    }
    .aging-type-b {
        background-color: rgba($color: $yellow-100, $alpha: 0.5);
        .aging-progress {
            background-color: $yellow-100;
        }
    }
    .aging-type-c {
        background-color: rgba($color: $red-200, $alpha: 0.5);
        .aging-progress {
            background-color: $red-200;
        }
    }
    .aging-type-d {
        background-color: rgba($color: $gray-200, $alpha: 0.5);
        .aging-progress {
            background-color: $gray-200;
        }
    }
}
