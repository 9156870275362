.x-btn-styles {
    width: 98%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.x-btn-styles:hover {
    cursor: pointer;
}

.saveIcon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eff6fc;
    border-radius: 50%;
    margin-bottom: 15px;
}

.saved-success {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* Theme / Primary */

    color: #0078d4;
    margin-bottom: 20px;
}

.list-name {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #000000;
}

.saved-container {
    height: 40px;
}
