@import 'app/assets/styles/css-assets';
@import '../animations';

.disableSearch {
    display:none;
}

.regular-text-field-search-customer {
    .ms-TextField-fieldGroup {
        padding: 0 15px;
        height: 45px;
        width: 800px;
        
        input {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    height: 45px;
}
.search-MSX-cont {
    
    background: $white;
    @include border-radius($radius);
    &:focus-within {
        animation: mymove 2s infinite;
    }
    .ms-TextField-fieldGroup {
        padding: 0 15px;
        border: hidden !important;
        min-height: 45px;
        input {
            &::placeholder {
                color: $gray-400;
            }
        }
        &::after {
            border: hidden;
        }
    }
    .active {
        .ms-TextField-fieldGroup {
            border-radius: 8px 8px 0 0 !important;
            border: hidden !important;
            &::after {
                border-radius: 8px 8px 0 0 !important;

                border: hidden;
                animation: none;
            }
            &:focus-within {
                border: none;
                animation: none;
            }
        }
    }

    .hide {
        display: none;
    }

    .show {
        display: block;
    }

    .add-cust-link {
        display: flex;
        width: 120px;
        justify-content: center;
        align-items: center;
    }

    .seperator-bar-margins {
        margin-top: -20px;
        font-weight: bolder;
    }
    .suggest-cont-box {
        background: $white;
        padding: 0 10px;
        border-radius: 0 0 10px 10px;
        margin: 0 10px;
        border-top: 1px solid $blue-700;
        .suggestion-heading {
            span {
                color: $gray-100;
                font-weight: 600;
            }
        }
        .suggestion-item {
            padding-left: 10px;
            padding-top: -20px;
            cursor: pointer;
            &:hover {
                background: $gray-600;
            }
        }
    }
}
